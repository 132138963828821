import React from 'react'
import Typography from '@mui/material/Typography'
import { COLORS } from '../utils/colors'
import { formatToHelsinkiDateTime, formatDateToFinnishFormat } from '../utils/transforms'

const isTextSame = (prevProps, nextProps) => {
  /*
  return true if passing nextProps.value to render would return
  the same result as passing prevProps.value to render,
  otherwise return false
  */
  return prevProps.text === nextProps.text
}
const styles = {
  caption: {
    color: COLORS.label
  }
}
// Use memoization to avoid unnecessary re-renderings (text not changed)
const DateText = React.memo((props) => {
  const styleObject = props.style ? props.style : styles.caption
  return (
    <Typography variant='caption' style={styleObject}>{formatDateToFinnishFormat(formatToHelsinkiDateTime(props.text))}</Typography>
  )
}, isTextSame)
export default DateText
