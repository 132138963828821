import { call, put } from 'redux-saga/effects'
import * as MeetingsActions from '../redux/MeetingsRedux'
import * as ApplicantsActions from '../redux/ApplicantsRedux'
import * as ErrorActions from '../redux/ErrorRedux'
import * as SuccessActions from '../redux/SuccessRedux'
import * as SavingActions from '../redux/SavingRedux'
import * as CoachingEntriesActions from '../redux/CoachingEntriesRedux'
import { composeSuccess, getDateTimeForApi } from '../utils/transforms'
import { isOkResponse } from '../services/api'
import { isMeetingEntry } from '../utils/entries'

export function * addCoachingEntry (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, newEntry } = action.payload
  try {
    let params = null
    let response = null
    if (isMeetingEntry(newEntry.entryType)) {
      /* Meeting response */
      params = {
        start: getDateTimeForApi(newEntry.start),
        end: getDateTimeForApi(newEntry.end),
        description: newEntry.description,
        talent: newEntry.talent,
        coach: newEntry.coach
      }
      response = yield call(api.createMeeting, projectId, params)
    } else {
      /* Regular entry response */
      params = {
        entryDateTime: getDateTimeForApi(newEntry.start),
        entryType: newEntry.entryType,
        description: newEntry.description,
        coachingId: newEntry.coachingId,
        coachId: newEntry.coach
      }
      response = yield call(api.addCoachingEntry, projectId, params)
    }

    if (isOkResponse(response)) {
      const success = composeSuccess('entry_created')
      yield put(SuccessActions.displaySuccess(success))
      yield put(CoachingEntriesActions.addCoachingEntrySuccess())
      yield put(SavingActions.setSaving(false))
      // Get applicants in case next meeting edited and also meetings with stateful filters
      if (isMeetingEntry(newEntry.entryType)) {
        yield put(ApplicantsActions.getApplicants())
        yield put(MeetingsActions.getMeetings())
      }
      yield put(CoachingEntriesActions.getCoachingEntries({ coachingId: newEntry.coachingId }))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(CoachingEntriesActions.addCoachingEntryFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(CoachingEntriesActions.addCoachingEntryFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * getCoachingEntrySummaryText (api, action) {
  const { coachingId } = action.payload
  try {
    const response = yield call(api.getCoachingEntrySummaryText, coachingId)
    if (isOkResponse(response)) {
      const summary = response.data
      yield put(CoachingEntriesActions.getCoachingEntrySummaryTextSuccess(summary))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(CoachingEntriesActions.getCoachingEntrySummaryTextFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(CoachingEntriesActions.getCoachingEntrySummaryTextFailure())
  }
}

export function * getCoachingEntries (api, action) {
  const { params } = action.payload
  try {
    const response = yield call(api.getCoachingEntries, params)
    if (isOkResponse(response)) {
      const coachingEntries = response.data
      yield put(CoachingEntriesActions.getCoachingEntriesSuccess(coachingEntries))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(CoachingEntriesActions.getCoachingEntriesFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(CoachingEntriesActions.getCoachingEntriesFailure())
  }
}

export function * editCoachingEntry (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, coachingEntryId, body } = action.payload
  try {
    const params = {
      entryDateTime: getDateTimeForApi(body.start),
      entryType: body.entryType,
      description: body.description,
      coachId: body.coach
    }
    const response = yield call(api.editCoachingEntry, projectId, coachingEntryId, params)
    if (isOkResponse(response)) {
      const success = composeSuccess('entry_edited')
      yield put(SuccessActions.displaySuccess(success))
      const coachingEntries = response.data
      yield put(CoachingEntriesActions.editCoachingEntrySuccess(coachingEntries))
      yield put(SavingActions.setSaving(false))
      yield put(CoachingEntriesActions.getCoachingEntries({ coachingId: body.coachingId }))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(CoachingEntriesActions.editCoachingEntryFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(CoachingEntriesActions.editCoachingEntryFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * deleteCoachingEntry (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, coachingEntryId, coachingId } = action.payload
  try {
    const response = yield call(api.deleteCoachingEntry, projectId, coachingEntryId)
    if (isOkResponse(response)) {
      const success = composeSuccess('entry_deleted')
      yield put(SuccessActions.displaySuccess(success))
      yield put(CoachingEntriesActions.deleteCoachingEntrySuccess())
      yield put(SavingActions.setSaving(false))
      yield put(CoachingEntriesActions.getCoachingEntries({ coachingId }))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(CoachingEntriesActions.deleteCoachingEntryFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(CoachingEntriesActions.deleteCoachingEntryFailure())
    yield put(SavingActions.setSaving(false))
  }
}
