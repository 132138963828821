import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Checkbox, FormControlLabel, TextField, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import Autocomplete from '../Autocomplete'
import EntryTypeSelect from './EntryTypeSelect'
import { COLORS } from '../../utils/colors'
import { getLocale, getCoachOptions } from '../../utils/transforms'
import { meeting as meetingConstant } from '../../utils/entries'
import { customReducer } from '../../utils/reducer'

const styles = {
  content: {
    padding: '16px 24px',
    display: 'flex'
  },
  calendar: {
    width: 312,
    display: 'flex',
    border: `1px solid ${COLORS.lightBorder}`
  },
  actionsHolder: {
    justifyContent: 'space-between'
  },
  redColor: {
    color: COLORS.red
  },
  cancelMeeting: {
    color: COLORS.white
  },
  marginRight: {
    marginRight: 10
  },
  spinner: {
    color: COLORS.white
  }
}

const EditMeetingForm = (props) => {
  const [t] = useTranslation()
  const { applicant, entry, loading } = props
  const initialState = {
    editFormAnimation: { enter: 0, exit: 250 },
    slideLeft: false,
    startCalendarOpen: false,
    endCalendarOpen: false,
    coach: '',
    start: null,
    end: null,
    description: '',
    meetingId: '',
    projectId: applicant ? applicant.projectId : '',
    cancelReason: '',
    cancelledByTalent: false,
    coachingId: applicant ? applicant.coachingId : ''
  }
  const reducer = (state, newState) => customReducer(state, newState, initialState)
  const [state, setState] = useReducer(reducer, initialState)

  const handleResetState = () => {
    setState({
      coach: entry.coachId,
      start: new Date(entry.start),
      end: getEndMeetingDate(),
      description: entry.description,
      meetingId: entry.id
    })
  }

  useEffect(() => {
    if (entry) {
      handleResetState()
    }
  }, [entry])

  const handleStartCalendarOpen = (e) => {
    setState({ startCalendarOpen: !state.startCalendarOpen })
  }

  const handleEndCalendarOpen = (e) => {
    setState({ endCalendarOpen: !state.endCalendarOpen })
  }

  const handleNewMeetingStartChange = (val) => {
    setState({ start: new Date(val) })
  }

  const handleNewMeetingEndChange = (val) => {
    setState({ end: new Date(val) })
  }

  const handleCoachChange = (val) => {
    setState({ coach: val })
  }

  const handleDescriptionChange = (event) => {
    setState({ description: event.target.value })
  }

  const handleApplicantCancelledChange = (event) => {
    setState({ cancelledByTalent: !state.cancelledByTalent })
  }

  const handleReasonChange = (event) => {
    setState({ cancelReason: event.target.value })
  }

  const toggleMeetingCancel = () => {
    setState({ slideLeft: !state.slideLeft })
    if (state.editFormAnimation.enter === 0) {
      setState({ editFormAnimation: { enter: 250, exit: 250 } })
    }
  }

  const handleSave = () => {
    if (!loading) props.onEditMeeting(state)
  }

  const handleCancelMeeting = () => {
    if (!loading) {
      props.onCancelMeeting(state)
      toggleMeetingCancel()
    }
  }

  const getEndMeetingDate = () => {
    if (entry) {
      if (entry.end) return new Date(entry.end)
      else return null
    } else return null
  }

  const getCoaches = () => applicant ? getCoachOptions(applicant.coachOptions) : []

  const getName = () => applicant ? `${applicant.firstNames} ${applicant.lastName}` : ''

  const validateEditForm = () => state.coach === '' || state.start == null || state.end == null

  const renderLoaderButtonContent = (text) => {
    if (loading) {
      return <CircularProgress style={styles.spinner} size={16} />
    }
    return t(text)
  }

  const renderEditButtons = () => {
    const cancelButtonText = entry.isCancelled ? t('cancelled') : t('cancel_meeting')
    return (
      <DialogActions sx={styles.actionsHolder}>
        <div>
          <Button onClick={toggleMeetingCancel} sx={styles.redColor} disabled={entry.isCancelled || props.loading}>
            {t(cancelButtonText)}
          </Button>
        </div>
        <div>
          <Button onClick={handleResetState} sx={styles.marginRight}>
            {t('cancel')}
          </Button>
          <Button
            onClick={handleSave}
            variant='contained'
            color='primary'
            style={styles.sendButton}
            disabled={validateEditForm()}
          >
            {renderLoaderButtonContent('save')}
          </Button>
        </div>
      </DialogActions>
    )
  }

  const renderCancelButtons = () => {
    return (
      <DialogActions style={styles.actionsHolder}>
        <div>
          <Button onClick={toggleMeetingCancel} startIcon={<ArrowBackIcon />}>
            {t('back')}
          </Button>
        </div>
        <div>
          <Button
            onClick={handleCancelMeeting}
            variant='contained'
            style={styles.cancelMeeting}
            disabled={state.cancelReason === ''}
          >
            {t('cancel_meeting')}
          </Button>
        </div>
      </DialogActions>
    )
  }

  return (
    <Box>
      <Slide direction='right' in={!state.slideLeft} timeout={state.editFormAnimation} unmountOnExit>
        <form autoComplete='off'>
          <EntryTypeSelect
            value={meetingConstant}
            disabled
          />
          <TextField
            value={getName()}
            label={t('applicant')}
            disabled
            margin='normal'
            fullWidth
            variant='standard'
          />
          <Autocomplete
            options={getCoaches()}
            value={state.coach}
            onChange={handleCoachChange}
            placeholder={`${t('select_trainer')} *`}
            noOptionsMessage={() => t('no_trainers_available')}
            margin='normal'
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
            <DatePicker
              value={state.start}
              open={state.startCalendarOpen}
              onOpen={handleStartCalendarOpen}
              onClose={handleStartCalendarOpen}
              onChange={handleNewMeetingStartChange}
              inputFormat={t('datetime_format')}
              mask={t('datetime_format_mask')}
              invalidDateMessage={t('invalid_date_format')}
              margin='normal'
              ampm={false}
              minDate={!props.isAdmin && new Date()}
              renderInput={props => <TextField {...props} label={`${t('start_datetime')} *`} variant='standard' style={styles.marginRight} InputProps={{ ...props.InputProps, color: 'primary' }} inputProps={{ ...props.inputProps, color: 'primary' }} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
            <DatePicker
              value={state.end}
              open={state.endCalendarOpen}
              onOpen={handleEndCalendarOpen}
              onClose={handleEndCalendarOpen}
              onChange={handleNewMeetingEndChange}
              inputFormat={t('datetime_format')}
              cancelLabel={t('cancel')}
              style={styles.datePicker}
              invalidDateMessage={t('invalid_date_format')}
              margin='normal'
              ampm={false}
              renderInput={props => <TextField {...props} label={`${t('end_datetime')} *`} variant='standard' InputProps={{ ...props.InputProps, color: 'primary' }} inputProps={{ ...props.inputProps, color: 'primary' }} />}
            />
          </LocalizationProvider>
          <TextField
            value={state.description}
            onChange={handleDescriptionChange}
            label={t('description')}
            fullWidth
            multiline
            margin='normal'
            variant='standard'
          />
          {renderEditButtons()}
        </form>
      </Slide>
      <Slide direction='left' in={state.slideLeft} timeout={{ enter: 250, exit: 250 }} mountOnEnter unmountOnExit>
        <form autoComplete='off'>
          <TextField
            value={state.cancelReason}
            onChange={handleReasonChange}
            label={t('reason_for_canceling')}
            fullWidth
            multiline
            margin='normal'
            variant='standard'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.cancelledByTalent}
                onChange={handleApplicantCancelledChange}
                color='primary'
              />
            }
            label={t('cancelled_by_applicants_request')}
          />
          {renderCancelButtons()}
        </form>
      </Slide>
    </Box>
  )
}

export default EditMeetingForm
