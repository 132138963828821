import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  user: null,
  loading: false,
  error: null,
  admins: []
})

/* ------------- Types and Action Creators ------------- */

export const getMe = createAction('GET_ME')
export const getMeSuccess = createAction('GET_ME_SUCCESS', (user) => ({ user }))
export const getMeFailure = createAction('GET_ME_FAILURE', (error) => ({ error }))

export const getAdminsRequest = createAction('GET_ADMINS_REQUEST')
export const getAdminsSuccess = createAction('GET_ADMINS_SUCCESS', (admins) => ({ admins }))
export const getAdminsFailure = createAction('GET_ADMINS_FAILURE', (error) => ({ error }))

/* ------------- Reducers ------------- */

const reducers = createReducer(
  {
    [getMe]: (state) => state.merge({ loading: true }),
    [getMeSuccess]: (state, { user }) => state.merge({ loading: false, user }),
    [getMeFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [getAdminsRequest]: (state) => state.merge({ loading: true }),
    [getAdminsSuccess]: (state, { admins }) => state.merge({ loading: false, admins }),
    [getAdminsFailure]: (state, { error }) => state.merge({ loading: false, error })
  },
  INITIAL_STATE
)

export default reducers
