import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  notes: [],
  error: null,
  fetching: null
})

/* ------------- Types and Action Creators ------------- */

export const getNotesRequest = createAction('GET_NOTES_REQUEST', (applicantId) => ({ applicantId }))
export const getNotesSuccess = createAction('GET_NOTES_SUCCESS', (notes) => ({ notes }))
export const getNotesFailure = createAction('GET_NOTES_FAILURE', (error) => ({ error }))
export const activateNoteRequest = createAction('ACTIVATE_NOTE_REQUEST', (projectId, noteId, applicantId) => ({ projectId, noteId, applicantId }))
export const activateNoteSuccess = createAction('ACTIVATE_NOTE_SUCCESS')
export const activateNoteFailure = createAction('ACTIVATE_NOTE_FAILURE', (error) => ({ error }))
export const passivateNoteRequest = createAction('PASSIVATE_NOTE_REQUEST', (projectId, noteId, applicantId) => ({ projectId, noteId, applicantId }))
export const passivateNoteSuccess = createAction('PASSIVATE_NOTE_SUCCESS')
export const passivateNoteFailure = createAction('PASSIVATE_NOTE_FAILURE', (error) => ({ error }))
export const editNoteRequest = createAction('EDIT_NOTE_REQUEST', (note) => ({ note }))
export const editNoteSuccess = createAction('EDIT_NOTE_SUCCCES')
export const editNoteFailure = createAction('EDIT_NOTE_FAILURE', (error) => ({ error }))
export const addNoteRequest = createAction('ADD_NOTE_REQUEST', (note) => ({ note }))
export const addNoteSuccess = createAction('ADD_NOTE_SUCCESS')
export const addNoteFailure = createAction('ADD_NOTE_FAILURE', (error) => ({ error }))

/* ------------- Reducers ------------- */
const reducers = createReducer(
  {
    [getNotesRequest]: (state) => state.merge({ fetching: true }),
    [getNotesSuccess]: (state, { notes }) => state.merge({ fetching: false, notes }),
    [getNotesFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [activateNoteRequest]: (state) => state.merge({ fetching: true }),
    [activateNoteSuccess]: (state) => state.merge({ fetching: false, error: null }),
    [activateNoteFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [passivateNoteRequest]: (state) => state.merge({ fetching: true }),
    [passivateNoteSuccess]: (state) => state.merge({ fetching: false, error: null }),
    [passivateNoteFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [editNoteRequest]: (state) => state.merge({ fetching: true }),
    [editNoteSuccess]: (state) => state.merge({ fetching: false, error: null }),
    [editNoteFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [addNoteRequest]: (state) => state.merge({ fetching: true }),
    [addNoteSuccess]: (state) => state.merge({ fetching: false, error: null }),
    [addNoteFailure]: (state, { error }) => state.merge({ fetching: false, error })
  },
  INITIAL_STATE
)

export default reducers
