import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

const styles = {
  root: {
    maxWidth: 800
  },
  content: {
    padding: '16px 24px'
  }
}

const TabPanel = (props) => {
  const { children, value, index } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  rootClass: PropTypes.any,
  boxClass: PropTypes.any
}

TabPanel.defaultProps = {
  boxClass: {},
  rootClass: {}
}

export default withStyles(styles)(withTranslation()(TabPanel))
