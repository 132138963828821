import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, I18nextProvider } from 'react-i18next'
import withRoot from './utils/withRoot'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import ErrorNotification from './components/ErrorNotification'
import SuccessNotification from './components/SuccessNotification'
import Spinner from './components/Spinner'
import i18n from './i18n/i18n'
import routes from './routes'
import * as ErrorActions from './redux/ErrorRedux'
import * as SuccessActions from './redux/SuccessRedux'
import * as ApplicantsActions from './redux/ApplicantsRedux'
import Layout from './components/Layout'
import { isAdmin } from './utils/permissions'
import SavingDialog from './components/SavingDialog'

class App extends Component {
  componentDidMount () {
    // Execute start up functino when app mounts
    this.props.startup()
  }

  generateRoutes = () => routes.map((item, index) => {
    const { user } = this.props
    if (!item.forAdmins) return <Route key={index} path={item.path} exact={item.exact} element={item.component} />
    else {
      if (user != null && isAdmin(user)) {
        return (
          <Route
            key={index}
            path={item.path}
            exact={item.exact}
            element={item.component}
          />
        )
      } else {
        return (
          <Route
            key={index}
            path='/applicants'
          />
        )
      }
    }
  })

  renderErrorNotification = () => {
    const { error, dismissError } = this.props
    return <ErrorNotification type='error' error={error} onClose={dismissError} />
  }

  renderSuccessNotification = () => {
    const { success, dismissSuccess } = this.props
    return <SuccessNotification type='success' success={success} onClose={dismissSuccess} />
  }

  renderSavingDialog = () => {
    const { error, success, isSaving, dismissSuccess, dismissError, errorCode } = this.props
    return <SavingDialog error={error} errorCode={errorCode} success={success} isSaving={isSaving} dismissSuccess={dismissSuccess} dismissError={dismissError} />
  }

  render () {
    return (
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Layout user={this.props.user}>
            {this.props.user &&
              <Routes>
                {this.generateRoutes()}
                {/* Catch-all redirect for paths that don't match the ones specified in routes.js */}
              </Routes>}
            {!this.props.user &&
              <div className={{ margin: '0 auto' }}>
                <Spinner centered />
              </div>}
          </Layout>
        </BrowserRouter>
        {this.renderSavingDialog()}

      </I18nextProvider>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    error: state.errors.error,
    errorCode: state.errors.errorCode,
    success: state.success.success,
    user: state.user.user,
    isSaving: state.saving.isSaving
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dismissError: () => dispatch(ErrorActions.dismissError()),
    dismissSuccess: () => dispatch(SuccessActions.dismissSuccess()),
    startup: () => dispatch(ApplicantsActions.startup())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withTranslation()(App))
)
