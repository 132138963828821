import React from 'react'
import { Paper, MenuList, MenuItem } from '@mui/material'
import { withStyles } from '@mui/styles'
import { SHARE_SITES } from 'react-add-to-calendar-hoc'

const styles = theme => ({
  menu: {
    width: 200
  }
})
function CalendarIntegrationMenu (props) {
  const handleClick = (item) => {
    if (item.enum === SHARE_SITES.GOOGLE) {
      const win = window.open(item.url, '_blank')
      win.focus()
    } else {
      const filename = 'springer-tapaaminen.ics'
      const blob = new Blob([item.url], { type: 'text/calendar;charset=utf-8' })

      /****************************************************************
      // many browsers do not properly support downloading data URIs
      // (even with 'download' attribute in use) so this solution
      // ensures the event will download cross-browser
      ****************************************************************/
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
  return (
    <Paper className={props.classes.menu}>
      <MenuList>
        {props.items.map((item, idx) => {
          return <MenuItem key={`calendar-link-${idx}`} onClick={(e) => handleClick(item)}>{item.enum}</MenuItem>
        })}
      </MenuList>
    </Paper>
  )
}

export default withStyles(styles)(CalendarIntegrationMenu)
