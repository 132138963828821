import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { COLORS } from './colors'

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.green,
      contrastText: COLORS.white
    },
    secondary: {
      main: COLORS.white,
      contrastText: COLORS.black
    },
    error: {
      main: COLORS.red,
      contrastText: COLORS.white
    }
  },
  typography: {
    useNextVariants: true
  }
})

function withRoot (Component) {
  function WithRoot (props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    )
  }

  return WithRoot
}

export default withRoot
