import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import { COLORS } from '../../utils/colors'
import { getObjectNameTranslation } from '../../utils/transforms'

const styles = theme => ({
  root: {
    padding: 4,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500,
      margin: 20
    },
    margin: 10
  },
  title: {
    padding: '16px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  contentWrapper: {
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px'
    },
    height: 60
  },
  actionsWrapper: {
    margin: 0,
    padding: '6px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  sendButton: {
    [theme.breakpoints.up('sm')]: {
      margin: '10px 0px',
      marginLeft: 20
    },
    marginLeft: 15,
    width: 102
  },
  cancelButton: {
    color: COLORS.red
  },
  spinner: {
    color: COLORS.white
  },
  formControl: {
    width: '100%'
  }
})

class ChangeProjectDialog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      project: ''
    }
  }

  componentDidUpdate (nextProps) {
    if (this.props.fetching && !nextProps.fetching) {
      this.handleClose()
    }
  }

  getOptions = () => {
    const { projects, applicant } = this.props
    if (projects.length && applicant) {
      const options = projects.filter((project) => project.id !== applicant.projectId)
      return options.map((item) => (
        <MenuItem value={item.id} key={item.id}>
          {getObjectNameTranslation(item)}
        </MenuItem>
      ))
    }
  }

  handleSetProject = (e) => this.setState({ project: e.target.value })
  handleChangeProject = () => {
    const { project } = this.state
    const { projectId, userId } = this.props.applicant
    this.props.changeProject(projectId, userId, project)
  }

  handleClose = () => {
    this.props.close()
    this.setState({ project: '' })
  }

  renderButtonContent = () => {
    const { fetching, t, classes } = this.props
    if (fetching) {
      return <CircularProgress className={classes.spinner} size={16} />
    }
    return t('save')
  }

  render () {
    const { classes, t, open, close } = this.props
    const { project } = this.state
    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby='form-dialog-title'
        classes={{ paper: classes.root }}
        fullWidth
      >
        <DialogTitle id='form-dialog-title' classes={{ root: classes.title }}>
          {t('change_applicant_project')}
        </DialogTitle>
        <DialogContent classes={{ root: classes.contentWrapper }}>
          <form>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor='project' required>{t('select_new_project')}</InputLabel>
              <Select value={project} onChange={this.handleSetProject} input={<Input id='project' />}>
                {this.getOptions()}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions classes={{ root: classes.actionsWrapper }}>
          <Button onClick={this.handleClose} className={classes.cancelButton}>
            {t('cancel')}
          </Button>
          <Button
            onClick={this.handleChangeProject}
            variant='contained'
            color='primary'
            className={classes.sendButton}
            disabled={!project}
          >
            {this.renderButtonContent()}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(withTranslation()(ChangeProjectDialog))
