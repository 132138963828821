import { call, put, select } from 'redux-saga/effects'
import * as MessagesActions from '../redux/MessagesRedux'
import * as ErrorActions from '../redux/ErrorRedux'
import { isOkResponse } from '../services/api'
import { composeMessageFilters } from '../utils/transforms'

export function * getMessages (api, action) {
  const messagesState = (state) => state.messages
  const { page, pageSize, messageFilters } = yield select(messagesState)
  const apiPage = page - 1
  const filters = composeMessageFilters(messageFilters)
  const params = {
    ...filters,
    skip: apiPage * pageSize,
    take: pageSize
  }

  try {
    const response = yield call(api.getMessages, params)
    if (isOkResponse(response)) {
      const messages = response.data.list
      const messagesTotal = response.data.totalCount
      const allIds = response.data.allIds
      yield put(MessagesActions.getMessagesSuccess(messages, messagesTotal, allIds))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(MessagesActions.getMessagesFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(MessagesActions.getMessagesFailure())
  }
}

export function * setMessageFilters (api, action) {
  yield put(MessagesActions.getMessages())
}

export function * changeMessagesPage (api, action) {
  const { page } = action.payload
  const messagesState = (state) => state.messages
  const { messages, pageSize } = yield select(messagesState)
  if ((page * pageSize) >= messages.length) {
    yield put(MessagesActions.getMessages())
  }
}

export function * changeMessageRowsPerPage (action) {
  yield put(MessagesActions.reloadVisibleMessages())
}

export function * reloadVisibleMessages (api, action) {
  const messagesState = (state) => state.messages
  const { page, messageFilters, pageSize } = yield select(messagesState)
  const filters = composeMessageFilters(messageFilters)
  const params = {
    ...filters,
    skip: ((page - 1) * pageSize),
    take: pageSize
  }

  try {
    const response = yield call(api.getMessages, params)
    if (isOkResponse(response)) {
      const messages = response.data.list
      const messagesTotal = response.data.totalCount
      const allIds = response.data.allIds
      yield put(MessagesActions.reloadVisibleMessagesSuccess(messages, messagesTotal, allIds))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(MessagesActions.reloadVisibleMessagesFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(MessagesActions.reloadVisibleMessagesFailure())
  }
}
