import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import TextField from '@mui/material/TextField'

function CustomComponent (props) {
  const { component: Component, inputRef, ...other } = props

  // implement `InputElement` interface
  React.useImperativeHandle(inputRef, () => ({
    focus: () => {
      // logic to focus the rendered component from 3rd party belongs here
    }
    // hiding the value e.g. react-stripe-elements
  }))

  // `Component` will be your `SomeThirdPartyComponent` from below
  return <Component {...other} />
}

function SomeThirdPartyComponent (props) {
  // const fileInputRef = React.createRef()

  function handleFileInputChange () {
    props.fileInputChange(props.fileInputRef)
  }

  return (
    <input type='file' style={{ width: '100%' }} id='myFile' name='filename' ref={props.fileInputRef} onChange={handleFileInputChange} />
  )
}

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.
const FileUploadInput = forwardRef((props, ref) => {
  const fileInput = useRef()

  const handleFileInputChange = (fileInputRef) => {
    props.onFileChange(fileInputRef.current.files[0])
  }
  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({

    resetInput () {
      fileInput.current.value = ''
    }

  }))

  return (
    <TextField
      style={props.classes || {}}
      variant='standard'
      InputProps={{
        inputComponent: CustomComponent,
        inputProps: { component: SomeThirdPartyComponent, fileInputChange: handleFileInputChange, fileInputRef: fileInput }
      }}
    />
  )
})

export default FileUploadInput
