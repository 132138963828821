import { takeLatest, all } from 'redux-saga/effects'
import API from '../services/api'

/* ------------- Types ------------- */

import * as UsersActions from '../redux/UsersRedux'
import * as ApplicantsActions from '../redux/ApplicantsRedux'
import * as MessagesActions from '../redux/MessagesRedux'
import * as MeetingsActions from '../redux/MeetingsRedux'
import * as ServicesActions from '../redux/ServicesRedux'
import * as ProjectsActions from '../redux/ProjectsRedux'
import * as AreasActions from '../redux/AreasRedux'
import * as CoachesActions from '../redux/CoachesRedux'
import * as NotesActions from '../redux/NotesRedux'
import * as AttachmentsActions from '../redux/AttachmentsRedux'
import * as CoachingEntriesActions from '../redux/CoachingEntriesRedux'

/* ------------- Sagas ------------- */

import {
  getMe,
  getAdmins
} from './UsersSagas'
import {
  startup,
  getApplicants,
  setApplicantTrainer,
  setApplicantStartDate,
  changeApplicantsPage,
  changeApplicantRowsPerPage,
  sendMail,
  sendSms,
  endService,
  setApplicantFilters,
  getTalentStatuses,
  exportApplicantsToExcel,
  exportCertificateTemplateExcel,
  reloadVisibleApplicants,
  rollbackApplicant,
  changeProject,
  setApplicantInfo,
  setApplicantTargetOccupations,
  getApplicantInfo,
  getApplicantTargetOccupations,
  rollbackApplicantToStatus,
  importSpringerApplicantsFile,
  toggleSafeMode,
  importKoulutusporttiApplicantsFile,
  getApplicantLanguageSkills,
  saveApplicantLanguageSkills,
  deleteApplicantLanguageSkill,
  getCooperationTypes,
  createInternship,
  getApplicantInternships,
  editInternship,
  deleteInternship,
  startInitialAssessment,
  endInitialAssessment
} from './ApplicantsSagas'
import {
  getMessages,
  setMessageFilters,
  changeMessagesPage,
  changeMessageRowsPerPage,
  reloadVisibleMessages
} from './MessagesSagas'
import { getMeetings, getAllMeetings, createMeeting, editMeeting, cancelMeeting } from './MeetingsSagas'
import {
  getServices,
  setServiceFilters,
  changeServicesPage,
  changeServiceRowsPerPage,
  reloadVisibleServices,
  getServicesOptions,
  createService,
  reloadAllFetchedServices,
  editService
} from './ServicesSagas'
import {
  getProjectsOptions,
  getProjects,
  changeProjectsPage,
  changeProjectRowsPerPage,
  reloadVisibleProjects,
  createProject,
  editProject,
  reloadAllFetchedProjects,
  getProject,
  getProjectsInCity,
  getFilteredProjectsOptions
} from './ProjectsSagas'
import {
  getRegions,
  getFinlandRegionsWithCities,
  getRegionsWithCities,
  getAreas,
  searchPostalCodes,
  getCountries,
  getLanguages,
  getLanguageSkills,
  getLanguageSkillLevels
} from './AreasSagas'
import {
  addCoaches,
  removeCoach,
  addCoachingResult,
  removeCoachingResult,
  getCoachingResults,
  getCoaches
} from './CoachesSagas'
import {
  getNotes,
  activateNote,
  passivateNote,
  editNote,
  addNote
} from './NotesSagas'
import { addCoachingAttachment, deleteCoachingAttachment, getCoachingAttachments, addProjectAttachment, getProjectAttachments, deleteProjectAttachment } from './AttachmentsSagas'
import { addCoachingEntry, getCoachingEntries, getCoachingEntrySummaryText, editCoachingEntry, deleteCoachingEntry } from './CoachingEntriesSagas'

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API.create()

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
  yield all([
    takeLatest(UsersActions.getMe, getMe, api),
    takeLatest(UsersActions.getAdminsRequest, getAdmins, api),
    takeLatest(ApplicantsActions.startup, startup, api),
    takeLatest(ApplicantsActions.getApplicants, getApplicants, api),
    takeLatest(ApplicantsActions.changeApplicantsPage, changeApplicantsPage),
    takeLatest(ApplicantsActions.changeApplicantRowsPerPage, changeApplicantRowsPerPage),
    takeLatest(ApplicantsActions.setApplicantTrainer, setApplicantTrainer, api),
    takeLatest(ApplicantsActions.setApplicantStartDate, setApplicantStartDate, api),
    takeLatest(ApplicantsActions.sendMail, sendMail, api),
    takeLatest(ApplicantsActions.sendSms, sendSms, api),
    takeLatest(ApplicantsActions.endService, endService, api),
    takeLatest(ApplicantsActions.setApplicantFilters, setApplicantFilters, api),
    takeLatest(ApplicantsActions.getTalentStatuses, getTalentStatuses, api),
    takeLatest(ApplicantsActions.exportApplicantsToExcel, exportApplicantsToExcel, api),
    takeLatest(ApplicantsActions.exportCertificateTemplateExcel, exportCertificateTemplateExcel, api),
    takeLatest(ApplicantsActions.reloadVisibleApplicants, reloadVisibleApplicants, api),
    takeLatest(ApplicantsActions.rollbackApplicant, rollbackApplicant, api),
    takeLatest(ApplicantsActions.changeProject, changeProject, api),
    takeLatest(ApplicantsActions.setApplicantInfo, setApplicantInfo, api),
    takeLatest(ApplicantsActions.setApplicantTargetOccupations, setApplicantTargetOccupations, api),
    takeLatest(ApplicantsActions.getApplicantInfo, getApplicantInfo, api),
    takeLatest(ApplicantsActions.getApplicantTargetOccupations, getApplicantTargetOccupations, api),
    takeLatest(ApplicantsActions.rollbackApplicantToStatus, rollbackApplicantToStatus, api),
    takeLatest(ApplicantsActions.importSpringerApplicantsFile, importSpringerApplicantsFile, api),
    takeLatest(ApplicantsActions.toggleSafeMode, toggleSafeMode, api),
    takeLatest(ApplicantsActions.importKoulutusporttiApplicantsFile, importKoulutusporttiApplicantsFile, api),
    takeLatest(ApplicantsActions.getApplicantLanguageSkills, getApplicantLanguageSkills, api),
    takeLatest(ApplicantsActions.saveApplicantLanguageSkills, saveApplicantLanguageSkills, api),
    takeLatest(ApplicantsActions.deleteApplicantLanguageSkill, deleteApplicantLanguageSkill, api),
    takeLatest(ApplicantsActions.getCooperationTypes, getCooperationTypes, api),
    takeLatest(ApplicantsActions.createInternship, createInternship, api),
    takeLatest(ApplicantsActions.getApplicantInternships, getApplicantInternships, api),
    takeLatest(ApplicantsActions.editInternship, editInternship, api),
    takeLatest(ApplicantsActions.deleteInternship, deleteInternship, api),
    takeLatest(ApplicantsActions.startInitialAssessmentRequest, startInitialAssessment, api),
    takeLatest(ApplicantsActions.endInitialAssessmentRequest, endInitialAssessment, api),

    takeLatest(MessagesActions.getMessages, getMessages, api),
    takeLatest(MessagesActions.setMessageFilters, setMessageFilters, api),
    takeLatest(MessagesActions.changeMessagesPage, changeMessagesPage, api),
    takeLatest(MessagesActions.changeMessageRowsPerPage, changeMessageRowsPerPage, api),
    takeLatest(MessagesActions.reloadVisibleMessages, reloadVisibleMessages, api),

    takeLatest(MeetingsActions.getMeetings, getMeetings, api),
    takeLatest(MeetingsActions.createMeeting, createMeeting, api),
    takeLatest(MeetingsActions.editMeeting, editMeeting, api),
    takeLatest(MeetingsActions.cancelMeeting, cancelMeeting, api),
    takeLatest(MeetingsActions.getAllMeetings, getAllMeetings, api),
    takeLatest(ServicesActions.getServices, getServices, api),
    takeLatest(ServicesActions.setServiceFilters, setServiceFilters, api),
    takeLatest(ServicesActions.changeServicesPage, changeServicesPage),
    takeLatest(ServicesActions.changeServiceRowsPerPage, changeServiceRowsPerPage),
    takeLatest(ServicesActions.reloadVisibleServices, reloadVisibleServices, api),
    takeLatest(ServicesActions.getServicesOptions, getServicesOptions, api),
    takeLatest(ServicesActions.createService, createService, api),
    takeLatest(ServicesActions.reloadAllFetchedServices, reloadAllFetchedServices, api),
    takeLatest(ServicesActions.editService, editService, api),
    takeLatest(ProjectsActions.getProjectsOptions, getProjectsOptions, api),
    takeLatest(ProjectsActions.getProjects, getProjects, api),
    takeLatest(ProjectsActions.changeProjectsPage, changeProjectsPage),
    takeLatest(ProjectsActions.changeProjectRowsPerPage, changeProjectRowsPerPage),
    takeLatest(ProjectsActions.reloadVisibleProjects, reloadVisibleProjects, api),
    takeLatest(ProjectsActions.createProject, createProject, api),
    takeLatest(ProjectsActions.reloadAllFetchedProjects, reloadAllFetchedProjects, api),
    takeLatest(ProjectsActions.editProject, editProject, api),
    takeLatest(ProjectsActions.getProject, getProject, api),
    takeLatest(ProjectsActions.getProjectsInCity, getProjectsInCity, api),
    takeLatest(ProjectsActions.getFilteredProjectsOptions, getFilteredProjectsOptions, api),
    takeLatest(AreasActions.getRegions, getRegions, api),
    takeLatest(AreasActions.getFinlandRegionsWithCities, getFinlandRegionsWithCities, api),
    takeLatest(AreasActions.getRegionsWithCities, getRegionsWithCities, api),
    takeLatest(AreasActions.getAreas, getAreas, api),
    takeLatest(AreasActions.searchPostalCodes, searchPostalCodes, api),
    takeLatest(AreasActions.getCountries, getCountries, api),
    takeLatest(AreasActions.getLanguages, getLanguages, api),
    takeLatest(AreasActions.getLanguageSkills, getLanguageSkills, api),
    takeLatest(AreasActions.getLanguageSkillLevels, getLanguageSkillLevels, api),
    takeLatest(CoachesActions.addCoaches, addCoaches, api),
    takeLatest(CoachesActions.removeCoach, removeCoach, api),
    takeLatest(CoachesActions.addCoachingResult, addCoachingResult, api),
    takeLatest(CoachesActions.removeCoachingResult, removeCoachingResult, api),
    takeLatest(CoachesActions.getCoachingResults, getCoachingResults, api),
    takeLatest(CoachesActions.getCoaches, getCoaches, api),

    takeLatest(NotesActions.getNotesRequest, getNotes, api),
    takeLatest(NotesActions.activateNoteRequest, activateNote, api),
    takeLatest(NotesActions.passivateNoteRequest, passivateNote, api),
    takeLatest(NotesActions.editNoteRequest, editNote, api),
    takeLatest(NotesActions.addNoteRequest, addNote, api),
    takeLatest(AttachmentsActions.getCoachingAttachmentsRequest, getCoachingAttachments, api),
    takeLatest(AttachmentsActions.addCoachingAttachmentRequest, addCoachingAttachment, api),
    takeLatest(AttachmentsActions.deleteCoachingAttachmentRequest, deleteCoachingAttachment, api),
    takeLatest(AttachmentsActions.addProjectAttachmentRequest, addProjectAttachment, api),
    takeLatest(AttachmentsActions.getProjectAttachmentsRequest, getProjectAttachments, api),
    takeLatest(AttachmentsActions.deleteProjectAttachmentRequest, deleteProjectAttachment, api),

    takeLatest(CoachingEntriesActions.addCoachingEntry, addCoachingEntry, api),
    takeLatest(CoachingEntriesActions.getCoachingEntrySummaryText, getCoachingEntrySummaryText, api),
    takeLatest(CoachingEntriesActions.getCoachingEntries, getCoachingEntries, api),
    takeLatest(CoachingEntriesActions.editCoachingEntry, editCoachingEntry, api),
    takeLatest(CoachingEntriesActions.deleteCoachingEntry, deleteCoachingEntry, api)
  ])
}
