import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import moment from 'moment'
import * as ProjectsActions from '../redux/ProjectsRedux'
import * as AreasActions from '../redux/AreasRedux'
import * as ServicesActions from '../redux/ServicesRedux'
import * as CoachesActions from '../redux/CoachesRedux'
import * as AttachmentsActions from '../redux/AttachmentsRedux'
import * as UsersActions from '../redux/UsersRedux'
import { COLORS } from '../utils/colors'
import ProjectsTableToolbar from '../components/projects/ProjectsTableToolbar'
import ProjectRowMobile from '../components/projects/ProjectRowMobile'
import ProjectFilterDialog from '../components/projects/ProjectFilterDialog'
import CreateProjectDialog from '../components/projects/CreateProjectDialog'
import ProjectDialog from '../components/projects/ProjectDialog'
import ProjectMenu from '../components/projects/ProjectMenu'
import SpringerTablePagination from '../components/applicants/SpringerTablePagination'
import ActionBar from '../components/ActionBar'
import ToolTip from '../components/ToolTip'
import { getFilterCount, getCurrentLang } from '../utils/transforms'

/* Material-UI components */
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { TableRow, TableCell, TableHead, Box, useMediaQuery } from '@mui/material'
import ArchiveIcon from '@mui/icons-material/Archive'
import { useTheme } from '@mui/styles'

const baseStyle = {
  backgroundColor: COLORS.orange,
  color: COLORS.white,
  padding: '8px 16px',
  fontWeight: 'bold'
}
const styles = {
  container: {
    width: '100%',
    maxWidth: 1360,
    margin: '0 auto',
    paddingBottom: 80
  },
  root: {
    width: '100%'
  },
  iconText: {
    verticalAlign: 'super',
    paddingLeft: 2
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  leftColumn: {
    display: 'flex',
    flex: 40
  },
  rightColumn: {
    display: 'flex',
    flex: 50,
    flexDirection: 'column'
  },
  tableContainer: {
    overflowX: 'auto',
    width: '100%',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    // Prevent vertical scrollbar when opening dropdown
    paddingBottom: 300,
    marginBottom: -300
  },
  table: {
    minWidth: 700
  },
  tableHead: {
    ...baseStyle,
    minWidth: 100
  },
  tableHeadWide: {
    ...baseStyle,
    minWidth: 200
  },
  tableHeadSmall: {
    ...baseStyle,
    minWidth: 60
  },
  spinner: {
    display: 'flex',
    padding: '64px 16px',
    justifyContent: 'center'
  },
  centered: {
    margin: '32px 0 16px',
    textAlign: 'center',
    width: '100%'
  },
  cellRow: {
    paddingTop: 4,
    paddingBottom: 4,
    display: 'flex',
    alignItems: 'center'
  },
  cell: {
    padding: '8px 16px'
  },
  infoCell: {
    padding: '8px 0px'
  },
  cellIcon: {
    color: COLORS.darkGrey
  },
  passiveIcon: {
    color: COLORS.lightText,
    marginRight: 4
  },
  smallCellIcon: {
    color: COLORS.darkGrey,
    fontSize: 12,
    marginRight: 8
  },
  actionLink: {
    color: COLORS.green,
    fontSize: '0.87rem',
    cursor: 'pointer',
    marginLeft: 4
  }
}

const ProjectsScreen = (props) => {
  const initialState = {
    isFilterDialogOpen: false,
    isCreateDialogOpen: false,
    projectFilters: {},
    showAllProjectsForProjects: [],
    projectDialog: false
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  const [t] = useTranslation()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const activeColor = 'textPrimary'
  const passiveColor = 'textSecondary'

  useEffect(() => {
    props.getProjects()
    props.getAdmins()
  }, []) // On component mount

  useEffect(() => {
    props.getProjects()
  }, [state.projectFilters])

  const isEmptyProjectsList = () => {
    const { projects, fetching } = props
    if (!fetching && !projects.length) {
      return true
    }
    return false
  }

  const showProjectDialog = (project) => {
    props.getProject(project.id)
    props.getFinlandRegionsWithCities()
    props.getServicesOptions()
    props.getCoaches()
    props.getCoachingResults()
    props.getProjectAttachments(project.id)
    setState({ projectDialog: true })
  }
  const closeProjectDialog = () => {
    setState({ projectDialog: false })
    props.deselectProject()
  }
  const onFilterDialogToggle = () => {
    props.getFinlandRegionsWithCities()
    props.getServicesOptions()
    setState({ isFilterDialogOpen: !state.isFilterDialogOpen })
  }
  const onCreateDialogToggle = () => {
    props.getFinlandRegionsWithCities()
    props.getServicesOptions()
    props.getCoaches()
    setState({ isCreateDialogOpen: !state.isCreateDialogOpen })
  }

  const setProjectFilters = (filters) => {
    // Merge properties from filters to project filters
    const mergedNewFilters = Object.assign({}, state.projectFilters, filters)
    setState({ projectFilters: mergedNewFilters })
    props.setProjectFilters(mergedNewFilters)
  }

  const getServiceName = (project) => {
    if (getCurrentLang() === 'fi') {
      return project.serviceName.fi || project.serviceName.en
    } else {
      return project.serviceName.en || project.serviceName.fi
    }
  }

  const renderEmptyProject = () => {
    return (
      <Typography style={styles.centered}>
        {t('no_results')}
      </Typography>
    )
  }

  const renderMobileActionBar = () => {
    const { projectsTotal, projectFilters } = props
    return (
      <ActionBar
        totalCount={projectsTotal}
        openFilter={onFilterDialogToggle}
        openFileUpload={onCreateDialogToggle}
        filterCount={getFilterCount(projectFilters)}
        paginationComponent={renderPagination()}
      />
    )
  }

  const renderMobileProjectsTable = () => {
    const { visibleProjects } = props
    if (isEmptyProjectsList()) return renderEmptyProject()
    return visibleProjects.map(project => (
      <ProjectRowMobile
        key={project.id}
        project={project}
        getServiceName={getServiceName}
        showProjectDialog={showProjectDialog}
      />
    ))
  }

  const renderInitSpinner = () => {
    const { fetching, visibleProjects } = props
    // Initial loading
    if (fetching && !visibleProjects.length) {
      return (
        <div style={styles.spinner}>
          <CircularProgress />
        </div>
      )
    }
  }

  const renderProjectRow = (msg) => {
    const { visibleProjects } = props
    return visibleProjects.map(project => (
      <TableRow key={project.id}>
        <TableCell style={styles.cell}>
          <div style={styles.row}>
            {!project.active &&
              <ArchiveIcon style={styles.passiveIcon} />}
            <Typography component='span' style={styles.leftColumn} color={project.active ? activeColor : passiveColor}>
              {project.name.fi}
            </Typography>
          </div>
        </TableCell>
        <TableCell style={styles.cell}>
          <Typography component='span' style={styles.leftColumn} color={project.active ? activeColor : passiveColor}>
            {project.name.en}
          </Typography>
        </TableCell>
        <TableCell style={styles.cell}>
          <Typography component='span' style={styles.leftColumn} color={project.active ? activeColor : passiveColor}>
            {project.areaId}
          </Typography>
        </TableCell>
        <TableCell style={styles.cell}>
          <Typography component='span' style={styles.leftColumn} color={project.active ? activeColor : passiveColor}>
            {project.start ? moment(project.start).format('DD.MM.YYYY') : '-'}
          </Typography>
        </TableCell>
        <TableCell style={styles.cell}>
          <Typography component='span' style={styles.leftColumn} color={project.active ? activeColor : passiveColor}>
            {project.end ? moment(project.end).format('DD.MM.YYYY') : '-'}
          </Typography>
        </TableCell>
        <TableCell style={styles.cell}>
          <Typography component='span' style={styles.leftColumn} color={project.active ? activeColor : passiveColor}>
            {getServiceName(project)}
          </Typography>
        </TableCell>
        <TableCell style={styles.infoCell} align='right'>
          <ToolTip title={t('actions')}>
            <div style={styles.rowIconContainer}>
              <ProjectMenu
                project={project}
                showProjectDialog={showProjectDialog}
              />
            </div>
          </ToolTip>
        </TableCell>
      </TableRow>
    ))
  }

  const renderProjectsTable = () => {
    const { projectsTotal, projectFilters, user } = props

    return (
      <div style={styles.tableContainer}>
        <ProjectsTableToolbar
          user={user}
          projectsTotal={projectsTotal}
          onFilterDialogToggle={onFilterDialogToggle}
          onCreateDialogToggle={onCreateDialogToggle}
          filterCount={getFilterCount(projectFilters)}
        />
        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHeadWide}>{t('name_fi')}</TableCell>
              <TableCell style={styles.tableHeadWide}>{t('name_en')}</TableCell>
              <TableCell style={styles.tableHead}>{t('area')}</TableCell>
              <TableCell style={styles.tableHead}>{t('start_date')}</TableCell>
              <TableCell style={styles.tableHead}>{t('end_date')}</TableCell>
              <TableCell style={styles.tableHead}>{t('service')}</TableCell>
              <TableCell style={styles.tableHeadSmall} />
            </TableRow>
          </TableHead>
          <TableBody>
            {renderProjectRow()}
          </TableBody>
        </Table>
        {renderPagination()}
      </div>
    )
  }

  // Render pagination controls
  const renderPagination = () => {
    const { page, fetching, projectsTotal, pageSize } = props
    return (
      <SpringerTablePagination
        page={page - 1}
        count={projectsTotal}
        handleChangePage={props.changeProjectsPage}
        handleChangeRowsPerPage={props.changeProjectRowsPerPage}
        fetching={fetching}
        pageSize={pageSize}
        labelRowsPerPage={t('projects_per_page')}
      />
    )
  }

  return (
    <div style={styles.container}>
      {!matches &&
        <Box>
          {renderMobileProjectsTable()}
          {renderInitSpinner()}
          {renderMobileActionBar()}
        </Box>}
      {matches &&
        <Box>
          {renderPagination()}
          <Paper style={styles.root}>
            {renderProjectsTable()}
            {renderInitSpinner()}
          </Paper>
        </Box>}
      <ProjectFilterDialog
        isOpen={state.isFilterDialogOpen}
        onClose={onFilterDialogToggle}
        setProjectFilters={setProjectFilters}
        regionsOptions={props.finlandRegionsWithCities}
        servicesOptions={props.servicesOptions}
        projectFilters={props.projectFilters}
        resetProjectFilters={props.resetProjectFilters}
      />
      <CreateProjectDialog
        isOpen={state.isCreateDialogOpen}
        onClose={onCreateDialogToggle}
        createProject={props.createProject}
        coachOptions={props.coaches}
        fetching={props.fetching}
        saveSuccessful={props.saveSuccessful}
        acknowledgeSaveSuccess={props.acknowledgeProjectSaveSuccess}
        regionsOptions={props.finlandRegionsWithCities}
        servicesOptions={props.servicesOptions}
        adminOptions={props.admins}
      />
      <ProjectDialog
        open={state.projectDialog}
        user={props.user}
        onClose={closeProjectDialog}
        fetching={props.fetching}
        coachesFetching={props.coachesFetching}
        projectInfo={props.project}
        editProject={props.editProject}
        addCoaches={props.addCoaches}
        removeCoach={props.removeCoach}
        addResult={props.addCoachingResult}
        removeResult={props.removeCoachingResult}
        regionsOptions={props.finlandRegionsWithCities}
        servicesOptions={props.servicesOptions}
        coachOptions={props.coaches}
        coachingResultOptions={props.coachingResultOptions}
        attachmentsLoading={props.attachmentsLoading}
        addAttachmentFile={props.addAttachmentFile}
        projectAttachments={props.projectAttachments}
        deleteProjectAttachment={props.deleteProjectAttachment}
        adminOptions={props.admins}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects.projects,
    visibleProjects: state.projects.visibleProjects,
    fetching: state.projects.fetching,
    page: state.projects.page,
    pageSize: state.projects.pageSize,
    projectsTotal: state.projects.projectsTotal,
    projectFilters: state.projects.projectFilters,
    saveSuccessful: state.projects.saveSuccessful,
    finlandRegionsWithCities: state.areas.finlandRegionsWithCities,
    servicesOptions: state.services.servicesOptions,
    project: state.projects.project,
    coaches: state.coaches.coaches,
    user: state.user.user,
    coachingResultOptions: state.coaches.coachingResults,
    coachesFetching: state.coaches.coachesFetching,
    attachmentsLoading: state.attachments.loading,
    projectAttachments: state.attachments.projectAttachments,
    admins: state.user.admins
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjects: () => dispatch(ProjectsActions.getProjects()),
    changeProjectsPage: (page) => dispatch(ProjectsActions.changeProjectsPage(page)),
    changeProjectRowsPerPage: (rows) => dispatch(ProjectsActions.changeProjectRowsPerPage(rows)),
    setProjectFilters: (filters) => dispatch(ProjectsActions.setProjectFilters(filters)),
    createProject: (project) => dispatch(ProjectsActions.createProject(project)),
    acknowledgeProjectSaveSuccess: () => dispatch(ProjectsActions.acknowledgeProjectSaveSuccess()),
    editProject: (project) => dispatch(ProjectsActions.editProject(project)),
    getFinlandRegionsWithCities: () => dispatch(AreasActions.getFinlandRegionsWithCities()),
    getServicesOptions: () => dispatch(ServicesActions.getServicesOptions()),
    getProject: (projectId) => dispatch(ProjectsActions.getProject(projectId)),
    deselectProject: () => dispatch(ProjectsActions.deselectProject()),
    getCoaches: () => dispatch(CoachesActions.getCoaches()),
    addCoaches: (projectId, coaches) => dispatch(CoachesActions.addCoaches(projectId, coaches)),
    removeCoach: (projectId, coachId) => dispatch(CoachesActions.removeCoach(projectId, coachId)),
    getCoachingResults: () => dispatch(CoachesActions.getCoachingResults()),
    addCoachingResult: (projectId, result) => dispatch(CoachesActions.addCoachingResult(projectId, result)),
    removeCoachingResult: (projectId, result) => dispatch(CoachesActions.removeCoachingResult(projectId, result)),
    resetProjectFilters: () => dispatch(ProjectsActions.resetProjectFilters()),
    addAttachmentFile: (projectId, file) => dispatch(AttachmentsActions.addProjectAttachmentRequest(projectId, file)),
    getProjectAttachments: (projectId) => dispatch(AttachmentsActions.getProjectAttachmentsRequest(projectId)),
    deleteProjectAttachment: (projectId, fileId) => dispatch(AttachmentsActions.deleteProjectAttachmentRequest(projectId, fileId)),
    getAdmins: () => dispatch(UsersActions.getAdminsRequest())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ProjectsScreen
)
