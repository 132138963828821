import React, { useEffect, useReducer } from 'react'
import { useTheme } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import isEmpty from 'lodash/isEmpty'
import ToolTip from '../ToolTip'
import InfoIcon from '@mui/icons-material/Info'
import FormTextField from '../FormTextField'
import { useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'

const styles = {
  root: {
    maxWidth: { md: 800, sm: '100%' }

  },
  title: {
    marginLeft: 6,
    flex: 1
  },
  content: {
    padding: {
      md: '16px 32px 64px',
      sm: '16px 16px 32px'
    },
    width: 800
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  caption: {
    marginTop: 16
  },
  divider: {
    padding: '0 16px'
  },
  menuButton: {
    marginLeft: 5
  },
  okButton: {
    marginRight: 15,
    fonFamily: 'Roboto',
    fontWeight: 'bold'
  },
  clearButton: {
    marginLeft: 8
  },
  additionalInfo: {
    padding: 16
  },
  additionalInfoContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  additionalInfoContent: {
    maxWidth: 420
  }
}

const MessageFilterDialog = (props) => {
  const initialState = {
    nameSearchString: '',
    senderNameSearchString: ''
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [t] = useTranslation()

  /* When filters come filled as props, set them to state */
  useEffect(() => {
    if (!isEmpty(props.messageFilters)) {
      setState({ ...props.messageFilters })
    }
  }, [props.messageFilters])

  const resetFilters = () => setState({
    nameSearchString: '',
    senderNameSearchString: ''
  })

  const handleOkPress = () => {
    const filters = { ...state }
    props.setMessageFilters(filters)
    props.onClose()
  }

  const setNameSearchString = (e) => setState({ nameSearchString: e.target.value })
  const setSenderNameSearchString = (e) => setState({ senderNameSearchString: e.target.value })

  const renderContent = () => {
    const { nameSearchString, senderNameSearchString } = state

    return (
      <Box sx={styles.content}>
        <div style={styles.flexContainer}>
          <div style={{ flex: 1 }}>
            <FormTextField
              label={t('recipient_name_search')}
              fullWidth
              onChange={setNameSearchString}
              value={nameSearchString}
              onEnterPress={value => handleOkPress()}
              variant='standard'
            />
          </div>
          <ToolTip title={t('name_search_info')}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </ToolTip>
        </div>
        <div style={styles.flexContainer}>
          <div style={{ flex: 1 }}>
            <FormTextField
              label={t('sender_name_search')}
              fullWidth
              onChange={setSenderNameSearchString}
              value={senderNameSearchString}
              onEnterPress={value => handleOkPress()}
              variant='standard'
            />
          </div>
          <ToolTip title={t('name_search_info')}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </ToolTip>
        </div>
      </Box>
    )
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ sx: styles.root }}
      fullWidth
      scroll='body'
    >
      <AppBar position='static'>
        <Toolbar disableGutters>
          <IconButton style={styles.menuButton} color='inherit' onClick={props.onClose} aria-label={t('close')}>
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' color='inherit' style={styles.title}>
            {t('filters')}
          </Typography>
          <Button color='inherit' onClick={resetFilters}>
            {t('reset')}
          </Button>
          <Button color='inherit' onClick={handleOkPress} style={styles.okButton}>
            {t('ok')}
          </Button>
        </Toolbar>
      </AppBar>
      {renderContent()}
    </Dialog>
  )
}

export default MessageFilterDialog
