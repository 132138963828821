import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MailIcon from '@mui/icons-material/Email'
import SmsIcon from '@mui/icons-material/Sms'
import ExportIcon from '@mui/icons-material/CloudDownload'
import FilterIcon from '@mui/icons-material/FilterList'
import FinishIcon from '@mui/icons-material/Gavel'
import RollbackIcon from '@mui/icons-material/Undo'
import ProjectIcon from '@mui/icons-material/Assignment'
import ApplicantIcon from '@mui/icons-material/PermIdentity'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ExploreIcon from '@mui/icons-material/Explore'
import CircularProgress from '@mui/material/CircularProgress'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ToolTip from '../ToolTip'
import { COLORS } from '../../utils/colors'
import TableToolbar from '../TableToolBar'
import { isAdmin } from '../../utils/permissions'

const styles = theme => ({
  root: {
    padding: '0px 20px',
    color: COLORS.white,
    backgroundColor: COLORS.green,
    display: 'flex',
    justifyContent: 'space-between',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%',
    maxWidth: 1360,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 20px)'
    },
    minHeight: 56
  },
  filterRoot: {
    backgroundColor: COLORS.orange
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tooltip: {
    backgroundColor: COLORS.black,
    boxShadow: theme.shadows[1]
  },
  icon: {
    color: COLORS.white
  },
  badge: {
    top: -5,
    right: -5
  },
  badgeHidden: {
    opacity: 0
  },
  badgeContent: {
    fontSize: 9
  }
})

class ApplicantsTableToolbar extends Component {
  state = {
    exportMenuElement: null
  }

  handleExportApplicants = () => {
    this.props.exportApplicantsToExcel()
    this.handleExportMenuClose()
  }

  handleExportCertificate = () => {
    this.props.exportCertificateTemplateExcel()
    this.handleExportMenuClose()
  }

  handleExportMenuOpen = (event) => this.setState({ exportMenuElement: event.currentTarget })
  handleExportMenuClose = () => this.setState({ exportMenuElement: null })

  handleOpenApplicant = () => this.props.openApplicant()
  renderApplicant = () => {
    const { classes, t, showApplicant } = this.props
    if (showApplicant) {
      return (
        <ToolTip title={t('applicant_info')}>
          <IconButton aria-label='Applicant info' onClick={this.handleOpenApplicant}>
            <ApplicantIcon className={classes.icon} />
          </IconButton>
        </ToolTip>
      )
    }
    return null
  }

  renderInitialAssessment = () => {
    const { t, classes, openInitialAssessment } = this.props
    return (
      <ToolTip title={t('initial_assessment')}>
        <IconButton aria-label='Initial assessment' onClick={openInitialAssessment}>
          <ExploreIcon className={classes.icon} />
        </IconButton>
      </ToolTip>
    )
  }

  renderEndService = () => {
    const { showEndService, endService, t, classes } = this.props
    if (showEndService) {
      return (
        <ToolTip title={t('end_service')}>
          <IconButton aria-label={t('end_service')} onClick={endService}>
            <FinishIcon className={classes.icon} />
          </IconButton>
        </ToolTip>
      )
    }
    return null
  }

  renderRollback = () => {
    const { showRollback } = this.props
    if (showRollback) {
      const { rollbackText, rollback, classes } = this.props
      return (
        <ToolTip title={rollbackText}>
          <IconButton aria-label={rollbackText} onClick={rollback}>
            <RollbackIcon className={classes.icon} />
          </IconButton>
        </ToolTip>
      )
    }
    return null
  }

  renderChangeProject = () => {
    const { showChangeProject } = this.props
    if (showChangeProject) {
      const { changeProject, t, classes } = this.props
      return (
        <ToolTip title={t('change_applicant_project')}>
          <IconButton aria-label={t('change_applicant_project')} onClick={changeProject}>
            <ProjectIcon className={classes.icon} />
          </IconButton>
        </ToolTip>
      )
    }
  }

  renderButtons = () => {
    const { classes, t, sendMail, sendSms, openFilters, user, exporting } = this.props
    const { exportMenuElement } = this.state
    return (
      <div className={classes.actions}>
        {this.renderApplicant()}
        {this.renderInitialAssessment()}
        {this.renderEndService()}
        {this.renderRollback()}
        {this.renderChangeProject()}
        <ToolTip title={t('send_email')}>
          <IconButton aria-label='Send' onClick={sendMail}>
            <MailIcon className={classes.icon} />
          </IconButton>
        </ToolTip>
        <ToolTip title={t('send_sms')}>
          <IconButton aria-label='Send' onClick={sendSms}>
            <SmsIcon className={classes.icon} />
          </IconButton>
        </ToolTip>
        {isAdmin(user) &&
          <>
            <ToolTip title={t('export_to_excel')}>
              <IconButton aria-label='Export' onClick={this.handleExportMenuOpen}>
                {!exporting &&
                  <ExportIcon className={classes.icon} />}
                {exporting &&
                  <CircularProgress color='secondary' size={16} />}
              </IconButton>
            </ToolTip>
            <Menu
              id='export-menu'
              anchorEl={exportMenuElement}
              open={Boolean(exportMenuElement)}
              onClose={this.handleExportMenuClose}
            >
              <MenuItem onClick={() => this.handleExportApplicants()}>{t('applicants_information')}</MenuItem>
              <MenuItem onClick={() => this.handleExportCertificate()}>{t('certificate_template')}</MenuItem>
            </Menu>
          </>}
        <ToolTip title={t('filters')}>
          <IconButton aria-label='Filters' onClick={openFilters}>
            <FilterIcon className={classes.icon} />
          </IconButton>
        </ToolTip>
      </div>
    )
  }

  renderSelected = () => {
    const { selectedCount, t } = this.props
    return selectedCount === 1 ? t('single_selected') : t('selected')
  }

  renderBadgeContent = () => {
    const { classes, filterCount } = this.props
    if (filterCount) {
      return <span className={classes.badgeContent}>{filterCount}</span>
    }
    return ''
  }

  renderImportButton = () => {
    const { classes, t, openFileUpload } = this.props
    return (
      <div className={this.props.classes.actions}>
        <ToolTip title={t('import_applicants')}>
          <IconButton aria-label='File upload' onClick={openFileUpload}>
            <CloudUploadIcon className={classes.icon} />
          </IconButton>
        </ToolTip>
      </div>
    )
  }

  renderToolbar = () => {
    const { classes, selectedCount, t, openFilters, filterCount, applicantsTotal } = this.props
    if (selectedCount > 0) {
      return (
        <Toolbar className={classes.root}>
          <Typography color='inherit' variant='subtitle1'>
            {selectedCount} / {applicantsTotal} {this.renderSelected()}
          </Typography>
          {this.renderButtons()}
        </Toolbar>
      )
    }
    return (
      <TableToolbar
        headerText={`${t('applicants')} ${applicantsTotal}`}
        openFilters={openFilters}
        filterCount={filterCount}
      >
        {this.renderImportButton()}
      </TableToolbar>
    )
  }

  render () {
    return (
      this.renderToolbar()
    )
  }
}

export default withStyles(styles)(withTranslation()(ApplicantsTableToolbar))
