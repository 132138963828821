import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Divider from '@mui/material/Divider'

const styles = theme => ({
  textareaAutosize: {
    width: '100%',
    font: 'inherit',
    border: 0,
    letteSpacing: 'inherit',
    color: 'currentColor'
  }
})

const TextArea = (props) => {
  const { label, value, onChange, minRows, maxRows, t, classes } = props
  const min = minRows || 4
  const max = maxRows || 20
  return (
    <>
      <TextareaAutosize
        label={t(label)}
        value={value || ''} // https://reactjs.org/docs/forms.html#controlled-components
        onChange={onChange}
        minRows={min}
        maxRows={max}
        className={classes.textareaAutosize}
        required={props.required}
      />
      <Divider />
    </>
  )
}

export default withStyles(styles)(withTranslation()(TextArea))
