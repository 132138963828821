import React from 'react'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'
const largeFontSize = 16
const styles = theme => ({
  root: {
  },
  input: {
    display: 'flex',
    padding: '6px 0'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chip: {
    height: 'unset',
    marginBottom: 6
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  smallPlaceholder: {
    position: 'absolute',
    left: 2,
    fontSize: 12
  },
  placeholder: {
    fontSize: largeFontSize,
    marginBottom: 4
  },
  paper: {
    marginTop: theme.spacing()
  },
  divider: {
    height: theme.spacing(2)
  }
})

class MultiAutocomplete extends React.Component {
  calcMenuHeight = (itemCount) => {
    const rowHeight = 43
    let numRows = 2
    if (itemCount > 2) {
      numRows = Math.min(itemCount, 4)
    }
    return rowHeight * numRows
  }

  render () {
    let value = this.props.value || []
    const { options, placeholder, noOptionsMessage, largeText, required } = this.props
    const fontSize = largeText ? largeFontSize : 12

    if (value.asMutable) value = value.asMutable()
    const placeholderText = required ? `${placeholder} *` : placeholder
    return (
      <div style={styles.root}>
        <Autocomplete
          groupBy={this.props?.groupBy}
          limitTags={4}
          filterSelectedOptions
          disableCloseOnSelect
          options={options}
          value={value}
          onChange={this.props.onChange}
          placeholder={placeholderText}
          noOptionsText={noOptionsMessage ? noOptionsMessage() : 'No options'}
          multiple
          size={largeText ? 'medium' : 'small'}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(params, option) => {
            return (
              <MenuItem
                selected={params.isFocused}
                style={{
                  fontWeight: params.isSelected ? 500 : 400,
                  padding: 8,
                  fontSize
                }}
                {...params}
              >
                {option.label}
              </MenuItem>
            )
          }}
          ListboxProps={{ sx: { ...styles.paper, fontSize } }}
          renderInput={(params, option) =>
            <TextField
              fullWidth
              sx={styles.input}
              {...params}
              variant='standard'
              label={this.props.label}
              placeholder={placeholderText}
            />}
          ChipProps={{
            sx: styles.chip
          }}
        />
      </div>
    )
  }
}

export default MultiAutocomplete
