import React, { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'

const styles = {
  iconButton: {
    color: 'inherit'
  }
}

const ServiceMenu = ({ service, showServiceDialog }) => {
  const [t] = useTranslation()
  const [anchorElement, setAnchorElement] = useState(null)
  const isShowing = Boolean(anchorElement)

  function toggleServiceMenu (event) {
    setAnchorElement(event.target)
  }
  function close () {
    setAnchorElement(null)
  }

  function onServiceInfoClick () {
    close()
    showServiceDialog(service)
  }

  return (
    <Fragment key='service-menu'>
      <IconButton style={styles.iconButton} onClick={toggleServiceMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='fade-menu'
        anchorEl={anchorElement}
        open={isShowing}
        onClose={close}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={onServiceInfoClick}>{t('service_info')}</MenuItem>
      </Menu>
    </Fragment>
  )
}

export default ServiceMenu
