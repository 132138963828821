import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

const INITIAL_STATE = Immutable({
  messages: [],
  messagesTotal: 0,
  allIds: [],
  messageFilters: {},
  visibleMessages: [], // Subset of messages, displayed in current page of messages table.
  previousVisibleMessages: [], // Previous visible messages are stored so that view changes can be reverted if API call fails.
  fetching: false,
  error: null,
  pageSize: 10,
  page: 1,
  selectAll: false
})

/* ------------- Types and Action Creators ------------- */

export const getMessages = createAction('GET_MESSAGES')
export const getMessagesSuccess = createAction('GET_MESSAGES_SUCCESS', (messages, messagesTotal, allIds) => ({ messages, messagesTotal, allIds }))
export const getMessagesFailure = createAction('GET_MESSAGES_FAILURE', (error) => ({ error }))
export const changeMessagesPage = createAction('CHANGE_MESSAGES_PAGE', (page) => ({ page })) // Payload reducer so arguments are object keys inside payload
export const changeMessageRowsPerPage = createAction('CHANGE_MESSAGE_ROWS_PER_PAGE', (pageSize) => ({ pageSize }))
export const setMessageFilters = createAction('SET_MESSAGE_FILTERS', (messageFilters) => ({ messageFilters }))
export const reloadVisibleMessages = createAction('RELOAD_VISIBLE_MESSAGES')
export const reloadVisibleMessagesSuccess = createAction('RELOAD_VISIBLE_MESSAGES_SUCCESS', (messages, messagesTotal, allIds) => ({ messages, messagesTotal, allIds }))
export const reloadVisibleMessagesFailure = createAction('RELOAD_VISIBLE_MESSAGES_FAILURE', (error) => ({ error }))

/* ------------- Reducers ------------- */

const reducers = createReducer(
  {
    [getMessages]: (state) => state.merge({ fetching: true }),
    [getMessagesSuccess]: (state, { messages, messagesTotal, allIds }) => {
      /* First fetch */
      if (state.page === 1) {
        /* First results to the screen */
        const firstResults = messages.slice(0, state.pageSize)
        return state.merge({
          fetching: false,
          messagesTotal,
          allIds,
          messages,
          visibleMessages: [...firstResults]
        })
      }
      /* Add next results to the messages array */
      return state.merge({
        fetching: false,
        messagesTotal,
        allIds,
        messages: [...state.messages, ...messages],
        visibleMessages: [...messages]
      })
    },
    [getMessagesFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [changeMessagesPage]: (state, { page }) => {
      const nextMessages = state.messages.slice((state.pageSize * (page - 1)), (state.pageSize * (page)))
      return state.merge({ page, visibleMessages: [...nextMessages] })
    },
    [changeMessageRowsPerPage]: (state, { pageSize }) => {
      return state.merge({ pageSize, page: 1, messages: [] })
    },
    [setMessageFilters]: (state, { messageFilters }) => {
      return state.merge({
        messageFilters,
        // Reset messages list state
        messages: [],
        visibleMessages: [],
        previousVisibleMessages: [],
        messagesTotal: 0,
        pageSize: state.pageSize,
        page: 1
      })
    },
    [reloadVisibleMessages]: (state) => state.merge({ fetching: true }),
    [reloadVisibleMessagesSuccess]: (state, { messages, messagesTotal, allIds }) => {
      const newMessages = [
        ...state.messages.slice(0, ((state.page - 1) * state.pageSize)),
        ...messages,
        ...state.messages.slice((state.page * state.pageSize))
      ]
      const visibleMessages = messages
      return state.merge({ fetching: false, messages: newMessages, visibleMessages, messagesTotal, allIds })
    },
    [reloadVisibleMessagesFailure]: (state, { error }) => state.merge({ error, fetching: false })
  },
  INITIAL_STATE
)

export default reducers
