import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  applicants: [],
  applicantsTotal: 0,
  applicantInfo: null,
  allIds: [],
  allCoachingIds: [],
  applicantFilters: {},
  visibleApplicants: [], // Subset of applicants, displayed in current page of applicants table.
  previousVisibleApplicants: [], // Previous visible applicants are stored so that view changes can be reverted if API call fails.
  fetching: false,
  error: null,
  pageSize: 10,
  page: 1,
  selectAll: false,
  select: [],
  exclude: [],
  emailFetching: false,
  smsFetching: false,
  serviceActionLoading: false,
  coachingResults: [],
  talentStatuses: [],
  rollbackFetching: false,
  changeProjectFetching: false,
  setApplicantInfoFetching: false,
  servicesFetching: false,
  occupations: [],
  uploadingFile: false,
  springerFileUploadSuccess: false,
  springerFileUploadFailure: false,
  safeModeCoaching: null,
  koulutusporttiFileUploadSuccess: false,
  koulutusporttiFileUploadFailure: false,
  applicantLanguageSkills: [],
  savingLanguages: false,
  exporting: false,
  cooperationTypes: [],
  cooperationTypeOptions: [],
  savingInternship: false,
  applicantInternships: [],
  assessmentInProgress: true
})

/* ------------- Types and Action Creators ------------- */
export const startup = createAction('STARTUP')
export const getApplicants = createAction('GET_APPLICANTS')
export const getApplicantsSuccess = createAction('GET_APPLICANTS_SUCCESS', (applicants, applicantsTotal, allIds, allCoachingIds) => ({ applicants, applicantsTotal, allIds, allCoachingIds }))
export const getApplicantsFailure = createAction('GET_APPLICANTS_FAILURE', (error) => ({ error }))
export const changeApplicantsPage = createAction('CHANGE_APPLICANTS_PAGE', (page) => ({ page }))
export const changeApplicantRowsPerPage = createAction('CHANGE_APPLICANT_ROWS_PER_PAGE', (pageSize) => ({ pageSize }))
export const setApplicantTrainer = createAction('SET_APPLICANT_TRAINER', (projectId, applicantId, trainerId, begin) => ({ projectId, applicantId, trainerId, begin }))
export const setApplicantTrainerSuccess = createAction('SET_APPLICANT_TRAINER_SUCCESS', (applicantId, trainerId, projectId) => ({ applicantId, trainerId, projectId }))
export const setApplicantTrainerFailure = createAction('SET_APPLICANT_TRAINER_FAILURE', (error) => ({ error }))
export const setApplicantStartDate = createAction('SET_APPLICANT_START_DATE', (projectId, applicantId, trainerId, date) => ({ projectId, applicantId, trainerId, date }))
export const setApplicantStartDateSuccess = createAction('SET_APPLICANT_START_DATE_SUCCESS', (applicantId, date, projectId) => ({ applicantId, date, projectId }))
export const setApplicantStartDateFailure = createAction('SET_APPLICANT_START_DATE_FAILURE', (error) => ({ error }))
export const toggleSelectAllApplicants = createAction('TOGGLE_SELECT_ALL_APPLICANTS')
export const toggleSelectApplicant = createAction('TOGGLE_SELECT_APPLICANT', (applicantId, projectId, status) => ({ applicantId, projectId, status }))
export const resetApplicantSelect = createAction('RESET_APPLICANT_SELECT')
export const sendMail = createAction('SEND_MAIL', (subject, message) => ({ subject, message }))
export const sendMailSuccess = createAction('SEND_MAIL_SUCCESS')
export const sendMailFailure = createAction('SEND_MAIL_FAILURE', (error) => ({ error }))
export const sendSms = createAction('SEND_SMS', (message) => ({ message }))
export const sendSmsSuccess = createAction('SEND_SMS_SUCCESS')
export const sendSmsFailure = createAction('SEND_SMS_FAILURE', (error) => ({ error }))
export const endService = createAction('END_SERVICE', (projectId, applicantId, resultId, end) => ({ projectId, applicantId, resultId, end }))
export const endServiceSuccess = createAction('END_SERVICE_SUCCESS')
export const endServiceFailure = createAction('END_SERVICE_FAILURE', (error) => ({ error }))
export const setApplicantFilters = createAction('SET_APPLICANT_FILTERS', (applicantFilters) => ({ applicantFilters }))
export const getTalentStatuses = createAction('GET_TALENT_STATUSES')
export const getTalentStatusesSuccess = createAction('GET_TALENT_STATUSES_SUCCESS', (talentStatuses) => ({ talentStatuses }))
export const getTalentStatusesFailure = createAction('GET_TALENT_STATUSES_FAILURE', (error) => ({ error }))
export const exportApplicantsToExcel = createAction('EXPORT_APPLICANTS_TO_EXCEL')
export const exportApplicantsToExcelFailure = createAction('EXPORT_APPLICANTS_TO_EXCEL_FAILURE', (error) => ({ error }))
export const exportApplicantsToExcelSuccess = createAction('EXPORT_APPLICANTS_TO_EXCEL_SUCCESS')
export const exportCertificateTemplateExcel = createAction('EXPORT_CERTIFICATE_TEMPLATE_EXCEL')
export const exportCertificateTemplateExcelSuccess = createAction('EXPORT_CERTIFICATE_TEMPLATE_EXCEL_SUCCESS')
export const exportCertificateTemplateExcelFailure = createAction('EXPORT_CERTIFICATE_TEMPLATE_EXCEL_FAILURE', (error) => ({ error }))
export const reloadVisibleApplicants = createAction('RELOAD_VISIBLE_APPLICANTS')
export const reloadVisibleApplicantsSuccess = createAction('RELOAD_VISIBLE_APPLICANTS_SUCCESS', (applicants, applicantsTotal, allIds, allCoachingIds) => ({ applicants, applicantsTotal, allIds, allCoachingIds }))
export const reloadVisibleApplicantsFailure = createAction('RELOAD_VISIBLE_APPLICANTS_FAILURE', (error) => ({ error }))
export const rollbackApplicant = createAction('ROLLBACK_APPLICANT', (projectId, applicantId) => ({ projectId, applicantId }))
export const rollbackApplicantSuccess = createAction('ROLLBACK_APPLICANT_SUCCESS')
export const rollbackApplicantFailure = createAction('ROLLBACK_APPLICANT_FAILURE', (error) => ({ error }))
export const changeProject = createAction('CHANGE_PROJECT', (projectId, applicantId, newProjectId) => ({ projectId, applicantId, newProjectId }))
export const changeProjectSuccess = createAction('CHANGE_PROJECT_SUCCESS')
export const changeProjectFailure = createAction('CHANGE_PROJECT_FAILURE', (error) => ({ error }))
export const setApplicantInfo = createAction('SET_APPLICANT_INFO', (applicant) => ({ applicant }))
export const setApplicantInfoSuccess = createAction('SET_APPLICANT_INFO_SUCCESS')
export const setApplicantInfoFailure = createAction('SET_APPLICANT_INFO_FAILURE', (error) => ({ error }))
export const setApplicantTargetOccupations = createAction('SET_APPLICANT_TARGET_OCCUPATIONS', (applicantId, occupations) => ({ applicantId, occupations }))
export const setApplicantTargetOccupationsSuccess = createAction('SET_APPLICANT_TARGET_OCCUPATIONS_SUCCESS')
export const setApplicantTargetOccupationsFailure = createAction('SET_APPLICANT_TARGET_OCCUPATIONS_FAILURE', (error) => ({ error }))
export const getApplicantInfo = createAction('GET_APPLICANT_INFO', (applicantId, params) => ({ applicantId, params }))
export const getApplicantInfoSuccess = createAction('GET_APPLICANT_INFO_SUCCESS', (applicantInfo) => ({ applicantInfo }))
export const getApplicantInfoFailure = createAction('GET_APPLICANT_INFO_FAILURE', (error) => ({ error }))
export const getApplicantTargetOccupations = createAction('GET_APPLICANT_TARGET_OCCUPATIONS', (applicantId) => ({ applicantId }))
export const getApplicantTargetOccupationsSuccess = createAction('GET_APPLICANT_TARGET_OCCUPATIONS_SUCCESS', (occupations) => ({ occupations }))
export const getApplicantTargetOccupationsFailure = createAction('GET_APPLICANT_TARGET_OCCUPATIONS_FAILURE', (error) => ({ error }))
export const resetApplicantFilters = createAction('RESET_APPLICANT_FILTERS')
export const rollbackApplicantToStatus = createAction('ROLLBACK_APPLICANT_TO_STATUS', (projectId, rollbackModel) => ({ projectId, rollbackModel }))
export const rollbackApplicantToStatusSuccess = createAction('ROLLBACK_APPLICANT_TO_STATUS_SUCCESS')
export const rollbackApplicantToStatusFailure = createAction('ROLLBACK_APPLICANT_TO_STATUS_FAILURE', (error) => ({ error }))
export const importSpringerApplicantsFile = createAction('IMPORT_APPLICANTS_FILE', (projectId, file) => ({ projectId, file }))
export const importSpringerApplicantsFileSuccess = createAction('IMPORT_APPLICANTS_FILE_SUCCESS')
export const importSpringerApplicantsFileFailure = createAction('IMPORT_APPLICANTS_FILE_FAILURE', (error) => ({ error }))
export const dismissApplicantFileUploadResult = createAction('DISMISS_APPLICANT_FILE_UPLOAD_RESULT')
export const toggleSafeMode = createAction('TOGGLE_SAFE_MODE', (coachingId) => ({ coachingId }))
export const importKoulutusporttiApplicantsFile = createAction('IMPORT_KOULUTUSPORTTI_APPLICANTS_FILE', (projectId, file) => ({ projectId, file }))
export const importKoulutusporttiApplicantsFileSuccess = createAction('IMPORT_KOULUTUSPORTTI_APPLICANTS_FILE_SUCCESS')
export const importKoulutusporttiApplicantsFileFailure = createAction('IMPORT_KOULUTUSPORTTI_APPLICANTS_FILE_FAILURE', (error) => ({ error }))
export const getApplicantLanguageSkills = createAction('GET_APPLICANT_LANGUAGE_SKILLS', (coachingId) => ({ coachingId }))
export const getApplicantLanguageSkillsSuccess = createAction('GET_APPLICANT_LANGUAGE_SKILLS_SUCCESS', (applicantLanguageSkills) => ({ applicantLanguageSkills }))
export const getApplicantLanguageSkillsFailure = createAction('GET_APPLICANT_LANGUAGE_SKILLS_FAILURE', (error) => ({ error }))
export const saveApplicantLanguageSkills = createAction('SAVE_APPLICANT_LANGUAGE_SKILLS', (applicantLanguageSkills) => ({ applicantLanguageSkills }))
export const saveApplicantLanguageSkillsSuccess = createAction('SAVE_APPLICANT_LANGUAGE_SKILLS_SUCCESS')
export const saveApplicantLanguageSkillsFailure = createAction('SAVE_APPLICANT_LANGUAGE_SKILLS_FAILURE', (error) => ({ error }))
export const deleteApplicantLanguageSkill = createAction('DELETE_APPLICANT_LANGUAGE_SKILL', (projectId, applicantLanguageId, coachingId) => ({ projectId, applicantLanguageId, coachingId }))
export const deleteApplicantLanguageSkillSuccess = createAction('DELETE_APPLICANT_LANGUAGE_SKILL_SUCCESS')
export const deleteApplicantLanguageSkillFailure = createAction('DELETE_APPLICANT_LANGUAGE_SKILL_FAILURE', (error) => ({ error }))
export const getCooperationTypes = createAction('GET_COOPERATION_TYPES')
export const getCooperationTypesSuccess = createAction('GET_COOPERATION_TYPES_SUCCESS', (cooperationTypes, cooperationTypeOptions) => ({ cooperationTypes, cooperationTypeOptions }))
export const getCooperationTypesFailure = createAction('GET_COOPERATION_TYPES_FAILURE', (error) => ({ error }))
export const createInternship = createAction('CREATE_INTERNSHIP', (projectId, internship) => ({ projectId, internship }))
export const createInternshipSuccess = createAction('CREATE_INTERNSHIP_SUCCESS')
export const createInternshipFailure = createAction('CREATE_INTERNSHIP_FAILURE', (error) => ({ error }))
export const getApplicantInternships = createAction('GET_APPLICANT_INTERNSHIPS', (coachingId) => ({ coachingId }))
export const getApplicantInternshipsSuccess = createAction('GET_APPLICANT_INTERNSHIPS_SUCCESS', (applicantInternships) => ({ applicantInternships }))
export const getApplicantInternshipsFailure = createAction('GET_APPLICANT_INTERNSHIPS_FAILURE', (error) => ({ error }))
export const editInternship = createAction('EDIT_INTERNSHIP', (projectId, internshipId, internship) => ({ projectId, internshipId, internship }))
export const editInternshipSuccess = createAction('EDIT_INTERNSHIP_SUCCESS')
export const editInternshipFailure = createAction('EDIT_INTERNSHIP_FAILURE', (error) => ({ error }))
export const deleteInternship = createAction('DELETE_INTERNSHIP', (projectId, internshipId, coachingId) => ({ projectId, internshipId, coachingId }))
export const deleteInternshipSuccess = createAction('DELETE_INTERNSHIP_SUCCESS')
export const deleteInternshipFailure = createAction('DELETE_INTERNSHIP_FAILURE', (error) => ({ error }))
export const startInitialAssessmentRequest = createAction('START_INITIAL_ASSESSMENT_REQUEST', (userIds, allApplicantsSelected, coachId) => ({ userIds, allApplicantsSelected, coachId }))
export const startInitialAssessmentSuccess = createAction('START_INITIAL_ASSESSMENT_SUCCESS')
export const startInitialAssessmentFailure = createAction('START_INITIAL_ASSESSMENT_FAILURE', (error) => ({ error }))
export const endInitialAssessmentRequest = createAction('END_INITIAL_ASSESSMENT_REQUEST', (userIds, allApplicantsSelected) => ({ userIds, allApplicantsSelected }))
export const endInitialAssessmentSuccess = createAction('END_INITIAL_ASSESSMENT_SUCCESS')
export const endInitialAssessmentFailure = createAction('END_INITIAL_ASSESSMENT_FAILURE', (error) => ({ error }))

/* ------------- Reducers ------------- */

const reducers = createReducer(
  {
    [startup]: (state) => state.merge({ fetching: true }),
    [getApplicants]: (state) => state.merge({ fetching: true }),
    [getApplicantsSuccess]: (state, { applicants, applicantsTotal, allIds, allCoachingIds }) => {
      /* First fetch */
      if (state.page === 1) {
        /* First results to the screen */
        const firstResults = applicants.slice(0, state.pageSize)
        return state.merge({
          fetching: false,
          applicantsTotal,
          allIds,
          allCoachingIds,
          applicants,
          visibleApplicants: [...firstResults]
        })
      }
      /* Add next results to the applicants array */
      return state.merge({
        fetching: false,
        applicantsTotal,
        allIds,
        allCoachingIds,
        applicants: [...state.applicants, ...applicants],
        visibleApplicants: [...applicants]
      })
    },
    [getApplicantsFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [changeApplicantsPage]: (state, { page }) => {
      const nextApplicants = state.applicants.slice((state.pageSize * (page - 1)), (state.pageSize * (page)))
      return state.merge({ page, visibleApplicants: [...nextApplicants] })
    },
    [changeApplicantRowsPerPage]: (state, { pageSize }) => state.merge({ pageSize, page: 1, applicants: [] }),
    [setApplicantTrainer]: (state, { projectId, applicantId, trainerId, begin }) => {
      const updatedApplicants = state.visibleApplicants.map((applicant) => {
        if (applicant.userId === applicantId && applicant.projectId === projectId) {
          return { ...applicant, coachId: trainerId, begin }
        }
        return { ...applicant }
      })
      return state.merge({
        error: null,
        visibleApplicants: updatedApplicants,
        previousVisibleApplicants: state.visibleApplicants
      })
    },
    [setApplicantTrainerSuccess]: (state, { applicantId, trainerId, projectId }) => {
      const index = state.applicants.findIndex(applicant => applicant.userId === applicantId && applicant.projectId === projectId)
      const updatedApplicant = { ...state.applicants[index], coachId: trainerId }
      const updatedApplicants = [
        ...state.applicants.slice(0, index),
        updatedApplicant,
        ...state.applicants.slice(index + 1)
      ]
      return state.merge({ applicants: updatedApplicants })
    },
    [setApplicantTrainerFailure]: (state, { error }) => {
      return state.merge({
        error,
        visibleApplicants: state.previousVisibleApplicants,
        previousVisibleApplicants: []
      })
    },
    [setApplicantStartDate]: (state, { projectId, applicantId, trainerId, date }) => {
      const updatedApplicants = state.visibleApplicants.map((applicant) => {
        if (applicant.userId === applicantId && applicant.projectId === projectId) {
          return { ...applicant, begin: date }
        }
        return { ...applicant }
      })
      return state.merge({
        error: null,
        visibleApplicants: updatedApplicants,
        previousVisibleApplicants: state.visibleApplicants,
        serviceActionLoading: true
      })
    },
    [setApplicantStartDateSuccess]: (state, { applicantId, date, projectId }) => {
      const index = state.applicants.findIndex(applicant => applicant.userId === applicantId && applicant.projectId === projectId)
      const updatedApplicant = { ...state.applicants[index], begin: date }
      const updatedApplicants = [
        ...state.applicants.slice(0, index),
        updatedApplicant,
        ...state.applicants.slice(index + 1)
      ]
      return state.merge({ applicants: updatedApplicants, serviceActionLoading: false })
    },
    [setApplicantStartDateFailure]: (state, { error }) => {
      return state.merge({
        error,
        visibleApplicants: state.previousVisibleApplicants,
        previousVisibleApplicants: [],
        serviceActionLoading: false
      })
    },
    [toggleSelectAllApplicants]: (state) => {
      if (state.exclude.length) {
        return state.merge({ exclude: [] })
      } else if (state.select.length) {
        return state.merge({ select: [], selectAll: !state.selectAll })
      }
      return state.merge({ selectAll: !state.selectAll })
    },
    [toggleSelectApplicant]: (state, { applicantId, projectId, status }) => {
      const check = applicant => applicant.userId === applicantId && applicant.projectId === projectId
      if (state.selectAll) {
        if (state.exclude.findIndex(check) !== -1) {
          const exclude = state.exclude.filter((applicant) => applicant.userId !== applicantId || applicant.projectId !== projectId)
          return state.merge({ exclude })
        }
        return state.merge({ exclude: [...state.exclude, { userId: applicantId, projectId, status }] })
      }
      if (state.select.findIndex(check) !== -1) {
        const select = state.select.filter((applicant) => applicant.userId !== applicantId || applicant.projectId !== projectId)
        return state.merge({ select })
      }
      return state.merge({ select: [...state.select, { userId: applicantId, projectId, status }] })
    },
    [resetApplicantSelect]: (state) => state.merge({ exclude: [], select: [], selectAll: false }),
    [sendMail]: (state, { subject, message }) => state.merge({ emailFetching: true, error: null }),
    [sendMailSuccess]: (state) => state.merge({ emailFetching: false }),
    [sendMailFailure]: (state, { error }) => state.merge({ error, emailFetching: false }),
    [sendSms]: (state, { message }) => state.merge({ smsFetching: true, error: null }),
    [sendSmsSuccess]: (state) => state.merge({ smsFetching: false }),
    [sendSmsFailure]: (state, { error }) => state.merge({ error, smsFetching: false }),
    [endService]: (state, { projectId, applicantId, resultId, end }) => state.merge({ serviceActionLoading: true, error: null }),
    [endServiceSuccess]: (state) => state.merge({ serviceActionLoading: false }),
    [endServiceFailure]: (state, { error }) => state.merge({ error, serviceActionLoading: false }),
    [setApplicantFilters]: (state, { applicantFilters }) => {
      return state.merge({
        applicantFilters,
        // Reset applicant list state
        applicants: [],
        visibleApplicants: [],
        previousVisibleApplicants: [],
        applicantsTotal: 0,
        pageSize: state.pageSize,
        page: 1
      })
    },
    [getTalentStatuses]: (state) => state.merge({ fetching: true, error: null }),
    [getTalentStatusesSuccess]: (state, { talentStatuses }) => state.merge({ fetching: false, talentStatuses }),
    [getTalentStatusesFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [exportApplicantsToExcel]: (state) => state.merge({ exporting: true }),
    [exportApplicantsToExcelSuccess]: (state) => state.merge({ exporting: false }),
    [exportApplicantsToExcelFailure]: (state, { error }) => state.merge({ error, exporting: false }),
    [exportCertificateTemplateExcel]: (state) => state.merge({ exporting: true }),
    [exportCertificateTemplateExcelSuccess]: (state) => state.merge({ exporting: false }),
    [exportCertificateTemplateExcelFailure]: (state, { error }) => state.merge({ error, exporting: false }),
    [reloadVisibleApplicants]: (state) => state.merge({ fetching: true }),
    [reloadVisibleApplicantsSuccess]: (state, { applicants, applicantsTotal, allIds, allCoachingIds }) => {
      const newApplicants = [
        ...state.applicants.slice(0, ((state.page - 1) * state.pageSize)),
        ...applicants,
        ...state.applicants.slice((state.page * state.pageSize))
      ]
      const visibleApplicants = applicants
      return state.merge({ fetching: false, applicants: newApplicants, visibleApplicants, applicantsTotal, allIds, allCoachingIds })
    },
    [reloadVisibleApplicantsFailure]: (state, { error }) => state.merge({ error, fetching: false }),
    [rollbackApplicant]: (state, { projectId, applicantId }) => state.merge({ rollbackFetching: true }),
    [rollbackApplicantSuccess]: (state) => state.merge({ rollbackFetching: false }),
    [rollbackApplicantFailure]: (state, { error }) => state.merge({ error, rollbackFetching: false }),
    [changeProject]: (state, { projectId, applicantId, newProjectId }) => state.merge({ changeProjectFetching: true }),
    [changeProjectSuccess]: (state) => state.merge({ changeProjectFetching: false }),
    [changeProjectFailure]: (state, { error }) => state.merge({ error, changeProjectFetching: false }),
    [setApplicantInfo]: (state, { applicant }) => state.merge({ setApplicantInfoFetching: true }),
    [setApplicantInfoSuccess]: (state) => state.merge({ setApplicantInfoFetching: false }),
    [setApplicantInfoFailure]: (state, { error }) => state.merge({ error, setApplicantInfoFetching: false }),
    [setApplicantTargetOccupations]: (state, { applicantId, occupations }) => state.merge({ fetching: true }),
    [setApplicantTargetOccupationsSuccess]: (state) => state.merge({ fetching: false }),
    [setApplicantTargetOccupationsFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [getApplicantInfo]: (state, { applicantId, params }) => state.merge({ applicantInfo: null, fetching: true }),
    [getApplicantInfoSuccess]: (state, { applicantInfo }) => state.merge({ applicantInfo, fetching: false }),
    [getApplicantInfoFailure]: (state, { error }) => state.merge({ fetching: false, error, applicantInfo: null }),
    [getApplicantTargetOccupations]: (state, { applicantId }) => state.merge({ fetching: true }),
    [getApplicantTargetOccupationsSuccess]: (state, { occupations }) => state.merge({ fetching: false, occupations }),
    [getApplicantTargetOccupationsFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [resetApplicantFilters]: (state) => state.merge({ applicantFilters: {} }),
    [rollbackApplicantToStatus]: (state, { projectId, rollbackModel }) => state.merge({ rollbackFetching: true, serviceActionLoading: true }),
    [rollbackApplicantToStatusSuccess]: (state) => state.merge({ rollbackFetching: false, serviceActionLoading: false }),
    [rollbackApplicantToStatusFailure]: (state, { error }) => state.merge({ error, rollbackFetching: false, serviceActionLoading: false }),
    [importSpringerApplicantsFile]: (state, { projectId, file }) => state.merge({ uploadingFile: true, springerFileUploadFailure: false, springerFileUploadSuccess: false }),
    [importSpringerApplicantsFileSuccess]: (state) => state.merge({ uploadingFile: false, springerFileUploadSuccess: true, springerFileUploadFailure: false }),
    [importSpringerApplicantsFileFailure]: (state, { error }) => state.merge({ uploadingFile: false, error, springerFileUploadFailure: true }),
    [dismissApplicantFileUploadResult]: (state) => state.merge({ springerFileUploadSuccess: false, springerFileUploadFailure: false }),
    [toggleSafeMode]: (state, { coachingId }) => state.merge({ safeModeCoaching: coachingId, page: 1 }),
    [importKoulutusporttiApplicantsFile]: (state, { projectId, file }) => state.merge({ uploadingFile: true, koulutusporttiFileUploadFailure: false, koulutusporttiFileUploadSuccess: false }),
    [importKoulutusporttiApplicantsFileSuccess]: (state) => state.merge({ uploadingFile: false, koulutusporttiFileUploadSuccess: true, koulutusporttiFileUploadFailure: false }),
    [importKoulutusporttiApplicantsFileFailure]: (state, { error }) => state.merge({ uploadingFile: false, error, koulutusporttiFileUploadFailure: true }),
    [getApplicantLanguageSkills]: (state) => state.merge({ fetching: true, error: null, applicantLanguageSkills: [] }),
    [getApplicantLanguageSkillsSuccess]: (state, { applicantLanguageSkills }) => state.merge({ fetching: false, applicantLanguageSkills }),
    [getApplicantLanguageSkillsFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [saveApplicantLanguageSkills]: (state) => state.merge({ fetching: true, error: null, savingLanguages: true }),
    [saveApplicantLanguageSkillsSuccess]: (state) => state.merge({ fetching: false, savingLanguages: false }),
    [saveApplicantLanguageSkillsFailure]: (state, { error }) => state.merge({ fetching: false, error, savingLanguages: false }),
    [deleteApplicantLanguageSkill]: (state) => state.merge({ fetching: true, error: null, savingLanguages: true }),
    [deleteApplicantLanguageSkillSuccess]: (state) => state.merge({ fetching: false, savingLanguages: false }),
    [deleteApplicantLanguageSkillFailure]: (state, { error }) => state.merge({ fetching: false, error, savingLanguages: false }),
    [getCooperationTypes]: (state) => state.merge({ fetching: true, error: null, cooperationTypes: [] }),
    [getCooperationTypesSuccess]: (state, { cooperationTypes, cooperationTypeOptions }) => state.merge({ fetching: false, cooperationTypes, cooperationTypeOptions }),
    [getCooperationTypesFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [createInternship]: (state) => state.merge({ fetching: true, error: null, savingInternship: true }),
    [createInternshipSuccess]: (state) => state.merge({ fetching: false, savingInternship: false }),
    [createInternshipFailure]: (state, { error }) => state.merge({ fetching: false, error, savingInternship: false }),
    [getApplicantInternships]: (state) => state.merge({ fetching: true, error: null, applicantInternships: [] }),
    [getApplicantInternshipsSuccess]: (state, { applicantInternships }) => state.merge({ fetching: false, applicantInternships }),
    [getApplicantInternshipsFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [editInternship]: (state) => state.merge({ fetching: true, error: null, savingInternship: true }),
    [editInternshipSuccess]: (state) => state.merge({ fetching: false, savingInternship: false }),
    [editInternshipFailure]: (state, { error }) => state.merge({ fetching: false, error, savingInternship: false }),
    [deleteInternship]: (state) => state.merge({ fetching: true, error: null, savingInternship: true }),
    [deleteInternshipSuccess]: (state) => state.merge({ fetching: false, savingInternship: false }),
    [deleteInternshipFailure]: (state, { error }) => state.merge({ fetching: false, error, savingInternship: false }),
    [startInitialAssessmentRequest]: (state, { userIds, allApplicantsSelected, coachId }) => state.merge({ fetching: true, assessmentInProgress: true, error: null }),
    [startInitialAssessmentSuccess]: (state) => state.merge({ fetching: false, assessmentInProgress: false }),
    [startInitialAssessmentFailure]: (state, { error }) => state.merge({ fetching: false, error, assessmentInProgress: false }),
    [endInitialAssessmentRequest]: (state, { userIds, allApplicantsSelected }) => state.merge({ fetching: true, assessmentInProgress: true, error: null }),
    [endInitialAssessmentSuccess]: (state) => state.merge({ fetching: false, assessmentInProgress: false }),
    [endInitialAssessmentFailure]: (state, { error }) => state.merge({ fetching: false, error, assessmentInProgress: false })
  },
  INITIAL_STATE
)

export default reducers
