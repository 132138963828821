import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import Badge from '@mui/material/Badge'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FilterIcon from '@mui/icons-material/FilterList'
import ToolTip from './ToolTip'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  root: {
    padding: '0px 20px',
    color: COLORS.white,
    backgroundColor: COLORS.green,
    display: 'flex',
    justifyContent: 'space-between',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%',
    maxWidth: 1360,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 20px)'
    },
    minHeight: 56
  },
  filterRoot: {
    backgroundColor: COLORS.orange
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: {
    color: COLORS.white
  },
  badge: {
    top: 5,
    right: 5
  },
  badgeHidden: {
    opacity: 0
  },
  badgeContent: {
    fontSize: 9
  }
})

class TableToolbar extends Component {
  renderBadgeContent = () => {
    const { classes, filterCount } = this.props
    if (filterCount) {
      return <span className={classes.badgeContent}>{filterCount}</span>
    }
    return ''
  }

  renderFilter = () => {
    const { classes, t, openFilters, filterCount } = this.props
    return (
      <ToolTip title={t('filters')}>
        <Badge
          badgeContent={this.renderBadgeContent()}
          color='primary'
          classes={{ badge: filterCount ? classes.badge : classes.badgeHidden }}
        >
          <IconButton aria-label='Filters' onClick={openFilters}>
            <FilterIcon className={classes.icon} />
          </IconButton>
        </Badge>
      </ToolTip>
    )
  }

  renderToolbar = () => {
    const { classes, children, headerText } = this.props
    return (
      <Toolbar className={`${classes.root} ${classes.filterRoot}`}>
        <Typography color='inherit' variant='h6'>
          {headerText}
        </Typography>
        <div className={classes.actions}>
          {children}
          {this.renderFilter()}
        </div>
      </Toolbar>
    )
  }

  render () {
    return (
      this.renderToolbar()
    )
  }
}

export default withStyles(styles)(withTranslation()(TableToolbar))
