import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import * as ApplicantsActions from '../redux/ApplicantsRedux'
import * as NotesActions from '../redux/NotesRedux'
import * as ProjectsActions from '../redux/ProjectsRedux'
import { getFilterCount } from '../utils/transforms'
import * as AttachmentsActions from '../redux/AttachmentsRedux'
import * as CoachesActions from '../redux/CoachesRedux'
import * as CoachingEntriesActions from '../redux/CoachingEntriesRedux'
import * as AreasActions from '../redux/AreasRedux'

/* Material-UI components */
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

/* Custom components */
import ApplicantRow from '../components/applicants/ApplicantRow'
import ApplicantRowMobile from '../components/applicants/ApplicantRowMobile'
import ApplicantTableHeader from '../components/applicants/ApplicantTableHeader'
import SpringerTablePagination from '../components/applicants/SpringerTablePagination'
import ApplicantsTableToolbar from '../components/applicants/ApplicantsTableToolbar'
import MessageForm from '../components/applicants/MessageForm'
import ApplicantFilterDialog from '../components/applicants/ApplicantFilterDialog'
import ActionBar from '../components/ActionBar'
import MobileApplicantActionMenu from '../components/applicants/MobileApplicantActionMenu'
import SmsForm from '../components/applicants/SmsForm'
import EndServiceDialog from '../components/applicants/EndServiceDialog'
import ChangeProjectDialog from '../components/applicants/ChangeProjectDialog'
import ApplicantDialog from '../components/applicants/ApplicantDialog'
import RollbackAlertDialog from '../components/applicants/RollbackAlertDialog'
import CoachingEntriesDialog from '../components/entries/CoachingEntriesDialog'
import NotesDialog from '../components/notes/NotesDialog'
import ApplicantStatusDialog from '../components/applicants/ApplicantStatusDialog'
import ApplicantFileUploadDialog from '../components/applicants/ApplicantFileUploadDialog'
import InitialAssessmentDialog from '../components/applicants/InitialAssessmentDialog'
import withMediaQuery from '../utils/withMediaQuery'

const styles = {
  container: {
    width: '100%',
    maxWidth: 1360,
    margin: '0 auto',
    paddingBottom: 80
  },
  root: {
    width: '100%'
  },
  tableContainer: {
    overflowX: 'auto',
    width: '100%',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    // Prevent vertical scrollbar when opening dropdown
    paddingBottom: 300,
    marginBottom: -300,
    marginTop: -2
  },
  table: {
    minWidth: 700
  },
  spinner: {
    display: 'flex',
    padding: '64px 16px',
    justifyContent: 'center'
  },
  centered: {
    margin: '32px 0 16px',
    textAlign: 'center',
    width: '100%'
  },
  scrollbar: {
    minHeight: 240
  }
}

class ApplicantsScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      messageDialog: false,
      smsDialog: false,
      filterDialog: false,
      endServiceDialog: false,
      rollbackDialog: false,
      changeProjectDialog: false,
      applicantDialog: false,
      editingApplicant: null,
      meetingsApplicant: null,
      nextMeetingForDialog: null,
      notesDialog: false,
      applicantStatusDialog: false,
      fileUploadDialog: false,
      initialAssessmentDialog: false
    }
  }

  handleChangePage = (event, page) => this.props.changeApplicantsPage(page + 1)
  handleChangeRowsPerPage = (event) => this.props.changeApplicantRowsPerPage(event.target.value)
  setStartDate = (projectId, applicantId, trainerId, date) => {
    this.props.setApplicantStartDate(projectId, applicantId, trainerId, date)
  }

  setTrainer = (projectId, applicantId, trainerId, begin) => {
    this.props.setApplicantTrainer(projectId, applicantId, trainerId, begin)
  }

  // Determine if id is selected
  isSelected = (applicantId, projectId) => {
    const { select, selectAll, exclude } = this.props
    const check = applicant => applicant.userId === applicantId && applicant.projectId === projectId
    if (selectAll) {
      return !(exclude.findIndex(check) !== -1)
    }
    return select.findIndex(check) !== -1
  }

  // Get selected users count
  getSelectedCount = () => {
    const { select, selectAll, exclude, applicantsTotal } = this.props
    if (selectAll) {
      return exclude.length ? (applicantsTotal - exclude.length) : applicantsTotal
    }
    return select.length
  }

  // Determine if list is empty
  isEmptyApplicantsList = () => {
    const { applicants, fetching } = this.props
    if (!fetching && !applicants.length) {
      return true
    }
    return false
  }

  openMail = () => this.setState({ messageDialog: true })
  closeMail = () => this.setState({ messageDialog: false })
  openSms = () => this.setState({ smsDialog: true })
  closeSms = () => this.setState({ smsDialog: false })
  toggleFilterDialog = () => this.setState({ filterDialog: !this.state.filterDialog })
  handleToggleFilterDialog = () => this.setState({ filterDialog: !this.state.filterDialog })
  handleToggleApplicantDialog = () => this.setState({ applicantDialog: !this.state.applicantDialog })
  handleToggleNotesDialog = () => this.setState({ notesDialog: !this.state.notesDialog })
  toggleApplicantStatusDialog = () => this.setState({ applicantStatusDialog: !this.state.applicantStatusDialog })
  toggleFileUploadDialog = () => this.setState({ fileUploadDialog: !this.state.fileUploadDialog })
  openEndService = () => this.setState({ endServiceDialog: true })
  closeEndService = () => this.setState({ endServiceDialog: false })
  openRollback = (applicant) => {
    this.setEditingApplicant(applicant)
    this.setState({ rollbackDialog: true })
  }

  closeRollback = () => this.setState({ rollbackDialog: false })
  openChangeProject = () => {
    const applicant = this.getSelectedApplicant()
    this.props.getProjectsInCity(applicant.areaId)
    this.setState({ changeProjectDialog: true })
  }

  closeChangeProject = () => this.setState({ changeProjectDialog: false })

  openApplicantDialog = (applicant) => {
    const { getApplicantInfo, getApplicantTargetOccupations, getCoachingAttachments } = this.props
    const targetApplicant = applicant || this.getSelectedApplicant()
    if (targetApplicant) {
      const { userId, projectId, coachingId } = targetApplicant
      this.setEditingApplicant(targetApplicant)
      /* Get data for applicant dialog */
      getApplicantInfo(userId, { projectId })
      getApplicantTargetOccupations(userId)
      getCoachingAttachments(coachingId)
      this.handleToggleApplicantDialog()
    }
  }

  openInitialAssessmentDialog = () => this.setState({ initialAssessmentDialog: true })
  closeInitialAssessmentDialog = () => this.setState({ initialAssessmentDialog: false })

  openNotesDialog = (applicant) => {
    this.setEditingApplicant(applicant)
    this.props.getApplicantNotes(applicant.coachingId)
    this.handleToggleNotesDialog()
  }

  openApplicantStatusDialog = (applicant) => {
    this.setEditingApplicant(applicant)
    this.toggleApplicantStatusDialog()
  }

  toggleCoachingEntriesDialog = (applicant, nextMeeting) => this.setState({ meetingsApplicant: applicant, nextMeetingForDialog: nextMeeting })

  setEditingApplicant = (applicant) => {
    if (applicant) this.setState({ editingApplicant: applicant })
    else {
      const openingApplicant = this.getSelectedApplicant()
      this.setState({ editingApplicant: openingApplicant })
    }
  }

  setApplicantDialogFilters = (obj) => {
    const { applicantFilters } = this.props
    // Merge properties from obj to applicantfilters
    const newObj = Object.assign({}, applicantFilters, obj)
    this.props.setApplicantFilters(newObj)
  }

  setOrderByToApplicantFilters = (orderBy) => {
    const { applicantFilters } = this.props
    const newObj = { ...applicantFilters, orderBy }
    this.props.setApplicantFilters(newObj)
  }

  // Show loader on initial page load
  renderInitSpinner = () => {
    const { fetching, visibleApplicants } = this.props
    // Initial loading
    if (fetching && !visibleApplicants.length) {
      return (
        <div style={styles.spinner}>
          <CircularProgress />
        </div>
      )
    }
  }

  renderEmptyMessage = () => {
    const { t } = this.props
    return (
      <Typography sx={styles.centered}>
        {t('no_results')}
      </Typography>
    )
  }

  renderMobileTable = () => {
    if (this.isEmptyApplicantsList()) return this.renderEmptyMessage()

    return this.props.visibleApplicants.map(applicant => (
      <ApplicantRowMobile
        key={`${applicant.userId}/${applicant.projectId}`}
        applicant={applicant}
        openApplicantDialog={this.openApplicantDialog}
        openRollback={this.openRollback}
        setStartDate={this.setStartDate}
        setTrainer={this.setTrainer}
        isSelected={this.isSelected}
        selectApplicant={this.props.toggleSelectApplicant}
        openCoachingEntriesDialog={this.toggleCoachingEntriesDialog}
        loadingCoachingEntries={this.props.loadingCoachingEntries}
        getCoachingSummary={this.props.getCoachingSummary}
        coachingSummary={this.props.coachingEntrySummary}
      />
    ))
  }

  renderApplicantRow = (applicant, index) => {
    const isSafeModeOnForCoaching = this.props.safeModeCoaching === applicant.coachingId
    return (
      <Fragment key={`${applicant.userId}/${applicant.projectId}`}>
        <ApplicantRow
          key={index}
          data-testid={`applicant-row-${index}`}
          applicant={applicant}
          openApplicantDialog={this.openApplicantDialog}
          openRollback={this.openRollback}
          setStartDate={this.setStartDate}
          setTrainer={this.setTrainer}
          isSelected={this.isSelected}
          selectApplicant={this.props.toggleSelectApplicant}
          openCoachingEntriesDialog={this.toggleCoachingEntriesDialog}
          openNotesDialog={this.openNotesDialog}
          openApplicantStatusDialog={this.openApplicantStatusDialog}
          toggleSafeMode={this.props.toggleSafeMode}
          safeModeOn={isSafeModeOnForCoaching}
          loadingCoachingEntries={this.props.loadingCoachingEntries}
          getCoachingSummary={this.props.getCoachingSummary}
          coachingSummary={this.props.coachingEntrySummary}
        />
      </Fragment>
    )
  }

  // Desktop view
  renderFullTable = () => {
    const { selectAll, exclude, toggleSelectAll, applicantFilters } = this.props

    return (
      <div style={styles.tableContainer}>
        <PerfectScrollbar sx={styles.scrollbar}>
          <Table sx={styles.table}>
            <ApplicantTableHeader
              selectAll={selectAll}
              indeterminate={!!exclude.length}
              onChangeSelect={toggleSelectAll}
              filterCount={getFilterCount(applicantFilters)}
              requestOrderBy={this.setOrderByToApplicantFilters}
            />
            <TableBody sx={{ root: styles.scrollBar }}>
              {this.props.visibleApplicants.map((applicant, index) => (
                this.renderApplicantRow(applicant, index)
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
        {this.isEmptyApplicantsList() && this.renderEmptyMessage()}
      </div>
    )
  }

  // Render pagination controls
  renderPagination = () => {
    const { page, fetching, applicantsTotal, pageSize, t } = this.props
    return (
      <SpringerTablePagination
        page={page - 1}
        count={applicantsTotal}
        handleChangePage={this.props.changeApplicantsPage}
        handleChangeRowsPerPage={this.props.changeApplicantRowsPerPage}
        fetching={fetching}
        pageSize={pageSize}
        labelRowsPerPage={t('applicants_per_page')}
      />
    )
  }

  renderMobileActionMenu = () => {
    const { applicantsTotal, applicantFilters } = this.props
    const selectedCount = this.getSelectedCount()
    return (
      <MobileApplicantActionMenu
        selectedCount={selectedCount}
        openMail={this.openMail}
        openSms={this.openSms}
        openApplicant={this.openApplicantDialog}
        endService={this.openEndService}
        showApplicant={this.showApplicant()}
        showEndService={this.showEndService()}
        filterCount={getFilterCount(applicantFilters)}
        exportExcel={this.exportExcel}
        applicantsTotal={applicantsTotal}
        showRollback={this.showRollback()}
        rollbackText={this.getRollbackText()}
        rollback={this.openRollback}
        changeProject={this.openChangeProject}
        showChangeProject={this.showChangeProject()}
      />
    )
  }

  // Render action bar on mobile
  renderActionBar = () => {
    const { selectAll, exclude, toggleSelectAll, applicantsTotal, applicantFilters } = this.props

    return (
      <ActionBar
        totalCount={applicantsTotal}
        selectAll={selectAll}
        indeterminate={!!exclude.length}
        toggleSelectAll={toggleSelectAll}
        openFilter={this.toggleFilterDialog}
        filterCount={getFilterCount(applicantFilters)}
        paginationComponent={this.renderPagination()}
        actionMenuComponent={this.renderMobileActionMenu()}
        openFileUpload={this.toggleFileUploadDialog}
      />
    )
  }

  // Get selected user
  getSelectedApplicant = () => {
    const { applicants, select } = this.props
    if (!select || !select.length || this.getSelectedCount() > 1) return null

    const index = applicants.findIndex(applicant => applicant.userId === select[0].userId && applicant.projectId === select[0].projectId)
    return applicants[index]
  }

  showApplicant = () => this.getSelectedCount() === 1

  // Determines if should show end service option
  showEndService = () => {
    if (this.getSelectedCount() === 1) {
      const applicant = this.getSelectedApplicant()
      if (applicant && applicant.begin && !applicant.end) {
        return true
      }
    }
    return false
  }

  // Determines if should show rollback option
  showRollback = () => {
    if (this.getSelectedCount() === 1) {
      const applicant = this.getSelectedApplicant()
      if (applicant && applicant.begin) return true
    }
    return false
  }

  showChangeProject = () => {
    if (this.getSelectedCount() === 1) {
      const applicant = this.getSelectedApplicant()
      if (applicant && !applicant.begin) return true
    }
    return false
  }

  getRollbackText = () => {
    const applicant = this.getSelectedApplicant() || this.state.editingApplicant
    if (applicant && applicant.begin) {
      if (applicant.end) return this.props.t('rollbackToActive')
      return this.props.t('rollbackToEnrolled')
    }
  }

  // Rollback user to previous state
  rollback = () => {
    const { projectId, userId } = this.getSelectedApplicant() || this.state.editingApplicant
    if (projectId && userId) {
      this.props.rollbackApplicant(projectId, userId)
    }
  }

  render () {
    const {
      emailFetching, smsFetching, serviceActionLoading,
      regionsWithCities, talentStatuses, coaches, endService, sendMail, sendSms, fetching,
      applicantsTotal, rollbackFetching, changeProject, changeProjectFetching,
      projectsInCity, setApplicantInfo, setApplicantInfoFetching, servicesOptions,
      setApplicantTargetOccupations, applicantInfo, applicantOccupations, user, applicantFilters,
      coachingResults, rollbackApplicantToStatus, coachingAttachments, safeModeCoaching, themeSMUp
    } = this.props
    const {
      messageDialog, smsDialog, filterDialog, endServiceDialog,
      changeProjectDialog, applicantDialog, rollbackDialog, applicantStatusDialog, fileUploadDialog,
      initialAssessmentDialog
    } = this.state
    return (
      <div style={styles.container}>
        {!themeSMUp &&
          <Box>
            {this.renderMobileTable()}
            {this.renderInitSpinner()}
            {this.renderActionBar()}
          </Box>}
        {themeSMUp &&
          <Box>
            {this.renderPagination()}
            <Paper sx={styles.root}>
              <ApplicantsTableToolbar
                applicantsTotal={applicantsTotal}
                user={user}
                selectedCount={this.getSelectedCount()}
                exporting={this.props.exporting}
                sendMail={this.openMail}
                sendSms={this.openSms}
                exportApplicantsToExcel={this.props.exportApplicantsToExcel}
                exportCertificateTemplateExcel={this.props.exportCertificateTemplateExcel}
                openFilters={this.toggleFilterDialog}
                openApplicant={this.openApplicantDialog}
                openInitialAssessment={this.openInitialAssessmentDialog}
                endService={this.openEndService}
                showApplicant={this.showApplicant()}
                showEndService={this.showEndService()}
                filterCount={getFilterCount(applicantFilters)}
                showRollback={this.showRollback()}
                rollbackText={this.getRollbackText()}
                rollback={this.openRollback}
                changeProject={this.openChangeProject}
                showChangeProject={this.showChangeProject()}
                openFileUpload={this.toggleFileUploadDialog}
              />
              {this.renderFullTable()}
              {this.renderInitSpinner()}
              {this.renderPagination()}
            </Paper>
          </Box>}
        <EndServiceDialog
          open={endServiceDialog}
          close={this.closeEndService}
          applicant={this.getSelectedApplicant()}
          endService={endService}
          fetching={serviceActionLoading}
        />
        <RollbackAlertDialog
          open={rollbackDialog}
          close={this.closeRollback}
          applicant={this.state.editingApplicant}
          rollback={this.rollback}
          rollbackText={this.getRollbackText()}
          fetching={rollbackFetching}
        />
        <ChangeProjectDialog
          open={changeProjectDialog}
          close={this.closeChangeProject}
          applicant={this.getSelectedApplicant()}
          projects={projectsInCity}
          changeProject={changeProject}
          fetching={changeProjectFetching}
        />
        <MessageForm
          open={messageDialog}
          close={this.closeMail}
          fetching={emailFetching}
          sendMail={sendMail}
        />
        <SmsForm
          open={smsDialog}
          close={this.closeSms}
          fetching={smsFetching}
          sendSms={sendSms}
        />
        <ApplicantFilterDialog
          open={filterDialog}
          onClose={this.handleToggleFilterDialog}
          setApplicantFilters={this.setApplicantDialogFilters}
          resetApplicantFilters={this.props.resetApplicantFilters}
          talentStatuses={talentStatuses}
          regions={regionsWithCities}
          services={servicesOptions}
          coaches={coaches}
          coachingResults={coachingResults}
          applicantFilters={applicantFilters}
          isSafeModeOn={!!safeModeCoaching}
          toggleSafeMode={this.props.toggleSafeMode}
          projects={this.props.filteredProjectsOptions}
          getFilteredProjectsOptions={this.props.getFilteredProjectsOptions}
          clearFilteredProjectsOptions={this.props.clearFilteredProjectsOptions}
        />
        <ApplicantDialog
          open={applicantDialog}
          onClose={this.handleToggleApplicantDialog}
          applicantInfo={applicantInfo}
          regionsWithCities={regionsWithCities}
          setApplicantInfo={setApplicantInfo}
          onOccupationsSave={setApplicantTargetOccupations}
          setApplicantInfoFetching={setApplicantInfoFetching}
          fetching={fetching}
          savedOccupations={applicantOccupations}
          attachments={coachingAttachments}
          coaching={this.state.editingApplicant}
          addAttachmentFile={this.props.addCoachingAttachment}
          attachmentLoading={this.props.attachmentLoading}
          deleteCoachingAttachment={this.props.deleteCoachingAttachment}
          searchPostalCodes={this.props.searchPostalCodes}
          searchedPostalCodes={this.props.searchedPostalCodes}
          searchingPostalCodes={this.props.searchingPostalCodes}
          countries={this.props.countries}
          getCountries={this.props.getCountries}
          languages={this.props.languages}
          getLanguages={this.props.getLanguages}
          clearSearchedPostalCodes={this.props.clearSearchedPostalCodes}
          getApplicantLanguageSkills={this.props.getApplicantLanguageSkills}
          getLanguageSkills={this.props.getLanguageSkills}
          getLanguageSkillLevels={this.props.getLanguageSkillLevels}
          languageSkills={this.props.languageSkills}
          applicantLanguageSkills={this.props.applicantLanguageSkills}
          languageSkillLevelOptions={this.props.languageSkillLevelOptions}
          savingLanguages={this.props.savingLanguages}
          saveLanguageSkills={this.props.saveLanguageSkills}
          deleteApplicantLanguage={this.props.deleteApplicantLanguage}
          getCooperationTypes={this.props.getCooperationTypes}
          cooperationTypeOptions={this.props.cooperationTypeOptions}
          createInternship={this.props.createInternship}
          savingInternship={this.props.savingInternship}
          applicantInternships={this.props.applicantInternships}
          getApplicantInternships={this.props.getApplicantInternships}
          editInternship={this.props.editInternship}
          deleteInternship={this.props.deleteInternship}
        />
        <CoachingEntriesDialog
          isOpen={Boolean(this.state.meetingsApplicant)}
          onClose={e => this.toggleCoachingEntriesDialog(null, null)}
          applicant={this.state.meetingsApplicant}
          nextMeeting={this.state.nextMeetingForDialog}
        />
        <NotesDialog
          isOpen={Boolean(this.state.notesDialog)}
          onClose={this.handleToggleNotesDialog}
          applicant={this.state.editingApplicant}
          notes={this.props.notes}
          fetchingNotes={this.props.fetchingNotes}
          activateNote={this.props.activateNote}
          passivateNote={this.props.passivateNote}
          editNote={this.props.editNote}
          addNote={this.props.addNote}
        />
        <ApplicantStatusDialog
          open={applicantStatusDialog}
          close={this.toggleApplicantStatusDialog}
          applicant={this.state.editingApplicant}
          talentStatuses={talentStatuses}
          fetching={serviceActionLoading}
          endService={endService}
          rollbackService={rollbackApplicantToStatus}
          activateService={this.setStartDate}
        />
        <ApplicantFileUploadDialog
          open={fileUploadDialog}
          close={this.toggleFileUploadDialog}
          importSpringerFile={this.props.importSpringerApplicantsFile}
          importKoulutusporttiFile={this.props.importKoulutusporttiApplicantsFile}
          services={servicesOptions}
          serviceProjectsOptions={this.props.filteredProjectsOptions}
          getServiceProjectsOptions={this.props.getFilteredProjectsOptions}
          dismissResult={this.props.dismissApplicantFileUploadResult}
          springerFileUploadSuccess={this.props.springerFileUploadSuccess}
          springerFileUploadFailure={this.props.springerFileUploadFailure}
          koulutusporttiFileUploadSuccess={this.props.koulutusporttiFileUploadSuccess}
          koulutusporttiFileUploadFailure={this.props.koulutusporttiFileUploadFailure}
        />
        <InitialAssessmentDialog
          open={initialAssessmentDialog}
          close={this.closeInitialAssessmentDialog}
          selectedApplicants={this.props.select}
          allApplicantsSelected={this.props.selectAll}
          fetching={fetching}
          coaches={coaches}
          startInitialAssessment={this.props.startInitialAssessment}
          endInitialAssessment={this.props.endInitialAssessment}
          assessmentInProgress={this.props.assessmentInProgress}
          applicantError={this.props.applicantError}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    applicants: state.applicants.applicants,
    visibleApplicants: state.applicants.visibleApplicants,
    fetching: state.applicants.fetching,
    page: state.applicants.page,
    pageSize: state.applicants.pageSize,
    selectAll: state.applicants.selectAll,
    select: state.applicants.select,
    exclude: state.applicants.exclude,
    coachingResults: state.coaches.coachingResults,
    regionsWithCities: state.areas.regionsWithCities,
    searchedPostalCodes: state.areas.searchedPostalCodes,
    searchingPostalCodes: state.areas.searchingPostalCodes,
    countries: state.areas.countries,
    languages: state.areas.languages,
    talentStatuses: state.applicants.talentStatuses,
    applicantsTotal: state.applicants.applicantsTotal,
    applicantFilters: state.applicants.applicantFilters,
    servicesOptions: state.services.servicesOptions,
    coaches: state.coaches.coaches,
    serviceActionLoading: state.applicants.serviceActionLoading,
    emailFetching: state.applicants.emailFetching,
    smsFetching: state.applicants.smsFetching,
    rollbackFetching: state.applicants.rollbackFetching,
    projectsInCity: state.projects.projectsInCity,
    changeProjectFetching: state.applicants.changeProjectFetching,
    setApplicantInfoFetching: state.applicants.setApplicantInfoFetching,
    user: state.user.user,
    applicantInfo: state.applicants.applicantInfo,
    applicantOccupations: state.applicants.occupations,
    notes: state.notes.notes,
    fetchingNotes: state.notes.fetching,
    uploadingFile: state.applicants.uploadingFile,
    projectsOptions: state.projects.projectsOptions,
    springerFileUploadSuccess: state.applicants.springerFileUploadSuccess,
    springerFileUploadFailure: state.applicants.springerFileUploadFailure,
    coachingAttachments: state.attachments.coachingAttachments,
    attachmentLoading: state.attachments.loading,
    safeModeCoaching: state.applicants.safeModeCoaching,
    loadingCoachingEntries: state.coachingEntries.loading,
    coachingEntrySummary: state.coachingEntries.coachingEntrySummary,
    koulutusporttiFileUploadSuccess: state.applicants.koulutusporttiFileUploadSuccess,
    koulutusporttiFileUploadFailure: state.applicants.koulutusporttiFileUploadFailure,
    filteredProjectsOptions: state.projects.filteredProjectsOptions,
    applicantLanguageSkills: state.applicants.applicantLanguageSkills,
    languageSkills: state.areas.languageSkills,
    languageSkillLevelOptions: state.areas.languageSkillLevelOptions,
    savingLanguages: state.applicants.savingLanguages,
    exporting: state.applicants.exporting,
    cooperationTypeOptions: state.applicants.cooperationTypeOptions,
    savingInternship: state.applicants.savingInternship,
    applicantInternships: state.applicants.applicantInternships,
    assessmentInProgress: state.applicants.assessmentInProgress,
    applicantError: state.applicants.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startup: () => dispatch(ApplicantsActions.startup()),
    changeApplicantsPage: (page) => dispatch(ApplicantsActions.changeApplicantsPage(page)),
    changeApplicantRowsPerPage: (rows) => dispatch(ApplicantsActions.changeApplicantRowsPerPage(rows)),
    setApplicantStartDate: (projectId, applicantId, trainerId, date) =>
      dispatch(ApplicantsActions.setApplicantStartDate(projectId, applicantId, trainerId, date)),
    setApplicantTrainer: (projectId, applicantId, trainerId, begin) =>
      dispatch(ApplicantsActions.setApplicantTrainer(projectId, applicantId, trainerId, begin)),
    toggleSelectAll: () => dispatch(ApplicantsActions.toggleSelectAllApplicants()),
    toggleSelectApplicant: (applicantId, projectId, status) =>
      dispatch(ApplicantsActions.toggleSelectApplicant(applicantId, projectId, status)),
    setApplicantFilters: (filters) => dispatch(ApplicantsActions.setApplicantFilters(filters)),
    exportApplicantsToExcel: () => dispatch(ApplicantsActions.exportApplicantsToExcel()),
    exportCertificateTemplateExcel: () => dispatch(ApplicantsActions.exportCertificateTemplateExcel()),
    endService: (projectId, applicantId, resultId, date) =>
      dispatch(ApplicantsActions.endService(projectId, applicantId, resultId, date)),
    getCoaches: () => dispatch(CoachesActions.getCoaches()),
    sendMail: (subject, message) => dispatch(ApplicantsActions.sendMail(subject, message)),
    sendSms: (message) => dispatch(ApplicantsActions.sendSms(message)),
    rollbackApplicant: (projectId, applicantId, talentStatus) =>
      dispatch(ApplicantsActions.rollbackApplicant(projectId, applicantId, talentStatus)),
    getProjectsInCity: (city) => dispatch(ProjectsActions.getProjectsInCity(city)),
    changeProject: (projectId, applicantId, newProjectId) =>
      dispatch(ApplicantsActions.changeProject(projectId, applicantId, newProjectId)),
    setApplicantInfo: (applicant) => dispatch(ApplicantsActions.setApplicantInfo(applicant)),
    resetSelect: () => dispatch(ApplicantsActions.resetApplicantSelect()),
    setApplicantTargetOccupations: (applicantId, occupations) => dispatch(ApplicantsActions.setApplicantTargetOccupations(applicantId, occupations)),
    getApplicantInfo: (applicantId, params) => dispatch(ApplicantsActions.getApplicantInfo(applicantId, params)),
    getApplicantTargetOccupations: (applicantId) => dispatch(ApplicantsActions.getApplicantTargetOccupations(applicantId)),
    resetApplicantFilters: () => dispatch(ApplicantsActions.resetApplicantFilters()),
    getApplicantNotes: (applicantId) => dispatch(NotesActions.getNotesRequest(applicantId)),
    activateNote: (projectId, noteId, applicantId) => dispatch(NotesActions.activateNoteRequest(projectId, noteId, applicantId)),
    passivateNote: (projectId, noteId, applicantId) => dispatch(NotesActions.passivateNoteRequest(projectId, noteId, applicantId)),
    editNote: (note) => dispatch(NotesActions.editNoteRequest(note)),
    addNote: (note) => dispatch(NotesActions.addNoteRequest(note)),
    rollbackApplicantToStatus: (projectId, rollbackModel) => dispatch(ApplicantsActions.rollbackApplicantToStatus(projectId, rollbackModel)),
    importSpringerApplicantsFile: (projectId, file) => dispatch(ApplicantsActions.importSpringerApplicantsFile(projectId, file)),
    getProjectsOptions: () => dispatch(ProjectsActions.getProjectsOptions()),
    dismissApplicantFileUploadResult: () => dispatch(ApplicantsActions.dismissApplicantFileUploadResult()),
    getCoachingAttachments: (coachingId) => dispatch(AttachmentsActions.getCoachingAttachmentsRequest(coachingId)),
    addCoachingAttachment: (projectId, coachingId, file) => dispatch(AttachmentsActions.addCoachingAttachmentRequest(projectId, coachingId, file)),
    deleteCoachingAttachment: (projectId, coachingId, fileId) => dispatch(AttachmentsActions.deleteCoachingAttachmentRequest(projectId, coachingId, fileId)),
    toggleSafeMode: (coachingId) => dispatch(ApplicantsActions.toggleSafeMode(coachingId)),
    getCoachingSummary: (coachingId) => dispatch(CoachingEntriesActions.getCoachingEntrySummaryText(coachingId)),
    importKoulutusporttiApplicantsFile: (projectId, file) => dispatch(ApplicantsActions.importKoulutusporttiApplicantsFile(projectId, file)),
    searchPostalCodes: (searchCode) => dispatch(AreasActions.searchPostalCodes(searchCode)),
    clearSearchedPostalCodes: () => dispatch(AreasActions.clearSearchedPostalCodes()),
    getCountries: () => dispatch(AreasActions.getCountries()),
    getLanguages: () => dispatch(AreasActions.getLanguages()),
    getFilteredProjectsOptions: (filters) => dispatch(ProjectsActions.getFilteredProjectsOptions(filters)),
    clearFilteredProjectsOptions: () => dispatch(ProjectsActions.clearFilteredProjectsOptions()),
    getApplicantLanguageSkills: (coachingId) => dispatch(ApplicantsActions.getApplicantLanguageSkills(coachingId)),
    getLanguageSkills: () => dispatch(AreasActions.getLanguageSkills()),
    getLanguageSkillLevels: () => dispatch(AreasActions.getLanguageSkillLevels()),
    saveLanguageSkills: (languageSkills) => dispatch(ApplicantsActions.saveApplicantLanguageSkills(languageSkills)),
    deleteApplicantLanguage: (projectId, applicantLanguageId, coachingId) => dispatch(ApplicantsActions.deleteApplicantLanguageSkill(projectId, applicantLanguageId, coachingId)),
    getCooperationTypes: () => dispatch(ApplicantsActions.getCooperationTypes()),
    createInternship: (projectId, internship) => dispatch(ApplicantsActions.createInternship(projectId, internship)),
    getApplicantInternships: (coachingId) => dispatch(ApplicantsActions.getApplicantInternships(coachingId)),
    editInternship: (projectId, internshipId, internship) => dispatch(ApplicantsActions.editInternship(projectId, internshipId, internship)),
    deleteInternship: (projectId, internshipId, coachingId) => dispatch(ApplicantsActions.deleteInternship(projectId, internshipId, coachingId)),
    startInitialAssessment: (userIds, coachId, allApplicantsSelected) => dispatch(ApplicantsActions.startInitialAssessmentRequest(userIds, coachId, allApplicantsSelected)),
    endInitialAssessment: (userIds, allApplicantsSelected) => dispatch(ApplicantsActions.endInitialAssessmentRequest(userIds, allApplicantsSelected))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withMediaQuery(withTranslation()(ApplicantsScreen))
)
