import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './locales/en.json'
import fi from './locales/fi.json'

export const languages = [
  { name: 'English', value: 'en' }, // First item is used as a fallback language
  { name: 'Suomi', value: 'fi' }
]

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: languages[0].value,
    resources: {
      en: {
        translation: en
      },
      fi: {
        translation: fi
      }
    },
    react: {
      useSuspense: false
    }
  })

export default i18n
