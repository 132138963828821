import React from 'react'

const styles = {
  wrapper: {
    padding: '16px 24px',
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
    minHeight: 240
  }
}
const DialogContentWrapper = (props) => {
  return (
    <div style={styles.wrapper}>
      {props.children}
    </div>
  )
}
export default DialogContentWrapper
