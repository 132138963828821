import React, { useReducer, useRef } from 'react'
import { useTheme } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import CircularProgress from '@mui/material/CircularProgress'

import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import DialogContentWrapper from '../DialogContentWrapper'
import FileUploadInput from '../FileUploadInput'
import AttachmentsList from '../attachments/AttachmentsList'
import isEmpty from 'lodash/isEmpty'
import TabPanel from '../TabPanel'
import DialogHeader from '../DialogHeader'
import Spinner from '../Spinner'
import { COLORS } from '../../utils/colors'
import EditBasicDetails from './EditBasicDetails'
import EditCoaches from './EditCoaches'
import EditCoachingResults from './EditCoachingResults'
import ProjectBasicDetails from './ProjectBasicDetails'
import CoachesList from './CoachesList'
import SaveButton from '../SaveButton'
import ConfirmationDialog from '../ConfirmationDialog'
import { getProjectAttachmentFile } from '../../services/api'
import { isAdmin } from '../../utils/permissions'
import CoachingResultsList from './CoachingResultsList'
import { useMediaQuery } from '@mui/material'
import { getDateTimeForApi } from '../../utils/transforms'

const styles = {
  root: {
    maxWidth: 800
  },
  textField: {
    marginTop: 8,
    marginBottom: 8
  },
  smallInput: {
    display: 'block'
  },
  multiline: {
    marginTop: 16
  },
  smallLabel: {
    marginTop: 8,
    color: COLORS.label
  },
  message: {
    padding: 16,
    backgroundColor: COLORS.light,
    borderRadius: 4,
    color: COLORS.darkGrey,
    fontSize: 12,
    margin: 8
  },
  actionsWrapper: {
    padding: 16,
    borderTop: `1px solid ${COLORS.lightBorder}`
  },
  cancelButton: {
    color: COLORS.red,
    marginRight: 10
  },
  marginTop20: {
    marginTop: 20
  },
  backgroundText: {
    whiteSpace: 'pre-line'
  },
  listRoot: {
    width: '100%',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  tab: {
    width: '50%',
    maxWidth: 'unset'
  },
  labelColor: {
    color: COLORS.label
  },
  occupationsText: {
    marginBottom: 12
  },
  spinner: {
    color: COLORS.white
  },
  attachmentsActions: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    alignSelf: 'flex-end'
  }
}

const ProjectDialog = (props) => {
  /* General component state */
  const initialState = {
    tabValue: 0,
    regions: [],
    services: [],
    isDownloadingFile: false
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  /* Basic details */
  const initialDetails = {
    nameFi: '',
    nameEn: '',
    area: '',
    service: '',
    backgroundHelpEn: '',
    backgroundHelpFi: '',
    code: '',
    descriptionEn: '',
    descriptionFi: '',
    start: null,
    end: null,
    fileForDelete: null,
    isConfirmFileDeleteDialogOpen: false,
    file: null,
    passiveStorageTime: '',
    trainer: '',
    responsiblePersonId: ''
  }
  const detailsReducer = (newBasicDetailsState, changedBasicDetails) => ({ ...newBasicDetailsState, ...changedBasicDetails })
  const [newBasicDetailsState, setNewBasicDetailsState] = useReducer(detailsReducer, initialDetails)

  const [t] = useTranslation()
  const fileRef = useRef()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => {
    resetState()
    fileRef.current.resetInput()
    props.onClose()
  }

  const resetState = () => {
    setState(initialState)
    setNewBasicDetailsState(initialDetails)
  }

  const downloadAttachment = async (file) => {
    setState({ isDownloadingFile: true })

    const downloadedAttachment = await getProjectAttachmentFile(props.projectInfo.id, file.projectFileId, file.filename)
    if (downloadedAttachment != null) setState({ isDownloadingFile: false })
    else setState({ isDownloadingFile: false })
  }

  const handleBasicDetailsSave = () => {
    const params = { ...newBasicDetailsState, id: props.projectInfo.id }
    const tStart = getDateTimeForApi(params.start)
    const tEnd = getDateTimeForApi(params.end)
    if (tStart.split('+').length === 2) params.start = tStart.split('+')[0]
    if (tEnd.split('+').length === 2) params.end = tEnd.split('+')[0]
    props.editProject(params)
  }

  const handleAddCoaches = (newCoaches) => {
    const { projectInfo } = props
    props.addCoaches(projectInfo.id, newCoaches)
  }

  const handleRemoveCoach = (coachId) => {
    const { projectInfo } = props
    props.removeCoach(projectInfo.id, coachId)
  }

  const handleAddResult = (result) => {
    const { projectInfo } = props
    props.addResult(projectInfo.id, result)
  }

  const handleRemoveResult = (result) => {
    const { projectInfo } = props
    props.removeResult(projectInfo.id, result)
  }

  const onBasicDetailsChange = (params) => setNewBasicDetailsState({ ...params })

  const handleFileChange = (file) => setState({ file })

  const shouldSaveBeDisabled = () => {
    return state.file == null || props.attachmentsLoading
  }

  const handleFilecancel = () => {
    fileRef.current.resetInput()
    setState({ file: null })
  }

  const handleFileSave = () => {
    const { projectInfo } = props
    const { file } = state
    const formData = new FormData()
    formData.append('File', file)
    props.addAttachmentFile(projectInfo.id, formData)
    fileRef.current.resetInput()
  }

  const handleAttachmentDelete = () => {
    const { fileForDelete } = state
    const { projectInfo } = props
    props.deleteProjectAttachment(projectInfo.id, fileForDelete.projectFileId)
    closeConfirmFileDelete()
  }

  const handleTabChange = (event, newValue) => {
    setState({ tabValue: newValue })
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const openConfirmFileDelete = (fileForDelete) => {
    setState({ fileForDelete, isConfirmFileDeleteDialogOpen: true })
  }

  const closeConfirmFileDelete = () => {
    setState({ fileForDelete: null, isConfirmFileDeleteDialogOpen: false })
  }

  const renderBasicInformationContent = () => {
    const { fetching, coachOptions, projectInfo, regionsOptions, servicesOptions, user, adminOptions } = props
    if (isAdmin(user)) {
      if (!isEmpty(regionsOptions) && !isEmpty(servicesOptions) && !isEmpty(adminOptions)) {
        return (
          <EditBasicDetails
            coachOptions={coachOptions}
            initialState={initialDetails}
            fetching={fetching}
            projectInfo={projectInfo}
            regions={regionsOptions}
            services={servicesOptions}
            adminOptions={adminOptions}
            basicDetailsChanged={onBasicDetailsChange}
            user={user}
          />
        )
      } else return (<Spinner />)
    } else return <ProjectBasicDetails project={projectInfo} />
  }

  const renderButtonContent = (saveButtonText) => {
    const { setProjectInfoFetching, fetching } = props
    if (setProjectInfoFetching || fetching) {
      return <CircularProgress style={styles.spinner} size={16} />
    }
    return t(saveButtonText)
  }

  const renderBasicInformationButtons = () => {
    const { setProjectInfoFetching, user } = props

    if (isAdmin(user)) {
      return (
        <DialogActions style={styles.actionsWrapper}>
          <Button onClick={handleClose} style={styles.cancelButton}>
            {t('cancel')}
          </Button>
          <Button
            onClick={handleBasicDetailsSave}
            variant='contained'
            color='primary'
            disabled={setProjectInfoFetching}
          >
            {renderButtonContent('save_basic_information')}
          </Button>
        </DialogActions>
      )
    } else return null
  }

  const renderCoachManagement = () => {
    const { fetching, coachesFetching, projectInfo, coachOptions, user } = props

    if (isAdmin(user)) {
      if (!isEmpty(coachOptions)) {
        return (
          <EditCoaches
            fetching={fetching}
            coachesFetching={coachesFetching}
            projectInfo={projectInfo}
            coachOptions={coachOptions}
            addCoaches={handleAddCoaches}
            removeCoach={handleRemoveCoach}
          />
        )
      } else return <Spinner />
    } else {
      if (projectInfo && projectInfo.coaches && projectInfo.coaches.length > 0) {
        return (
          <CoachesList
            coaches={projectInfo.coaches}
          />
        )
      }
    }
  }

  const renderCoachingResults = () => {
    const { fetching, coachesFetching, projectInfo, coachingResultOptions, user } = props
    if (isAdmin(user)) {
      if (!isEmpty(coachingResultOptions)) {
        return (
          <EditCoachingResults
            fetching={fetching}
            coachesFetching={coachesFetching}
            projectInfo={projectInfo}
            coachingResultOptions={props.coachingResultOptions}
            addResult={handleAddResult}
            removeCoachingResult={handleRemoveResult}
          />
        )
      } else return <Spinner />
    } else {
      if (projectInfo && projectInfo.coaches && projectInfo.coaches.length > 0) {
        return (
          <CoachingResultsList
            resultOptions={coachingResultOptions}
          />
        )
      }
    }
  }

  const renderAttachments = () => {
    return (
      <DialogContentWrapper>
        <Typography variant='h6' gutterBottom>{t('upload_new_attachment')}</Typography>
        <FileUploadInput
          ref={fileRef}
          onFileChange={handleFileChange}
        />
        <div style={styles.attachmentsActions}>
          <Button onClick={handleFilecancel} style={styles.cancelButton}>
            {t('cancel')}
          </Button>
          <SaveButton
            label='save'
            fetching={props.attachmentsLoading}
            disabled={shouldSaveBeDisabled()}
            handleSave={handleFileSave}
          />
        </div>
        <Typography variant='h6' style={styles.marginTop20}>{t('project_attachments')}</Typography>
        <AttachmentsList
          items={props.projectAttachments}
          confirmDeleteFile={file => openConfirmFileDelete(file)}
          downloadAttachment={downloadAttachment}
          isDownloadingFile={state.isDownloadingFile}
          emptyListMessage='no_project_attachments'
        />
      </DialogContentWrapper>
    )
  }

  const renderDialogActions = () => {
    if (state.tabValue === 0) {
      return renderBasicInformationButtons()
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={handleClose}
      PaperProps={{ style: styles.root }}
      fullWidth
      scroll='paper'
    >
      <DialogHeader
        handleClose={handleClose}
        label='project_info'
      />
      <AppBar position='static' color='secondary'>
        <Tabs value={state.tabValue} onChange={handleTabChange} aria-label='simple tabs example' indicatorColor='primary'>
          <Tab label={t('basic_information')} {...a11yProps(0)} />
          <Tab label={t('trainers')} {...a11yProps(1)} />
          <Tab label={t('results')} {...a11yProps(2)} />
          <Tab label={t('attachment_files')} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <DialogContent dividers>
        <TabPanel value={state.tabValue} index={0}>
          {renderBasicInformationContent()}
        </TabPanel>
        <TabPanel value={state.tabValue} index={1}>
          {renderCoachManagement()}
        </TabPanel>
        <TabPanel value={state.tabValue} index={2}>
          {renderCoachingResults()}
        </TabPanel>
        <TabPanel value={state.tabValue} index={3}>
          {renderAttachments()}
        </TabPanel>
      </DialogContent>
      {renderDialogActions()}
      <ConfirmationDialog
        isOpen={state.isConfirmFileDeleteDialogOpen}
        title={t('delete_attachment')}
        message={`${t('delete_project_file_confirmation')} ${state.fileForDelete ? state.fileForDelete.filename : ''}`}
        close={closeConfirmFileDelete}
        handleOk={handleAttachmentDelete}
      />
    </Dialog>
  )
}

export default ProjectDialog
