import React, { useState } from 'react'
import { truncate } from 'lodash'
import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import PhoneIcon from '@mui/icons-material/PhoneOutlined'
import HelpIcon from '@mui/icons-material/Help'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import EventAvailable from '@mui/icons-material/EventAvailable'
import Checkbox from '@mui/material/Checkbox'
import { format } from 'date-fns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ApplicantMenu from './ApplicantMenu'
import Autocomplete from '../Autocomplete'
import CustomDatePicker from '../DatePicker'
import Spinner from '../Spinner'
import { COLORS } from '../../utils/colors'
import { getObjectNameTranslation, getLocale } from '../../utils/transforms'
import { TextField } from '@mui/material'

const styles = {
  card: {
    marginBottom: 4
  },
  cardSelected: {
    marginBottom: 4,
    backgroundColor: COLORS.light
  },
  cellRow: {
    paddingTop: 4,
    paddingBottom: 4,
    display: 'flex',
    alignItems: 'center'
  },
  contactIcon: {
    color: COLORS.darkGrey,
    fontSize: 12,
    marginRight: 1
  },
  link: {
    color: COLORS.grey,
    textDecoration: 'none',
    '&:hover': {
      color: COLORS.darkGrey
    }
  },
  empty: {
    color: COLORS.lightText
  },
  leftCell: {
    padding: '8px 16px',
    width: 90
  },
  cell: {
    padding: '8px 16px'
  },
  active: {
    color: COLORS.greenDarken
  },
  enrolled: {
    color: COLORS.orange
  },
  ended: {
    color: COLORS.red
  },
  header: {
    background: COLORS.orange,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignContent: 'stretch'
  },
  leftHeader: {
    display: 'flex',
    width: '50%'
  },
  rightHeader: {
    display: 'flex'
  },
  headerText: {
    color: COLORS.white,
    display: 'inline-flex',
    transition: 'none',
    alignItems: 'center'
  },
  checkbox: {
    color: COLORS.white
  },
  additionalInfoContent: {
    maxWidth: 620,
    minWidth: 320,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    wordBreak: 'break-word'
  },
  additionalInfo: {
    padding: 16,
    whiteSpace: 'pre-line'
  },
  headerIcon: {
    color: COLORS.white
  },
  menuIconContainer: {
    color: COLORS.white
  },
  datePicker: {
    width: '100%',
    '& input[type=text]': {
      fontSize: 12
    }

  },
  block: {
    display: 'block',
    wordWrap: 'break-word'
  }
}

const ApplicantRowMobile = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [t] = useTranslation()
  const { applicant } = props

  const handleInfoClick = event => {
    setAnchorEl(event.currentTarget)
    props.getCoachingSummary(props.applicant.coachingId)
  }

  const handleInfoClose = () => setAnchorEl(null)

  const setStartDate = (date) => {
    const { userId, projectId, coachId } = props.applicant
    props.setStartDate(projectId, userId, coachId, date)
  }

  const setTrainer = (trainerId) => {
    const { userId, projectId, begin } = props.applicant
    props.setTrainer(projectId, userId, trainerId, begin)
  }

  const selectApplicant = () => props.selectApplicant(props.applicant.userId, props.applicant.projectId, props.applicant.status)

  const isSelected = () => props.isSelected(props.applicant.userId, props.applicant.projectId)

  const service = () => getObjectNameTranslation(props.applicant.serviceName)
  const project = () => getObjectNameTranslation(props.applicant.projectName)
  const area = () => props.applicant.areaId

  const openApplicantDialog = () => props.openApplicantDialog(props.applicant)

  const openRollback = () => props.openRollback(props.applicant)

  const getRollbackText = () => {
    if (props.applicant && props.applicant.begin) {
      if (props.applicant.end) return t('rollbackToActive')
      return t('rollbackToEnrolled')
    }
  }

  const getCoachOptions = () => {
    const { coachOptions } = props.applicant
    return [...coachOptions].map(({ firstName, lastName, id }) =>
      ({ label: `${firstName} ${lastName}`, value: id })
    )
  }

  const renderStatus = () => {
    const { begin, end, coachingResultName } = props.applicant
    const statusArray = getObjectNameTranslation(props.applicant.statusString).split(' ')
    const status = statusArray[0]
    const date = statusArray.length > 1 ? statusArray[1] : null
    const statusClass = end ? styles.ended : (begin ? styles.active : styles.enrolled)

    return (
      <TableCell sx={styles.cell}>
        <span style={statusClass}>{status}</span> {date}
        {coachingResultName && (<span style={styles.block}>{getObjectNameTranslation(coachingResultName)}</span>)}
      </TableCell>
    )
  }

  const renderPhone = () => {
    const { phoneNumber } = props.applicant
    if (!phoneNumber) return <span style={styles.empty}>{t('empty_contact_info')}</span>
    return <a style={styles.link} href={`tel:${phoneNumber}`}>{phoneNumber}</a>
  }

  const renderEmail = () => {
    const { email } = props.applicant
    if (!email) return <span style={styles.empty}>{t('empty_contact_info')}</span>
    return <a style={styles.link} href={`mailto:${email}`}>{email}</a>
  }

  const handleCopySummaryToClipBoard = () => {
    navigator.clipboard.writeText(props.coachingSummary)
  }

  const renderCoachingSummary = () => {
    const { coachingSummary, loadingCoachingEntries } = props
    return (
      <div style={styles.additionalInfoContainer}>
        <IconButton onClick={handleInfoClick} sx={styles.headerIcon}>
          <InfoIcon />
        </IconButton>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleInfoClose}
        >
          <div style={styles.additionalInfoContent}>
            <Typography sx={styles.additionalInfo}>
              {coachingSummary}
              {(!coachingSummary && loadingCoachingEntries) &&
                <Spinner />}
              {(!coachingSummary && !loadingCoachingEntries) &&
                t('no_entry_summary')}
            </Typography>
            {coachingSummary !== '' &&
              <IconButton onClick={handleCopySummaryToClipBoard}>
                <FileCopyIcon />
                <Typography>Kopioi</Typography>
              </IconButton>}
          </div>
        </Popover>
      </div>
    )
  }

  const renderApplicantMenu = () => {
    return (
      <div style={styles.menuIconContainer}>
        <ApplicantMenu
          showApplicantDialog={openApplicantDialog}
          showCoachingEntriesDialog={event => props.openCoachingEntriesDialog(applicant, null)}
          openRollBack={openRollback}
          rollbackText={getRollbackText()}
        />
      </div>
    )
  }

  const renderNextMeetingDescription = () => {
    const { nextMeeting } = applicant
    if (nextMeeting) {
      return (
        <div style={styles.cellRow}>
          <HelpIcon sx={styles.contactIcon} />
          <span>
            {truncate(nextMeeting.description, { length: 16, separator: ' ' }) || t('no_description')}
          </span>
        </div>
      )
    } else {
      return null
    }
  }

  const renderMeetingsInput = () => {
    const { nextMeeting } = applicant
    const meetingDate = nextMeeting && nextMeeting.start ? new Date(nextMeeting.start) : null
    const startAdornment = <EventAvailable sx={styles.contactIcon} />
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
        <DatePicker
          value={meetingDate}
          open={false}
          onOpen={event => handleNextMeetingClick(event, nextMeeting)}
          onChange={handleMeetingsChange}
          inputFormat={t('date_format')}
          disableMaskedInput
          cancelLabel={t('cancel')}
          labelFunc={getMeetingsLabel}
          emptyLabel={t('no_future_meetings')}
          sx={styles.datePicker}
          renderInput={props => <TextField color='primary' {...props} sx={styles.datePicker} placeholder={getMeetingsLabel()} variant='standard' InputProps={{ ...props.InputProps, color: 'primary', endAdornment: '', startAdornment }} inputProps={{ ...props.inputProps, color: 'primary', readOnly: true, value: getMeetingsLabel() }} onClick={event => handleNextMeetingClick(event, nextMeeting)} />}
        />
      </LocalizationProvider>
    )
  }

  const getMeetingsLabel = () => {
    const { nextMeeting } = applicant
    if (nextMeeting) return format(new Date(nextMeeting.start), t('datetime_format'))
    return t('not_planned')
  }

  const handleMeetingsChange = (event) => {
    console.log(event)
  }

  const handleNextMeetingClick = (event, nextMeeting) => {
    props.openCoachingEntriesDialog(applicant, nextMeeting)
  }

  return (
    <Card sx={isSelected() ? styles.cardSelected : styles.card}>
      <div style={styles.header}>
        <div style={styles.leftHeader} onClick={selectApplicant}>
          <Checkbox checked={isSelected()} style={styles.checkbox} />
          <Typography sx={styles.headerText}>{applicant.firstNames} {applicant.lastName}</Typography>
        </div>
        <div style={styles.rightHeader}>
          {renderCoachingSummary()}
          {renderApplicantMenu()}
        </div>
      </div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={styles.leftCell}>{t('contact_info')}</TableCell>
            <TableCell sx={styles.cell}>
              <div style={styles.cellRow}>
                <HomeIcon sx={styles.contactIcon} />
                {area()}
              </div>
              <div style={styles.cellRow}>
                <PhoneIcon sx={styles.contactIcon} />
                {renderPhone()}
              </div>
              <div style={styles.cellRow}>
                <EmailIcon sx={styles.contactIcon} />
                {renderEmail()}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={styles.leftCell}>{t('service')}</TableCell>
            <TableCell sx={styles.cell}>{service()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={styles.leftCell}>{t('project')}</TableCell>
            <TableCell sx={styles.cell}>{project()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={styles.leftCell}>{t('state')}</TableCell>
            {renderStatus()}
          </TableRow>
          <TableRow>
            <TableCell sx={styles.leftCell}>{t('trainer')}</TableCell>
            <TableCell sx={styles.cell}>
              <Autocomplete
                options={getCoachOptions()}
                value={applicant.coachId}
                onChange={setTrainer}
                placeholder={t('select_trainer')}
                noOptionsMessage={() => t('no_trainers_available')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={styles.leftCell}>{t('start_date')}</TableCell>
            <TableCell sx={styles.cell}>
              <CustomDatePicker value={applicant.begin} onChange={setStartDate} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={styles.leftCell}>{t('next_meeting')}</TableCell>
            <TableCell sx={styles.cell}>
              {renderNextMeetingDescription()}
              <div style={styles.cellRow}>
                {renderMeetingsInput()}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  )
}

export default ApplicantRowMobile
