import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { CUSTOM_TOOLBAR_SETTINGS, EDITOR_TRANSLATIONS } from '../../utils/editor'
import { COLORS } from '../../utils/colors'
import { getCurrentLang } from '../../utils/transforms'
import withMediaQuery from '../../utils/withMediaQuery'

const styles = {
  root: {
    padding: 4,
    maxWidth: { md: 960, sm: 'initial' },
    margin: { md: 20, sm: 0 }

    // margin: 10,
  },
  title: {
    padding: { md: '16px 24px', sm: 10 }
  },
  contentWrapper: {
    paddingBottom: 0
  },
  actionsWrapper: {
    margin: 0,
    padding: { md: '6px 24px', sm: 10 }
  },
  editor: {
    border: `1px solid ${COLORS.lightBorder}`,
    fontFamily: 'Arial',
    padding: 8,
    minHeight: 300,
    maxHeight: 300
  },
  toolbar: {
    border: `1px solid ${COLORS.lightBorder}`,
    fontFamily: 'Roboto'
  },
  sendButton: {
    margin: '10px 0px',
    marginLeft: { md: 20, sm: 15 },
    width: 102
  },
  textField: {
    marginBottom: 1
  },
  cancelButton: {
    color: COLORS.red
  },
  spinner: {
    color: COLORS.white
  }
}

class MessageForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      subject: '',
      editorState: EditorState.createEmpty()
    }
  }

  componentDidUpdate (nextProps) {
    if (this.props.fetching && !nextProps.fetching) {
      this.handleClose()
    }
  }

  handleOnEditorStateChange = (editorState) => this.setState({ editorState })
  handleSetSubject = (e) => this.setState({ subject: e.target.value })
  getLang = () => EDITOR_TRANSLATIONS(getCurrentLang())
  handleSendMessage = () => {
    const { subject, editorState } = this.state
    const htmlMessage = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    this.props.sendMail(subject, htmlMessage)
  }

  handleClose = () => {
    this.props.close()
    this.setState({ subject: '', editorState: EditorState.createEmpty() })
  }

  renderButtonContent = () => {
    const { fetching, t } = this.props
    if (fetching) {
      return <CircularProgress sx={styles.spinner} size={16} />
    }
    return t('send')
  }

  render () {
    const { t, open, close, fullScreen } = this.props
    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ style: styles.root }}
        fullWidth
      >
        <DialogTitle id='form-dialog-title' sx={styles.title}>
          {t('new_email')}
        </DialogTitle>
        <DialogContent sx={{ '.MuiDialogContent-root': styles.contentWrapper }}>
          <TextField
            label={t('subject')}
            sx={styles.textField}
            value={this.state.subject}
            onChange={this.handleSetSubject}
            autoFocus
            fullWidth
            required
            variant='standard'
          />
          <Editor
            wrapperStyle={styles.editorWrapper}
            editorStyle={styles.editor}
            toolbarStyle={styles.toolbar}
            editorState={this.state.editorState}
            onEditorStateChange={this.handleOnEditorStateChange}
            sx={styles.editor}
            localization={{
              locale: getCurrentLang(),
              translations: this.getLang()
            }}
            toolbar={CUSTOM_TOOLBAR_SETTINGS}
          />
        </DialogContent>
        <DialogActions sx={{ '.MuiDialogActions-root': styles.actionsWrapper }}>
          <Button onClick={this.handleClose} sx={styles.cancelButton}>
            {t('cancel')}
          </Button>
          <Button
            onClick={this.handleSendMessage}
            variant='contained'
            color='primary'
            sx={styles.sendButton}
            disabled={!this.state.subject}
          >
            {this.renderButtonContent()}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withMediaQuery(withTranslation()(MessageForm))
