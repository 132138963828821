import React, { Fragment, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { truncate } from 'lodash'
import moment from 'moment'
import * as ServicesActions from '../redux/ServicesRedux'
import { COLORS } from '../utils/colors'
import ServicesTableToolbar from '../components/services/ServicesTableToolbar'
import ServiceFilterDialog from '../components/services/ServiceFilterDialog'
import ServiceRowMobile from '../components/services/ServiceRowMobile'
import CreateServiceDialog from '../components/services/CreateServiceDialog'
import EditServiceDialog from '../components/services/EditServiceDialog'
import SpringerTablePagination from '../components/applicants/SpringerTablePagination'
import ActionBar from '../components/ActionBar'
import ServiceMenu from '../components/services/ServiceMenu'
import ToolTip from '../components/ToolTip'
import { getCurrentLang, getFilterCount } from '../utils/transforms'

/* Material-UI components */
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { TableRow, TableCell, TableHead, Box, useMediaQuery } from '@mui/material'
import ArchiveIcon from '@mui/icons-material/Archive'
import { useTheme } from '@mui/styles'

const baseStyle = {
  backgroundColor: COLORS.orange,
  color: COLORS.white,
  padding: '8px 16px',
  fontWeight: 'bold'
}
const styles = {
  container: {
    width: '100%',
    maxWidth: 1360,
    margin: '0 auto',
    paddingBottom: 80
  },
  root: {
    width: '100%'
  },
  iconText: {
    verticalAlign: 'super',
    paddingLeft: 2
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  leftColumn: {
    display: 'flex',
    flex: 40
  },
  rightColumn: {
    display: 'flex',
    flex: 50,
    flexDirection: 'column'
  },
  tableContainer: {
    overflowX: 'auto',
    width: '100%',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    // Prevent vertical scrollbar when opening dropdown
    paddingBottom: 300,
    marginBottom: -300
  },
  table: {
    minWidth: 700
  },
  tableHead: {
    ...baseStyle,
    minWidth: 100
  },
  tableHeadWide: {
    ...baseStyle,
    minWidth: 200,
    maxWidth: 200
  },
  wideCell: {
    width: 300
  },
  tableHeadSmall: {
    ...baseStyle,
    minWidth: 60
  },
  spinner: {
    display: 'flex',
    padding: '64px 16px',
    justifyContent: 'center'
  },
  centered: {
    margin: '32px 0 16px',
    textAlign: 'center',
    width: '100%'
  },
  cellRow: {
    paddingTop: 4,
    paddingBottom: 4,
    display: 'flex',
    alignItems: 'center'
  },
  cell: {
    padding: '8px 16px'
  },
  infoCell: {
    padding: '8px 0px'
  },
  cellIcon: {
    color: COLORS.darkGrey
  },
  passiveIcon: {
    color: COLORS.lightText,
    marginRight: 4
  },
  smallCellIcon: {
    color: COLORS.darkGrey,
    fontSize: 12,
    marginRight: 8
  },
  actionLink: {
    color: COLORS.green,
    fontSize: '0.87rem',
    cursor: 'pointer',
    marginLeft: 4
  }
}

const ServicesScreen = (props) => {
  const initialState = {
    isFilterDialogOpen: false,
    isCreateDialogOpen: false,
    showAllProjectsForServices: [],
    serviceInfo: null
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  const [t] = useTranslation()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const activeColor = 'textPrimary'
  const passiveColor = 'textSecondary'

  useEffect(() => {
    props.getServices()
  }, []) // On component mount

  useEffect(() => {
    props.getServices()
  }, [state.serviceFilters])

  const isEmptyServicesList = () => {
    const { services, fetching } = props
    if (!fetching && !services.length) {
      return true
    }
    return false
  }

  const showServiceDialog = (service) => setState({ serviceInfo: service })
  const closeServiceDialog = () => setState({ serviceInfo: null })
  const onFilterDialogToggle = () => setState({ isFilterDialogOpen: !state.isFilterDialogOpen })
  const onCreateDialogToggle = () => setState({ isCreateDialogOpen: !state.isCreateDialogOpen })

  const setServiceFilters = (filters) => {
    const { serviceFilters } = props
    // Merge properties from filters to serviceFilters
    const mergedNewFilters = Object.assign({}, serviceFilters, filters)
    props.setServiceFilters(mergedNewFilters)
  }

  /* Get project's name with preferred language but if not found, use any other translation */
  const getAnyTranslatedProjectName = (project, preferredLang) => {
    const supportedLanguages = ['fi', 'en']
    const fallBackLanguage = supportedLanguages.find(lang => lang !== preferredLang)
    return project.name[preferredLang] || project.name[fallBackLanguage]
  }

  const getAllTranslatedProjectNames = (service, lang) => {
    if (service.projects) {
      return service.projects.reduce((total, project, idx, arr) => {
        if (idx === 0) return getAnyTranslatedProjectName(project, lang)
        else return `${total}, ${getAnyTranslatedProjectName(project, lang)}`
      }, '')
    } else {
      return ''
    }
  }

  const getProjectNames = (service) => {
    if (getCurrentLang() === 'fi') {
      return getAllTranslatedProjectNames(service, 'fi')
    } else {
      return getAllTranslatedProjectNames(service, 'en')
    }
  }

  const getTruncatedProjectNames = (service) => {
    return truncate(getProjectNames(service), { length: 80, separator: ' ' })
  }

  const doProjectNamesNeedTruncating = (service) => getProjectNames(service).length > 80

  const handleMoreProjectNames = (service) => setState({ showAllProjectsForServices: state.showAllProjectsForServices.concat([service.id]) })
  const handleLessProjectNames = (service) => {
    const { showAllProjectsForServices } = state
    const newServiceIds = showAllProjectsForServices.filter(id => id !== service.id)
    setState({ showAllProjectsForServices: newServiceIds })
  }

  const renderExpandProjectsButton = (service) => {
    const { showAllProjectsForServices } = state
    if (showAllProjectsForServices.includes(service.id)) {
      return (
        <Typography style={styles.actionLink} onClick={e => handleLessProjectNames(service)}>
          {t('show_less')}
        </Typography>
      )
    } else {
      return (
        <Typography style={styles.actionLink} onClick={e => handleMoreProjectNames(service)}>
          {t('show_more')}
        </Typography>
      )
    }
  }

  const renderProjectNames = (service) => {
    const { showAllProjectsForServices } = state
    const projectNames = showAllProjectsForServices.includes(service.id) ? getProjectNames(service) : getTruncatedProjectNames(service)
    return (
      <Fragment key='project-names'>
        <Typography component='span' style={styles.leftColumn} color={service.active ? activeColor : passiveColor}>
          {projectNames}
        </Typography>
        {doProjectNamesNeedTruncating(service) &&
          renderExpandProjectsButton(service)}
      </Fragment>
    )
  }

  const renderEmptyService = () => {
    return (
      <Typography style={styles.centered}>
        {t('no_results')}
      </Typography>
    )
  }

  const renderMobileActionBar = () => {
    const { servicesTotal, serviceFilters } = props
    return (
      <ActionBar
        totalCount={servicesTotal}
        openFilter={onFilterDialogToggle}
        openFileUpload={onCreateDialogToggle}
        filterCount={getFilterCount(serviceFilters)}
        paginationComponent={renderPagination()}
      />
    )
  }

  const renderMobileServicesTable = () => {
    const { visibleServices } = props
    if (isEmptyServicesList()) return renderEmptyService()
    return visibleServices.map(service => (
      <ServiceRowMobile
        key={service.id}
        service={service}
        getProjectNames={getProjectNames}
        showServiceDialog={showServiceDialog}
      />
    ))
  }

  const renderInitSpinner = () => {
    const { fetching, visibleServices } = props
    // Initial loading
    if (fetching && !visibleServices.length) {
      return (
        <div style={styles.spinner}>
          <CircularProgress />
        </div>
      )
    }
  }

  const renderServiceRow = (msg) => {
    const { visibleServices } = props
    return visibleServices.map(service => (
      <TableRow key={service.id}>
        <TableCell style={styles.cell}>
          <div style={styles.row}>
            {!service.active &&
              <ArchiveIcon style={styles.passiveIcon} />}
            <Typography component='span' style={styles.leftColumn} color={service.active ? activeColor : passiveColor}>
              {service.name.fi}
            </Typography>
          </div>
        </TableCell>
        <TableCell style={styles.cell}>
          <Typography component='span' style={styles.leftColumn} color={service.active ? activeColor : passiveColor}>
            {service.name.en}
          </Typography>
        </TableCell>
        <TableCell style={styles.cell}>
          <Typography component='span' style={styles.leftColumn} color={service.active ? activeColor : passiveColor}>
            {service.start ? moment(service.start).format('DD.MM.YYYY') : '-'}
          </Typography>
        </TableCell>
        <TableCell style={styles.cell}>
          <Typography component='span' style={styles.leftColumn} color={service.active ? activeColor : passiveColor}>
            {service.end ? moment(service.end).format('DD.MM.YYYY') : '-'}
          </Typography>
        </TableCell>
        <TableCell style={styles.wideCell}>
          {renderProjectNames(service)}
        </TableCell>
        <TableCell style={styles.infoCell} align='right'>
          <ToolTip title={t('actions')}>
            <div style={styles.rowIconContainer}>
              <ServiceMenu
                service={service}
                showServiceDialog={showServiceDialog}
              />
            </div>
          </ToolTip>
        </TableCell>
      </TableRow>
    ))
  }

  const renderServicesTable = () => {
    const { servicesTotal, serviceFilters } = props

    return (
      <div style={styles.tableContainer}>
        <ServicesTableToolbar
          servicesTotal={servicesTotal}
          onFilterDialogToggle={onFilterDialogToggle}
          onCreateDialogToggle={onCreateDialogToggle}
          filterCount={getFilterCount(serviceFilters)}
        />
        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHeadWide}>{t('name_fi')}</TableCell>
              <TableCell style={styles.tableHeadWide}>{t('name_en')}</TableCell>
              <TableCell style={styles.tableHead}>{t('start_date')}</TableCell>
              <TableCell style={styles.tableHead}>{t('end_date')}</TableCell>
              <TableCell style={styles.tableHeadWide}>{t('projects')}</TableCell>
              <TableCell style={styles.tableHeadSmall} />
            </TableRow>
          </TableHead>
          <TableBody>
            {renderServiceRow()}
          </TableBody>
        </Table>
        {renderPagination()}
      </div>
    )
  }

  // Render pagination controls
  const renderPagination = () => {
    const { page, fetching, servicesTotal, pageSize } = props
    return (
      <SpringerTablePagination
        page={page - 1}
        count={servicesTotal}
        handleChangePage={props.changeServicesPage}
        handleChangeRowsPerPage={props.changeServiceRowsPerPage}
        fetching={fetching}
        pageSize={pageSize}
        labelRowsPerPage={t('services_per_page')}
      />
    )
  }

  return (
    <div style={styles.container}>
      {!matches &&
        <Box>
          {renderMobileServicesTable()}
          {renderInitSpinner()}
          {renderMobileActionBar()}
        </Box>}
      {matches &&
        <Box>
          {renderPagination()}
          <Paper style={styles.root}>
            {renderServicesTable()}
            {renderInitSpinner()}
          </Paper>
        </Box>}
      <ServiceFilterDialog
        isOpen={state.isFilterDialogOpen}
        onClose={onFilterDialogToggle}
        setServiceFilters={setServiceFilters}
        serviceFilters={props.serviceFilters}
        resetServiceFilters={props.resetServiceFilters}
      />
      <CreateServiceDialog
        isOpen={state.isCreateDialogOpen}
        onClose={onCreateDialogToggle}
        createService={props.createService}
        fetching={props.fetching}
        saveSuccessful={props.saveSuccessful}
        acknowledgeSaveSuccess={props.acknowledgeServiceSaveSuccess}
      />
      <EditServiceDialog
        isOpen={Boolean(state.serviceInfo)}
        onClose={closeServiceDialog}
        service={state.serviceInfo}
        editService={props.editService}
        fetching={props.fetching}
        saveSuccessful={props.saveSuccessful}
        acknowledgeSaveSuccess={props.acknowledgeServiceSaveSuccess}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    services: state.services.services,
    visibleServices: state.services.visibleServices,
    fetching: state.services.fetching,
    page: state.services.page,
    pageSize: state.services.pageSize,
    servicesTotal: state.services.servicesTotal,
    serviceFilters: state.services.serviceFilters,
    saveSuccessful: state.services.saveSuccessful,
    user: state.user.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getServices: () => dispatch(ServicesActions.getServices()),
    changeServicesPage: (page) => dispatch(ServicesActions.changeServicesPage(page)),
    changeServiceRowsPerPage: (rows) => dispatch(ServicesActions.changeServiceRowsPerPage(rows)),
    setServiceFilters: (filters) => dispatch(ServicesActions.setServiceFilters(filters)),
    createService: (service) => dispatch(ServicesActions.createService(service)),
    acknowledgeServiceSaveSuccess: () => dispatch(ServicesActions.acknowledgeServiceSaveSuccess()),
    editService: (service) => dispatch(ServicesActions.editService(service)),
    resetServiceFilters: () => dispatch(ServicesActions.resetServiceFilters())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ServicesScreen
)
