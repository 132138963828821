import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  meetings: [],
  meetingsFilters: {},
  loading: false,
  createSuccessfull: false,
  error: null,
  allMeetings: []
})

/* ------------- Types and Action Creators ------------- */

export const getMeetings = createAction('GET_MEETINGS')
export const getMeetingsSuccess = createAction('GET_MEETINGS_SUCCESS', (meetings) => ({ meetings }))
export const getMeetingsFailure = createAction('GET_MEETINGS_FAILURE', (error) => ({ error }))
export const setMeetingsFilters = createAction('SET_MEETINGS_FILTERS', (meetingsFilters) => ({ meetingsFilters }))
export const createMeeting = createAction('CREATE_MEETING', (newMeeting) => ({ newMeeting }))
export const createMeetingSuccess = createAction('CREATE_MEETING_SUCCESS')
export const createMeetingFailure = createAction('CREATE_MEETING_FAILURE', (error) => ({ error }))
export const acknowledgeMeetingSaveSuccess = createAction('ACKNOWLEDGE_MEETING_SAVE_SUCCESS')
export const editMeeting = createAction('EDIT_MEETING', (meeting) => ({ meeting }))
export const editMeetingSuccess = createAction('EDIT_MEETING_SUCCESS')
export const editMeetingFailure = createAction('EDIT_MEETING_FAILURE', (error) => ({ error }))
export const cancelMeeting = createAction('CANCEL_MEETING', (meeting) => ({ meeting }))
export const cancelMeetingSuccess = createAction('CANCEL_MEETING_SUCCESS')
export const cancelMeetingFailure = createAction('CANCEL_MEETING_FAILURE', (error) => ({ error }))
export const getAllMeetings = createAction('GET_ALL_MEETINGS')
export const getAllMeetingsSuccess = createAction('GET_ALL_MEETINGS_SUCCESS', (allMeetings) => ({ allMeetings }))
export const getAllMeetingsFailure = createAction('GET_ALL_MEETINGS_FAILURE', (error) => ({ error }))

/* ------------- Reducers ------------- */
const reducers = createReducer(
  {
    [getMeetings]: (state) => state.merge({ loading: true }),
    [getMeetingsSuccess]: (state, { meetings }) => state.merge({ loading: false, meetings }),
    [getMeetingsFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [setMeetingsFilters]: (state, { meetingsFilters }) => state.merge({ meetingsFilters }),
    [createMeeting]: (state, { newMeeting }) => state.merge({ loading: true }),
    [createMeetingSuccess]: (state) => state.merge({ loading: false, createSuccessfull: true }),
    [createMeetingFailure]: (state) => state.merge({ loading: false, createSuccessfull: false }),
    [acknowledgeMeetingSaveSuccess]: (state) => state.merge({ createSuccessfull: false }),
    [editMeeting]: (state, { meeting }) => state.merge({ loading: true }),
    [editMeetingSuccess]: (state) => state.merge({ loading: false }),
    [editMeetingFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [cancelMeeting]: (state, { meeting }) => state.merge({ loading: true }),
    [cancelMeetingSuccess]: (state) => state.merge({ loading: false }),
    [cancelMeetingFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [getAllMeetings]: (state) => state.merge({ loading: true }),
    [getAllMeetingsSuccess]: (state, { allMeetings }) => state.merge({ loading: false, allMeetings }),
    [getAllMeetingsFailure]: (state, { error }) => state.merge({ loading: false, error })
  },
  INITIAL_STATE
)

export default reducers
