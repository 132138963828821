import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import green from '@mui/material/colors/green'
import amber from '@mui/material/colors/amber'
import IconButton from '@mui/material/IconButton'
import SnackbarContent from '@mui/material/SnackbarContent'
import WarningIcon from '@mui/icons-material/Warning'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const styles = {
  success: {
    backgroundColor: green[600]
  },
  warning: {
    backgroundColor: amber[700]
  },
  error: {
    backgroundColor: 'rgb(170, 46, 37)'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}

const CustomSnackbarContent = React.forwardRef((props, ref) => {
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]
  return (
    <SnackbarContent
      sx={{ ...styles[variant], ...className }}
      ref={ref}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' style={styles.message}>
          <Icon style={{ ...styles.icon, ...styles.iconVariant }} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          style={styles.close}
          onClick={onClose}
        >
          <CloseIcon style={styles.icon} />
        </IconButton>
      ]}
      {...other}
    />
  )
})

export default CustomSnackbarContent
