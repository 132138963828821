import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import Checkbox from '@mui/material/Checkbox'
import { COLORS } from '../../utils/colors'
import { applicantOrderBys } from '../../constants/applicantOrderBys'

const baseStyle = {
  backgroundColor: COLORS.orange,
  color: COLORS.white,
  padding: '8px 16px',
  fontWeight: 'bold'
}

const styles = {
  tableHead: {
    ...baseStyle,
    minWidth: 100
  },
  tableHeadWide: {
    ...baseStyle,
    minWidth: 200
  },
  tableHeadSmall: {
    ...baseStyle,
    minWidth: 40
  },
  tableHeadWithInput: {
    ...baseStyle,
    minWidth: 150
  },
  checkboxContainer: {
    backgroundColor: COLORS.orange
  },
  checkbox: {
    color: COLORS.white
  }
}

const ApplicantTableHeader = ({ selectAll, onChangeSelect, indeterminate, requestOrderBy }) => {
  const [t] = useTranslation()
  const [orderBy, setOrderBy] = useState(null)
  const isActiveSort = (asc, desc) => orderBy === asc || orderBy === desc
  const isAscending = (id) => id === 0 || (id % 2 === 0)

  const toggleSort = (asc, desc) => {
    const isActive = isActiveSort(asc, desc)
    if (isActive) {
      const newOrder = orderBy === asc ? desc : null
      setOrderBy(newOrder)
    } else {
      setOrderBy(asc)
    }
  }

  /* When orderBy changes, fire this callback */
  React.useEffect(() => {
    requestOrderBy(orderBy)
  }, [orderBy])

  return (
    <TableHead sx={{ backgroundColor: 'orange' }}>
      <TableRow>
        <TableCell sx={styles.checkboxContainer} padding='checkbox'>
          <Checkbox
            checked={selectAll}
            onChange={onChangeSelect}
            sx={styles.checkbox}
            indeterminate={indeterminate}
            color='secondary'
          />
        </TableCell>
        <TableCell sx={styles.tableHead}>
          <TableSortLabel
            active={isActiveSort(applicantOrderBys.nameAsc, applicantOrderBys.nameDesc)}
            direction={isAscending(orderBy) ? 'asc' : 'desc'}
            onClick={event => toggleSort(applicantOrderBys.nameAsc, applicantOrderBys.nameDesc)}
          >
            {t('name')}
          </TableSortLabel>
        </TableCell>
        <TableCell sx={styles.tableHead}>
          <TableSortLabel
            active={isActiveSort(applicantOrderBys.cityAsc, applicantOrderBys.cityDesc)}
            direction={isAscending(orderBy) ? 'asc' : 'desc'}
            onClick={event => toggleSort(applicantOrderBys.cityAsc, applicantOrderBys.cityDesc)}
          >
            {t('hometown')}
          </TableSortLabel>
        </TableCell>
        <TableCell sx={styles.tableHead}>
          <TableSortLabel
            active={isActiveSort(applicantOrderBys.projectAsc, applicantOrderBys.projectDesc)}
            direction={isAscending(orderBy) ? 'asc' : 'desc'}
            onClick={event => toggleSort(applicantOrderBys.projectAsc, applicantOrderBys.projectDesc)}
          >
            {t('project')}
          </TableSortLabel>
        </TableCell>
        <TableCell sx={styles.tableHead}>
          <TableSortLabel
            active={isActiveSort(applicantOrderBys.serviceAsc, applicantOrderBys.serviceDesc)}
            direction={isAscending(orderBy) ? 'asc' : 'desc'}
            onClick={event => toggleSort(applicantOrderBys.serviceAsc, applicantOrderBys.serviceDesc)}
          >
            {t('service')}
          </TableSortLabel>
        </TableCell>
        <TableCell sx={styles.tableHead}>
          <TableSortLabel
            active={isActiveSort(applicantOrderBys.statusAsc, applicantOrderBys.statusDesc)}
            direction={isAscending(orderBy) ? 'asc' : 'desc'}
            onClick={event => toggleSort(applicantOrderBys.statusAsc, applicantOrderBys.statusDesc)}
          >
            {t('state')}
          </TableSortLabel>
        </TableCell>

        <TableCell sx={styles.tableHeadWide}>
          <TableSortLabel
            active={isActiveSort(applicantOrderBys.coachAsc, applicantOrderBys.coachDesc)}
            direction={isAscending(orderBy) ? 'asc' : 'desc'}
            onClick={event => toggleSort(applicantOrderBys.coachAsc, applicantOrderBys.coachDesc)}
          >
            {t('trainer')}
          </TableSortLabel>
        </TableCell>
        <TableCell sx={styles.tableHeadWithInput}>
          <TableSortLabel
            active={isActiveSort(applicantOrderBys.startDateAsc, applicantOrderBys.startDateDesc)}
            direction={isAscending(orderBy) ? 'asc' : 'desc'}
            onClick={event => toggleSort(applicantOrderBys.startDateAsc, applicantOrderBys.startDateDesc)}
          >
            {t('start_date')}
          </TableSortLabel>
        </TableCell>
        <TableCell sx={styles.tableHeadWithInput}>
          <TableSortLabel
            active={isActiveSort(applicantOrderBys.meetingAsc, applicantOrderBys.meetingDesc)}
            direction={isAscending(orderBy) ? 'asc' : 'desc'}
            onClick={event => toggleSort(applicantOrderBys.meetingAsc, applicantOrderBys.meetingDesc)}
          >
            {t('next_meeting')}
          </TableSortLabel>
        </TableCell>
        <TableCell sx={styles.tableHeadSmall} />
        <TableCell sx={styles.tableHeadSmall} />
      </TableRow>
    </TableHead>
  )
}

export default ApplicantTableHeader
