import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  coachingAttachments: [],
  loading: false,
  error: null,
  projectAttachments: []
})

/* ------------- Types and Action Creators ------------- */

export const getCoachingAttachmentsRequest = createAction('GET_COACHING_ATTACHMENTS_REQUEST', (coachingId) => ({ coachingId }))
export const getCoachingAttachmentsSuccess = createAction('GET_COACHING_ATTACHMENTS_SUCCESS', (coachingAttachments) => ({ coachingAttachments }))
export const getCoachingAttachmentsFailure = createAction('GET_COACHING_ATTACHMENTS_FAILURE', (error) => ({ error }))
export const addCoachingAttachmentRequest = createAction('ADD_COACHING_ATTACHMENT_REQUEST', (projectId, coachingId, file) => ({ projectId, coachingId, file }))
export const addCoachingAttachmentSuccess = createAction('ADD_COACHING_ATTACHMENT_SUCCESS')
export const addCoachingAttachmentFailure = createAction('ADD_COACHING_ATTACHMENT_FAILURE', (error) => ({ error }))
export const deleteCoachingAttachmentRequest = createAction('DELETE_COACHING_ATTACHMENT_REQUEST', (projectId, coachingId, fileId) => ({ projectId, coachingId, fileId }))
export const deleteCoachingAttachmentSuccess = createAction('DELETE_COACHING_ATTACHMENT_SUCCESS')
export const deleteCoachingAttachmentFailure = createAction('DELETE_COACHING_ATTACHMENT_FAILURE', (error) => ({ error }))
export const addProjectAttachmentRequest = createAction('ADD_PROJECT_ATTACHMENT_REQUEST', (projectId, file) => ({ projectId, file }))
export const addProjectAttachmentSuccess = createAction('ADD_PROJECT_ATTACHMENT_SUCCESS')
export const addProjectAttachmentFailure = createAction('ADD_PROJECT_ATTACHMENT_FAILURE', (error) => ({ error }))
export const getProjectAttachmentsRequest = createAction('GET_PROJECT_ATTACHMENTS_REQUEST', (projectId) => ({ projectId }))
export const getProjectAttachmentsSuccess = createAction('GET_PROJECT_ATTACHMENTS_SUCCESS', (projectAttachments) => ({ projectAttachments }))
export const getProjectAttachmentsFailure = createAction('GET_PROJECT_ATTACHMENTS_FAILURE', (error) => ({ error }))
export const deleteProjectAttachmentRequest = createAction('DELETE_PROJECT_ATTACHMENT_REQUEST', (projectId, fileId) => ({ projectId, fileId }))
export const deleteProjectAttachmentSuccess = createAction('DELETE_PROJECT_ATTACHMENT_SUCCESS')
export const deleteProjectAttachmentFailure = createAction('DELETE_PROJECT_ATTACHMENT_FAILURE', (error) => ({ error }))

/* ------------- Reducers ------------- */

const reducers = createReducer(
  {
    [getCoachingAttachmentsRequest]: (state) => state.merge({ loading: true }),
    [getCoachingAttachmentsSuccess]: (state, { coachingAttachments }) => state.merge({ loading: false, coachingAttachments }),
    [getCoachingAttachmentsFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [addCoachingAttachmentRequest]: (state) => state.merge({ loading: true }),
    [addCoachingAttachmentSuccess]: (state) => state.merge({ loading: false }),
    [addCoachingAttachmentFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [deleteCoachingAttachmentRequest]: (state) => state.merge({ loading: true }),
    [deleteCoachingAttachmentSuccess]: (state) => state.merge({ loading: false }),
    [deleteCoachingAttachmentFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [addProjectAttachmentRequest]: (state) => state.merge({ loading: true }),
    [addProjectAttachmentSuccess]: (state) => state.merge({ loading: false }),
    [addProjectAttachmentFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [getProjectAttachmentsRequest]: (state) => state.merge({ loading: true }),
    [getProjectAttachmentsSuccess]: (state, { projectAttachments }) => state.merge({ loading: false, projectAttachments }),
    [getProjectAttachmentsFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [deleteProjectAttachmentRequest]: (state) => state.merge({ loading: true }),
    [deleteProjectAttachmentSuccess]: (state) => state.merge({ loading: false }),
    [deleteProjectAttachmentFailure]: (state, { error }) => state.merge({ loading: false, error })
  },
  INITIAL_STATE
)

export default reducers
