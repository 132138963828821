import React, { useEffect, useReducer } from 'react'
import { useTheme } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'

import DialogHeader from '../DialogHeader'
import SaveButton from '../SaveButton'
import { COLORS } from '../../utils/colors'
import DialogContentWrapper from '../DialogContentWrapper'
import { useMediaQuery } from '@mui/material'

const styles = {
  root: {
    maxWidth: 800
  },
  textField: {
    marginTop: 8,
    marginBottom: 8
  },
  smallInput: {
    display: 'block'
  },
  multiline: {
    marginTop: 16
  },
  smallLabel: {
    marginTop: 8,
    color: COLORS.label
  },
  message: {
    padding: 16,
    backgroundColor: COLORS.light,
    borderRadius: 4,
    color: COLORS.darkGrey,
    fontSize: 12,
    margin: 8
  },
  actionsWrapper: {
    padding: 16,
    borderTop: `1px solid ${COLORS.lightBorder}`
  },
  cancelButton: {
    color: COLORS.red,
    marginRight: 10
  },
  marginTop20: {
    marginTop: 20
  },
  backgroundText: {
    whiteSpace: 'pre-line'
  },
  listRoot: {
    width: '100%',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  tab: {
    width: '50%',
    maxWidth: 'unset'
  },
  labelColor: {
    color: COLORS.label
  },
  occupationsText: {
    marginBottom: 12
  },
  spinner: {
    color: COLORS.white
  }
}

const CreateServiceDialog = (props) => {
  const initialState = {
    nameFi: '',
    nameEn: ''
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [t] = useTranslation()

  useEffect(() => {
    if (props.saveSuccessful && props.isOpen) {
      handleClose()
      props.acknowledgeSaveSuccess()
    }
  }, [props.saveSuccessful])

  const resetState = () => setState({
    nameFi: '',
    nameEn: ''
  })

  const handleClose = () => {
    resetState()
    props.onClose()
  }

  const handleSave = () => {
    const { nameFi, nameEn } = state
    const generatedUUID = uuidv4()
    const params = {
      id: generatedUUID,
      fi: nameFi,
      en: nameEn
    }
    props.createService(params)
  }

  const setNameFi = (e) => setState({ nameFi: e.target.value })
  const setNameEn = (e) => setState({ nameEn: e.target.value })

  const isFormInvalid = () => {
    return state.nameFi === ''
  }

  const renderForm = () => {
    const { nameFi, nameEn } = state
    return (
      <DialogContentWrapper>
        <TextField
          label={t('name_fi')}
          style={styles.textField}
          value={nameFi}
          variant='standard'
          onChange={setNameFi}
          fullWidth
          required
        />
        <TextField
          label={t('name_en')}
          style={styles.textField}
          value={nameEn}
          variant='standard'
          onChange={setNameEn}
          fullWidth
        />
      </DialogContentWrapper>
    )
  }

  const renderDialogActions = () => {
    return (
      <DialogActions style={{ root: styles.actionsWrapper }}>
        <Button onClick={handleClose} style={styles.cancelButton}>
          {t('cancel')}
        </Button>
        <SaveButton
          label='create_service'
          fetching={props.fetching}
          disabled={isFormInvalid()}
          handleSave={handleSave}
        />
      </DialogActions>
    )
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.isOpen}
      onClose={handleClose}
      PaperProps={{ style: styles.root }}
      fullWidth
    >
      <DialogHeader
        handleClose={handleClose}
        label='new_service'
      />
      {renderForm()}
      {renderDialogActions()}
    </Dialog>
  )
}

export default CreateServiceDialog
