import React, { useReducer, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'
import Box from '@mui/material/Box'
import MultiAutocomplete from '../MultiAutocomplete'

import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CircularProgress from '@mui/material/CircularProgress'
import FormLabel from '../FormLabel'
import { COLORS } from '../../utils/colors'
import DialogContentWrapper from '../DialogContentWrapper'
import CoachesList from './CoachesList'
import Spinner from '../Spinner'

const styles = {
  root: {

    maxWidth: 800
  },
  textField: {
    marginTop: 8,
    marginBottom: 8
  },
  smallInput: {
    display: 'block'
  },
  multiline: {
    marginTop: 16
  },
  smallLabel: {
    marginTop: 8,
    color: COLORS.label
  },
  message: {
    padding: 16,
    backgroundColor: COLORS.light,
    borderRadius: 4,
    color: COLORS.darkGrey,
    fontSize: 12,
    margin: 8
  },
  actionsWrapper: {
    padding: 16,
    borderTop: `1px solid ${COLORS.lightBorder}`
  },
  cancelButton: {
    color: COLORS.red,
    marginRight: 10
  },
  marginTop20: {
    marginTop: 20
  },
  backgroundText: {
    whiteSpace: 'pre-line'
  },
  listRoot: {
    width: '100%',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  tab: {
    width: '50%',
    maxWidth: 'unset'
  },
  labelColor: {
    color: COLORS.label
  },
  occupationsText: {
    marginBottom: 12
  },
  spinner: {
    color: COLORS.white
  },
  coachesContainer: {
    paddingTop: 10
  },
  formContainer: {
    paddingBottom: 10
  },
  buttonContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
}

const arePropsTheSame = (prevProps, nextProps) => {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return isEqual(prevProps.projectInfo, nextProps.projectInfo) && prevProps.fetching === nextProps.fetching &&
  isEqual(prevProps.coachOptions, nextProps.coachOptions)
}
const EditCoaches = React.memo((props) => {
  const initialState = {
    newCoaches: [],
    removableCoachId: null,
    showRemoveCoachAlert: false
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  const [t] = useTranslation()

  useEffect(() => {
    if (props.projectInfo != null) {
      const coachesAlreadyAdded = state.newCoaches.filter(newCoach => isCoachOnProject(newCoach.value))
      if (coachesAlreadyAdded.length >= 0) {
        /* Clear the autocomplete of added coaches */
        const refreshedCoaches = state.newCoaches.filter(newCoach => !isCoachOnProject(newCoach.value))
        setState({ newCoaches: refreshedCoaches })
      }
    }
  }, [props.projectInfo])

  const setCoaches = (newCoaches) => setState({ newCoaches })

  const handleAddNewCoaches = () => {
    if (state.newCoaches.length > 0) {
      const newCoaches = [...state.newCoaches]
      const newCoachIds = newCoaches.map(coach => coach.value)
      props.addCoaches(newCoachIds)
    }
  }

  const handleRemoveCoach = () => {
    const coachId = state.removableCoachId
    props.removeCoach(coachId)
    setState({
      removableCoachId: null,
      showRemoveCoachAlert: false
    })
  }

  const handleRemoveCoachAlert = (removableCoachId) => {
    setState({ showRemoveCoachAlert: !state.showRemoveCoachAlert, removableCoachId })
  }

  const isCoachOnProject = (coachId) => props.projectInfo != null && props.projectInfo.coaches != null && props.projectInfo.coaches.find(projectCoach => projectCoach.id === coachId)

  const getAvailableCoachOptions = () => {
    const coaches = []
    if (props.coachOptions.length > 0) {
      props.coachOptions.forEach((coach) => {
        if (!isCoachOnProject(coach.userId)) {
          const label = `${coach.firstNames} ${coach.lastName}`
          coaches.push({ label, value: coach.userId })
        }
      })
    }
    return coaches
  }

  const renderRemoveCoachAlert = () => {
    return (
      <Dialog
        open={state.showRemoveCoachAlert}
        onClose={handleRemoveCoachAlert}
        aria-labelledby='remove-coach-alert'
      >
        <DialogContent>
          <DialogContentText>
            {t('remove_coach_confirmation')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveCoachAlert} color='inherit'>
            {t('cancel')}
          </Button>
          <Button onClick={handleRemoveCoach} color='primary' autoFocus>
            {t('remove')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderButtonContent = (saveButtonText) => {
    const { setProjectInfoFetching, fetching } = props
    if (setProjectInfoFetching || fetching) {
      return <CircularProgress style={styles.spinner} size={16} />
    }
    return t(saveButtonText)
  }

  const renderSavedCoaches = () => {
    const { projectInfo } = props
    if (projectInfo) {
      return (
        <CoachesList
          coaches={projectInfo.coaches}
          onClick={(coach) => handleRemoveCoachAlert(coach.id)}
        />
      )
    } else {
      return <Spinner />
    }
  }

  const renderContent = () => {
    const { fetching } = props
    const { newCoaches } = state
    return (
      <DialogContentWrapper>
        <Box style={styles.formContainer}>
          <FormLabel text={t('new_trainers')} />
          <MultiAutocomplete
            value={newCoaches}
            onChange={(e, coaches) => setCoaches(coaches)}
            options={getAvailableCoachOptions()}
            noOptionsMessage={() => t('no_coaches_available')}
            placeholder={`${t('select_coaches')}...`}
            largeText
          />
          <Box style={styles.buttonContainer}>
            <Button
              onClick={handleAddNewCoaches}
              variant='contained'
              color='primary'
              disabled={fetching || newCoaches.length === 0}
            >
              {renderButtonContent('add_trainers')}
            </Button>
          </Box>
        </Box>
        {renderSavedCoaches()}
        {renderRemoveCoachAlert()}
      </DialogContentWrapper>
    )
  }

  return renderContent()
}, arePropsTheSame)

export default EditCoaches
