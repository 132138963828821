import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  coaches: [],
  coachesTotal: 0,
  allIds: [],
  fetching: false,
  error: null,
  selectAll: false,
  select: [],
  coachesOptions: [],
  coachingResults: [],
  coachesFetching: false
})

/* ------------- Types and Action Creators ------------- */

export const getCoachesOptions = createAction('GET_COACHES_OPTIONS')
export const getCoachesOptionsSuccess = createAction('GET_COACHES_OPTIONS_SUCCESS', (coachesOptions) => ({ coachesOptions }))
export const getCoachesOptionsFailure = createAction('GET_COACHES_OPTIONS_FAILURE', (error) => ({ error }))
export const addCoaches = createAction('ADD_COACHES', (projectId, coaches) => ({ projectId, coaches }))
export const addCoachesSuccess = createAction('ADD_COACHES_SUCCESS')
export const addCoachesFailure = createAction('ADD_COACHES_FAILURE', (error) => ({ error }))
export const removeCoach = createAction('REMOVE_COACH', (projectId, coachId) => ({ projectId, coachId }))
export const removeCoachSuccess = createAction('REMOVE_COACH_SUCCESS')
export const removeCoachFailure = createAction('REMOVE_COACH_FAILURE', (error) => ({ error }))
export const addCoachingResult = createAction('ADD_COACHING_RESULT', (projectId, result) => ({ projectId, result }))
export const addCoachingResultSuccess = createAction('ADD_COACHING_RESULT_SUCCESS')
export const addCoachingResultFailure = createAction('ADD_COACHING_RESULT_FAILURE', (error) => ({ error }))
export const removeCoachingResult = createAction('REMOVE_COACHING_RESULT', (projectId, result) => ({ projectId, result }))
export const removeCoachingResultSuccess = createAction('REMOVE_COACHING_RESULT_SUCCESS')
export const removeCoachingResultFailure = createAction('REMOVE_COACHING_RESULT_FAILURE', (error) => ({ error }))
export const getCoachingResults = createAction('GET_COACHING_RESULTS')
export const getCoachingResultsSuccess = createAction('GET_COACHING_RESULTS_SUCCESS', (coachingResults) => ({ coachingResults }))
export const getCoachingResultsFailure = createAction('GET_COACHING_RESULTS_FAILURE', (error) => ({ error }))
export const getCoaches = createAction('GET_COACHES')
export const getCoachesSuccess = createAction('GET_COACHES_SUCCESS', (coaches) => ({ coaches }))
export const getCoachesFailure = createAction('GET_COACHES_FAILURE', (error) => ({ error }))

/* ------------- Reducers ------------- */
const reducers = createReducer(
  {
    [getCoachesOptions]: (state) => state.merge({ coachesFetching: true }),
    [getCoachesOptionsSuccess]: (state, { coachesOptions }) => state.merge({ coachesOptions, coachesFetching: false }),
    [getCoachesOptionsFailure]: (state, { error }) => state.merge({ error, coachesFetching: false }),
    [addCoaches]: (state) => state.merge({ fetching: true }),
    [addCoachesSuccess]: (state) => state.merge({ fetching: false }),
    [addCoachesFailure]: (state, { error }) => state.merge({ error, fetching: false }),
    [removeCoach]: (state) => state.merge({ fetching: true }),
    [removeCoachSuccess]: (state) => state.merge({ fetching: false }),
    [removeCoachFailure]: (state, { error }) => state.merge({ error, fetching: false }),
    [addCoachingResult]: (state) => state.merge({ fetching: true }),
    [addCoachingResultSuccess]: (state) => state.merge({ fetching: false }),
    [addCoachingResultFailure]: (state, { error }) => state.merge({ error, fetching: false }),
    [removeCoachingResult]: (state) => state.merge({ fetching: true }),
    [removeCoachingResultSuccess]: (state) => state.merge({ fetching: false }),
    [removeCoachingResultFailure]: (state, { error }) => state.merge({ error, fetching: false }),
    [getCoachingResults]: (state) => state.merge({ fetching: true, error: null }),
    [getCoachingResultsSuccess]: (state, { coachingResults }) => state.merge({ fetching: false, coachingResults }),
    [getCoachingResultsFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [getCoaches]: (state) => state.merge({ coachesFetching: true }),
    [getCoachesSuccess]: (state, { coaches }) => state.merge({ coaches, coachesFetching: false }),
    [getCoachesFailure]: (state, { error }) => state.merge({ error, coachesFetching: false })
  },
  INITIAL_STATE
)

export default reducers
