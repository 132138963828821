import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'

const AlertDialog = (props) => {
  const [t] = useTranslation()

  const handleOk = () => {
    props.handleOk()
  }

  const handleClose = () => {
    props.close()
  }

  return (
    <Dialog
      open={Boolean(props.isOpen)}
      onClose={handleClose}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {props.cancelButtonMessage || t('cancel')}
        </Button>
        <Button onClick={handleOk} color='error' autoFocus>
          {props.okButtonMessage || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog
