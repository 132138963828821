import React from 'react'
import { withTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { COLORS } from '../utils/colors'

const isTextSame = (prevProps, nextProps) => {
  /*
  return true if passing nextProps.value to render would return
  the same result as passing prevProps.value to render,
  otherwise return false
  */
  return prevProps.text === nextProps.text
}
const styles = {
  caption: {
    color: COLORS.label
  }
}
// Use memoization to avoid unnecessary re-renderings (text not changed)
const FormLabel = React.memo((props) => {
  const classObject = props.styles ? { ...styles.caption, ...props.styles } : styles.caption
  return (
    <Typography variant='caption' sx={classObject}>{props.text}</Typography>
  )
}, isTextSame)
export default withTranslation()(FormLabel)
