import React, { useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as MessagesActions from '../redux/MessagesRedux'
import { COLORS } from '../utils/colors'
import { getFilterCount } from '../utils/transforms'
import TableToolbar from '../components/TableToolBar'
import MessageFilterDialog from '../components/messages/MessageFilterDialog'
import MessageRowMobile from '../components/messages/MessageRowMobile'
import SpringerTablePagination from '../components/applicants/SpringerTablePagination'
import ActionBar from '../components/ActionBar'

/* Material-UI components */
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import SmsIcon from '@mui/icons-material/Sms'
import { TableRow, TableCell, TableHead, Box, useMediaQuery } from '@mui/material'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import PhoneIcon from '@mui/icons-material/PhoneOutlined'
import PersonIcon from '@mui/icons-material/PersonOutlined'
import ScheduleIcon from '@mui/icons-material/ScheduleOutlined'
import DateText from '../components/DateText'
import { useTheme } from '@mui/styles'

const baseStyle = {
  backgroundColor: COLORS.orange,
  color: COLORS.white,
  padding: '8px 16px',
  fontWeight: 'bold'
}
const styles = {
  container: {
    width: '100%',
    maxWidth: 1360,
    margin: '0 auto',
    paddingBottom: 80
  },
  root: {
    width: '100%'
  },
  iconText: {
    verticalAlign: 'super',
    paddingLeft: 4
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  leftColumn: {
    display: 'flex',
    flex: 40
  },
  rightColumn: {
    display: 'flex',
    flex: 50,
    flexDirection: 'column'
  },
  tableContainer: {
    overflowX: 'auto',
    width: '100%',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    // Prevent vertical scrollbar when opening dropdown
    paddingBottom: 300,
    marginBottom: -300
  },
  table: {
    minWidth: 700
  },
  tableHead: {
    ...baseStyle,
    minWidth: 80
  },
  tableHeadWide: {
    ...baseStyle,
    width: 200
  },
  spinner: {
    display: 'flex',
    padding: '64px 16px',
    justifyContent: 'center'
  },
  centered: {
    margin: '32px 0 16px',
    textAlign: 'center',
    width: '100%'
  },
  cellRow: {
    paddingTop: 4,
    paddingBottom: 4,
    display: 'flex',
    alignItems: 'center'
  },
  wideCell: {
    width: 360
  },
  cell: {
    padding: '8px 16px'
  },
  cellIcon: {
    color: COLORS.darkGrey
  },
  smallCellIcon: {
    color: COLORS.darkGrey,
    fontSize: 12,
    marginRight: 8
  },
  text: {
    fontSize: '0.875rem',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400
  }
}

const MessagesScreen = (props) => {
  const initialState = {
    filterDialog: false
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  const [t] = useTranslation()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  useEffect(() => {
    props.getMessages()
  }, []) // On component mount

  const renderInitSpinner = () => {
    const { fetching, visibleMessages } = props
    // Initial loading
    if (fetching && !visibleMessages.length) {
      return (
        <div style={styles.spinner}>
          <CircularProgress />
        </div>
      )
    }
  }

  const isSms = (msg) => {
    return msg.messageType === 'SMS'
  }

  const isEmail = (msg) => {
    return msg.messageType === 'Email'
  }

  const renderSMSMessageRow = (msg) => {
    return (
      <TableRow key={msg.id}>
        <TableCell style={styles.cell}>
          <SmsIcon style={styles.cellIcon} />
          <Typography component='span' style={styles.iconText} color='textPrimary'>
            {t('sms')}
          </Typography>
        </TableCell>
        <TableCell style={styles.cell}>
          <div style={styles.cellRow}>
            <PersonIcon style={styles.smallCellIcon} />
            <Typography component='span' style={styles.leftColumn} color='textPrimary'>
              {`${msg.senderFirstNames} ${msg.senderLastName}`}
            </Typography>
          </div>
        </TableCell>
        <TableCell style={styles.cell}>
          <div style={styles.cellRow}>
            <PersonIcon style={styles.smallCellIcon} />
            {`${msg.receiverFirstNames} ${msg.receiverLastName}`}
          </div>
          <div style={styles.cellRow}>
            <PhoneIcon style={styles.smallCellIcon} />
            {msg.receiverPhoneNumber}
          </div>
          <div style={styles.cellRow}>
            <ScheduleIcon style={styles.smallCellIcon} />
            <DateText text={msg.sentDateTime} style={styles.text} />
          </div>
        </TableCell>
        <TableCell style={styles.cell}>
          <Typography component='span' style={styles.leftColumn} color='textPrimary'>
            -
          </Typography>
        </TableCell>
        <TableCell style={styles.wideCell}>
          <Typography component='span' style={styles.rightColumn} color='textPrimary'>
            {msg.plainTextMessage}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  const renderEmailMessageRow = (msg) => {
    return (
      <TableRow key={msg.id}>
        <TableCell style={styles.cell}>
          <EmailIcon style={styles.cellIcon} />
          <Typography component='span' style={styles.iconText} color='textPrimary'>
            {t('email')}
          </Typography>
        </TableCell>
        <TableCell style={styles.cell}>
          <div style={styles.cellRow}>
            <PersonIcon style={styles.smallCellIcon} />
            <Typography component='span' style={styles.leftColumn} color='textPrimary'>
              {`${msg.senderFirstNames} ${msg.senderLastName}`}
            </Typography>
          </div>
        </TableCell>
        <TableCell style={styles.cell}>
          <div style={styles.cellRow}>
            <PersonIcon style={styles.smallCellIcon} />
            {`${msg.receiverFirstNames} ${msg.receiverLastName}`}
          </div>
          <div style={styles.cellRow}>
            <EmailIcon style={styles.smallCellIcon} />
            {msg.receiverEmail}
          </div>
          <div style={styles.cellRow}>
            <ScheduleIcon style={styles.smallCellIcon} />
            <DateText text={msg.sentDateTime} style={styles.text} />
          </div>
        </TableCell>
        <TableCell style={styles.cell}>
          <Typography component='span' color='textPrimary'>
            {msg.subject}
          </Typography>
        </TableCell>
        <TableCell style={styles.wideCell}>
          <Typography
            component='span' style={styles.rightColumn} color='textPrimary'
            dangerouslySetInnerHTML={{ __html: msg.formattedMessage }}
          />
        </TableCell>
      </TableRow>
    )
  }

  const isEmptyMessagesList = () => {
    const { messages } = props
    if (!messages.length) {
      return true
    }
    return false
  }

  const renderEmptyMessage = () => {
    const { messagesTotal } = props
    if (messagesTotal == null || messagesTotal.length === 0) {
      return (
        <Typography style={styles.centered}>
          {t('no_results')}
        </Typography>
      )
    }
    return null
  }

  const renderMobileActionBar = () => {
    const { messagesTotal } = props
    return (
      <ActionBar
        totalCount={messagesTotal}
        openFilter={toggleFilterDialog}
        filterCount={getFilterCount(props.messageFilters)}
        paginationComponent={renderPagination()}
      />
    )
  }

  const renderMobileMessagesTable = () => {
    if (isEmptyMessagesList()) return renderEmptyMessage()
    return props.visibleMessages.map(msg => (
      <MessageRowMobile
        key={msg.id}
        message={msg}
      />
    ))
  }

  const renderMessagesTable = () => {
    const { visibleMessages, messagesTotal } = props

    return (
      <div style={styles.tableContainer}>
        <TableToolbar
          headerText={`${t('messages')} ${messagesTotal}`}
          openFilters={toggleFilterDialog}
          filterCount={getFilterCount(props.messageFilters)}
        />
        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHead}>{t('message_type')}</TableCell>
              <TableCell style={styles.tableHead}>{t('sender')}</TableCell>
              <TableCell style={styles.tableHead}>{t('recipient')}</TableCell>
              <TableCell style={styles.tableHeadWide}>{t('subject')}</TableCell>
              <TableCell style={styles.tableHead}>{t('message')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleMessages.map(msg => {
              if (isSms(msg)) { return renderSMSMessageRow(msg) }
              if (isEmail(msg)) { return renderEmailMessageRow(msg) } else { return null }
            })}
            {renderEmptyMessage()}
          </TableBody>
        </Table>
        {renderPagination()}
      </div>
    )
  }

  const toggleFilterDialog = () => setState({ filterDialog: !state.filterDialog })

  // Render pagination controls
  const renderPagination = () => {
    const { page, fetching, messagesTotal, pageSize } = props
    return (
      <SpringerTablePagination
        page={page - 1}
        count={messagesTotal}
        handleChangePage={props.changeMessagesPage}
        handleChangeRowsPerPage={props.changeMessageRowsPerPage}
        fetching={fetching}
        pageSize={pageSize}
        labelRowsPerPage={t('messages_per_page')}
      />
    )
  }

  return (
    <div style={styles.container}>
      {!matches &&
        <Box>
          {renderMobileMessagesTable()}
          {renderInitSpinner()}
          {renderMobileActionBar()}
        </Box>}
      {matches &&
        <Box>
          {renderPagination()}
          <Paper style={styles.root}>
            {renderMessagesTable()}
            {renderInitSpinner()}
          </Paper>
        </Box>}
      <MessageFilterDialog
        open={state.filterDialog}
        onClose={toggleFilterDialog}
        setMessageFilters={props.setMessageFilters}
        messageFilters={props.messageFilters}
        resetMessageFilters={props.resetMessageFilters}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    messages: state.messages.messages,
    visibleMessages: state.messages.visibleMessages,
    fetching: state.messages.fetching,
    page: state.messages.page,
    pageSize: state.messages.pageSize,
    messagesTotal: state.messages.messagesTotal,
    messageFilters: state.messages.messageFilters,
    user: state.user.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMessages: () => dispatch(MessagesActions.getMessages()),
    changeMessagesPage: (page) => dispatch(MessagesActions.changeMessagesPage(page)),
    changeMessageRowsPerPage: (rows) => dispatch(MessagesActions.changeMessageRowsPerPage(rows)),
    setMessageFilters: (filters) => dispatch(MessagesActions.setMessageFilters(filters))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  MessagesScreen
)
