import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import { Box } from '@mui/material'
import EditMeetingForm from './EditMeetingForm'
import EditNoteForm from './EditNoteForm'
import EditSummaryForm from './EditSummaryForm'
import ConfirmationDialog from '../ConfirmationDialog'
import { isMeetingEntry, isNoteEntry, isSummaryEntry } from '../../utils/entries'

const styles = {
  entryBox: {
    minHeight: 200
  }
}

const EditEntry = (props) => {
  const initialState = {
    isConfirmDeleteDialogOpen: false
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  const [t] = useTranslation()

  /* Created event happened */
  useEffect(() => {
    if (props.created) {
      handleClose()
      props.okCreate()
    }
  }, [props.created])

  const handleSave = (formState) => {
    if (!props.loading) {
      // Merge selected entry type with form state
      const entry = { ...formState, coachingId: props.applicant.coachingId }
      const projectId = props.applicant.projectId
      const coachingEntryId = props.entry.id
      props.onEditEntry(projectId, coachingEntryId, entry)
    }
  }

  const handleClose = (e) => {
    props.goBack(e, 0)
  }

  const openConfirmDelete = (fileForDelete) => setState({ isConfirmDeleteDialogOpen: true })

  const closeConfirmDelete = () => setState({ isConfirmDeleteDialogOpen: false })

  const handleEntryDelete = () => {
    const { applicant, entry } = props
    props.onDeleteCoachingEntry(applicant.projectId, entry.id, applicant.coachingId)
    closeConfirmDelete()
  }

  const renderEntryForm = () => {
    const { entry } = props
    if (entry.entryType == null || isMeetingEntry(entry.entryType)) { // If no entryType, it's a good old meeting
      return (
        <EditMeetingForm
          entry={entry}
          applicant={props.applicant}
          onEditMeeting={props.onEditMeeting}
          onCancelMeeting={props.onCancelMeeting}
          loading={props.loading}
          isAdmin={props.isAdmin}
        />
      )
    } else if (isNoteEntry(entry.entryType)) {
      return (
        <EditNoteForm
          entry={entry}
          applicant={props.applicant}
          user={props.user}
          onEditEntry={handleSave}
          onDeleteEntry={openConfirmDelete}
          loading={props.loading}
          isAdmin={props.isAdmin}
        />
      )
    } else if (isSummaryEntry(entry.entryType)) {
      return (
        <EditSummaryForm
          entry={entry}
          goBack={props.goBack}
          applicant={props.applicant}
          user={props.user}
          onEditEntry={handleSave}
          onDeleteEntry={openConfirmDelete}
          loading={props.loading}
          coachingEntrySummary={props.coachingEntrySummary}
          isAdmin={props.isAdmin}
        />
      )
    }
  }

  return (
    <Box style={styles.entryBox}>
      {renderEntryForm()}
      <ConfirmationDialog
        isOpen={state.isConfirmDeleteDialogOpen}
        title={t('delete_entry')}
        message={t('delete_coaching_entry_confirmation')}
        close={closeConfirmDelete}
        handleOk={handleEntryDelete}
      />
    </Box>
  )
}

export default withStyles(styles)((EditEntry))
