import React, { useEffect, useReducer } from 'react'
import { withTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CancelIcon from '@mui/icons-material/Cancel'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import InfoIcon from '@mui/icons-material/Info'
import isEmpty from 'lodash/isEmpty'
import DialogContent from '@mui/material/DialogContent'
import PolicyIcon from '@mui/icons-material/Policy'
import ToolTip from '../ToolTip'
import Autocomplete from '../Autocomplete'
import AsyncAutocomplete from '../AsyncAutocomplete'
import MultiAutocomplete from '../MultiAutocomplete'
import DatePicker from '../DatePicker'
import { getCurrentLang, getObjectNameTranslation } from '../../utils/transforms'
import FormTextField from '../FormTextField'
import ESCOAPI from '../../services/escoApi'
import EscoOccupationList from '../EscoOccupationList'
import Spinner from '../Spinner'
import FormFieldWrapper from '../FormFieldWrapper'
import { COLORS } from '../../utils/colors'
import DialogContentWrapper from '../DialogContentWrapper'
import { Checkbox, useMediaQuery } from '@mui/material'
import { customReducer } from '../../utils/reducer'
import { useTheme } from '@mui/styles'
import { addYears } from 'date-fns'

const styles = {
  root: {
    maxWidth: 800
  },
  title: {
    marginLeft: 6,
    flex: 1
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  caption: {
    color: COLORS.label
  },
  divider: {
    padding: '0 16px'
  },
  menuButton: {
    marginLeft: 5
  },
  okButton: {
    marginRight: 15,
    fonFamily: 'Roboto',
    fontWeight: 'bold'
  },
  clearButton: {
    marginLeft: 8
  },
  additionalInfo: {
    padding: 16
  },
  additionalInfoContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  additionalInfoContent: {
    maxWidth: 420
  },
  occupationsFilter: {
    marginTop: 20,
    width: '80%'
  },
  notice: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row'
  },
  iconButton: {
    color: COLORS.black
  },
  error: {
    color: COLORS.red
  }
}

const ApplicantFilterDialog = (props) => {
  const initialState = {
    hometowns: [],
    services: [],
    projects: [],
    trainers: [],
    status: null,
    coachingResult: null,
    startDateStart: null,
    startDateEnd: null,
    endDateStart: null,
    endDateEnd: null,
    searchString: '',
    nameSearchString: '',
    occupations: [],
    selectedOccupations: [],
    assessmentState: null,
    assessmentCoaches: [],
    noCoachOnly: false,
    socialSecurityNumber: '',
    dateOfBirth: null
  }
  const reducer = (state, newState) => customReducer(state, newState, initialState)
  const [state, setState] = useReducer(reducer, initialState)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const assessmentStates = [
    { value: 1, label: props.t('no_initial_assessment') },
    { value: 2, label: props.t('active') },
    { value: 3, label: props.t('ended') },
    { value: 4, label: props.t('all_initial_asessment_states') }
  ]

  useEffect(() => {
    if (props.open) {
      ESCOAPI().getTopLevelConcepts(getCurrentLang()).then(data => {
        const arr = data || []
        setState({ occupations: arr })
        return data
      })
    }
  }, [props.open]) // On component mount

  /* When filters come filled as props, set them to state */
  useEffect(() => {
    if (!isEmpty(props.applicantFilters)) {
      setState({ ...props.applicantFilters })
    }
  }, [props.applicantFilters])

  const fetchOccupationChildren = (uri) => {
    return ESCOAPI().getChildOccupations(uri, getCurrentLang()).then(data => data)
  }

  const setHometowns = (hometowns) => { setState({ hometowns }) }
  const setServices = (services) => setState({ services })
  const setProjects = (projects) => setState({ projects })
  const setTrainers = (trainers) => setState({ trainers })
  const setInitialAssessmentState = (assessmentState) => setState({ assessmentState })
  const setAssessmentCoaches = (assessmentCoaches) => setState({ assessmentCoaches })
  const clearInitialAssessmentState = () => setInitialAssessmentState(null)
  const setStartDateStart = (startDateStart) => setState({ startDateStart })
  const setStartDateEnd = (startDateEnd) => setState({ startDateEnd })
  const setEndDateStart = (endDateStart) => setState({ endDateStart })
  const setEndDateEnd = (endDateEnd) => setState({ endDateEnd })
  const setSocialSecurityNumber = (e) => setState({ socialSecurityNumber: e.target.value })
  const setDateOfBirth = (dateOfBirth) => setState({ dateOfBirth })

  const setSearchStringValue = (e) => setState({ searchString: e.target.value })
  const setNameSearchStringValue = (e) => setState({ nameSearchString: e.target.value })
  const setStatus = (status) => setState({ status })
  const clearStatus = () => setStatus(null)
  const setCoachingResult = (coachingResult) => setState({ coachingResult })
  const clearCoachingResult = () => setCoachingResult(null)
  const clearStartDate = () => {
    setStartDateStart(null)
    setStartDateEnd(null)
  }
  const clearEndDate = () => {
    setEndDateStart(null)
    setEndDateEnd(null)
  }
  const clearDateOfBirth = () => {
    setDateOfBirth(null)
  }
  const toggleNoCoach = () => setState({ noCoachOnly: !state.noCoachOnly })
  const resetFilters = () => {
    const filters = getFiltersWithoutUnrelatedProperties(initialState)
    setState(filters)
    props.resetApplicantFilters()
  }

  const onProjectInputChange = (value) => {
    props.getFilteredProjectsOptions({ searchString: value })
  }

  const onProjectsClose = (event, reason) => {
    props.clearFilteredProjectsOptions()
  }

  const handleOkPress = () => {
    const filters = getFiltersWithoutUnrelatedProperties(state)
    props.setApplicantFilters(filters)
    props.onClose()
  }

  /* Get filters object with only filter related properties */
  const getFiltersWithoutUnrelatedProperties = (givenState) => {
    const defaultState = { ...givenState }
    delete defaultState.occupations
    return defaultState
  }

  // TODO: check that this works when api gets consultants data and use this function in dialog
  const getTrainerOptions = () => {
    const trainers = []
    if (props.coaches.length > 0) {
      props.coaches.forEach((coach) => {
        const label = `${coach.firstNames} ${coach.lastName}`
        trainers.push({ label, value: coach.userId })
      })
    }
    return trainers
  }

  const getTalentStatusOptions = () => props.talentStatuses.map(item => ({
    label: getObjectNameTranslation(item),
    value: item.en // API uses english names to filter applicants
  }))

  const getCoachingResultOptions = () => props.coachingResults.map(item => ({
    label: getObjectNameTranslation(item),
    value: item.en // API uses english names to filter applicants
  }))

  const getProjectOptions = () => {
    if (props.projects.length > 0) {
      const projects = []
      props.projects.forEach(project => {
        projects.push({
          label: getObjectNameTranslation(project.name),
          value: project.id
        })
      })
      return projects
    } return []
  }

  const getPreselectedProjectsValue = () => state.projects

  const isOccupationSelected = (occup) => state.selectedOccupations.find(selected => selected.code === occup.code) != null

  const handleOccupationCheck = (occupation) => {
    if (isOccupationSelected(occupation)) {
      /* Remove selected occupations from component state */
      const occupationsWithoutUncheckedOccupation = state.selectedOccupations.filter(selected => selected.code !== occupation.code)
      setState({ selectedOccupations: occupationsWithoutUncheckedOccupation })
    } else {
      /* Set selected occupation to state */
      const occupationsWithNewlyChecked = [...state.selectedOccupations, occupation]
      setState({ selectedOccupations: occupationsWithNewlyChecked })
    }
  }

  const renderSafeModeInformation = () => {
    const { t, isSafeModeOn, toggleSafeMode } = props
    if (isSafeModeOn) {
      return (
        <div style={styles.notice}>
          <ToolTip title={t('safe_mode_off')}>
            <IconButton style={styles.iconButton} onClick={() => toggleSafeMode(null)}>
              <PolicyIcon fontSize='large' />
            </IconButton>
          </ToolTip>
          <Typography style={styles.error}>{t('safe_mode_warning')}</Typography>
        </div>
      )
    } else return null
  }

  const renderTargetOccupations = () => {
    const { occupations, selectedOccupations } = state
    if (occupations.length > 0) {
      return (
        <div style={styles.listRoot}>
          <EscoOccupationList
            occupations={occupations}
            topLevel={2}
            selectable
            fetchOccupationChildren={fetchOccupationChildren}
            onOccupationCheck={handleOccupationCheck}
            selectedOccupations={selectedOccupations}
            badges
          />
        </div>
      )
    } else {
      return <Spinner />
    }
  }

  const renderContent = () => {
    const { hometowns, services, trainers, status, startDateStart, startDateEnd, endDateStart, endDateEnd, searchString, nameSearchString, coachingResult, projects, noCoachOnly, socialSecurityNumber, dateOfBirth } = state
    const { t } = props
    return (
      <DialogContent>
        <DialogContentWrapper>
          {renderSafeModeInformation()}
          <FormFieldWrapper>
            <div style={styles.flexContainer}>
              <div style={{ flex: 1 }}>
                <FormTextField
                  label={t('name')}
                  style={styles.textField}
                  variant='standard'
                  fullWidth
                  onChange={value => setNameSearchStringValue(value)}
                  value={nameSearchString}
                  onEnterPress={value => handleOkPress()}
                />
              </div>
              <ToolTip title={t('name_search_info')}>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </ToolTip>
            </div>
          </FormFieldWrapper>

          <FormFieldWrapper>
            <div style={styles.flexContainer}>
              <div style={{ flex: 1 }}>
                <FormTextField
                  label={t('social_security_number')}
                  style={styles.textField}
                  variant='standard'
                  fullWidth
                  onChange={value => setSocialSecurityNumber(value)}
                  value={socialSecurityNumber}
                  onEnterPress={value => handleOkPress()}
                />
              </div>
            </div>
          </FormFieldWrapper>

          <FormFieldWrapper>
            <Typography variant='caption'>{t('birthday')}</Typography>
            <div style={styles.flexContainer}>
              <DatePicker value={dateOfBirth} onChange={date => setDateOfBirth(date)} placeholder={`${t('select')}...`} minDate={addYears(new Date(), -125)} />
              <IconButton style={styles.clearButton} onClick={clearDateOfBirth} disabled={!dateOfBirth}>
                <CancelIcon />
              </IconButton>
            </div>
          </FormFieldWrapper>

          <FormFieldWrapper>
            <MultiAutocomplete
              value={hometowns}
              label={t('hometown')}
              groupBy={(option) => option.region}
              onChange={(e, hometowns) => setHometowns(hometowns)}
              options={props.regions}
              noOptionsMessage={() => t('no_areas_available')}
              placeholder={`${t('select')}...`}
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <MultiAutocomplete
              value={services}
              label={t('service')}
              onChange={(e, services) => setServices(services)}
              options={[...props.services]}
              noOptionsMessage={() => t('no_services_available')}
              placeholder={`${t('select')}...`}
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <AsyncAutocomplete
              multiple
              value={projects}
              label={t('project')}
              options={getProjectOptions()}
              onChange={onProjectInputChange}
              onValueSelect={projects => setProjects(projects)}
              onClose={onProjectsClose}
              noOptionsText={`${t('type_project_name')}...`}
              largeText
              preselectedValue={getPreselectedProjectsValue()}
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <MultiAutocomplete
              value={trainers}
              onChange={(e, trainers) => setTrainers(trainers)}
              options={getTrainerOptions()}
              label={t('trainer')}
              noOptionsMessage={() => t('no_trainers_available')}
              placeholder={`${t('select')}...`}
            />
            <Typography variant='caption'>{t('no_trainer')}</Typography>
            <Checkbox
              checked={noCoachOnly}
              onChange={toggleNoCoach}
              color='primary'
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <div style={styles.flexContainer}>
              <div style={{ flex: 1 }}>
                <Autocomplete
                  value={status}
                  label={t('state')}
                  options={[...getTalentStatusOptions()]}
                  onChange={status => setStatus(status)}
                  placeholder={`${t('select')}...`}
                />
              </div>
              <IconButton style={styles.clearButton} onClick={clearStatus} disabled={!status}>
                <CancelIcon />
              </IconButton>
            </div>
          </FormFieldWrapper>

          <FormFieldWrapper>
            <div style={styles.flexContainer}>
              <div style={{ flex: 1 }}>
                <Autocomplete
                  value={coachingResult}
                  label={t('coaching_result')}
                  options={[...getCoachingResultOptions()]}
                  onChange={status => setCoachingResult(status)}
                  placeholder={`${t('select')}...`}
                />
              </div>
              <IconButton style={styles.clearButton} onClick={clearCoachingResult} disabled={!coachingResult}>
                <CancelIcon />
              </IconButton>
            </div>
          </FormFieldWrapper>

          <FormFieldWrapper>
            <div style={styles.flexContainer}>
              <div style={{ flex: 1 }}>
                <Autocomplete
                  value={state.assessmentState}
                  label={t('initial_assessment')}
                  options={assessmentStates}
                  onChange={state => setInitialAssessmentState(state)}
                  placeholder={`${t('select')}...`}
                />
              </div>
              <IconButton style={styles.clearButton} onClick={clearInitialAssessmentState} disabled={!state.assessmentState}>
                <CancelIcon />
              </IconButton>
            </div>
          </FormFieldWrapper>

          <FormFieldWrapper>
            <MultiAutocomplete
              value={state.assessmentCoaches}
              label={t('initial_assessment_coach')}
              onChange={(e, trainers) => setAssessmentCoaches(trainers)}
              options={getTrainerOptions()}
              noOptionsMessage={() => t('no_trainers_available')}
              placeholder={`${t('select')}...`}
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <Typography variant='caption'>{t('start_date_range')}</Typography>
            <div style={styles.flexContainer}>
              <DatePicker value={startDateStart} onChange={date => setStartDateStart(date)} placeholder={`${t('select')}...`} maxDate={startDateEnd} />
              <span style={styles.divider}>-</span>
              <DatePicker value={startDateEnd} onChange={date => setStartDateEnd(date)} placeholder={`${t('select')}...`} minDate={startDateStart} />
              <IconButton style={styles.clearButton} onClick={clearStartDate} disabled={!startDateStart && !startDateEnd}>
                <CancelIcon />
              </IconButton>
            </div>
          </FormFieldWrapper>

          <FormFieldWrapper>
            <Typography variant='caption'>{t('end_date_range')}</Typography>
            <div style={styles.flexContainer}>
              <DatePicker value={endDateStart} onChange={date => setEndDateStart(date)} placeholder={`${t('select')}...`} maxDate={endDateEnd} />
              <span style={styles.divider}>-</span>
              <DatePicker value={endDateEnd} onChange={date => setEndDateEnd(date)} placeholder={`${t('select')}...`} minDate={endDateStart} />
              <IconButton style={styles.clearButton} onClick={clearEndDate} disabled={!endDateStart && !endDateEnd}>
                <CancelIcon />
              </IconButton>
            </div>
          </FormFieldWrapper>

          <FormFieldWrapper>
            <div style={styles.flexContainer}>
              <div style={{ flex: 1 }}>
                <FormTextField
                  label={t('additional_info_text_search')}
                  fullWidth
                  variant='standard'
                  onChange={value => setSearchStringValue(value)}
                  value={searchString}
                />
              </div>
              <ToolTip title={t('text_search_info')}>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </ToolTip>
            </div>
          </FormFieldWrapper>
          <div style={styles.occupationsFilter}>
            <Typography style={styles.caption}>{t('target_occupations')}</Typography>
            {renderTargetOccupations()}
          </div>
        </DialogContentWrapper>
      </DialogContent>
    )
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      PaperProps={{ style: styles.root }}
      fullWidth
      scroll='paper'
    >
      <AppBar position='static'>
        <Toolbar disableGutters>
          <IconButton style={styles.menuButton} color='inherit' onClick={props.onClose} aria-label={props.t('close')}>
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' color='inherit' style={styles.title}>
            {props.t('filters')}
          </Typography>
          <Button color='inherit' onClick={resetFilters}>
            {props.t('reset')}
          </Button>
          <Button color='inherit' onClick={handleOkPress} style={styles.okButton}>
            {props.t('ok')}
          </Button>
        </Toolbar>
      </AppBar>
      {renderContent()}
    </Dialog>
  )
}

export default withTranslation()(ApplicantFilterDialog)
