import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  regions: [],
  finlandRegionsWithCities: [],
  loading: false,
  error: null,
  regionsWithCities: [],
  areas: null,
  searchedPostalCodes: [],
  searchingPostalCodes: false,
  countries: [],
  languages: [],
  languageSkills: [],
  languageSkillLevels: [],
  languageSkillLevelOptions: []
})

/* ------------- Types and Action Creators ------------- */

export const getRegions = createAction('GET_REGIONS')
export const getRegionsSuccess = createAction('GET_REGIONS_SUCCESS', (regions) => ({ regions }))
export const getRegionsFailure = createAction('GET_REGIONS_FAILURE', (error) => ({ error }))
export const getFinlandRegionsWithCities = createAction('GET_FINLAND_REGIONS_WITH_CITIES')
export const getFinlandRegionsWithCitiesSuccess = createAction('GET_FINLAND_REGIONS_WITH_CITIES_SUCCESS', (finlandRegionsWithCities) => ({ finlandRegionsWithCities }))
export const getFinlandRegionsWithCitiesFailure = createAction('GET_FINLAND_REGIONS_WITH_CITIES_FAILURE', (error) => ({ error }))
export const getRegionsWithCities = createAction('GET_REGIONS_WITH_CITIES')
export const getRegionsWithCitiesSuccess = createAction('GET_REGIONS_WITH_CITIES_SUCCESS', (regionsWithCities) => ({ regionsWithCities }))
export const getRegionsWithCitiesFailure = createAction('GET_REGIONS_WITH_CITIES_FAILURE', (error) => ({ error }))
export const getAreas = createAction('GET_AREAS')
export const getAreasSuccess = createAction('GET_AREAS_SUCCESS', (areas) => ({ areas }))
export const getAreasFailure = createAction('GET_AREAS_FAILURE', (error) => ({ error }))
export const searchPostalCodes = createAction('SEARCH_POSTAL_CODES', (searchCode) => ({ searchCode }))
export const searchPostalCodesSuccess = createAction('SEARCH_POSTAL_CODES_SUCCESS', (searchedPostalCodes) => ({ searchedPostalCodes }))
export const searchPostalCodesFailure = createAction('SEARCH_POSTAL_CODES_FAILURE', (error) => ({ error }))
export const clearSearchedPostalCodes = createAction('CLEAR_SEARCHED_POSTAL_CODES')
export const getCountries = createAction('GET_COUNTRIES')
export const getCountriesSuccess = createAction('GET_COUNTRIES_SUCCESS', (countries) => ({ countries }))
export const getCountriesFailure = createAction('GET_COUNTRIES_FAILURE', (error) => ({ error }))
export const getLanguages = createAction('GET_LANGUAGES')
export const getLanguagesSuccess = createAction('GET_LANGUAGES_SUCCESS', (languages) => ({ languages }))
export const getLanguagesFailure = createAction('GET_LANGUAGES_FAILURE', (error) => ({ error }))
export const getLanguageSkillLevels = createAction('GET_LANGUAGE_SKILL_LEVELS')
export const getLanguageSkillLevelsSuccess = createAction('GET_LANGUAGE_SKILL_LEVELS_SUCCESS', (languageSkillLevels, languageSkillLevelOptions) => ({ languageSkillLevels, languageSkillLevelOptions }))
export const getLanguageSkillLevelsFailure = createAction('GET_LANGUAGE_SKILL_LEVELS_FAILURE', (error) => ({ error }))
export const getLanguageSkills = createAction('GET_LANGUAGE_SKILLS')
export const getLanguageSkillsSuccess = createAction('GET_LANGUAGE_SKILLS_SUCCESS', (languageSkills) => ({ languageSkills }))
export const getLanguageSkillsFailure = createAction('GET_LANGUAGE_SKILLS_FAILURE', (error) => ({ error }))

/* ------------- Reducers ------------- */
const reducers = createReducer(
  {
    [getRegions]: (state) => state.merge({ loading: true }),
    [getRegionsSuccess]: (state, { regions }) => state.merge({ loading: false, regions }),
    [getRegionsFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [getFinlandRegionsWithCities]: (state) => state.merge({ loading: true }),
    [getFinlandRegionsWithCitiesSuccess]: (state, { finlandRegionsWithCities }) => state.merge({ loading: false, finlandRegionsWithCities }),
    [getFinlandRegionsWithCitiesFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [getRegionsWithCities]: (state) => state.merge({ loading: true, error: null }),
    [getRegionsWithCitiesSuccess]: (state, { regionsWithCities }) => state.merge({ loading: false, regionsWithCities }),
    [getRegionsWithCitiesFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [getAreas]: (state) => state.merge({ loading: true, error: null }),
    [getAreasSuccess]: (state, { areas }) => state.merge({ loading: false, error: null, areas }),
    [getAreasFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [searchPostalCodes]: (state) => state.merge({ loading: true, searchingPostalCodes: true, error: null }),
    [searchPostalCodesSuccess]: (state, { searchedPostalCodes }) => state.merge({ loading: false, searchingPostalCodes: false, error: null, searchedPostalCodes }),
    [searchPostalCodesFailure]: (state, { error }) => state.merge({ loading: false, searchingPostalCodes: false, error }),
    [clearSearchedPostalCodes]: (state) => state.merge({ searchedPostalCodes: [] }),
    [getCountries]: (state) => state.merge({ loading: true, error: null }),
    [getCountriesSuccess]: (state, { countries }) => state.merge({ loading: false, error: null, countries }),
    [getCountriesFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [getLanguages]: (state) => state.merge({ loading: true, error: null }),
    [getLanguagesSuccess]: (state, { languages }) => state.merge({ loading: true, error: null, languages }),
    [getLanguagesFailure]: (state, { error }) => state.merge({ loading: true, error }),
    [getLanguageSkillLevels]: (state) => state.merge({ loading: true, error: null }),
    [getLanguageSkillLevelsSuccess]: (state, { languageSkillLevels, languageSkillLevelOptions }) => state.merge({ loading: true, error: null, languageSkillLevels, languageSkillLevelOptions }),
    [getLanguageSkillLevelsFailure]: (state, { error }) => state.merge({ loading: true, error }),
    [getLanguageSkills]: (state) => state.merge({ loading: true, error: null }),
    [getLanguageSkillsSuccess]: (state, { languageSkills }) => state.merge({ loading: true, error: null, languageSkills }),
    [getLanguageSkillsFailure]: (state, { error }) => state.merge({ loading: true, error })
  },
  INITIAL_STATE
)

export default reducers
