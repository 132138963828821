import React, { useEffect, useReducer } from 'react'
import { useTheme } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CancelIcon from '@mui/icons-material/Cancel'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormTextField from '../FormTextField'

import isEmpty from 'lodash/isEmpty'
import MultiAutocomplete from '../MultiAutocomplete'
import DatePicker from '../DatePicker'
import FormFieldWrapper from '../FormFieldWrapper'
import DialogContentWrapper from '../DialogContentWrapper'
import { useMediaQuery } from '@mui/material'

const styles = {
  root: {
    maxWidth: 800
  },
  title: {
    marginLeft: 6,
    flex: 1
  },
  flexContainer: {
    flex: 1,
    alignItems: 'center'
  },
  caption: {
    marginTop: 16
  },
  divider: {
    padding: '0 16px'
  },
  menuButton: {
    marginLeft: 5
  },
  okButton: {
    marginRight: 15,
    fonFamily: 'Roboto',
    fontWeight: 'bold'
  },
  clearButton: {
    marginLeft: 8
  },
  additionalInfo: {
    padding: 16
  },
  additionalInfoContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  additionalInfoContent: {
    maxWidth: 420
  },
  radio: {
    padding: 5
  }
}

const ProjectFilterDialog = (props) => {
  const initialState = {
    startDateStart: null,
    startDateEnd: null,
    endDateStart: null,
    endDateEnd: null,
    searchString: '',
    // activity: activityOptions.active,
    regions: [],
    services: []
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [t] = useTranslation()

  /* When filters come filled as props, set them to state */
  useEffect(() => {
    if (!isEmpty(props.projectFilters)) {
      setState({ ...props.projectFilters })
    }
  }, [props.projectFilters])

  const resetState = () => {
    setState(initialState)
    props.resetProjectFilters()
  }

  const handleOkPress = () => {
    const filters = { ...state }
    props.setProjectFilters(filters)
    props.onClose()
  }

  const handleClose = () => props.onClose()

  const setRegions = (regions) => setState({ regions })

  const setServices = (services) => setState({ services })

  const setStartDateStart = (startDateStart) => setState({ startDateStart })

  const setStartDateEnd = (startDateEnd) => setState({ startDateEnd })

  const setEndDateStart = (endDateStart) => setState({ endDateStart })

  const setEndDateEnd = (endDateEnd) => setState({ endDateEnd })

  const setSearchStringValue = (e) => setState({ searchString: e.target.value })

  const clearStartDate = () => {
    setState({ startDateStart: null, startDateEnd: null })
  }

  const clearEndDate = () => {
    setState({ endDateStart: null, endDateEnd: null })
  }

  const renderForm = () => {
    const { startDateStart, startDateEnd, endDateStart, endDateEnd, searchString, services, regions } = state
    return (
      <DialogContentWrapper>
        <div style={styles.flexContainer}>
          <FormFieldWrapper>
            <FormTextField
              label={t('project_name_search')}
              fullWidth
              variant='standard'
              onChange={value => setSearchStringValue(value)}
              value={searchString}
              onEnterPress={value => handleOkPress()}
            />
          </FormFieldWrapper>
        </div>
        <FormFieldWrapper>
          <MultiAutocomplete
            label={t('area')}
            value={regions}
            groupBy={(option) => option.region}
            onChange={(e, regions) => setRegions(regions)}
            options={[...props.regionsOptions]}
            noOptionsMessage={() => t('no_areas_available')}
            placeholder={`${t('select')}...`}
          />
        </FormFieldWrapper>

        <FormFieldWrapper>
          <MultiAutocomplete
            label={t('service')}
            value={services}
            onChange={(e, services) => setServices(services)}
            options={[...props.servicesOptions]}
            noOptionsMessage={() => t('no_services_available')}
            placeholder={`${t('select')}...`}
          />
        </FormFieldWrapper>

        <FormFieldWrapper>
          <Typography variant='caption' style={styles.caption}>{t('start_date_range')}</Typography>
          <div style={styles.flexContainer}>
            <DatePicker value={startDateStart} onChange={date => setStartDateStart(date)} placeholder={`${t('select')}...`} maxDate={startDateEnd} />
            <span style={styles.divider}>-</span>
            <DatePicker value={startDateEnd} onChange={date => setStartDateEnd(date)} placeholder={`${t('select')}...`} minDate={startDateStart} />
            <IconButton style={styles.clearButton} onClick={clearStartDate} disabled={!startDateStart && !startDateEnd}>
              <CancelIcon />
            </IconButton>
          </div>
        </FormFieldWrapper>

        <FormFieldWrapper>
          <Typography variant='caption' style={styles.caption}>{t('end_date_range')}</Typography>
          <div style={styles.flexContainer}>
            <DatePicker value={endDateStart} onChange={date => setEndDateStart(date)} placeholder={`${t('select')}...`} maxDate={endDateEnd} />
            <span style={styles.divider}>-</span>
            <DatePicker value={endDateEnd} onChange={date => setEndDateEnd(date)} placeholder={`${t('select')}...`} minDate={endDateStart} />
            <IconButton style={styles.clearButton} onClick={clearEndDate} disabled={!endDateStart && !endDateEnd}>
              <CancelIcon />
            </IconButton>
          </div>
        </FormFieldWrapper>

      </DialogContentWrapper>
    )
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.isOpen}
      onClose={handleClose}
      PaperProps={{ style: styles.root }}
      fullWidth
    >
      <AppBar position='static'>
        <Toolbar disableGutters>
          <IconButton style={styles.menuButton} color='inherit' onClick={handleClose} aria-label={t('close')}>
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' color='inherit' style={styles.title}>
            {t('filters')}
          </Typography>
          <Button color='inherit' onClick={resetState}>
            {t('reset')}
          </Button>
          <Button color='inherit' onClick={handleOkPress} style={styles.okButton}>
            {t('ok')}
          </Button>
        </Toolbar>
      </AppBar>
      {renderForm()}
    </Dialog>
  )
}

export default ProjectFilterDialog
