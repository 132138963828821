import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { withStyles } from '@mui/styles'
import { withTranslation } from 'react-i18next'
import CustomSnackbarContentWrapper from './CustomSnackbarContentWrapper'

const notificationStyles = theme => ({
  container: {
    zIndex: 10000
  }
})

class SuccessNotification extends React.Component {
  render () {
    const { classes, onClose, success, t } = this.props
    const isOpen = Boolean(success)
    let successMessage = null
    // Get success message based on message key
    if (success && success.messageKey) {
      successMessage = t(success.messageKey)
    }
    // Display API success message that we got from API
    // TODO: Use translated messages if that support is added to API
    if (success && success.message) {
      successMessage = success.message
    }
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isOpen}
        autoHideDuration={2000}
        transitionDuration={400}
        onClose={onClose}
        className={classes.container}
      >
        <CustomSnackbarContentWrapper onClose={onClose} variant='success' message={successMessage} />
      </Snackbar>
    )
  }
}

export default withStyles(notificationStyles)(withTranslation()(SuccessNotification))
