import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isSaving: null
})

/* ------------- Types and Action Creators ------------- */

export const setSaving = createAction('DISPLAY_SAVING', (isSaving) => ({ isSaving }))

/* ------------- Reducers ------------- */
const reducers = createReducer(
  {
    [setSaving]: (state, { isSaving }) => state.merge({ isSaving })
  },
  INITIAL_STATE
)

export default reducers
