import { call, put, select } from 'redux-saga/effects'
import saveAs from 'file-saver'
import moment from 'moment-timezone'
import uniq from 'lodash/uniq'
import * as ApplicantsActions from '../redux/ApplicantsRedux'
import * as UsersActions from '../redux/UsersRedux'
import * as ErrorActions from '../redux/ErrorRedux'
import * as SuccessActions from '../redux/SuccessRedux'
import * as SavingActions from '../redux/SavingRedux'
import * as ServicesActions from '../redux/ServicesRedux'
import * as AreasActions from '../redux/AreasRedux'
import * as CoachesActions from '../redux/CoachesRedux'
import { castToSelectOptions, composeError, composeSuccess, composeApplicantFilters } from '../utils/transforms'
import { isOkResponse } from '../services/api'
import { initializeAuth, signIn } from '../authConfig'

export function * startup (api, action) {
  try {
    // AD Sign in
    initializeAuth()
    yield call(signIn)
    const response = yield call(api.checkAdUserRegistration)
    if (isOkResponse(response)) {
      yield put(UsersActions.getMe())
      yield put(ApplicantsActions.getApplicants())
      yield put(AreasActions.getRegionsWithCities())
      yield put(ApplicantsActions.getTalentStatuses())
      yield put(CoachesActions.getCoachingResults())
      yield put(CoachesActions.getCoaches())
      yield put(ServicesActions.getServicesOptions())
    }
  } catch (e) {
    console.error(e)
    yield put(ErrorActions.displayError(e))
  }
}

export function * getApplicants (api, action) {
  const applicantsState = (state) => state.applicants
  const { page, pageSize, applicantFilters, safeModeCoaching } = yield select(applicantsState)
  const apiPage = page - 1

  try {
    const filters = composeApplicantFilters(applicantFilters)
    const params = {
      ...filters,
      skip: apiPage * pageSize,
      take: pageSize
    }
    if (safeModeCoaching) params.coachingId = safeModeCoaching

    const response = yield call(api.getApplicants, params)

    if (isOkResponse(response)) {
      const applicants = response.data.list
      const applicantsTotal = response.data.totalCount
      const allIds = response.data.allIds
      const allCoachingIds = response.data.allCoachingIds
      yield put(ApplicantsActions.getApplicantsSuccess(applicants, applicantsTotal, allIds, allCoachingIds))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.getApplicantsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.getApplicantsFailure())
  }
}

export function * reloadVisibleApplicants (api, action) {
  const applicantsState = (state) => state.applicants
  const { page, applicantFilters, pageSize, safeModeCoaching } = yield select(applicantsState)
  const filters = composeApplicantFilters(applicantFilters)
  const params = {
    ...filters,
    skip: ((page - 1) * pageSize),
    take: pageSize
  }
  if (safeModeCoaching) params.coachingId = safeModeCoaching

  try {
    const response = yield call(api.getApplicants, params)
    if (isOkResponse(response)) {
      const applicants = response.data.list
      const applicantsTotal = response.data.totalCount
      const allIds = response.data.allIds
      const allCoachingIds = response.data.allCoachingIds
      yield put(ApplicantsActions.reloadVisibleApplicantsSuccess(applicants, applicantsTotal, allIds, allCoachingIds))
    } else {
      yield put(ApplicantsActions.reloadVisibleApplicantsFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(ApplicantsActions.reloadVisibleApplicantsFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * changeApplicantsPage (action) {
  const { page } = action.payload
  const applicantsState = (state) => state.applicants
  const { applicants, pageSize } = yield select(applicantsState)

  if ((page * pageSize) >= applicants.length) {
    yield put(ApplicantsActions.getApplicants())
  }
}
export function * changeApplicantRowsPerPage (action) {
  yield put(ApplicantsActions.reloadVisibleApplicants())
}

export function * setApplicantTrainer (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, applicantId, trainerId, begin } = action.payload
  const beginDateTime = begin != null ? moment.tz(begin, 'Europe/Helsinki').utc(true).format() : null
  const body = {
    talent: applicantId,
    coach: trainerId,
    startDateTime: beginDateTime
  }
  try {
    const response = yield call(api.setApplicantTrainer, projectId, body)
    if (isOkResponse(response)) {
      yield put(ApplicantsActions.setApplicantTrainerSuccess(applicantId, trainerId, projectId))
      const success = composeSuccess('applicant_trainer_changed')
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.setApplicantTrainerFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.setApplicantTrainerFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * setApplicantStartDate (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, applicantId, trainerId, date } = action.payload

  const body = {
    talent: applicantId,
    coach: trainerId,
    startDateTime: moment.tz(date, 'Europe/Helsinki').utc(true).format()
  }
  try {
    const response = yield call(api.setApplicantStartDate, projectId, body)
    if (isOkResponse(response)) {
      const success = composeSuccess('applicant_status_changed')
      yield put(SuccessActions.displaySuccess(success))
      yield put(ApplicantsActions.setApplicantStartDateSuccess(applicantId, date, projectId))
      yield put(SavingActions.setSaving(false))
      // reload applicants
      yield put(ApplicantsActions.reloadVisibleApplicants())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.setApplicantStartDateFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.setApplicantStartDateFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * sendMail (api, action) {
  yield put(SavingActions.setSaving(true))
  const { subject, message } = action.payload
  const applicantsState = (state) => state.applicants
  const state = yield select(applicantsState)
  let receivers
  if (state.select.length) {
    receivers = state.select.map(applicant => applicant.userId)
  } else {
    let allApplicants = state.allIds
    if (state.exclude.length) {
      allApplicants = allApplicants.filter((applicant) => !(state.exclude.findIndex(excludedApplicant => excludedApplicant.userId === applicant) !== -1))
    }
    receivers = allApplicants
  }

  const body = {
    receivers: uniq(receivers),
    subject,
    formattedMessage: message,
    email: true
  }

  try {
    const response = yield call(api.sendMessage, body)

    if (isOkResponse(response)) {
      const success = composeSuccess('email_sent')
      yield put(ApplicantsActions.sendMailSuccess())
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.sendMailFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.sendMailFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * sendSms (api, action) {
  yield put(SavingActions.setSaving(true))
  const { message } = action.payload
  const applicantsState = (state) => state.applicants
  const state = yield select(applicantsState)
  let receivers
  if (state.select.length) {
    receivers = state.select.map(applicant => applicant.userId)
  } else {
    let allApplicants = state.allIds
    if (state.exclude.length) {
      allApplicants = allApplicants.filter((applicant) => !(state.exclude.findIndex(excludedApplicant => excludedApplicant.userId === applicant) !== -1))
    }
    receivers = allApplicants
  }

  const body = {
    receivers: uniq(receivers),
    plainTextMessage: message,
    sms: true
  }

  try {
    const response = yield call(api.sendMessage, body)
    if (isOkResponse(response)) {
      const success = composeSuccess('sms_sent')
      yield put(ApplicantsActions.sendSmsSuccess())
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.sendSmsFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.sendSmsFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * setApplicantFilters (api, action) {
  yield put(ApplicantsActions.resetApplicantSelect())
  // reload applicants
  yield put(ApplicantsActions.getApplicants())
}

export function * endService (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, applicantId, resultId, end } = action.payload
  const body = {
    resultId,
    talent: applicantId,
    endDateTime: moment.tz(end, 'Europe/Helsinki').utc(true).format()
  }

  try {
    const response = yield call(api.endService, projectId, body)

    if (isOkResponse(response)) {
      const success = composeSuccess('applicant_status_changed')
      yield put(SuccessActions.displaySuccess(success))
      yield put(ApplicantsActions.endServiceSuccess())
      yield put(ApplicantsActions.resetApplicantSelect())
      yield put(SavingActions.setSaving(false))
      // reload applicants
      yield put(ApplicantsActions.reloadVisibleApplicants())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.endServiceFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.endServiceFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * getTalentStatuses (api, action) {
  try {
    const response = yield call(api.getTalentStatuses)
    if (isOkResponse(response)) {
      const talentStatuses = response.data
      yield put(ApplicantsActions.getTalentStatusesSuccess(talentStatuses))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.getTalentStatusesFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.getTalentStatusesFailure())
  }
}

export function * exportCertificateTemplateExcel (api, action) {
  const applicantsState = (state) => state.applicants
  const state = yield select(applicantsState)
  let params

  if (state.select.length) {
    params = { TalentIds: state.select.map(applicant => applicant.userId) }
  } else {
    let allApplicants = state.allIds
    if (state.exclude.length) {
      allApplicants = allApplicants.filter((applicant) => !(state.exclude.findIndex(excludedApplicant => excludedApplicant.userId === applicant) !== -1))
    }
    params = { TalentIds: allApplicants }
  }

  try {
    const filterResponse = yield call(api.getExcelFilterId, params)
    if (filterResponse.status === 200) {
      const filterId = filterResponse.data.FilterId
      const response = yield call(api.exportCertificateTemplateExcel, { filterId })
      if (isOkResponse(response)) {
        saveAs(new Blob([response.data]), 'todistuspohja.xls')
        yield put(ApplicantsActions.exportCertificateTemplateExcelSuccess())
      } else {
        yield put(ErrorActions.displayError(response))
        yield put(ApplicantsActions.exportCertificateTemplateExcelFailure())
      }
    } else {
      yield put(ErrorActions.displayError(filterResponse))
      yield put(ApplicantsActions.exportCertificateTemplateExcelFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.filterResponse))
    yield put(ApplicantsActions.exportCertificateTemplateExcelFailure())
  }
}

export function * exportApplicantsToExcel (api, action) {
  const applicantsState = (state) => state.applicants
  const state = yield select(applicantsState)
  let params

  if (state.select.length) {
    params = { TalentIds: state.select.map(applicant => applicant.userId) }
  } else {
    let allApplicants = state.allIds
    if (state.exclude.length) {
      allApplicants = allApplicants.filter((applicant) => !(state.exclude.findIndex(excludedApplicant => excludedApplicant.userId === applicant) !== -1))
    }
    params = { TalentIds: allApplicants }
  }

  try {
    const filterResponse = yield call(api.getExcelFilterId, params)
    if (filterResponse.status === 200) {
      const filterId = filterResponse.data.FilterId
      const response = yield call(api.exportApplicantsToExcel, { filterId })
      if (isOkResponse(response)) {
        saveAs(new Blob([response.data]), 'hakijat.xls')
        yield put(ApplicantsActions.exportApplicantsToExcelSuccess())
      } else {
        yield put(ErrorActions.displayError(response))
        yield put(ApplicantsActions.exportApplicantsToExcelFailure())
      }
    } else {
      yield put(ErrorActions.displayError(filterResponse))
      yield put(ApplicantsActions.exportApplicantsToExcelFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e))
    yield put(ApplicantsActions.exportApplicantsToExcelFailure())
  }
}

export function * rollbackApplicant (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, applicantId } = action.payload
  try {
    const response = yield call(api.rollbackApplicant, projectId, applicantId)
    if (isOkResponse(response)) {
      const success = composeSuccess('applicant_status_changed')
      yield put(SuccessActions.displaySuccess(success))
      yield put(ApplicantsActions.rollbackApplicantSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(ApplicantsActions.reloadVisibleApplicants())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.rollbackApplicantFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.rollbackApplicantFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * rollbackApplicantToStatus (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, rollbackModel } = action.payload
  try {
    const response = yield call(api.rollbackApplicantToStatus, projectId, rollbackModel)

    if (isOkResponse(response)) {
      const success = composeSuccess('applicant_status_changed')
      yield put(SuccessActions.displaySuccess(success))
      yield put(ApplicantsActions.rollbackApplicantToStatusSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(ApplicantsActions.reloadVisibleApplicants())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.rollbackApplicantToStatusFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.rollbackApplicantToStatusFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * changeProject (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, applicantId, newProjectId } = action.payload
  const body = { newProjectId, talent: applicantId }
  try {
    const response = yield call(api.changeProject, projectId, body)
    if (isOkResponse(response)) {
      const success = composeSuccess('applicant_project_changed')
      yield put(SuccessActions.displaySuccess(success))
      yield put(ApplicantsActions.changeProjectSuccess())
      yield put(ApplicantsActions.resetApplicantSelect())
      yield put(SavingActions.setSaving(false))
      yield put(ApplicantsActions.reloadVisibleApplicants())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.changeProjectFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.changeProjectFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * setApplicantInfo (api, action) {
  yield put(SavingActions.setSaving(true))
  const { applicant } = action.payload
  const { talentId, projectId } = applicant

  try {
    const response = yield call(api.setApplicantInfo, projectId, talentId, applicant)
    if (isOkResponse(response)) {
      const success = composeSuccess('applicant_info_changed')
      yield put(SuccessActions.displaySuccess(success))
      yield put(ApplicantsActions.reloadVisibleApplicants())
      yield put(SavingActions.setSaving(false))
      return yield put(ApplicantsActions.setApplicantInfoSuccess())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(SavingActions.setSaving(false))
      return yield put(ApplicantsActions.setApplicantInfoFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(SavingActions.setSaving(false))
    return yield put(ApplicantsActions.setApplicantInfoFailure())
  }
}

export function * setApplicantTargetOccupations (api, action) {
  yield put(SavingActions.setSaving(true))
  const { applicantId, occupations } = action.payload

  try {
    const response = yield call(api.setTargetOccupations, applicantId, occupations)
    if (isOkResponse(response)) {
      const success = composeSuccess('applicant_occupations_changed')
      yield put(SuccessActions.displaySuccess(success))
      yield put(ApplicantsActions.setApplicantTargetOccupationsSuccess())
      yield put(SavingActions.setSaving(false))
      // reload applicants
      yield put(ApplicantsActions.reloadVisibleApplicants())
      yield put(ApplicantsActions.getApplicantTargetOccupations(applicantId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.setApplicantTargetOccupationsFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.setApplicantTargetOccupationsFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * getApplicantInfo (api, action) {
  const { applicantId, params } = action.payload
  try {
    const response = yield call(api.getApplicantInfo, applicantId, params)
    if (isOkResponse(response)) {
      const applicantInfo = response.data
      yield put(ApplicantsActions.getApplicantInfoSuccess(applicantInfo))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.getApplicantInfoFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.getApplicantInfoFailure())
  }
}

export function * getApplicantTargetOccupations (api, action) {
  const { applicantId, params } = action.payload
  try {
    const response = yield call(api.getApplicantProfessions, applicantId, params)
    if (isOkResponse(response)) {
      const occupations = response.data
      yield put(ApplicantsActions.getApplicantTargetOccupationsSuccess(occupations))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.getApplicantTargetOccupationsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.getApplicantTargetOccupationsFailure())
  }
}

export function * importSpringerApplicantsFile (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, file } = action.payload
  try {
    const response = yield call(api.importSpringerApplicantsFile, projectId, file)
    if (isOkResponse(response)) {
      yield put(ApplicantsActions.importSpringerApplicantsFileSuccess())
      const success = composeSuccess('applicant_import_successful')
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
      // reload applicants
      yield put(ApplicantsActions.reloadVisibleApplicants())
    } else {
      yield put(ErrorActions.displayImportErrors(response))
      yield put(ApplicantsActions.importSpringerApplicantsFileFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayImportErrors(e.response))
    yield put(ApplicantsActions.importSpringerApplicantsFileFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * toggleSafeMode (api, action) {
  yield put(ApplicantsActions.resetApplicantSelect())
  // reload applicants
  yield put(ApplicantsActions.getApplicants())
}

export function * importKoulutusporttiApplicantsFile (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, file } = action.payload
  try {
    const response = yield call(api.importKoulutusporttiApplicantsFile, projectId, file)
    if (isOkResponse(response)) {
      yield put(ApplicantsActions.importKoulutusporttiApplicantsFileSuccess())
      const success = composeSuccess('applicant_import_successful')
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
      // reload applicants
      yield put(ApplicantsActions.reloadVisibleApplicants())
    } else {
      yield put(ErrorActions.displayImportErrors(response))
      yield put(ApplicantsActions.importKoulutusporttiApplicantsFileFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayImportErrors(e.response))
    yield put(ApplicantsActions.importKoulutusporttiApplicantsFileFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * getApplicantLanguageSkills (api, action) {
  try {
    const { coachingId } = action.payload
    const response = yield call(api.getApplicantLanguageSkills, coachingId)
    if (isOkResponse(response)) {
      const applicantLanguageSkills = response.data
      yield put(ApplicantsActions.getApplicantLanguageSkillsSuccess(applicantLanguageSkills))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.getApplicantLanguageSkillsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.getApplicantLanguageSkillsFailure())
  }
}

export function * saveApplicantLanguageSkills (api, action) {
  yield put(SavingActions.setSaving(true))
  const { applicantLanguageSkills } = action.payload
  try {
    let response = null
    // Creating a new language skill and editing old ones use different APIs
    // If coaching language skill id is set, then it is editing old one
    if (applicantLanguageSkills.coachingLanguageSkills[0].coachingLanguageSkillId) response = yield call(api.editCoachingLanguageSkill, applicantLanguageSkills.projectId, applicantLanguageSkills.coachingLanguageSkills[0].coachingLanguageSkillId, applicantLanguageSkills)
    else if (!applicantLanguageSkills.coachingLanguageSkillId) response = yield call(api.addCoachingLanguageSkill, applicantLanguageSkills.projectId, applicantLanguageSkills)

    if (isOkResponse(response)) {
      yield put(ApplicantsActions.saveApplicantLanguageSkillsSuccess())
      const success = composeSuccess('language_skill_saved')
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
      // reload coaching language skills
      yield put(ApplicantsActions.getApplicantLanguageSkills(applicantLanguageSkills.coachingId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.saveApplicantLanguageSkillsFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.saveApplicantLanguageSkillsFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * deleteApplicantLanguageSkill (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, applicantLanguageId, coachingId } = action.payload
  try {
    const response = yield call(api.deleteCoachingLanguageSkill, projectId, applicantLanguageId)

    if (isOkResponse(response)) {
      yield put(ApplicantsActions.deleteApplicantLanguageSkillSuccess())
      const success = composeSuccess('language_skill_deleted')
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
      // reload coaching language skills
      yield put(ApplicantsActions.getApplicantLanguageSkills(coachingId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.deleteApplicantLanguageSkillFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.deleteApplicantLanguageSkillFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * getCooperationTypes (api, action) {
  try {
    const response = yield call(api.getCooperationTypes)
    if (isOkResponse(response)) {
      const cooperationTypes = response.data
      const cooperationTypeOptions = castToSelectOptions(cooperationTypes, true)
      yield put(ApplicantsActions.getCooperationTypesSuccess(cooperationTypes, cooperationTypeOptions))
    } else {
      yield put(ApplicantsActions.getCooperationTypesFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(ApplicantsActions.getCooperationTypesFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * createInternship (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, internship } = action.payload
  try {
    const response = yield call(api.addInternship, projectId, internship)

    if (isOkResponse(response)) {
      yield put(ApplicantsActions.createInternshipSuccess())
      const success = composeSuccess('internship_saved')
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
      // reload coaching internships
      yield put(ApplicantsActions.getApplicantInternships(internship.coachingId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.createInternshipFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.createInternshipFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * getApplicantInternships (api, action) {
  try {
    const { coachingId } = action.payload
    const response = yield call(api.getApplicantInternships, coachingId)
    if (isOkResponse(response)) {
      const applicantLanguageSkills = response.data
      yield put(ApplicantsActions.getApplicantInternshipsSuccess(applicantLanguageSkills))
      yield put(SavingActions.setSaving(false))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.getApplicantInternshipsFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.getApplicantInternshipsFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * editInternship (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, internshipId, internship } = action.payload
  try {
    const response = yield call(api.editInternship, projectId, internshipId, internship)

    if (isOkResponse(response)) {
      yield put(ApplicantsActions.editInternshipSuccess())
      const success = composeSuccess('internship_saved')
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
      // reload coaching internships
      yield put(ApplicantsActions.getApplicantInternships(internship.coachingId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.editInternshipFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.editInternshipFailure())
    yield put(SavingActions.setSaving(false))
  }
}
export function * deleteInternship (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, internshipId, coachingId } = action.payload
  try {
    const response = yield call(api.deleteInternship, projectId, internshipId)

    if (isOkResponse(response)) {
      yield put(ApplicantsActions.deleteInternshipSuccess())
      const success = composeSuccess('internship_deleted')
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
      // reload coaching internships
      yield put(ApplicantsActions.getApplicantInternships(coachingId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.deleteInternshipFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.deleteInternshipFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * startInitialAssessment (api, action) {
  yield put(SavingActions.setSaving(true))
  const { userIds, allApplicantsSelected, coachId } = action.payload
  const applicantsState = (state) => state.applicants
  const state = yield select(applicantsState)
  try {
    let coachingIds = []
    if (allApplicantsSelected) coachingIds = state.allCoachingIds
    else coachingIds = state.applicants.filter(applicant => userIds.includes(applicant.userId)).map(applicant => applicant.coachingId)
    const response = yield call(api.startInitialAssessment, { coachingIds, coachId })

    if (isOkResponse(response)) {
      yield put(ApplicantsActions.startInitialAssessmentSuccess())
      const success = composeSuccess('initial_assessment_started')
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
      // reload applicants
      yield put(ApplicantsActions.reloadVisibleApplicants())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ApplicantsActions.startInitialAssessmentFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ApplicantsActions.startInitialAssessmentFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * endInitialAssessment (api, action) {
  yield put(SavingActions.setSaving(true))
  const { userIds, allApplicantsSelected } = action.payload
  const applicantsState = (state) => state.applicants
  const state = yield select(applicantsState)
  try {
    let coachingIds = []
    if (allApplicantsSelected) coachingIds = state.allCoachingIds
    else coachingIds = state.applicants.filter(applicant => userIds.includes(applicant.userId)).map(applicant => applicant.coachingId)
    const response = yield call(api.endInitialAssessment, { coachingIds })

    if (isOkResponse(response)) {
      yield put(ApplicantsActions.endInitialAssessmentSuccess())
      const success = composeSuccess('initial_assessment_ended')
      yield put(SuccessActions.displaySuccess(success))
      yield put(SavingActions.setSaving(false))
      // reload applicants
      yield put(ApplicantsActions.reloadVisibleApplicants())
    } else {
      yield put(ErrorActions.displayError(response))
      const error = composeError(response)
      yield put(ApplicantsActions.endInitialAssessmentFailure(error))
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    const error = composeError(e.response)
    yield put(ApplicantsActions.endInitialAssessmentFailure(error))
    yield put(SavingActions.setSaving(false))
  }
}
