import React from 'react'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import DeleteIcon from '@mui/icons-material/Delete'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { COLORS } from '../../utils/colors'

const useStyles = makeStyles(theme => ({
  h6: {
    marginTop: 20,
    marginBottom: 20
  },
  label: {
    color: COLORS.label,
    fontSize: 14,
    marginTop: 16
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    width: 200
  }
}))

export default function CoachingResultsList (props) {
  const classes = useStyles()
  const [t] = useTranslation()
  const { resultOptions } = props
  if (resultOptions && resultOptions.length > 0) {
    return (
      <Box className={classes.coachesContainer}>
        <Typography className={classes.labelColor}>{t('coaching_results')}:</Typography>
        <List dense>
          {resultOptions.map((option, idx) => {
            return (
              <ListItem key={`${option.fi}-${idx}`}>
                <ListItemText
                  primary={`${option.fi} | ${option.en}`}
                />
                {props.onClick &&
                  <ListItemSecondaryAction>
                    <IconButton edge='end' aria-label='delete' onClick={(e) => props.onClick(option)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>}
              </ListItem>
            )
          })}
        </List>
      </Box>
    )
  } else {
    return (
      <Box className={classes.coachesContainer}>
        <Typography className={classes.labelColor}>{t('coaching_results')}:</Typography>
        <Typography className={classNames(classes.regularText, classes.labelColor)}>{t('not_set')}</Typography>
      </Box>
    )
  }
}
