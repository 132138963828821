import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  projects: [],
  projectsTotal: 0,
  allIds: [],
  projectFilters: {},
  visibleProjects: [], // Subset of projects, displayed in current page of projects table.
  previousVisibleProjects: [], // Previous visible projects are stored so that view changes can be reverted if API call fails.
  fetching: false,
  error: null,
  pageSize: 10,
  page: 1,
  project: null,
  projectsOptions: [],
  saveSuccessful: false,
  projectsInCity: [],
  filteredProjectsOptions: []
})
/* ------------- Types and Action Creators ------------- */

export const getProjects = createAction('GET_PROJECTS')
export const getProjectsSuccess = createAction('GET_PROJECTS_SUCCESS', (projects, projectsTotal, allIds) => ({ projects, projectsTotal, allIds }))
export const getProjectsFailure = createAction('GET_PROJECTS_FAILURE', (error) => ({ error }))
export const changeProjectsPage = createAction('CHANGE_PROJECTS_PAGE', (page) => ({ page }))
export const changeProjectRowsPerPage = createAction('CHANGE_PROJECT_ROWS_PER_PAGE', (pageSize) => ({ pageSize }))
export const setProjectFilters = createAction('SET_PROJECT_FILTERS', (projectFilters) => ({ projectFilters }))
export const reloadVisibleProjects = createAction('RELOAD_VISIBLE_PROJECTS')
export const reloadVisibleProjectsSuccess = createAction('RELOAD_VISIBLE_PROJECTS_SUCCESS', (projects, projectsTotal, allIds) => ({ projects, projectsTotal, allIds }))
export const reloadVisibleProjectsFailure = createAction('RELOAD_VISIBLE_PROJECTS_FAILURE', (error) => ({ error }))
export const getProjectsOptions = createAction('GET_PROJECTS_OPTIONS')
export const getProjectsOptionsSuccess = createAction('GET_PROJECTS_OPTIONS_SUCCESS', (projectsOptions) => ({ projectsOptions }))
export const getProjectsOptionsFailure = createAction('GET_PROJECTS_OPTIONS_FAILURE', (error) => ({ error }))
export const createProject = createAction('CREATE_PROJECT', (project) => ({ project }))
export const createProjectSuccess = createAction('CREATE_PROJECT_SUCCESS')
export const createProjectFailure = createAction('CREATE_PROJECT_FAILURE', (error) => ({ error }))
export const acknowledgeProjectSaveSuccess = createAction('ACKNOWLEDGE_PROJECT_SAVE_SUCCESS')
export const reloadAllFetchedProjects = createAction('RELOAD_ALL_FETCHED_PROJECTS')
export const reloadAllFetchedProjectsSuccess = createAction('RELOAD_ALL_FETCHED_PROJECTS_SUCCESS', (projects, projectsTotal, allIds) => ({ projects, projectsTotal, allIds }))
export const reloadAllFetchedProjectsFailure = createAction('RELOAD_ALL_FETCHED_PROJECTS_FAILURE', (error) => ({ error }))
export const editProject = createAction('EDIT_PROJECT', (project) => ({ project }))
export const editProjectSuccess = createAction('EDIT_PROJECT_SUCCESS')
export const editProjectFailure = createAction('EDIT_PROJECT_FAILURE', (error) => ({ error }))
export const getProject = createAction('GET_PROJECT', (projectId) => ({ projectId }))
export const getProjectSuccess = createAction('GET_PROJECT_SUCCESS', (project) => ({ project }))
export const getProjectFailure = createAction('GET_PROJECT_FAILURE', (error) => ({ error }))
export const deselectProject = createAction('DESELECT_PROJECT')
export const resetProjectFilters = createAction('RESET_PROJECT_FILTERS')
export const getProjectsInCity = createAction('GET_PROJECTS_IN_CITY', (city) => ({ city }))
export const getProjectsInCitySuccess = createAction('GET_PROJECTS_IN_CITY_SUCCESS', (projectsInCity) => ({ projectsInCity }))
export const getProjectsInCityFailure = createAction('GET_PROJECTS_IN_CITY_FAILURE', (error) => ({ error }))
export const getFilteredProjectsOptions = createAction('GET_FILTERED_PROJECTS_OPTIONS', (projectFilters) => ({ projectFilters }))
export const getFilteredProjectsOptionsSuccess = createAction('GET_FILTERED_PROJECTS_OPTIONS_SUCCESS', (filteredProjectsOptions) => ({ filteredProjectsOptions }))
export const getFilteredProjectsOptionsFailure = createAction('GET_FILTERED_PROJECTS_OPTIONS_FAILURE', (error) => ({ error }))
export const clearFilteredProjectsOptions = createAction('CLEAR_FILTERED_PROJECTS_OPTIONS')

/* ------------- Reducers ------------- */
const reducers = createReducer(
  {
    [setProjectFilters]: (state, { projectFilters }) => {
      return state.merge({
        projectFilters,

        // Reset projects list state
        projects: [],
        visibleProjects: [],
        previousVisibleProjects: [],
        projectsTotal: 0,
        pageSize: state.pageSize,
        page: 1
      })
    },
    [getProjects]: (state) => state.merge({ fetching: true }),
    [getProjectsSuccess]: (state, { projects, projectsTotal, allIds }) => {
      /* First fetch */
      if (state.page === 1) {
        /* First results to the screen */
        const firstResults = projects.slice(0, state.pageSize)
        return state.merge({
          fetching: false,
          projectsTotal,
          allIds,
          projects,
          visibleProjects: [...firstResults]
        })
      }
      /* Add next results to the projects array */
      return state.merge({
        fetching: false,
        projectsTotal,
        allIds,
        projects: [...state.projects, ...projects],
        visibleProjects: [...projects]
      })
    },
    [getProjectsFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [changeProjectsPage]: (state, { page }) => {
      const nextProjects = state.projects.slice((state.pageSize * (page - 1)), (state.pageSize * (page)))
      return state.merge({ page, visibleProjects: [...nextProjects] })
    },
    [changeProjectRowsPerPage]: (state, { pageSize }) => {
      return state.merge({ pageSize, page: 1, projects: [] })
    },
    [reloadVisibleProjects]: (state) => state.merge({ fetching: true }),
    [reloadVisibleProjectsSuccess]: (state, { projects, projectsTotal, allIds }) => {
      const newProjects = [
        ...state.projects.slice(0, ((state.page - 1) * state.pageSize)),
        ...projects,
        ...state.projects.slice((state.page * state.pageSize))
      ]
      const visibleProjects = projects

      return state.merge({ fetching: false, projects: newProjects, visibleProjects, projectsTotal, allIds })
    },
    [reloadVisibleProjectsFailure]: (state, { error }) => state.merge({ error, fetching: false }),
    [getProjectsOptions]: (state) => state.merge({ projectsFetching: true }),
    [getProjectsOptionsSuccess]: (state, { projectsOptions }) => state.merge({ projectsOptions, projectsFetching: false }),
    [getProjectsOptionsFailure]: (state, { error }) => state.merge({ error, projectsFetching: false }),
    [createProject]: (state) => state.merge({ fetching: true }),
    [createProjectSuccess]: (state) => state.merge({ fetching: false, saveSuccessful: true }),
    [createProjectFailure]: (state, { error }) => state.merge({ error, fetching: false, saveSuccessful: false }),
    [editProject]: (state) => state.merge({ fetching: true }),
    [editProjectSuccess]: (state) => state.merge({ fetching: false, saveSuccessful: true }),
    [editProjectFailure]: (state, { error }) => state.merge({ error, fetching: false, saveSuccessful: false }),
    [acknowledgeProjectSaveSuccess]: (state) => state.merge({ saveSuccessful: false }),
    [reloadAllFetchedProjects]: (state) => state.merge({ fetching: true }),
    [reloadAllFetchedProjectsSuccess]: (state, { projects, projectsTotal, allIds }) => {
      const visibleProjects = [...projects.slice(((state.page - 1) * state.pageSize), projects.length)]
      return state.merge({ fetching: false, projects, visibleProjects, projectsTotal, allIds })
    },
    [reloadAllFetchedProjectsFailure]: (state, { error }) => state.merge({ error, fetching: false }),
    [getProject]: (state) => state.merge({ fetching: true }),
    [getProjectSuccess]: (state, { project }) => state.merge({ fetching: false, project }),
    [getProjectFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [deselectProject]: (state) => state.merge({ project: null }),
    [resetProjectFilters]: (state) => state.merge({ projectFilters: {} }),
    [getProjectsInCity]: (state) => state.merge({ fetching: true }),
    [getProjectsInCitySuccess]: (state, { projectsInCity }) => state.merge({ fetching: false, projectsInCity }),
    [getProjectsInCityFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [getFilteredProjectsOptions]: (state) => state.merge({ projectsFetching: true }),
    [getFilteredProjectsOptionsSuccess]: (state, { filteredProjectsOptions }) => state.merge({ filteredProjectsOptions, projectsFetching: false }),
    [getFilteredProjectsOptionsFailure]: (state, { error }) => state.merge({ error, projectsFetching: false }),
    [clearFilteredProjectsOptions]: (state) => state.merge({ filteredProjectsOptions: INITIAL_STATE.filteredProjectsOptions })
  },
  INITIAL_STATE
)

export default reducers
