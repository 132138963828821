import React from 'react'
import { Navigate } from 'react-router-dom'
import ApplicantsScreen from './containers/ApplicantsScreen'
import MessagesScreen from './containers/MessagesScreen'
import ServicesScreen from './containers/ServicesScreen'
import ProjectsScreen from './containers/ProjectsScreen'

export const pathRoot = '*'
export const pathApplicants = '/applicants'
export const pathMessages = '/messages/'
export const pathServices = '/services/'
export const pathProjects = '/projects/'

const routes = [
  { path: pathApplicants, component: <ApplicantsScreen />, exact: true },
  { path: pathMessages, component: <MessagesScreen />, exact: true },
  { path: pathProjects, component: <ProjectsScreen />, exact: true },
  { path: pathServices, component: <ServicesScreen />, exact: true, forAdmins: true },
  { path: pathRoot, component: <Navigate to={pathApplicants} />, exact: true }
]

export default routes
