import { PublicClientApplication, InteractionStatus, LogLevel } from '@azure/msal-browser'
import { environment, isQryRequest } from './services/api'

let authContext = null
let authConfig = {}
let SCOPE_ID = {}

export const initializeAuth = () => {
  if (environment === 'dev') {
    authConfig =
    {
      auth: {
        clientId: '27c2ffee-d3d1-40ee-8cdc-869bd9c12296',
        authority: 'https://login.microsoftonline.com/5303131b-40b3-4f89-9b09-3a4ad7bc2275'
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: true // isIE || isEdge, // Set this to 'true' if you are having issues on IE11 or Edge
      },
      system: {
        // Debug logging
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message)
                return
              case LogLevel.Info:
                console.info(message)
                return
              case LogLevel.Verbose:
                console.debug(message)
                return
              case LogLevel.Warning:
                console.warn(message)
            }
          },
          piiLoggingEnabled: false
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
      }
    }
  } else if (environment === 'stag') {
    authConfig =
    {
      auth: {
        clientId: '2356fedd-de90-4e3b-a2f4-7454772171c9',
        authority: 'https://login.microsoftonline.com/5303131b-40b3-4f89-9b09-3a4ad7bc2275'
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: true // isIE || isEdge, // Set this to 'true' if you are having issues on IE11 or Edge
      },
      system: {
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
      }
    }
  } else if (environment === 'prod') {
    authConfig =
    {
      auth: {
        clientId: '591f8083-b5bc-42bb-80cb-2193c7281b89 ',
        authority: 'https://login.microsoftonline.com/5303131b-40b3-4f89-9b09-3a4ad7bc2275',
        postLogoutRedirectUri: 'https://www.staffpoint.fi'
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: true // isIE || isEdge, // Set this to 'true' if you are having issues on IE11 or Edge
      },
      system: {
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
      }
    }
  }

  authContext = new PublicClientApplication(authConfig)

  if (environment === 'dev') {
    SCOPE_ID = {
      CMD: '3eb77bc2-04b1-4745-b629-de062b26bdea/access_as_user',
      QRY: 'd351a48b-b60c-4876-9c1c-4b2569c1b272/access_as_user'
    }
  } else if (environment === 'stag') {
    SCOPE_ID = {
      CMD: '6994229d-4988-4b92-8e8c-24e10fa9cbd1/access_as_user',
      QRY: 'b9e7d845-06cb-453f-aceb-8f37d359f06a/access_as_user'
    }
  } else if (environment === 'prod') {
    SCOPE_ID = {
      CMD: '32769483-79a2-46ef-8727-378ae24a083d/access_as_user',
      QRY: 'edbbc62a-2af3-40cf-b3d9-e9844ac013d5/access_as_user'
    }
  }
}

export async function signIn (method) {
  const isAuthenticated = authContext.getAllAccounts().length > 0

  // If a user is not logged in and authentication is not already in progress, invoke login
  if (!isAuthenticated && (authContext.inProgress === InteractionStatus.None || authContext.inProgress == null)) {
    // Redirect to AD login
    await authContext.handleRedirectPromise()
  }
  const account = authContext.getAllAccounts()[0]
  if (account) {
    // This if is entered after successful redirect
    const qryRequest = { scopes: [SCOPE_ID.QRY] }
    const cmdRequest = { scopes: [SCOPE_ID.CMD] }
    authContext.setActiveAccount(account)
    try {
      // Get tokens
      await authContext.acquireTokenSilent(qryRequest)
      await authContext.acquireTokenSilent(cmdRequest)
    } catch (e) {
      console.error(e)
      signOut()
    }
  }
  return null
}

export function signOut () {
  authContext.logoutRedirect({ account: authContext.getActiveAccount(), postLogoutRedirectUri: 'https://www.staffpoint.fi' })
}

export const getTokenSilently = async (method, url) => {
  if (process.env.NODE_ENV === 'test') return 'testToken'
  const account = authContext.getAllAccounts()[0]
  authContext.setActiveAccount(account)
  const requestObject = {
    account,
    scopes: isQryRequest(method, url) ? [SCOPE_ID.QRY] : [SCOPE_ID.CMD]
  }
  try {
    const tokenResponse = await authContext.acquireTokenSilent(requestObject)
    return tokenResponse.accessToken
  } catch (error) {
    console.error(error)
    // Call interaction API. This is needed if Refresh token not applicable
    // Refresh tokens given to Single-Page Applications are limited-time refresh tokens (usually 24 hours from the time of retrieval). This is a non-adjustable lifetime.
    const tokenRedirectResponse = await authContext.acquireTokenRedirect(requestObject)
    return tokenRedirectResponse.accessToken
  }
}
