import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'

function withMediaQuery (Component) {
  return function WrappedComponent (props) {
    const theme = useTheme()
    const themeSMUp = useMediaQuery(theme.breakpoints.up('sm'))
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    return (<Component {...props} themeSMUp={themeSMUp} fullScreen={fullScreen} />)
  }
}
export default withMediaQuery
