import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  success: null
})

/* ------------- Types and Action Creators ------------- */

export const displaySuccess = createAction('DISPLAY_SUCCESS', (success) => ({ success }))
export const dismissSuccess = createAction('DISMISS_SUCCESS')

/* ------------- Reducers ------------- */

const reducers = createReducer(
  {
    [displaySuccess]: (state, { success }) => state.merge({ success }),
    [dismissSuccess]: (state) => state.merge({ success: null })
  },
  INITIAL_STATE
)

export default reducers
