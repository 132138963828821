import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import { CircularProgress } from '@mui/material'

const styles = theme => ({
  spinner: {
    display: 'flex',
    padding: '16px 16px'
  },
  centered: {
    width: '100%',
    justifyContent: 'center'
  }
})
const Spinner = (props) => {
  if (props.centered) {
    return (
      <div className={classNames(props.classes.spinner, props.classes.centered)}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={props.classes.spinner}>
      <CircularProgress />
    </div>
  )
}

export default (withStyles(styles)(Spinner))
