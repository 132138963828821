import React, { useReducer, Fragment } from 'react'
import List from '@mui/material/List'
import Checkbox from '@mui/material/Checkbox'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Badge from '@mui/material/Badge'
import Spinner from './Spinner'

const styles = {
  listCheckboxRoot: {
    minWidth: 36
  },
  badge: {
    flex: '1 1 auto'
  },
  listItemText: {
    fontSize: '0.9rem'
  }
}

export const EscoOccupationList = (props) => {
  const initialState = {
    openOccupations: [], // Array of uris,
    selectedOccupations: [], // Array of occupations
    children: {} // ParentCode: parent occupation code, value: it's children
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)

  const { occupations, topLevel, fetchOccupationChildren, selectable, onOccupationCheck, level, selectableLevel } = props
  const isOccupationOpen = (uri) => {
    if (state.openOccupations.length > 0) {
      return state.openOccupations.find(occu => occu === uri) != null
    } else return false
  }

  const isWithinMaxParentLevel = (occu) => occu.code.length <= topLevel

  const isOccupationChecked = (occupation) => props.selectedOccupations[0] != null && props.selectedOccupations.find(selected => selected.code === occupation.code) != null

  const countSelectedChildren = (occupation) => {
    const codeLength = occupation.code.length
    if (props.selectedOccupations.length === 1 && props.selectedOccupations[0] == null) return 0
    const children = props.selectedOccupations.filter(selectedOccupation => {
      return selectedOccupation.code.substring(0, codeLength) === occupation.code && selectedOccupation.code.length > codeLength
    })
    return children.length
  }

  const handleOccupationOpen = (clickedOccupation) => {
    if (isOccupationOpen(clickedOccupation.uri)) {
      // Close
      const newOpenOccupations = state.openOccupations.filter(occu => {
        return occu !== clickedOccupation.uri
      })
      setState({ openOccupations: newOpenOccupations })
    } else if (isWithinMaxParentLevel(clickedOccupation)) {
      // Open
      const newOpenOccupations = [...state.openOccupations, clickedOccupation.uri]
      setState({ openOccupations: newOpenOccupations })
      handleChildrenOccupations(clickedOccupation)
    }
  }

  const handleOccupationCheck = (checkedOccupation) => {
    onOccupationCheck(checkedOccupation)
  }

  const handleChildrenOccupations = (openedOccupation) => {
    // Only need to fetch children, if they are not already fetched
    if (!state.children[openedOccupation.code]) {
      fetchOccupationChildren(openedOccupation.uri).then(fetchedChildren => {
        const newChildren = { ...state.children }
        newChildren[openedOccupation.code] = fetchedChildren
        setState({ children: newChildren })
      })
    }
  }

  const renderSelect = (selectableOccupation) => {
    // Selectable props given but no selectableLevel defined means everything is selectable
    if ((selectable && !selectableLevel) ||
      // selectableLevel props defined. Check if it matches the length of occupation code in question
      (selectableLevel && selectableLevel === selectableOccupation.code.length)) {
      return (
        <ListItemIcon sx={styles.listCheckboxRoot}>
          <Checkbox
            color='primary'
            edge='start'
            onChange={e => handleOccupationCheck(selectableOccupation)}
            checked={isOccupationChecked(selectableOccupation)}
          />
        </ListItemIcon>
      )
    } else return null
  }

  const renderText = (occupation) => {
    if (props.badges != null) {
      return (
        <Badge
          color='primary'
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          badgeContent={countSelectedChildren(occupation)}
          style={{ root: styles.badge }}
        >
          <ListItemText primary={occupation.title} onClick={e => handleOccupationOpen(occupation)} primaryTypographyProps={{ sx: styles.listItemText }} />
        </Badge>
      )
    } else {
      return (
        <ListItemText primary={occupation.title} onClick={e => handleOccupationOpen(occupation)} />
      )
    }
  }

  const renderExpandIcon = (occupation) => {
    if (isWithinMaxParentLevel(occupation)) {
      return (
        <div>
          {isOccupationOpen(occupation.uri) ? <ExpandLess onClick={e => handleOccupationOpen(occupation)} /> : <ExpandMore onClick={e => handleOccupationOpen(occupation)} />}
        </div>
      )
    } else return null
  }
  const nestedLevel = level || 1
  const nestedPadding = nestedLevel * 16
  const possibleNestedStyles = { paddingLeft: nestedPadding }
  return (
    <List>
      {occupations &&
        occupations.map((occupation, index) => {
          return (
            <Fragment key={`${occupation.uri}-${nestedLevel}`}>
              <ListItem button style={possibleNestedStyles}>
                {renderSelect(occupation)}
                {renderText(occupation)}
                {renderExpandIcon(occupation)}
              </ListItem>
              <Collapse in={isOccupationOpen(occupation.uri)} timeout='auto' unmountOnExit>
                <EscoOccupationList
                  occupations={state.children[occupation.code]}
                  selectedOccupations={props.selectedOccupations}
                  topLevel={2}
                  level={nestedLevel + 1}
                  selectable={selectable}
                  selectableLevel={selectableLevel}
                  badges={props.badges}
                  fetchOccupationChildren={fetchOccupationChildren}
                  onOccupationCheck={onOccupationCheck}
                />
              </Collapse>
            </Fragment>
          )
        })}
      {occupations == null &&
        <Spinner centered />}
    </List>
  )
}

export default EscoOccupationList
