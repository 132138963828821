import { call, put } from 'redux-saga/effects'
import * as AreasActions from '../redux/AreasRedux'
import * as ErrorActions from '../redux/ErrorRedux'
import orderBy from 'lodash/orderBy'
import { isOkResponse } from '../services/api'
import { castToSelectOptions } from '../utils/transforms'

function getRegionsOptions (data) {
  const areas = []
  if (data.length > 0) {
    data.forEach((region) => {
      const area = region.fi // API uses finnish names for areas
      areas.push({ label: area.toUpperCase(), value: area, region: area })
      region.cities.forEach((city) => {
        areas.push({ label: city, value: city, region: area })
      })
    })
  }
  return areas
}

export function * getRegions (api, action) {
  try {
    const response = yield call(api.getRegions)
    if (isOkResponse(response)) {
      const regions = response.data
      yield put(AreasActions.getRegionsSuccess(regions))
    } else {
      yield put(AreasActions.getRegionsFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(AreasActions.getRegionsFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * getFinlandRegionsWithCities (api, action) {
  try {
    const response = yield call(api.getFinlandRegionsWithCities)
    if (isOkResponse(response)) {
      const finlandRegionsWithCities = yield call(getRegionsOptions, response.data)
      yield put(AreasActions.getFinlandRegionsWithCitiesSuccess(finlandRegionsWithCities))
    } else {
      yield put(AreasActions.getFinlandRegionsWithCitiesFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(AreasActions.getFinlandRegionsWithCitiesFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * getRegionsWithCities (api, action) {
  try {
    const response = yield call(api.getRegionsWithCities)
    if (isOkResponse(response)) {
      const regionsWithCities = yield call(getRegionsOptions, response.data)
      yield put(AreasActions.getRegionsWithCitiesSuccess(orderBy(regionsWithCities, ['region'], ['asc'])))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(AreasActions.getRegionsWithCitiesFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(AreasActions.getRegionsWithCitiesFailure())
  }
}

export function * getAreas (api, action) {
  try {
    const response = yield call(api.getAreas)
    if (isOkResponse(response)) {
      const areas = response.data
      yield put(AreasActions.getAreasSuccess(areas))
    } else {
      yield put(AreasActions.getAreasFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(AreasActions.getAreasFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * searchPostalCodes (api, action) {
  try {
    const { searchCode } = action.payload
    const response = yield call(api.searchPostalCodes, searchCode)
    if (isOkResponse(response)) {
      const searchedPostalCodes = response.data
      yield put(AreasActions.searchPostalCodesSuccess(searchedPostalCodes))
    } else {
      yield put(AreasActions.searchPostalCodesFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(AreasActions.searchPostalCodesFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * getCountries (api, action) {
  try {
    const response = yield call(api.getCountries)
    if (isOkResponse(response)) {
      const countries = response.data
      yield put(AreasActions.getCountriesSuccess(countries))
    } else {
      yield put(AreasActions.getCountriesFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(AreasActions.getCountriesFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * getLanguages (api, action) {
  try {
    const response = yield call(api.getLanguages)

    if (isOkResponse(response)) {
      const languages = response.data
      yield put(AreasActions.getLanguagesSuccess(languages))
    } else {
      yield put(AreasActions.getLanguagesFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(AreasActions.getLanguagesFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * getLanguageSkills (api, action) {
  try {
    const response = yield call(api.getLanguageSkillOptions)
    if (isOkResponse(response)) {
      const languageSkills = response.data
      yield put(AreasActions.getLanguageSkillsSuccess(languageSkills))
    } else {
      yield put(AreasActions.getLanguageSkillsFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(AreasActions.getLanguageSkillsFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * getLanguageSkillLevels (api, action) {
  try {
    const response = yield call(api.getLanguageSkillLevels)
    if (isOkResponse(response)) {
      const languageSkillLevels = response.data
      const languageSkillLevelOptions = castToSelectOptions(languageSkillLevels)
      yield put(AreasActions.getLanguageSkillLevelsSuccess(languageSkillLevels, languageSkillLevelOptions))
    } else {
      yield put(AreasActions.getLanguageSkillLevelsFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(AreasActions.getLanguageSkillLevelsFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}
