import React from 'react'
import { withStyles } from '@mui/styles'
import { withTranslation } from 'react-i18next'
import { COLORS } from '../../utils/colors'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import LaunchIcon from '@mui/icons-material/Launch'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const styles = theme => ({
  container: {
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: `1px solid ${COLORS.lightBorder}`,
    backgroundColor: COLORS.white,
    alignItems: 'center',
    padding: 4
  },
  badge: {
    top: -5,
    right: -5
  },
  badgeTotal: {
    top: -5,
    right: -5,
    color: COLORS.darkGrey,
    backgroundColor: COLORS.lightBorder
  },
  badgeHidden: {
    opacity: 0
  },
  badgeContent: {
    fontSize: 9
  },
  spacer: {
    flex: 1
  }
})

class MobileApplicantActionMenu extends React.Component {
  state = {
    anchorEl: null
  }

  handleMenuClick = (event) => { this.setState({ anchorEl: event.currentTarget }) }
  handleMenuClose = () => this.setState({ anchorEl: null })

  handleEndServicePress = () => {
    this.props.endService()
    this.handleMenuClose()
  }

  handleRollbackPress = () => {
    this.props.rollback()
    this.handleMenuClose()
  }

  handleChangeProject = () => {
    this.props.changeProject()
    this.handleMenuClose()
  }

  handleMailPress = () => {
    this.props.openMail()
    this.handleMenuClose()
  }

  handleSmsPress = () => {
    this.props.openSms()
    this.handleMenuClose()
  }

  handleExcelExport = () => {
    this.props.exportExcel()
    this.handleMenuClose()
  }

  getSelectedBadgeContent = () => {
    const { classes, selectedCount } = this.props
    return <span className={classes.badgeContent}>{selectedCount}</span>
  }

  renderApplicant = () => {
    const { showApplicant, openApplicant, t } = this.props
    if (showApplicant) {
      return (
        <MenuItem onClick={(e) => openApplicant()}>
          {t('applicant_info')}
        </MenuItem>
      )
    }
  }

  renderEndService = () => {
    const { showEndService, t } = this.props
    if (showEndService) {
      return (
        <MenuItem onClick={this.handleEndServicePress}>
          {t('end_service')}
        </MenuItem>
      )
    }
  }

  renderRollback = () => {
    const { showRollback } = this.props
    if (showRollback) {
      return (
        <MenuItem onClick={this.handleRollbackPress}>
          {this.props.rollbackText}
        </MenuItem>
      )
    }
  }

  renderChangeProject = () => {
    const { showChangeProject, t } = this.props
    if (showChangeProject) {
      return (
        <MenuItem onClick={this.handleChangeProject}>
          {t('change_applicant_project')}
        </MenuItem>
      )
    }
  }

  render () {
    const { classes, selectedCount, t } = this.props
    const { anchorEl } = this.state
    if (selectedCount) {
      return (
        <div>
          <Badge
            badgeContent={this.getSelectedBadgeContent()}
            color='primary'
            classes={{ badge: classes.badge }}
          >
            <IconButton onClick={this.handleMenuClick}>
              <LaunchIcon />
            </IconButton>
          </Badge>
          <Menu
            id='actions-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleMenuClose}
          >
            {this.renderApplicant()}
            {this.renderEndService()}
            {this.renderRollback()}
            {this.renderChangeProject()}
            <MenuItem onClick={this.handleMailPress}>{t('send_message')}</MenuItem>
            <MenuItem onClick={this.handleSmsPress}>{t('send_sms')}</MenuItem>
            <MenuItem onClick={this.handleExcelExport}>{t('export_to_excel')}</MenuItem>
          </Menu>
        </div>
      )
    } else {
      return null
    }
  }
}

export default withStyles(styles)(withTranslation()(MobileApplicantActionMenu))
