import { all, call, put } from 'redux-saga/effects'
import * as CoachesActions from '../redux/CoachesRedux'
import * as ProjectsActions from '../redux/ProjectsRedux'
import * as ErrorActions from '../redux/ErrorRedux'
import * as SuccessActions from '../redux/SuccessRedux'
import * as SavingActions from '../redux/SavingRedux'
import { composeSuccess, getObjectNameTranslation } from '../utils/transforms'
import { isOkResponse } from '../services/api'

function getCoachesOptionsArray (data) {
  if (data.length > 0) {
    return data.map((item) => {
      return { label: getObjectNameTranslation(item.name), value: item.id.toLowerCase() }
    })
  } else return []
}

export function * getCoachesOptions (api, action) {
  try {
    const response = yield call(api.getCoachesOptions)
    if (isOkResponse(response)) {
      const coachesOptions = yield call(getCoachesOptionsArray, response.data)
      yield put(CoachesActions.getCoachesOptionsSuccess(coachesOptions))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(CoachesActions.getCoachesOptionsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(CoachesActions.getCoachesOptionsFailure())
  }
}

export function * getCoachingResults (api, action) {
  try {
    const response = yield call(api.getCoachingResults)
    if (isOkResponse(response)) {
      const coachingResults = response.data
      yield put(CoachesActions.getCoachingResultsSuccess(coachingResults))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(CoachesActions.getCoachingResultsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(CoachesActions.getCoachingResultsFailure())
  }
}

export function * addCoaches (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, coaches } = action.payload
  try {
    const responses = yield all(coaches.map(coachId => call(api.addCoach, projectId, coachId)))
    const failuredResponses = responses.filter(response => isOkResponse(response) === false)
    if (failuredResponses.length === 0) {
      const success = composeSuccess('coach_added')
      yield put(SuccessActions.displaySuccess(success))
      yield put(CoachesActions.addCoachesSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(ProjectsActions.getProject(projectId))
    } else {
      yield put(CoachesActions.addCoachesFailure())
      yield all(failuredResponses.map(response => put(ErrorActions.displayError(response))))
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(CoachesActions.addCoachesFailure())
    yield put(ErrorActions.displayError(e.response))
    yield put(SavingActions.setSaving(false))
  }
}

export function * removeCoach (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, coachId } = action.payload
  try {
    const response = yield call(api.removeCoach, projectId, coachId)
    if (isOkResponse(response)) {
      const success = composeSuccess('coach_removed')
      yield put(SuccessActions.displaySuccess(success))
      yield put(CoachesActions.removeCoachSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(ProjectsActions.getProject(projectId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(CoachesActions.removeCoachFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(CoachesActions.removeCoachFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * addCoachingResult (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, result } = action.payload
  try {
    const response = yield call(api.addCoachingResult, projectId, result)
    if (isOkResponse(response)) {
      const success = composeSuccess('coaching_result_added')
      yield put(SuccessActions.displaySuccess(success))
      yield put(CoachesActions.addCoachingResultSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(ProjectsActions.getProject(projectId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(CoachesActions.addCoachingResultFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(CoachesActions.addCoachingResultFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * removeCoachingResult (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, result } = action.payload
  try {
    const response = yield call(api.removeCoachingResult, projectId, result)
    if (isOkResponse(response)) {
      const success = composeSuccess('coaching_result_removed')
      yield put(SuccessActions.displaySuccess(success))
      yield put(CoachesActions.removeCoachingResultSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(ProjectsActions.getProject(projectId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(CoachesActions.removeCoachingResultFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(CoachesActions.removeCoachingResultFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * getCoaches (api, action) {
  try {
    const response = yield call(api.getCoaches)
    if (isOkResponse(response)) {
      const coaches = response.data
      yield put(CoachesActions.getCoachesSuccess(coaches))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(CoachesActions.getCoachesFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(CoachesActions.getCoachesFailure())
  }
}
