import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import DialogHeader from '../DialogHeader'
import { COLORS } from '../../utils/colors'
import DialogContentWrapper from '../DialogContentWrapper'
import SaveButton from '../SaveButton'
import { isApplicantStatusStringEnded } from '../../utils/applicant'
import { customReducer } from '../../utils/reducer'
import Autocomplete from '../Autocomplete'
import FormFieldWrapper from '../FormFieldWrapper'
import { IconButton } from '@mui/material'

const styles = theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500,
      margin: 20
    }
  },
  title: {
    padding: '16px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  actionsWrapper: {
    margin: 0,
    padding: '6px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  cancelButton: {
    marginRight: 10
  },
  spinner: {
    color: COLORS.white
  },
  formControl: {
    width: '100%',
    marginTop: 10
  },
  selectMenu: {
    maxHeight: 300
  },
  labelColor: {
    color: COLORS.label,
    display: 'inline-block'
  },
  newStatus: {
    width: '100%',
    marginTop: 10
  },
  notificationText: {
    color: COLORS.red,
    marginTop: 20
  }
})

const InitialAssessmentDialog = (props) => {
  const [t] = useTranslation()
  const initialState = {
    newStatusSelection: null,
    coach: ''
  }
  const reducer = (state, newState) => customReducer(state, newState, initialState)
  const [state, setState] = useReducer(reducer, initialState)
  const statuses = {
    active: 'Active',
    ended: 'Ended'
  }

  useEffect(() => {
    // When assessment in progress changes to false, dialog is still open and there are no errors, it's safe to assume that
    // either start or ending of assessment has ended successfully
    if (props.open && !props.assessmentInProgress && !props.applicantError) handleClose()
  }, [props.assessmentInProgress])

  const clearTrainers = () => setState({ coach: '' })
  const getTrainerOptions = () => {
    const trainers = []
    if (props.coaches.length > 0) {
      props.coaches.forEach((coach) => {
        const label = `${coach.firstNames} ${coach.lastName}`
        trainers.push({ label, value: coach.userId })
      })
    }
    return trainers
  }
  const setNewStatusSelection = (e) => setState({ newStatusSelection: e.target.value })
  const setCoach = (coach) => setState({ coach })

  const handleSave = () => {
    const { newStatusSelection, coach } = state
    const { selectedApplicants, allApplicantsSelected } = props
    const userIds = selectedApplicants.map(applicant => applicant.userId)
    // Activate assessment
    if (newStatusSelection === statuses.active) props.startInitialAssessment(userIds, allApplicantsSelected, coach)
    // End assessmemt
    if (newStatusSelection === statuses.ended) props.endInitialAssessment(userIds, allApplicantsSelected)
  }

  const handleClose = () => {
    props.close()
    setState({})
  }

  const shouldSaveBeDisabled = () => {
    return props.fetching || state.newStatusSelection === null || (state.newStatusSelection === statuses.active && state.coach === '')
  }

  const renderProblems = () => {
    const { selectedApplicants } = props
    let problemText = null
    selectedApplicants.forEach(applicant => {
      if (isApplicantStatusStringEnded(applicant.status)) {
        problemText = 'one_applicant_status_is_ended'
      }
    })
    if (problemText) {
      return <Typography className={props.classes.notificationText}>{t(problemText)}</Typography>
    }
    return null
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      classes={{ paper: props.classes.root }}
      fullWidth
    >
      <DialogHeader
        handleClose={handleClose}
        label='change_initial_assessment_state'
      />
      <DialogContent dividers>
        <DialogContentWrapper>
          <FormControl className={props.classes.newStatus}>
            <InputLabel htmlFor='result' required>{t('select_initial_assessment_status')}</InputLabel>
            <Select value={state.newStatusSelection} onChange={setNewStatusSelection} input={<Input id='newStatus' />}>
              <MenuItem value={statuses.active}>
                {t('active')}
              </MenuItem>
              <MenuItem value={statuses.ended}>
                {t('ended')}
              </MenuItem>
            </Select>
          </FormControl>
          {state.newStatusSelection === statuses.active &&
            <FormFieldWrapper>
              <Typography variant='caption'>{t('trainer')}</Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                  <Autocomplete
                    value={state.coach}
                    options={[...getTrainerOptions()]}
                    onChange={status => setCoach(status)}
                    placeholder={`${t('select')}...`}
                    largeText
                  />
                </div>
                <IconButton className={props.classes.clearButton} onClick={clearTrainers} disabled={!state.coach}>
                  <CancelIcon />
                </IconButton>
              </div>
            </FormFieldWrapper>}
          {renderProblems()}
        </DialogContentWrapper>
      </DialogContent>
      <DialogActions classes={{ root: props.classes.actionsWrapper }}>
        <Button onClick={handleClose} className={props.classes.cancelButton}>
          {t('cancel')}
        </Button>
        <SaveButton
          label='save'
          fetching={props.fetching}
          disabled={shouldSaveBeDisabled()}
          handleSave={handleSave}
        />
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)((InitialAssessmentDialog))
