import React from 'react'
import { withStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import AddCircle from '@mui/icons-material/AddCircle'
import TableToolbar from '../TableToolBar'
import ToolTip from '../ToolTip'
import { COLORS } from '../../utils/colors'

const styles = theme => ({
  root: {
    padding: '0px 20px',
    color: COLORS.white,
    backgroundColor: COLORS.green,
    display: 'flex',
    justifyContent: 'space-between',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%',
    maxWidth: 1360,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 20px)'
    },
    minHeight: 56
  },
  filterRoot: {
    backgroundColor: COLORS.orange
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: {
    color: COLORS.white
  },
  badge: {
    top: -5,
    right: -5
  },
  badgeHidden: {
    opacity: 0
  },
  badgeContent: {
    fontSize: 9
  }
})

const ServicesTableToolbar = (props) => {
  const [t] = useTranslation()
  const { classes } = props

  const renderButtons = () => {
    return (
      <div className={classes.actions}>
        <ToolTip title={t('create_service')}>
          <IconButton aria-label='Send' onClick={props.onCreateDialogToggle}>
            <AddCircle className={classes.icon} />
          </IconButton>
        </ToolTip>
      </div>
    )
  }

  const onOpenFilters = props.onFilterDialogToggle
  return (
    <TableToolbar
      headerText={`${t('services')} ${props.servicesTotal}`}
      openFilters={onOpenFilters}
      filterCount={props.filterCount}
    >
      {renderButtons()}
    </TableToolbar>
  )
}

export default withStyles(styles)((ServicesTableToolbar))
