import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  coachingEntrySummary: null,
  coachingEntries: [],
  createSuccessfull: false
})

/* ------------- Types and Action Creators ------------- */

export const addCoachingEntry = createAction('ADD_COACHING_ENTRY', (projectId, newEntry) => ({ projectId, newEntry }))
export const addCoachingEntrySuccess = createAction('ADD_COACHING_ENTRY_SUCCESS')
export const addCoachingEntryFailure = createAction('ADD_COACHING_ENTRY_FAILURE', (error) => ({ error }))

export const getCoachingEntrySummaryText = createAction('GET_COACHING_ENTRY_SUMMARY_TEXT', (coachingId) => ({ coachingId }))
export const getCoachingEntrySummaryTextSuccess = createAction('GET_COACHING_ENTRY_SUMMARY_TEXT_SUCCESS', (coachingEntrySummary) => ({ coachingEntrySummary }))
export const getCoachingEntrySummaryTextFailure = createAction('GET_COACHING_ENTRY_SUMMARY_TEXT_FAILURE', (error) => ({ error }))

export const getCoachingEntries = createAction('GET_COACHING_ENTRIES', (params) => ({ params }))
export const getCoachingEntriesSuccess = createAction('GET_COACHING_ENTRIES_SUCCESS', (coachingEntries) => ({ coachingEntries }))
export const getCoachingEntriesFailure = createAction('GET_COACHING_ENTRIES_FAILURE', (error) => ({ error }))

export const editCoachingEntry = createAction('EDIT_COACHING_ENTRY', (projectId, coachingEntryId, body) => ({ projectId, coachingEntryId, body }))
export const editCoachingEntrySuccess = createAction('EDIT_COACHING_ENTRY_SUCCESS')
export const editCoachingEntryFailure = createAction('EDIT_COACHING_ENTRY_FAILURE', (error) => ({ error }))

export const deleteCoachingEntry = createAction('DELETE_COACHING_ENTRY', (projectId, coachingEntryId, coachingId) => ({ projectId, coachingEntryId, coachingId }))
export const deleteCoachingEntrySuccess = createAction('DELETE_COACHING_ENTRY_SUCCESS')
export const deleteCoachingEntryFailure = createAction('DELETE_COACHING_ENTRY_FAILURE', (error) => ({ error }))

export const acknowledgeEntrySaveSuccess = createAction('ACKNOWLEDGE_ENTRY_SAVE_SUCCESS')

/* ------------- Reducers ------------- */
const reducers = createReducer(
  {
    [addCoachingEntry]: (state) => state.merge({ loading: true }),
    [addCoachingEntrySuccess]: (state) => state.merge({ loading: false, createSuccessfull: true }),
    [addCoachingEntryFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [getCoachingEntrySummaryText]: (state) => state.merge({ loading: true }),
    [getCoachingEntrySummaryTextSuccess]: (state, { coachingEntrySummary }) => state.merge({ loading: false, coachingEntrySummary }),
    [getCoachingEntrySummaryTextFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [getCoachingEntries]: (state) => state.merge({ loading: true, coachingEntries: [] }),
    [getCoachingEntriesSuccess]: (state, { coachingEntries }) => state.merge({ loading: false, coachingEntries }),
    [getCoachingEntriesFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [editCoachingEntry]: (state) => state.merge({ loading: true }),
    [editCoachingEntrySuccess]: (state) => state.merge({ loading: false }),
    [editCoachingEntryFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [deleteCoachingEntry]: (state) => state.merge({ loading: true }),
    [deleteCoachingEntrySuccess]: (state) => state.merge({ loading: false }),
    [deleteCoachingEntryFailure]: (state, { error }) => state.merge({ loading: false, error }),
    [acknowledgeEntrySaveSuccess]: (state) => state.merge({ createSuccessfull: false })
  },
  INITIAL_STATE
)

export default reducers
