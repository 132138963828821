import React, { Fragment } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  textfield: {
    fontSize: '0.875rem'
  }
}))
export default function AsyncAutocomplete (props) {
  const classes = useStyles()
  const [t] = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(props.preselectedValue ? props.preselectedValue : (props.multiple ? [] : ''))
  const loading = open && props.searchingOptions
  const options = []
  if (value?.asMutable)setValue(value.asMutable())
  if (props.options.length !== 0)options.push(...props.options)
  if (props.preselectedValue && !Array.isArray(props.preselectedValue)) options.push(props.preselectedValue)
  if (props.preselectedValue && Array.isArray(props.preselectedValue)) options.push(...props.preselectedValue)
  if (!props.multiple) options.push('')

  React.useEffect(() => { if (value === '' && props.preselectedValue) setValue(props.preselectedValue) }, [props.preselectedValue])
  React.useEffect(() => { if (Array.isArray(value) && props.value) setValue(props.value) }, [props.value])

  let debuffer
  const handleValueChange = (e) => {
    const searchCode = e.target.value
    clearTimeout(debuffer)
    debuffer = setTimeout(() => {
      props.onChange(searchCode)
    }, 700)
  }

  /* If multiple, return array. If not, return value */
  const onValueChange = (e, val) => {
    setValue(val)
    let tempValue
    if (props.multiple) tempValue = val.length > 0 ? val : []
    else tempValue = val ? val.value : null
    props.onValueSelect(tempValue)
  }

  return (
    <Autocomplete
      fullWidth
      multiple={props.multiple}
      open={props.keepClosed ? false : open}
      onOpen={() => {
        if (!props.keepClosed) setOpen(true)
      }}
      onClose={(event, reason) => {
        if (!props.keepClosed) {
          setOpen(false)
          props.onClose(event, reason)
        }
      }}
      onChange={onValueChange}
      loadingText={t('loading')}
      getOptionLabel={(option) => option.label || ''}
      value={value}
      options={options}
      loading={loading}
      placeholder={props.placeholder}
      noOptionsText={props.noOptionsText}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          onChange={handleValueChange}
          value={value}
          variant='standard'
          InputLabelProps={{ classes: { root: classes.textfield } }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment key='async-autocomplete-field'>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {props.keepClosed ? null : params.InputProps.endAdornment}
              </Fragment>
            )
          }}
        />
      )}
    />
  )
}
