import React, { useReducer, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/styles'
import { Button, Dialog, DialogActions, DialogContent, CircularProgress, TextField, Typography, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import DialogHeader from '../DialogHeader'
import SaveButton from '../SaveButton'
import DateText from '../DateText'
import { COLORS } from '../../utils/colors'
import { getApplicantName } from '../../utils/applicant'
import { customReducer } from '../../utils/reducer'

const styles = {
  root: {
    minHeight: 530,
    maxWidth: 880
  },
  spinner: {
    display: 'flex',
    padding: '64px 16px',
    justifyContent: 'center',
    zIndex: 20
  },
  noteWrapper: {
    marginTop: 16
  },
  actionsWrapper: {
    justifyContent: 'space-between'
  },
  redColor: {
    color: COLORS.red
  },
  greenColor: {
    color: COLORS.green
  },
  cancelButton: {
    marginRight: 10
  },
  labelColor: {
    color: COLORS.label,
    display: 'inline-block'
  },
  applicantName: {
    marginBottom: 20,
    marginLeft: 4,
    display: 'inline-block'
  },
  caption: {
    color: COLORS.label
  }
}

const NotesDialog = (props) => {
  const [t] = useTranslation()
  const { isOpen, onClose } = props
  const initialState = {
    newNoteText: ''
  }
  /* Experiment with useReducer; useful on complex state objects, so not that useful here. */
  const reducer = (state, newState) => customReducer(state, newState, initialState)
  const [state, setState] = useReducer(reducer, initialState)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  // Run when isOpen changes
  useEffect(() => {
    if (isOpen && !props.loading) {
      /* props.getMeetings()
      props.getAllMeetings() */
    }
  }, [isOpen])

  useEffect(() => {
    if (props.created) {
      // setState({ editedMeetingId: null })
    }
  }, [props.created, props.loading])

  // Note props changed
  useEffect(() => {
    if (props.notes.length > 0) {
      // When notes has content, set the first one's value on edit box (because right now only one note per applicant)
      setState({ newNoteText: props.notes[0].text })
    } else setState({ newNoteText: '' })
  }, [props.notes])

  const setInitialState = () => {
    setState({})
  }

  const setNewNoteText = (e) => setState({ newNoteText: e.target.value })

  const closeDialog = () => {
    setInitialState()
    onClose()
  }

  const handleCancel = () => {
    setInitialState()
    closeDialog()
  }

  const handleEdit = (note) => {
    const { newNoteText } = state
    const { applicant } = props
    if (note) {
      // Edit
      const body = {
        note: newNoteText,
        coachingId: applicant.coachingId,
        noteId: note.id,
        projectId: applicant.projectId
      }
      props.editNote(body)
    }
  }

  const handleCreate = (note) => {
    const { newNoteText } = state
    const { applicant } = props
    if (note) {
      const body = {
        note: newNoteText,
        coachingId: applicant.coachingId,
        projectId: applicant.projectId
      }
      props.addNote(body)
    }
  }

  const handleNoteActivation = (note) => {
    if (note.passive) props.activateNote(props.applicant.projectId, note.id, props.applicant.coachingId)
    else props.passivateNote(props.applicant.projectId, note.id, props.applicant.coachingId)
  }

  const getActivityButtonText = (note) => {
    if (note) return note.passive ? 'activate' : 'passivate'
    else return 'activate'
  }

  const getActivityButtonClass = (note) => {
    if (isNotePassive(note)) return styles.greenColor
    else return styles.redColor
  }

  const getActivityColorClass = (note) => {
    if (isNotePassive(note)) return styles.redColor
    else return styles.greenColor
  }

  const getNoteEditor = (note) => {
    const creator = `${t('created')}: ${note.createdBy.firstNames} ${note.createdBy.lastName} `
    const updater = note.updated ? `${t('edited')}: ${note.updatedBy.firstNames} ${note.updatedBy.lastName} ` : null
    return updater || creator
  }

  const getNoteTimeStamp = (note) => {
    return note.updated || note.created
  }

  const getNoteFieldLabel = (note) => {
    return note ? '' : `${t('notable')}: `
  }

  const isNotePassive = (note) => note != null && note.passive

  const renderLoader = () => {
    const { fetchingNotes } = props
    if (fetchingNotes) {
      return (
        <div style={styles.spinner}>
          <CircularProgress />
        </div>
      )
    }
  }

  const renderNoteInformation = (note) => {
    if (note) {
      return (
        <Typography variant='caption' style={styles.caption}>
          {getNoteEditor(note)}
          <DateText text={getNoteTimeStamp(note)} />
        </Typography>
      )
    } else return null
  }

  const renderActivityButton = () => {
    /* Display activate/passivate button only if note exists. */
    if (props.notes[0]) {
      return (
        <Button onClick={() => handleNoteActivation(props.notes[0])} style={getActivityButtonClass(props.notes[0])}>
          {t(getActivityButtonText(props.notes[0]))}
        </Button>
      )
    } else return null
  }

  const renderNotesList = () => {
    const { notes, fetchingNotes } = props
    const { newNoteText } = state
    if (!fetchingNotes) {
      return (
        <div style={styles.noteWrapper}>
          <Typography style={styles.labelColor}>{`${t('applicant')}:`}</Typography>
          <Typography style={styles.applicantName}>{`${getApplicantName()}`}</Typography>
          {notes[0] &&
            <Typography variant='subtitle1' style={getActivityColorClass(notes[0])}>{isNotePassive(notes[0]) ? t('passive_note') : t('active_note')}</Typography>}
          <TextField
            label={getNoteFieldLabel(notes[0])}
            value={newNoteText}
            onChange={setNewNoteText}
            rows={14}
            rowsMax={14}
            multiline
            fullWidth
            placeholder={t('type_new_note')}
            variant='standard'
          />
          {renderNoteInformation(notes[0])}
        </div>
      )
    } else return null
  }

  /* Render into a DOM node that exists outside the DOM hierarchy of the parent component.
  Document body is the target in this case.
  */
  return ReactDOM.createPortal(
    <Dialog
      open={isOpen}
      PaperProps={{ style: styles.root }}
      fullScreen={fullScreen}
      fullWidth
      scroll='paper'
    >
      <DialogHeader
        handleClose={closeDialog}
        label='notes'
      />
      <DialogContent dividers>
        {renderLoader()}
        {renderNotesList()}
      </DialogContent>
      <DialogActions style={styles.actionsWrapper}>
        <div>
          {renderActivityButton()}
        </div>
        <div>
          <Button onClick={handleCancel} style={styles.cancelButton}>
            {t('cancel')}
          </Button>
          {props.notes.length > 0 && // Edit
            <SaveButton
              label='save'
              fetching={props.fetchingNotes}
              disabled={props.fetchingNotes}
              handleSave={() => handleEdit(props.notes[0])}
            />}
          {props.notes.length === 0 && // Create
            <SaveButton
              label='save'
              fetching={props.fetchingNotes}
              disabled={props.fetchingNotes}
              handleSave={handleCreate}
            />}
        </div>
      </DialogActions>
    </Dialog>,
    document.body
  )
}

NotesDialog.propTypes = {
  applicant: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.any,
  notes: PropTypes.array
}

export default NotesDialog
