export const COLORS = {
  white: '#FFF',
  black: '#000',
  darkGrey: '#222',
  grey: '#666',
  orange: '#fe8901',
  orangeDarken: '#bb6c2e',
  violet: '#6d32a6',
  green: '#1fab66',
  greenDarken: '#16844e',
  red: '#f44336',
  link: '#536dfe',
  light: '#f8f8f8',
  lightBorder: '#e0e0e0',
  lightText: '#aaa',
  label: 'rgba(0, 0, 0, 0.54)',
  errorBg: 'rgba(244, 67, 54, 0.26)'
}
