import React, { useEffect, useState, Fragment, useReducer } from 'react'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import EscoOccupationList from '../EscoOccupationList'
import { COLORS } from '../../utils/colors'
import SaveButton from '../SaveButton'

const useStyles = makeStyles(theme => ({
  paper: {
    width: 600
  }
}))

export default function OccupationPickerMenu (props) {
  const [t] = useTranslation()
  const classes = useStyles()
  const [anchorElement, setAnchorElement] = useState(null)
  const isShowing = Boolean(anchorElement)
  const initialState = {
    selectedOccupation: null
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)

  useEffect(() => {
    setState({ selectedOccupation: props.selectedOccupation })
  }, [props.selectedOccupation])

  const toggleMenu = (event) => {
    setAnchorElement(event.target)
  }
  const close = () => {
    setAnchorElement(null)
  }

  const handleOccupationCheck = (occupation) => setState({ selectedOccupation: occupation })

  const handleCancel = () => {
    close()
    setState({ selectedOccupation: null })
  }

  const handleOk = () => {
    props.onOccupationSelect(state.selectedOccupation)
    close()
  }

  return (
    <Fragment key='occupation-menu'>
      {!state.selectedOccupation &&
        <Button variant='contained' onClick={toggleMenu} sx={{ backgroundColor: COLORS.lightBorder, color: COLORS.black }}>
          {t(props.buttonText)}
        </Button>}
      {state.selectedOccupation &&
        <IconButton className={classes.iconButton} onClick={toggleMenu}>
          <EditIcon />
        </IconButton>}
      <Dialog
        open={isShowing}
        onClose={close}
        PaperProps={{ sx: { width: 600 } }}
      >
        <DialogContent>
          <EscoOccupationList
            occupations={props.occupations}
            topLevel={props.topLevel}
            selectable={props.selectable}
            selectableLevel={props.selectableLevel}
            badges={props.badges}
            fetchOccupationChildren={props.fetchOccupationChildren}
            onOccupationCheck={handleOccupationCheck}
            selectedOccupations={[state.selectedOccupation]}
          />
        </DialogContent>
        <DialogActions>
          <Button className={classes.iconButton} onClick={handleCancel}>
            {t('cancel')}
          </Button>
          <SaveButton
            label='ok'
            handleSave={handleOk}
            className={classes.iconButton}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
