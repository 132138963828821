import { combineReducers } from 'redux'
import { persistStore, persistCombineReducers } from 'redux-persist'
import configureStore from './CreateStore'
import rootSaga from '../sagas'
import { rootPersistConfig } from './persistConfig'
import messagesReducer from './MessagesRedux'
import notesReducer from './NotesRedux'
import projectsReducer from './ProjectsRedux'
import servicesReducer from './ServicesRedux'
import successReducer from './SuccessRedux'
import userReducer from './UsersRedux'
import meetingsReducer from './MeetingsRedux'
import errorReducer from './ErrorRedux'
import coachesRedux from './CoachesRedux'
import attachmentsRedux from './AttachmentsRedux'
import areasRedux from './AreasRedux'
import applicantsRedux from './ApplicantsRedux'
import coachingEntriesRedux from './CoachingEntriesRedux'
import savingReducer from './SavingRedux'

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
  applicants: applicantsRedux,
  messages: messagesReducer,
  meetings: meetingsReducer,
  user: userReducer,
  errors: errorReducer,
  saving: savingReducer,
  success: successReducer,
  services: servicesReducer,
  projects: projectsReducer,
  areas: areasRedux,
  coaches: coachesRedux,
  notes: notesReducer,
  attachments: attachmentsRedux,
  coachingEntries: coachingEntriesRedux
})

export default () => {
  const reducersObject = {
    applicants: applicantsRedux,
    messages: messagesReducer,
    meetings: meetingsReducer,
    user: userReducer,
    errors: errorReducer,
    success: successReducer,
    saving: savingReducer,
    services: servicesReducer,
    projects: projectsReducer,
    areas: areasRedux,
    coaches: coachesRedux,
    notes: notesReducer,
    attachments: attachmentsRedux,
    coachingEntries: coachingEntriesRedux
  }
  const persistedReducer = persistCombineReducers(rootPersistConfig, {
    ...reducersObject
  })
  let { store, sagasManager, sagaMiddleware } = configureStore(persistedReducer, rootSaga)
  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = reducers
      store.replaceReducer(nextRootReducer)

      const newYieldedSagas = require('../sagas').default
      sagasManager.cancel()
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas)
      })
    })
  }
  const persistor = persistStore(store)

  return { store, persistor }
}
