import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { COLORS } from '../../utils/colors'
import withMediaQuery from '../../utils/withMediaQuery'

const styles = {
  root: {
    padding: 4,
    maxWidth: 960,
    margin: 20
  },
  title: {
    padding: { md: '16px 24px', sm: 10 }
  },
  contentWrapper: {
    paddingBottom: 0,
    padding: '0px 10px'
  },
  actionsWrapper: {
    margin: 0,
    padding: { md: '6px 24px', sm: 10 }
  },
  sendButton: {
    margin: '10px 0px',
    marginLeft: { md: 20, sm: 15 },
    width: 102
  },
  textField: {
    marginBottom: 10
  },
  cancelButton: {
    color: COLORS.red
  },
  spinner: {
    color: COLORS.white
  }
}

class SmsForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      message: ''
    }
  }

  componentDidUpdate (nextProps) {
    if (this.props.fetching && !nextProps.fetching) {
      this.handleClose()
    }
  }

  handleSetMessage = (e) => this.setState({ message: e.target.value })
  handleSendMessage = () => this.props.sendSms(this.state.message)

  handleClose = () => {
    this.props.close()
    this.setState({ message: '' })
  }

  renderButtonContent = () => {
    const { fetching, t } = this.props
    if (fetching) {
      return <CircularProgress style={styles.spinner} size={16} />
    }
    return t('send')
  }

  render () {
    const { t, open, close, fullScreen } = this.props
    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby='form-dialog-title'
        PaperProps={{ style: styles.root }}
        fullScreen={fullScreen}
        fullWidth
      >
        <DialogTitle id='form-dialog-title' sx={{ '.MuiDialogTitle-root': styles.title }}>
          {t('new_sms')}
        </DialogTitle>
        <DialogContent sx={{ '.MuiDialogContent-root': styles.contentWrapper }}>
          <TextField
            label={t('message')}
            style={styles.textField}
            value={this.state.message}
            onChange={this.handleSetMessage}
            rows={5}
            rowsMax={8}
            multiline
            autoFocus
            fullWidth
            required
            variant='standard'
          />
        </DialogContent>
        <DialogActions sx={{ '.MuiDialogContent-root': styles.actionsWrapper }}>
          <Button onClick={this.handleClose} style={styles.cancelButton}>
            {t('cancel')}
          </Button>
          <Button
            onClick={this.handleSendMessage}
            variant='contained'
            color='primary'
            style={styles.sendButton}
            disabled={!this.state.message}
          >
            {this.renderButtonContent()}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withMediaQuery(withTranslation()(SmsForm))
