import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { withStyles } from '@mui/styles'
import { withTranslation } from 'react-i18next'
import CustomSnackbarContentWrapper from './CustomSnackbarContentWrapper'

const notificationStyles = theme => ({
  container: {
    zIndex: 10000
  }
})

class ErrorNotification extends React.Component {
  render () {
    const { classes, onClose, error, t } = this.props
    const isOpen = Boolean(error)
    let errorMessage = null
    // Get error message based on message key
    if (error && error.messageKey) {
      errorMessage = t(error.messageKey)
    }
    // Display API error message that we got from API
    // TODO: Use translated messages if that support is added to API
    if (error && error.message) {
      errorMessage = error.message
    }
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isOpen}
        onClose={onClose}
        className={classes.container}
      >
        <CustomSnackbarContentWrapper onClose={onClose} variant='error' message={errorMessage} />
      </Snackbar>
    )
  }
}

export default withStyles(notificationStyles)(withTranslation()(ErrorNotification))
