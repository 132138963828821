import React from 'react'
import { useTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: 120
  }
}

const TimePicker = (props) => {
  const [t] = useTranslation()
  const timeChanged = (e) => props.onTimeChange(e.target.value)
  const getLabel = () => `${t(props.label)} ${props.required ? '*' : ''}`
  return (
    <TextField
      label={getLabel()}
      type='time'
      margin='normal'
      value={props.value}
      style={styles.textField}
      onChange={timeChanged}
      variant='standard'
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        step: 300 // 5 min
      }}
    />
  )
}

export default TimePicker
