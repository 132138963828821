import React from 'react'
import { withTranslation } from 'react-i18next'
import { COLORS } from '../utils/colors'
import Checkbox from '@mui/material/Checkbox'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import FilterIcon from '@mui/icons-material/FilterList'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'

const styles = {
  container: {
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: `1px solid ${COLORS.lightBorder}`,
    backgroundColor: COLORS.white,
    alignItems: 'center',
    padding: 4
  },
  badge: {
    top: -5,
    right: -5
  },
  badgeTotal: {
    '& .MuiBadge-badge': {
      top: -5,
      right: -5,
      color: COLORS.darkGrey,
      backgroundColor: COLORS.lightBorder
    }
  },
  onlyBadge: {
    '& .MuiBadge-badge': {
      top: -5,
      left: 11,
      color: COLORS.darkGrey,
      backgroundColor: COLORS.lightBorder
    }
  },
  badgeHidden: {
    opacity: 0
  },
  badgeContent: {
    fontSize: 9
  },
  spacer: {
    flex: 1
  }
}

class ActionBar extends React.Component {
  state = {
    anchorEl: null
  }

  handleMenuClick = (event) => { this.setState({ anchorEl: event.currentTarget }) }
  handleMenuClose = () => this.setState({ anchorEl: null })

  getFilterBadgeContent = () => {
    const { filterCount } = this.props
    if (filterCount) {
      return <span style={styles.badgeContent}>{filterCount}</span>
    }
    return 0
  }

  getSelectedBadgeContent = () => {
    const { selectedCount } = this.props
    return <span style={styles.badgeContent}>{selectedCount}</span>
  }

  getTotalBadgeContent = () => {
    const { totalCount } = this.props
    return <span style={styles.badgeContent}>{totalCount}</span>
  }

  render () {
    const { selectAll, indeterminate, toggleSelectAll, openFilter, openFileUpload } = this.props
    const applicantScreen = this.props.openFileUpload.name !== 'onCreateDialogToggle' || false
    const buttonIcon = !applicantScreen ? <AddCircleOutline sx={styles.icon} /> : <CloudUploadIcon sx={styles.icon} />
    return (
      <div style={styles.container}>
        <Badge
          badgeContent={this.getTotalBadgeContent()}
          color='default'
          sx={toggleSelectAll ? styles.badgeTotal : styles.onlyBadge}
        >
          {toggleSelectAll != null &&
            <Checkbox
              checked={selectAll}
              indeterminate={indeterminate}
              onChange={toggleSelectAll}
              color='primary'
            />}
        </Badge>

        {this.props.actionMenuComponent}

        <div style={styles.spacer} />
        <IconButton aria-label='File upload' onClick={openFileUpload}>
          {buttonIcon}
        </IconButton>
        <Badge
          badgeContent={this.getFilterBadgeContent()}
          color='primary'
          styles={styles.badge}
        >
          <IconButton onClick={openFilter}>
            <FilterIcon />
          </IconButton>
        </Badge>
        {this.props.paginationComponent}
      </div>
    )
  }
}

export default withTranslation()(ActionBar)
