import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc'
import { buildShareUrl } from 'react-add-to-calendar-hoc/lib/utils'
import CalendarIntegrationMenu from './CalendarIntegrationMenu'
import { COLORS } from '../utils/colors'

const styles = {
  addToCalendarButton: {
    display: 'inline-flex',
    flexDirection: 'column',
    backgroundColor: COLORS.orange,
    /* padding: 5, */
    cursor: 'pointer',
    color: COLORS.white,
    marginBottom: 10
  }
}
const AddToCalendarButton = (props) => {
  const [t] = useTranslation()

  const options = [SHARE_SITES.GOOGLE, SHARE_SITES.OUTLOOK]

  const getMenuItems = () => {
    if (props.event) {
      return [
        { enum: SHARE_SITES.GOOGLE, url: buildShareUrl(props.event, SHARE_SITES.GOOGLE) },
        { enum: SHARE_SITES.OUTLOOK, url: buildShareUrl(props.event, SHARE_SITES.OUTLOOK) }
      ]
    } else return []
  }

  const AddToCalendarDropdown = AddToCalendarHOC(Button, CalendarIntegrationMenu)

  return (
    <AddToCalendarDropdown
      /* linkProps={{
        className: linkStyles
      }} */
      buttonProps={{ style: styles.addToCalendarButton, variant: 'contained' }}
      buttonText={t('add_to_your_calendar')}
      event={props.event}
      items={options}
      dropdownProps={{ items: getMenuItems() }}
    />
  )
}

export default AddToCalendarButton
