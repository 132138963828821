export const educationLevels = {
  university: 'UniversityMasters',
  university_studies: 'UniversityStudies',
  uoas_masters: 'UniversityOfAppliedSciencesMasters',
  uoas_bachelors: 'UniversityOfAppliedSciencesBachelors',
  general_upper_secondary_school: 'GeneralUpperSecondarySchool',
  vocational_college: 'VocationalCollege',
  vocational_college_studies: 'VocationalCollegeStudies',
  comprehensive_school: 'ComprehensiveSchool',
  dual_qualification: 'DualQualification',
  no_education: 'NoEducation',
  comperehensive_school_studies: 'ComprehensiveSchoolStudies',
  general_upper_secondary_school_studies: 'GeneralUpperSecondarySchoolStudies',
  university_bachelors: 'UniversityBachelors'
}
