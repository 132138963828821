import { call, put, select } from 'redux-saga/effects'
import * as ServicesActions from '../redux/ServicesRedux'
import * as ErrorActions from '../redux/ErrorRedux'
import * as SuccessActions from '../redux/SuccessRedux'
import * as SavingActions from '../redux/SavingRedux'
import { composeSuccess, getObjectNameTranslation, composeServiceFilters } from '../utils/transforms'
import { isOkResponse } from '../services/api'

export function * getServices (api, action) {
  const servicesState = (state) => state.services
  const { page, pageSize, serviceFilters } = yield select(servicesState)
  const apiPage = page - 1
  const filters = composeServiceFilters(serviceFilters)

  const params = {
    ...filters,
    skip: apiPage * pageSize,
    take: pageSize
  }
  try {
    const response = yield call(api.getServices, params)

    if (isOkResponse(response)) {
      const services = response.data.list
      const servicesTotal = response.data.totalCount
      const allIds = response.data.allIds
      yield put(ServicesActions.getServicesSuccess(services, servicesTotal, allIds))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ServicesActions.getServicesFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ServicesActions.getServicesFailure())
  }
}

export function * setServiceFilters (api, action) {
  yield put(ServicesActions.getServices())
}

export function * changeServicesPage (action) {
  const { page } = action.payload
  const servicesState = (state) => state.services
  const { services, pageSize } = yield select(servicesState)

  if ((page * pageSize) >= services.length) {
    yield put(ServicesActions.getServices())
  }
}

export function * changeServiceRowsPerPage (action) {
  yield put(ServicesActions.reloadVisibleServices())
}

export function * reloadVisibleServices (api, action) {
  const servicesState = (state) => state.services
  const { page, serviceFilters, pageSize } = yield select(servicesState)
  const filters = composeServiceFilters(serviceFilters)

  const params = {
    ...filters,
    skip: ((page - 1) * pageSize),
    take: pageSize
  }

  try {
    const response = yield call(api.getServices, params)
    if (isOkResponse(response)) {
      const services = response.data.list
      const servicesTotal = response.data.totalCount
      const allIds = response.data.allIds
      yield put(ServicesActions.reloadVisibleServicesSuccess(services, servicesTotal, allIds))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ServicesActions.reloadVisibleServicesFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ServicesActions.reloadVisibleServicesFailure())
  }
}

/* Use this after creating new service! Because this fetches all the services already
fetched and updates them to state. This is incase user adds a service which should be listed in the pages
before the current page the user is on. */
export function * reloadAllFetchedServices (api, action) {
  const servicesState = (state) => state.services
  const { page, serviceFilters, pageSize } = yield select(servicesState)
  const params = {
    ...serviceFilters,
    skip: 0,
    take: (page * pageSize)
  }
  try {
    const response = yield call(api.getServices, params)

    if (isOkResponse(response)) {
      const services = response.data.list
      const servicesTotal = response.data.totalCount
      const allIds = response.data.allIds
      yield put(ServicesActions.reloadAllFetchedServicesSuccess(services, servicesTotal, allIds))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ServicesActions.reloadAllFetchedServicesFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ServicesActions.reloadAllFetchedServicesFailure())
  }
}

function getServicesOptionsArray (data) {
  if (data.length > 0) {
    return data.map((service) => {
      return { label: getObjectNameTranslation(service.name), value: service.id.toLowerCase() }
    })
  } else return []
}

export function * getServicesOptions (api, action) {
  try {
    const response = yield call(api.getServicesOptions)
    if (isOkResponse(response)) {
      const servicesOptions = yield call(getServicesOptionsArray, response.data)
      yield put(ServicesActions.getServicesOptionsSuccess(servicesOptions))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ServicesActions.getServicesOptionsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ServicesActions.getServicesOptionsFailure())
  }
}

export function * createService (api, action) {
  yield put(SavingActions.setSaving(true))
  const { service } = action.payload
  try {
    const response = yield call(api.putService, service)
    if (isOkResponse(response)) {
      const success = composeSuccess('service_created')
      yield put(SuccessActions.displaySuccess(success))
      yield put(ServicesActions.createServiceSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(ServicesActions.reloadAllFetchedServices())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ServicesActions.createServiceFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ServicesActions.createServiceFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * editService (api, action) {
  yield put(SavingActions.setSaving(true))
  const { service } = action.payload
  try {
    const response = yield call(api.putService, service)
    if (isOkResponse(response)) {
      const success = composeSuccess('service_updated')
      yield put(SuccessActions.displaySuccess(success))
      yield put(ServicesActions.editServiceSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(ServicesActions.reloadAllFetchedServices())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ServicesActions.editServiceFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ServicesActions.editServiceFailure())
    yield put(SavingActions.setSaving(false))
  }
}
