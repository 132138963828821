import React, { Fragment } from 'react'
import { withStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import ServiceMenu from './ServiceMenu'
import { COLORS } from '../../utils/colors'
import { getCurrentLang } from '../../utils/transforms'

const styles = {
  card: {
    marginBottom: 32
  },
  cellRow: {
    paddingTop: 4,
    paddingBottom: 4,
    display: 'flex',
    alignItems: 'center'
  },
  cellIcon: {
    color: COLORS.white,
    margin: '0 4px'
  },
  smallCellIcon: {
    color: COLORS.darkGrey,
    fontSize: 12,
    marginRight: 8
  },
  link: {
    color: COLORS.grey,
    textDecoration: 'none',
    '&:hover': {
      color: COLORS.darkGrey
    }
  },
  empty: {
    color: COLORS.lightText
  },
  leftCell: {
    padding: '8px 16px',
    width: 90
  },
  cell: {
    padding: '8px 16px'
  },
  active: {
    color: COLORS.greenDarken
  },
  enrolled: {
    color: COLORS.orange
  },
  ended: {
    color: COLORS.red
  },
  header: {
    background: COLORS.orange,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 9,
    paddingTop: 4,
    paddingBottom: 4,
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignContent: 'stretch'
  },
  leftHeader: {
    display: 'flex',
    width: '50%'
  },
  rightHeader: {
    display: 'flex'
  },
  headerText: {
    color: COLORS.white,
    display: 'inline-flex',
    transition: 'none',
    alignItems: 'center'
  },
  menuIconContainer: {
    color: COLORS.white
  }
}

const ServiceRowMobile = (props) => {
  const [t] = useTranslation()
  const { service } = props

  const getServiceName = () => {
    if (getCurrentLang() === 'fi') {
      return service.name.fi || service.name.en
    } else {
      return service.name.en || service.name.fi
    }
  }

  const renderServiceMenu = () => {
    return (
      <div style={styles.menuIconContainer}>
        <ServiceMenu
          service={service}
          showServiceDialog={props.showServiceDialog}
        />
      </div>
    )
  }

  const renderServiceMobileRow = () => {
    const { getProjectNames } = props
    return (
      <Fragment key={service.id}>
        <TableRow>
          <TableCell style={styles.leftCell}>{t('start_date')}</TableCell>
          <TableCell style={styles.cell}>{service.start ? moment(service.start).format('DD.MM.YYYY') : '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={styles.leftCell}>{t('end_date')}</TableCell>
          <TableCell style={styles.cell}>{service.end ? moment(service.end).format('DD.MM.YYYY') : '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={styles.leftCell}>{t('passivated')}</TableCell>
          <TableCell style={styles.cell}>{service.active ? t('no') : t('yes')}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={styles.leftCell}>{t('projects')}</TableCell>
          <TableCell style={styles.cell}>{getProjectNames(service)}</TableCell>
        </TableRow>
      </Fragment>
    )
  }

  return (
    <Card style={styles.card}>
      <div style={styles.header}>
        <div style={styles.leftHeader}>
          <Typography style={styles.headerText}>{getServiceName()}</Typography>
        </div>
        <div style={styles.rightHeader}>
          {renderServiceMenu()}
        </div>
      </div>
      <Table>
        <TableBody>
          {renderServiceMobileRow()}
        </TableBody>
      </Table>
    </Card>
  )
}

export default withStyles(styles)((ServiceRowMobile))
