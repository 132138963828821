import React, { useEffect, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PolicyIcon from '@mui/icons-material/Policy'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import ToolTip from '../ToolTip'

const styles = {
  iconButton: {
    color: 'inherit'
  },
  iconBadge: {
    padding: 0
  },
  menuIconBadge: {
    padding: 0,
    top: 5
  }
}

/*
 This is a react hook experiment! They only work with function components.
 React hooks seem useful in certain situations, like this where a component
 should only worry about it's state and not the parent.
*/
const ApplicantMenu = (props) => {
  const [t] = useTranslation()
  const [anchorElement, setAnchorElement] = useState(null)
  const isShowing = Boolean(anchorElement)
  const [isNoteBadgeInvisible, setNoteBadgeInvisible] = useState(true)

  useEffect(() => {
    if (props.hasActiveNotes && isNoteBadgeInvisible) setNoteBadgeInvisible(false)
    else if (!props.hasActiveNotes && !isNoteBadgeInvisible) setNoteBadgeInvisible(true)
  }, [props.hasActiveNotes])

  const toggleApplicantMenu = (event) => {
    setAnchorElement(event.target)
  }
  const close = () => {
    setAnchorElement(null)
  }
  const applicantInfo = () => {
    close()
    props.showApplicantDialog()
  }
  const meetingsInfo = () => {
    close()
    props.showCoachingEntriesDialog()
  }
  const notesInfo = () => {
    close()
    props.showNotesDialog()
  }
  const rollback = () => {
    close()
    props.openRollBack()
  }
  const applicantStatus = () => {
    close()
    props.showApplicantStatusDialog()
  }
  const toggleSafeMode = () => {
    close()
    props.toggleSafeMode()
  }

  const renderOneBadgeAtATime = () => {
    if (!props.safeModeOn) {
      return (
        <Badge color='primary' variant='dot' invisible={isNoteBadgeInvisible}>
          <MoreVertIcon />
        </Badge>
      )
    }
    return (
      <Badge badgeContent={<PolicyIcon />} invisible={!props.safeModeOn} style={styles.iconBadge}>
        <MoreVertIcon />
      </Badge>
    )
  }

  return (
    <Fragment key='applicant-menu'>
      <ToolTip title={t('actions')}>
        <IconButton style={styles.iconButton} onClick={toggleApplicantMenu}>
          {renderOneBadgeAtATime()}
        </IconButton>
      </ToolTip>
      <Menu
        id='fade-menu'
        anchorEl={anchorElement}
        open={isShowing}
        onClose={close}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={applicantInfo}>{t('applicant_info')}</MenuItem>
        <MenuItem onClick={meetingsInfo}>{t('entries')}</MenuItem>
        <MenuItem onClick={notesInfo}>
          <Badge color='primary' variant='dot' invisible={isNoteBadgeInvisible}>
            {t('notes')}
          </Badge>
        </MenuItem>
        <MenuItem onClick={applicantStatus}>{t('change_applicant_status')}</MenuItem>
        {props.rollbackText && (
          <MenuItem onClick={rollback}>
            {props.rollbackText}
          </MenuItem>
        )}
        <Badge badgeContent={<PolicyIcon />} invisible={!props.safeModeOn} style={styles.menuIconBadge}>
          <MenuItem onClick={toggleSafeMode}>{props.safeModeOn ? t('safe_mode_off') : t('safe_mode_on')}</MenuItem>
        </Badge>
      </Menu>
    </Fragment>
  )
}

export default ApplicantMenu
