import axios from 'axios'
import download from 'downloadjs'
import { getTokenSilently } from '../authConfig'

export const isOkResponse = (response) => response.status === 200 || response.status === 201 || response.status === 202

const specialQryUrls = ['Coachings/ResultsToFile']
const specialCmdUrls = ['Users/FindIdByName', 'Coachings/EnrollFromExcelTemplate']

export const isQryRequest = (method, url) => {
  if (method !== 'get') { // If is not get method, it's most likely not a Query api call
    if (specialQryUrls.find(specialUrl => url.includes(specialUrl))) return true // Unless it's a special url within Qry api
    else return false
  } else { // So most likely the call is to Qry api
    if (specialCmdUrls.find(specialUrl => url.includes(specialUrl))) return false // If method get but actually a special Cmd api url
    else return true
  }
}
export const isCmdRequest = (method, url) => {
  if (method !== 'get') { // If is not get method, it's most likely a Command api call
    if (specialQryUrls.find(specialUrl => url.includes(specialUrl))) return false // Unless it's a special url within Qry api
    else return true
  } else { // Most likely a call to Qry api
    if (specialCmdUrls.find(specialUrl => url.includes(specialUrl))) return true // Unless it's special url within Cmd api
    return false
  }
}

// API uses repeat format for querystring array params

const stagCommandApi = (path = '') => `https://springer-stag-cmd-api.azurewebsites.net/Api/1/${path}`
const stagQueryApi = (path = '') => `https://springer-stag-qry-api.azurewebsites.net/Api/1/${path}`

const devCommandApi = (path = '') => `https://localhost:44359/Api/1/${path}`
const devQueryApi = (path = '') => `https://localhost:44371/Api/1/${path}`

const prodCommandApi = (path = '') => `https://springer-prod-cmd-api.azurewebsites.net/Api/1/${path}`
const prodQueryApi = (path = '') => `https://springer-prod-qry-api.azurewebsites.net/Api/1/${path}`

// Choose environment: dev | stag | prod
export const environment = 'prod'

let command
let query
let messageUrl

if (environment === 'dev') {
  command = devCommandApi
  query = devQueryApi
  messageUrl = 'https://localhost:44359/api/Messaging'
} else if (environment === 'stag') {
  command = stagCommandApi
  query = stagQueryApi
  messageUrl = 'https://springer-stag-cmd-api.azurewebsites.net/api/Messaging'
} else {
  command = prodCommandApi
  query = prodQueryApi
  messageUrl = 'https://springer-prod-cmd-api.azurewebsites.net/api/Messaging'
}

/* We don't need this template file to keep in redux so this is a separate API call */
export const getImportApplicantsTemplate = async () => {
  const token = await getTokenSilently('get', 'Coachings/EnrollFromExcelTemplate')
  if (token) {
    return window.fetch(command('Projects/Coachings/EnrollFromExcelTemplate'), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => response.blob())
      .then(blob => download(blob))
      .catch((error, statusCode) => { return error })
  } else {
    return null
  }
}

export const getCoachingAttachmentFile = async (coachingId, fileId, fileName) => {
  const token = await getTokenSilently('get', `Coachings/${coachingId}/CoachingFiles/${fileId}`)
  if (token) {
    return window.fetch(query(`Coachings/${coachingId}/CoachingFiles/${fileId}`), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        return response.blob()
      })
      .then(blob => download(blob, fileName))
      .catch((error, statusCode) => { return error })
  } else {
    return null
  }
}

export const getProjectAttachmentFile = async (projectId, fileId, fileName) => {
  const token = await getTokenSilently('get', `Projects/${projectId}/ProjectFiles/${fileId}`)
  if (token) {
    return window.fetch(query(`Projects/${projectId}/ProjectFiles/${fileId}`), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        return response.blob()
      })
      .then(blob => download(blob, fileName))
      .catch((error, statusCode) => { return error })
  } else {
    return null
  }
}

const create = () => {
  const api = axios.create({
    baseURL: '...',
    timeout: 60000
  })

  // Check and acquire a token before the request is sent
  api.interceptors.request.use(async (config) => {
    try {
      const token = await getTokenSilently(config.method, config.url)
      config.headers.Authorization = `Bearer ${token}`
      return config
    } catch (e) {
      console.log('Jotain meni pieleen auth tokenien kanssa!')
      console.error(e)
      return config
    }
  })

  const checkAdUserRegistration = () => api.put(command('Users/CheckADUserRegistration'))
  const getAreas = () => api.get(query('Areas'))
  const getApplicants = (params) => api.get(query('Coachings/Talents'), { params })
  const setApplicantTrainer = (projectId, body) => api.post(
    command(`Projects/${projectId}/Coachings/StartCoaching`),
    body
  )
  const setApplicantStartDate = (projectId, body) => api.post(
    command(`Projects/${projectId}/Coachings/StartCoaching`),
    body
  )
  const sendMessage = (body) => api.post(messageUrl, body)
  const endService = (projectId, body) => api.post(
    command(`Projects/${projectId}/Coachings/EndCoaching`),
    body
  )
  const getCoachingResults = () => api.get(query('Coachings/Results'))
  const getRegionsWithCities = () => api.get(query('Finland/Regions/Cities'))
  const getTalentStatuses = () => api.get(query('Coachings/TalentStatuses'))
  const exportApplicantsToExcel = (params) => api.get(
    query('Coachings/ResultsToFile'),
    { params, responseType: 'blob' }
  )
  const exportCertificateTemplateExcel = (params) => api.get(
    query('Coachings/ReportCardsToFile'),
    { params, responseType: 'blob' }
  )
  const getServicesOptions = () => api.get(query('Products/Options'))
  const getProjectsOptions = () => api.get(query('Projects/All'))
  const getCoaches = () => api.get(query('Users/Coaches'))
  const rollbackApplicant = (projectId, talentId) => api.put(
    command(`Projects/${projectId}/Coachings/RollBackCoachingStatus?talent=${talentId}`)
  )
  const rollbackApplicantToStatus = (projectId, rollbackModel) => api.put(
    command(`Projects/${projectId}/Coachings/RollBackCoachingStatusToStatus`), rollbackModel
  )
  const changeProject = (projectId, body) => api.post(
    command(`Projects/${projectId}/Coachings/ChangeCoachingProject`),
    body
  )

  const getProjectsInCity = (city) => api.get(query(`Projects/ForEnrolment?city=${city}`))

  const setUserCity = (id, city) => api.put(command(`Users/${id}/SetCity?city=${encodeURIComponent(city)}`))

  const setUserEmail = (id, email) => api.put(
    command(`Users/${id}/SetEmailAddress?emailAddress=${encodeURIComponent(email)}`)
  )

  const setUserPhoneNumber = (id, phoneNumber) => api.put(
    command(`Users/${id}/SetPhoneNumber?phoneNumber=${encodeURIComponent(phoneNumber)}`)
  )

  const setUserNames = (id, firstNames, lastName) => api.put(
    command(`Users/${id}?firstNames=${encodeURIComponent(firstNames)}&lastName=${encodeURIComponent(lastName)}`)
  )

  const setAdditionalInfo = (projectId, userId, body) => api.put(
    command(`Projects/${projectId}/Talents/${userId}/AdditionalInfo`),
    body
  )

  const getExcelFilterId = (body) => api.post(query('Coachings/ResultsToFile'), body)

  const getMessages = (params) => api.get(query('Messages/AllFiltered'), { params })

  const getMeetings = (params) => api.get(query('Coachings/Meetings'), { params })

  const createMeeting = (projectId, body) => api.post(
    command(`Projects/${projectId}/Coachings/AddMeeting`),
    body
  )

  const editMeeting = (projectId, meetingId, body) => api.put(
    command(`Projects/${projectId}/Coachings/Meetings/${meetingId}/EditMeeting`),
    body
  )

  const cancelMeeting = (projectId, meetingId, body) => api.put(
    command(`Projects/${projectId}/Coachings/Meetings/${meetingId}/CancelMeeting`),
    body
  )

  const getMe = () => api.get(query('Users/Me'))

  const setTargetOccupations = (talentId, body) => api.put(
    command(`Talents/${talentId}/SetTargetProfessions`),
    body
  )

  const getApplicantInfo = (talentId, params) => api.get(query(`Coachings/Talents/${talentId}`), { params })

  const setApplicantInfo = (projectId, talentId, body) => api.put(
    command(`Projects/${projectId}/Talents/${talentId}/TalentInfo`),
    body
  )

  const getApplicantProfessions = (talentId, params) => api.get(query(`Talents/${talentId}/TargetProfessions`), {}, {})

  const getServices = (params) => api.get(query('Products/AllFiltered'), { params })

  /* This is used for create and edit */
  const putService = (body) => api.put(command(`Products/${body.id}?fi=${body.fi}&en=${body.en}`))

  const getProjects = (params) => api.get(query('Projects/AllFiltered'), { params })

  const getRegions = (params) => api.get(query('Finland/Regions'), { params })

  const getFinlandRegionsWithCities = () => api.get(query('Finland/Regions/CitiesAndEntireFinland'))

  const createProject = (body) => api.put(command(`Projects/${body.id}/Open`), body)

  const editProject = (body) => api.put(command(`Projects/${body.id}/Edit`), body)

  const getProject = (id) => api.get(query(`Projects/${id}`))

  const addCoach = (projectId, coachId) => api.put(command(`Projects/${projectId}/Coaches/${coachId}/AddCoach`))

  const removeCoach = (projectId, coachId) => api.put(command(`Projects/${projectId}/Coaches/${coachId}/RemoveCoach`))

  const addCoachingResult = (projectId, result) => api.put(command(`Projects/${projectId}/CoachingResults/AddCoachingResult`), result)

  const removeCoachingResult = (projectId, result) => api.put(command(`Projects/${projectId}/CoachingResults/RemoveCoachingResult`), result)

  const getNotes = (params) => api.get(query('Coachings/CoachingNotes'), { params })

  const passivateCoachingNote = (projectId, noteId) => api.put(command(`Projects/${projectId}/Coachings/PassivateNote?note=${noteId}`))

  const activateCoachingNote = (projectId, noteId) => api.put(command(`Projects/${projectId}/Coachings/ActivateNote?note=${noteId}`))

  const editCoachingNote = (body) => api.put(command(`Projects/${body.projectId}/Coachings/EditNote`), body)

  const addCoachingNote = (body) => api.post(command(`Projects/${body.projectId}/Coachings/AddNote`), body)

  const importSpringerApplicantsFile = (projectId, file) => api.post(command(`Projects/${projectId}/Coachings/EnrollFromExcel`), file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  const getCoachingAttachments = (coachingId) => api.get(query(`Coachings/${coachingId}/CoachingFiles`))

  const addCoachingAttachment = (projectId, coachingId, file) => api.post(command(`Projects/${projectId}/Coachings/${coachingId}/CoachingFiles`), file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  const deleteCoachingAttachment = (projectId, coachingId, coachingFileId) => api.delete(command(`Projects/${projectId}/Coachings/${coachingId}/CoachingFiles/${coachingFileId}`))

  const addCoachingEntry = (projectId, body) => api.post(command(`Projects/${projectId}/Coachings/AddCoachingEntry`), body)

  const getCoachingEntrySummaryText = (coachingId) => api.get(query(`Coachings/${coachingId}/CoachingEntrySummaryText`))

  const getCoachingEntries = (params) => api.get(query('Coachings/AllCoachingEntries'), { params })

  const editCoachingEntry = (projectId, coachingEntryId, body) => api.put(command(`Projects/${projectId}/Coachings/CoachingEntries/${coachingEntryId}/EditCoachingEntry`), body)

  const deleteCoachingEntry = (projectId, coachingEntryId) => api.delete(command(`Projects/${projectId}/Coachings/CoachingEntries/${coachingEntryId}/DeleteCoachingEntry`))

  const importKoulutusporttiApplicantsFile = (projectId, file) => api.post(command(`Projects/${projectId}/Coachings/EnrollFromKoulutusportti`), file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  const getPostalCodes = () => api.get(query('PostalCodes/Options'), { })

  const searchPostalCodes = (searchCode) => api.get(query(`PostalCodes/Find?searchCode=${searchCode}`))

  const getCountries = () => api.get(query('Countries/Options'))

  const getLanguages = () => api.get(query('Languages/Options'))

  const getLanguageSkillLevels = () => api.get(query('Languages/LanguageSkillLevels/Options'))

  const getApplicantLanguageSkills = (coachingId) => api.get(query(`Coachings/CoachingLanguages?coachingId=${coachingId}`))

  const getLanguageSkillOptions = () => api.get(query('Languages/LanguageSkills/Options'))

  const addCoachingLanguageSkill = (projectId, body) => api.post(command(`Projects/${projectId}/Coachings/AddCoachingLanguage`), body)

  const editCoachingLanguageSkill = (projectId, coachingLanguageId, body) => api.put(command(`Projects/${projectId}/Coachings/CoachingLanguages/${coachingLanguageId}/EditCoachingLanguage`), body)

  const deleteCoachingLanguageSkill = (projectId, coachingLanguageId) => api.delete(command(`Projects/${projectId}/Coachings/CoachingLanguages/${coachingLanguageId}/DeleteCoachingLanguage`), {})

  const getCooperationTypes = () => api.get(query('Coachings/CooperationTypes/Options'))

  const addInternship = (projectId, body) => api.post(command(`Projects/${projectId}/Coachings/AddCoachingEmployment`), body)

  const getApplicantInternships = (coachingId) => api.get(query(`Coachings/CoachingEmployments?coachingId=${coachingId}`))

  const editInternship = (projectId, internshipId, body) => api.put(command(`Projects/${projectId}/Coachings/CoachingEmployments/${internshipId}/EditCoachingEmployment`), body)

  const deleteInternship = (projectId, internshipId) => api.delete(command(`Projects/${projectId}/Coachings/CoachingEmployments/${internshipId}/DeleteCoachingEmployment`), {})

  const addProjectAttachment = (projectId, file) => api.post(command(`Projects/${projectId}/ProjectFiles`), file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  const getProjectAttachments = (projectId) => api.get(query(`Projects/${projectId}/ProjectFiles`))

  const deleteProjectAttachment = (projectId, fileId) => api.delete(command(`Projects/${projectId}/ProjectFiles/${fileId}`))

  const startInitialAssessment = (body) => api.put(command('Projects/Coachings/StartInitialAssessment'), body)

  const endInitialAssessment = (body) => api.put(command('Projects/Coachings/EndInitialAssessment'), body)

  const getAdmins = () => api.get(query('Users/Admins'), {})

  return {
    checkAdUserRegistration,
    getAreas,
    getApplicants,
    setApplicantTrainer,
    setApplicantStartDate,
    sendMessage,
    endService,
    getCoachingResults,
    getRegionsWithCities,
    getTalentStatuses,
    exportApplicantsToExcel,
    exportCertificateTemplateExcel,
    getServicesOptions,
    getProjectsOptions,
    getCoaches,
    rollbackApplicant,
    changeProject,
    getProjectsInCity,
    setUserCity,
    setUserEmail,
    setUserPhoneNumber,
    setUserNames,
    setAdditionalInfo,
    getExcelFilterId,
    getMessages,
    getMeetings,
    createMeeting,
    editMeeting,
    cancelMeeting,
    getMe,
    setTargetOccupations,
    getApplicantInfo,
    setApplicantInfo,
    getApplicantProfessions,
    getServices,
    putService,
    getProjects,
    getRegions,
    getFinlandRegionsWithCities,
    createProject,
    editProject,
    getProject,
    addCoach,
    removeCoach,
    addCoachingResult,
    removeCoachingResult,
    getNotes,
    passivateCoachingNote,
    activateCoachingNote,
    editCoachingNote,
    addCoachingNote,
    rollbackApplicantToStatus,
    importSpringerApplicantsFile,
    getCoachingAttachments,
    addCoachingAttachment,
    deleteCoachingAttachment,
    addCoachingEntry,
    getCoachingEntrySummaryText,
    getCoachingEntries,
    editCoachingEntry,
    deleteCoachingEntry,
    importKoulutusporttiApplicantsFile,
    getPostalCodes,
    searchPostalCodes,
    getCountries,
    getLanguages,
    getLanguageSkillLevels,
    getApplicantLanguageSkills,
    getLanguageSkillOptions,
    addCoachingLanguageSkill,
    editCoachingLanguageSkill,
    deleteCoachingLanguageSkill,
    getCooperationTypes,
    addInternship,
    getApplicantInternships,
    editInternship,
    deleteInternship,
    addProjectAttachment,
    getProjectAttachments,
    deleteProjectAttachment,
    startInitialAssessment,
    endInitialAssessment,
    getAdmins
  }
}

export default {
  create
}
