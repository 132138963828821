import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import DatePicker from '../DatePicker'
import DialogHeader from '../DialogHeader'
import { COLORS } from '../../utils/colors'
import { getObjectNameTranslation } from '../../utils/transforms'
import DialogContentWrapper from '../DialogContentWrapper'
import SaveButton from '../SaveButton'
import { isApplicantStatusActive, isApplicantStatusEnded, getApplicantName, isApplicantStatusEnrolled, isApplicantStatusHardEnded } from '../../utils/applicant'
import { customReducer } from '../../utils/reducer'

const styles = theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500,
      margin: 20
    }
  },
  title: {
    padding: '16px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  actionsWrapper: {
    margin: 0,
    padding: '6px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  cancelButton: {
    marginRight: 10
  },
  spinner: {
    color: COLORS.white
  },
  formControl: {
    width: '100%',
    marginTop: 10
  },
  marginTop10: {
    marginTop: 10
  },
  marginTop20: {
    marginTop: 20
  },
  datePicker: {
    width: '100%',
    '& input[type=text]': {
      fontSize: '1rem',
      color: COLORS.label
    }
  },
  active: {
    color: COLORS.greenDarken
  },
  enrolled: {
    color: COLORS.orange
  },
  ended: {
    color: COLORS.red
  },
  labelColor: {
    color: COLORS.label,
    display: 'inline-block'
  },
  newStatus: {
    width: '100%',
    marginTop: 10
  },
  notificationText: {
    color: COLORS.red,
    marginTop: 20
  }
})

const ApplicantStatusDialog = (props) => {
  const [t] = useTranslation()
  const initialState = {
    result: '',
    newStatusSelection: null,
    coach: '',
    start: null,
    end: null
  }
  const reducer = (state, newState) => customReducer(state, newState, initialState)
  const [state, setState] = useReducer(reducer, initialState)
  const statuses = {
    ended: 'Ended',
    active: 'Active',
    enrolled: 'Enrolled'
  }

  const getEndResultOptions = () => {
    if (props.applicant) {
      const { coachingResultOptions } = props.applicant
      if (coachingResultOptions.length === 0) {
        return <MenuItem disabled value=''> {t('no_coaching_results')}</MenuItem>
      }
      return coachingResultOptions.map((item) => (
        <MenuItem value={item.id} key={item.id}>
          {getObjectNameTranslation(item.name)}
        </MenuItem>
      ))
    }
  }

  const getCoachOptions = () => {
    if (props.applicant) {
      const { coachOptions } = props.applicant
      return coachOptions.map((item) => (
        <MenuItem value={item.id} key={item.id}>
          {`${item.firstName} ${item.lastName}`}
        </MenuItem>
      ))
    }
  }

  const setNewStatusSelection = (e) => setState({ newStatusSelection: e.target.value })
  const setResult = (e) => setState({ result: e.target.value })
  const setEndDate = (end) => setState({ end })
  const setCoach = (e) => setState({ coach: e.target.value })
  const setStartDate = (start) => setState({ start })

  const getActivityColorClass = (applicant) => {
    const { classes } = props
    if (isApplicantStatusEnded(applicant)) return classes.ended
    else if (isApplicantStatusActive(applicant)) return classes.active
    else return classes.enrolled
  }

  const getRollbackStatusApiModel = (targetStatus) => {
    if (targetStatus === statuses.active) {
      return props.talentStatuses.find(status => status.en === statuses.active)
    } else if (targetStatus === statuses.enrolled) {
      return props.talentStatuses.find(status => status.en === statuses.enrolled)
    }
  }

  const handleSave = () => {
    const { newStatusSelection, result, end, start, coach } = state
    const { projectId, userId } = props.applicant

    // End service
    if (newStatusSelection === statuses.ended) props.endService(projectId, userId, result, end)
    // Activate service
    if (newStatusSelection === statuses.active) {
      // From enrolled to active
      if (isApplicantStatusEnrolled(props.applicant)) props.activateService(projectId, userId, coach, start)
      // From ended to active
      else if (isApplicantStatusEnded(props.applicant)) props.rollbackService(projectId, { talent: userId, status: getRollbackStatusApiModel(statuses.active) })
    }
    // Enroll service
    if (newStatusSelection === statuses.enrolled) props.rollbackService(projectId, { talent: userId, status: getRollbackStatusApiModel(statuses.enrolled) })
    handleClose()
  }

  const handleClose = () => {
    props.close()
    setState({})
  }

  const isChangingFromStraightEndedToActive = () => state.newStatusSelection === statuses.active && isApplicantStatusHardEnded(props.applicant)

  const shouldSaveBeDisabled = () => {
    return props.fetching || stateSelectionSameAsCurrentState() || state.newStatusSelection == null ||
    (isChangingFromStraightEndedToActive() ||
    // If activation form from enrolled to active
    (!isChangingFromStraightEndedToActive() && isApplicantStatusEnrolled(props.applicant) && state.newStatusSelection === statuses.active && state.start == null) ||
    // If ending form
    (state.newStatusSelection === statuses.ended && (state.end == null || state.result === '')))
  }

  const stateSelectionSameAsCurrentState = () => {
    const { applicant } = props
    const { newStatusSelection } = state
    if (newStatusSelection === statuses.enrolled) return isApplicantStatusEnrolled(applicant)
    else if (newStatusSelection === statuses.active) return isApplicantStatusActive(applicant)
    else if (newStatusSelection === statuses.ended) return isApplicantStatusEnded(applicant)
    else return false
  }

  const getCurrentStatus = () => props.applicant && props.applicant.statusString ? getObjectNameTranslation(props.applicant.statusString) : ''

  const renderApplicantInformation = () => {
    const { applicant, classes } = props
    return (
      <div className={classes.applicant}>
        <Typography className={classes.labelColor}>{`${t('applicant')}:`}</Typography>
        <Typography className={classes.applicantName}>{`${getApplicantName(applicant)}`}</Typography>
        <Typography className={getActivityColorClass(applicant)}>{getCurrentStatus()}</Typography>
      </div>
    )
  }

  const renderStatusNotification = (applicantAlreadyHasStatus) => {
    if (applicantAlreadyHasStatus) {
      return <Typography className={props.classes.notificationText}>{t('applicant_already_has_status')}</Typography>
    } else if (isChangingFromStraightEndedToActive()) {
      // Can't change applicant status from ended to active right now
      return <Typography className={props.classes.notificationText}>{t('cant_change_from_ended_to_active')}</Typography>
    } else return null
  }

  const renderEndService = () => {
    const isApplicantEnded = props.applicant.end
    const begin = props.applicant.begin || null
    return (
      <>
        <Box className={props.classes.marginTop10}>
          {renderStatusNotification(isApplicantEnded)}
          <FormControl className={props.classes.formControl}>
            <InputLabel htmlFor='result' required>{t('select_result')}</InputLabel>
            <Select value={state.result} onChange={setResult} input={<Input id='result' />}>
              {getEndResultOptions()}
            </Select>
          </FormControl>
        </Box>
        <Box className={props.classes.marginTop10}>
          <FormControl className={props.classes.formControl}>
            <DatePicker
              className={props.classes.datePicker}
              required
              value={state.end}
              minDate={begin}
              placeholder={t('end_date')}
              onChange={setEndDate}
            />
          </FormControl>
        </Box>
      </>
    )
  }

  const renderActivateService = () => {
    const isApplicantActive = props.applicant.begin && props.applicant.end == null
    if (isApplicantStatusEnrolled(props.applicant)) {
      return (
        <Box className={props.classes.marginTop10}>
          {renderStatusNotification(isApplicantActive)}
          <FormControl className={props.classes.formControl}>
            <InputLabel id='select_trainer_label'>{t('select_trainer')}</InputLabel>
            <Select labelId='select_trainer_label' value={state.coach} onChange={setCoach} input={<Input id='coach' />}>
              {getCoachOptions()}
            </Select>
          </FormControl>
          {renderActivateDatePicker()}
        </Box>
      )
    } else return renderStatusNotification(isApplicantActive)
  }

  const renderActivateDatePicker = () => {
    // Date picker is needed only when changing from enrolled to active
    if (isApplicantStatusEnrolled(props.applicant)) {
      return (
        <Box className={props.classes.marginTop10}>
          <FormControl className={props.classes.formControl}>
            <DatePicker
              className={props.classes.datePicker}
              required
              value={state.start}
              minDate={begin}
              placeholder={t('start_date')}
              onChange={setStartDate}
            />
          </FormControl>
        </Box>
      )
    } else return null
  }

  const renderEnrollService = () => {
    const isApplicantEnrolled = props.applicant.enrolled && props.applicant.begin == null && props.applicant.end == null
    return (
      <form>
        {renderStatusNotification(isApplicantEnrolled)}
      </form>
    )
  }

  const renderNewStatusDetailsForm = () => {
    const { newStatusSelection } = state
    if (newStatusSelection === statuses.ended) {
      return renderEndService()
    } else if (newStatusSelection === statuses.active) {
      return renderActivateService()
    } else if (newStatusSelection === statuses.enrolled) {
      return renderEnrollService()
    } else return null
  }

  const renderNewStatusSelector = () => {
    return (
      <FormControl className={props.classes.newStatus}>
        <InputLabel id='result' required>{t('select_new_status')}</InputLabel>
        <Select labelId='result' value={state.newStatusSelection} onChange={setNewStatusSelection} input={<Input id='newStatus' />}>
          <MenuItem value={statuses.enrolled}>
            {t('enrolled')}
          </MenuItem>
          <MenuItem value={statuses.active}>
            {t('active')}
          </MenuItem>
          <MenuItem value={statuses.ended}>
            {t('ended')}
          </MenuItem>
        </Select>
      </FormControl>
    )
  }

  const begin = props.applicant ? new Date(props.applicant.begin) : null

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      classes={{ paper: props.classes.root }}
      fullWidth
    >
      <DialogHeader
        handleClose={handleClose}
        label='change_applicant_status'
      />
      <DialogContent dividers>
        <DialogContentWrapper>
          {renderApplicantInformation()}
          {renderNewStatusSelector()}
          {renderNewStatusDetailsForm()}
        </DialogContentWrapper>
      </DialogContent>
      <DialogActions classes={{ root: props.classes.actionsWrapper }}>
        <Button onClick={handleClose} className={props.classes.cancelButton}>
          {t('cancel')}
        </Button>
        <SaveButton
          label='save'
          fetching={props.fetching}
          disabled={shouldSaveBeDisabled()}
          handleSave={handleSave}
        />
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)((ApplicantStatusDialog))
