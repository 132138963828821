import React, { useReducer, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete, { getTrainerOptions } from '../Autocomplete'
import FormTextField from '../FormTextField'
import FormLabel from '../FormLabel'
import Spinner from '../Spinner'
import DialogContentWrapper from '../DialogContentWrapper'
import FormFieldWrapper from '../FormFieldWrapper'
import CustomDatePicker from '../DatePicker'
import { storageTimes } from '../../constants/passiveStorageTimes'
import { isAdmin } from '../../utils/permissions'

const styles = {
  root: {
    maxWidth: 800
  },
  textField: {
    marginTop: 8,
    marginBottom: 8
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  dateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 40
  },
  datepicker: {
    width: 200,
    '& input[type=text]': {
      fontSize: 16
    }
  }
}

const isProjectInfoSame = (prevProps, nextProps) => {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return isEqual(prevProps.projectInfo, nextProps.projectInfo)
}
const EditBasicDetails = React.memo((props) => {
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, props.initialState)
  const [t] = useTranslation()
  const isAdminUser = isAdmin(props.user)

  useEffect(() => {
    if (props.projectInfo) setProjectInfoToState()
  }, [props.projectInfo])

  useEffect(() => {
    const params = { ...state }
    props.basicDetailsChanged(params)
  }, [state.nameEn, state.nameFi, state.area, state.service, state.backgroundHelpEn, state.backgroundHelpFi, state.code, state.descriptionEn, state.descriptionFi, state.start, state.end, state.passiveStorageTime, state.responsiblePersonId])

  useEffect(() => {
    if (!isEmpty(props.projectInfo)) setProjectInfoToState()
  }, [props.projectInfo])

  const setProjectInfoToState = () => {
    const { projectInfo } = props
    setState({
      nameFi: projectInfo.name.fi,
      nameEn: isEmpty(projectInfo.name.en) ? '' : projectInfo.name.en,
      service: projectInfo.serviceId,
      area: projectInfo.areaId,
      backgroundHelpEn: isEmpty(projectInfo.backgroundHelp) ? '' : projectInfo.backgroundHelp.en,
      backgroundHelpFi: isEmpty(projectInfo.backgroundHelp) ? '' : projectInfo.backgroundHelp.fi,
      code: isEmpty(projectInfo.code) ? '' : projectInfo.code,
      descriptionEn: isEmpty(projectInfo.description) ? '' : projectInfo.description.en,
      descriptionFi: isEmpty(projectInfo.description) ? '' : projectInfo.description.fi,
      start: projectInfo.start,
      end: projectInfo.end,
      passiveStorageTime: projectInfo.passiveStorageTime,
      responsiblePersonId: isEmpty(projectInfo.responsiblePerson) ? '' : projectInfo.responsiblePerson.id
    })
  }

  const getPassiveStorageTimeOptions = () => {
    const times = [
      <MenuItem value='' key='0'>-</MenuItem>
    ]
    for (const key in storageTimes) {
      if (Object.prototype.hasOwnProperty.call(storageTimes, key) && storageTimes[key] != null) {
        times.push(
          <MenuItem value={storageTimes[key]} key={storageTimes[key]}>
            {`${key} ${t('y')}`}
          </MenuItem>
        )
      }
    }
    return times
  }

  const setNameFi = (e) => setState({ nameFi: e.target.value })
  const setNameEn = (e) => setState({ nameEn: e.target.value })
  const setCode = (e) => setState({ code: e.target.value })
  const setArea = (area) => setState({ area })
  const setBackgroundHelpFi = (e) => setState({ backgroundHelpFi: e.target.value })
  const setBackgroundHelpEn = (e) => setState({ backgroundHelpEn: e.target.value })
  const setDescriptionFi = (e) => setState({ descriptionFi: e.target.value })
  const setDescriptionEn = (e) => setState({ descriptionEn: e.target.value })
  const handleServiceChange = (service) => setState({ service })
  const setStart = (start) => setState({ start })
  const setEnd = (end) => setState({ end })
  const setPassiveStorageTime = (e) => setState({ passiveStorageTime: e.target.value })
  const setResponsible = (responsiblePersonId) => setState({ responsiblePersonId })

  const renderBasicInformationContent = () => {
    const { fetching, services, regions } = props
    if (!fetching) {
      const { nameFi, nameEn, code, area, service, descriptionFi, descriptionEn, backgroundHelpFi, backgroundHelpEn, start, end, passiveStorageTime, responsiblePersonId } = state
      return (
        <DialogContentWrapper>
          <FormFieldWrapper>
            <FormTextField
              style={styles.textField}
              label={t('name_fi')}
              value={nameFi}
              onChange={setNameFi}
              fullWidth
              required
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              style={styles.textField}
              label={t('name_en')}
              value={nameEn}
              onChange={setNameEn}
              fullWidth
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormLabel text={t('service')} />
            <Autocomplete
              value={service}
              options={[...services]}
              onChange={handleServiceChange}
              placeholder={`${t('select_service')}...`}
              largeText
              margin='form'
              required
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              style={styles.textField}
              label={t('code')}
              value={code}
              onChange={setCode}
              fullWidth
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <Typography variant='caption'>{`${t('passive_phase_storage_time')} *`}</Typography>
            <Select
              value={passiveStorageTime}
              onChange={setPassiveStorageTime}
              fullWidth
              disabled={!isAdminUser}
              variant='standard'
            >
              {getPassiveStorageTimeOptions()}
            </Select>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Typography variant='caption'>{`${t('responsible_trainer')} *`}</Typography>
            <Autocomplete
              largeText
              value={responsiblePersonId}
              onChange={setResponsible}
              options={getTrainerOptions(props.coachOptions)}
              noOptionsMessage={() => t('no_trainers_available')}
              placeholder={`${t('select')}...`}
            />
          </FormFieldWrapper>
          <div style={styles.row}>
            <FormFieldWrapper style={styles.dateWrapper}>
              <Typography variant='caption' style={styles.caption}>{t('start_date')}</Typography>
              <CustomDatePicker
                value={start}
                onChange={setStart}
                maxDate={end}
                placeholder={t('select')}
                style={styles.datepicker}
              />
            </FormFieldWrapper>
            <FormFieldWrapper style={styles.dateWrapper}>
              <Typography variant='caption' style={styles.caption}>{`${t('end_date')} *`}</Typography>
              <CustomDatePicker
                value={end}
                onChange={setEnd}
                minDate={start}
                placeholder={t('select')}
                style={styles.datepicker}
              />
            </FormFieldWrapper>
          </div>
          <FormFieldWrapper>
            <FormLabel text={t('area')} />
            <Autocomplete
              value={area}
              options={[...regions]}
              groupBy={(option) => option.region}
              onChange={setArea}
              placeholder={`${t('select_area')}...`}
              largeText
              margin='form'
              required
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              style={styles.textField}
              label={t('description_fi')}
              value={descriptionFi}
              onChange={setDescriptionFi}
              rows={3}
              rowsMax={8}
              multiline
              fullWidth
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              style={styles.textField}
              label={t('description_en')}
              value={descriptionEn}
              onChange={setDescriptionEn}
              rows={3}
              rowsMax={8}
              multiline
              fullWidth
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              style={styles.textField}
              label={t('background_help_fi')}
              value={backgroundHelpFi}
              onChange={setBackgroundHelpFi}
              rows={5}
              rowsMax={8}
              multiline
              fullWidth
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              style={styles.textField}
              label={t('background_help_en')}
              value={backgroundHelpEn}
              onChange={setBackgroundHelpEn}
              rows={5}
              rowsMax={8}
              multiline
              fullWidth
            />
          </FormFieldWrapper>
        </DialogContentWrapper>
      )
    } else {
      return (
        <Spinner centered />
      )
    }
  }

  return renderBasicInformationContent()
}, isProjectInfoSame)

export default EditBasicDetails
