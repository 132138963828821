import React from 'react'
import { withTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'

const isValueSame = (prevProps, nextProps) => {
  /*
  return true if passing nextProps.value to render would return
  the same result as passing prevProps.value to render,
  otherwise return false
  */
  return prevProps.value === nextProps.value
}
const styles = {
  textfield: {
    fontSize: '0.875rem'
  }
}
// Use memoization to avoid unnecessary re-renderings (value not changed)
const FormTextField = React.memo((props) => {
  const keyPress = (e) => {
    if (props.onEnterPress != null) {
      if (e.key === 'Enter') { // On Enter key press
        e.preventDefault()
        props.onEnterPress(e.target.value)
      }
    }
  }
  return (
    <TextField
      label={props.label}
      placeholder={props.placeholder}
      variant='standard'
      value={props.value}
      onChange={props.onChange}
      fullWidth={props.fullWidth}
      rows={props.rows}
      multiline={props.multiline}
      required={props.required}
      InputLabelProps={{ style: styles.textfield }}
      onKeyDown={keyPress}
      style={props?.styles}
    />
  )
}, isValueSame)
export default withTranslation()(FormTextField)
