import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Box, Typography } from '@mui/material'
import { COLORS } from '../../utils/colors'
import { isMeetingEntry, isNoteEntry, isSummaryEntry } from '../../utils/entries'
import { format } from 'date-fns'

const useStyles = makeStyles(theme => ({
  entryBox: {
    borderTopWidth: 1,
    borderTopColor: COLORS.lightBorder,
    borderTopStyle: 'solid',
    minHeight: 200
  },
  label: {
    color: COLORS.label,
    fontSize: 14,
    marginTop: 16
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    justifyContent: 'end'
  },
  description: {
    whiteSpace: 'pre-line'
  }
}))

export default function EntryView (props) {
  const classes = useStyles()
  const [t] = useTranslation()

  const getEntryType = () => {
    const { entry } = props
    if (entry.entryType == null || isMeetingEntry(entry.entryType)) return t('meeting')
    else if (isNoteEntry(entry.entryType)) return t('note')
    else if (isSummaryEntry(entry.entryType)) return t('summary')
  }

  const getTime = (entry) => {
    if (!entry.start) return null
    else if (entry.start && !entry.end) {
      return format(new Date(entry.start), t('time_format'))
    } else if (entry.start && entry.end) {
      return `${format(new Date(entry.start), t('time_format'))} - ${format(new Date(entry.end), t('time_format'))}`
    } else return '-'
  }

  const renderDateTimes = () => {
    const { entry } = props
    if (entry.entryType == null || isMeetingEntry(entry.entryType)) {
      return (
        <div className={classes.row}>
          <div className={classes.col}>
            <Typography className={classes.label}>{t('date')}</Typography>
            <Typography>{entry.start ? format(new Date(entry.start), t('date_format')) : '-'}</Typography>
          </div>
          <div className={classes.col}>
            <Typography className={classes.label}>{t('clock')}</Typography>
            <Typography>{getTime(entry)}</Typography>
          </div>
        </div>
      )
    } else if (isNoteEntry(entry.entryType)) {
      return (
        <div className={classes.row}>
          <div className={classes.col}>
            <Typography className={classes.label}>{t('date')}</Typography>
            <Typography>{entry.start ? format(new Date(entry.start), t('date_format')) : '-'}</Typography>
          </div>
          <div className={classes.col}>
            <Typography className={classes.label}>{t('clock')}</Typography>
            <Typography>{getTime(entry)}</Typography>
          </div>
        </div>
      )
    } else if (isSummaryEntry(entry.entryType)) {
      return (
        <div className={classes.row}>
          <div className={classes.col}>
            <Typography className={classes.label}>{t('date')}</Typography>
            <Typography>{entry.start ? format(new Date(entry.start), t('date_format')) : '-'}</Typography>
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <Box className={classes.entryBox}>
      <Typography className={classes.label}>{t('type')}</Typography>
      <Typography>{getEntryType()}</Typography>
      <Typography className={classes.label}>{t('applicant')}</Typography>
      <Typography>{`${props.entry.talentFirstNames} ${props.entry.talentLastName}`}</Typography>
      <Typography className={classes.label}>{t('trainer')}</Typography>
      <Typography>{`${props.entry.coachFirstNames} ${props.entry.coachLastName}`}</Typography>
      {renderDateTimes()}
      <Typography className={classes.label}>{t('description')}</Typography>
      <Typography className={classes.description}>{props.entry.description || '-'}</Typography>
    </Box>
  )
}
