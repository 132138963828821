import { getObjectNameTranslation } from './transforms'

export const isApplicantStatusStringEnded = (status) => {
  if (status && (getObjectNameTranslation(status) === 'Päättynyt' || getObjectNameTranslation(status) === 'Ended')) return true
  else return false
}

export const isApplicantStatusEnrolled = (applicant) => {
  if (applicant && applicant.end == null && applicant.begin == null) return true
  else return false
}

export const isApplicantStatusActive = (applicant) => {
  if (applicant && applicant.begin != null && applicant.end == null) return true
  else return false
}

export const isApplicantStatusEnded = (applicant) => {
  if (applicant && applicant.end != null) return true
  return false
}

export const isApplicantStatusHardEnded = (applicant) => {
  if (applicant && applicant.end != null && applicant.begin == null) return true
  return false
}

export const getApplicantName = (applicant) => {
  if (applicant) {
    return `${applicant.firstNames} ${applicant.lastName}`
  } else return ''
}
