import React, { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import add from 'date-fns/add'
import sub from 'date-fns/sub'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import isValid from 'date-fns/isValid'
import Autocomplete from '../Autocomplete'
import FormFieldWrapper from '../FormFieldWrapper'
import OccupationPickerMenu from './OccupationPickerMenu'
import FormLabel from '../FormLabel'
import { COLORS } from '../../utils/colors'
import ESCOAPI from '../../services/escoApi'
import { getCurrentLang, getLocale, oldGetDateTimeForApi, getObjectNameTranslation, isDifferenceInDaysNegative } from '../../utils/transforms'
import { isEmpty } from 'lodash'
import DatePicker from '../DatePicker'

const styles = {
  root: {
    maxWidth: 800,
    alignItems: 'center'
  },
  content: {
    padding: '16px 24px',
    display: 'flex'
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  actionsWrapper: {
    padding: 16
  },
  divider: {
    padding: '0 8px'
  },
  spinner: {
    color: COLORS.white
  },
  entryBox: {
    minHeight: 200
  },
  textField: {
    marginTop: 1,
    marginBottom: 1
  },
  marginRight: {
    marginRight: 1
  },
  redColor: {
    color: COLORS.red,
    float: 'left'
  },
  actionsHolder: {
    justifyContent: 'space-between'
  },
  label: {
    fontSize: '1rem',
    display: 'block'
  },
  selectedOccupation: {
    display: 'inline'
  }
}

const EditInternship = (props) => {
  const [t] = useTranslation()
  const initialState = {
    projectId: props.projectId || null,
    coachingEmploymentId: props.internship ? props.internship.id : null
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  const [occupations, setOccupations] = useState([])
  const [errors, setErrors] = useState({})
  const minDate = state.start ? new Date(state.start) : sub(new Date(), { years: 5 })
  const maxDate = state.end ? new Date(state.end) : add(new Date(), { years: 5 })
  useEffect(() => {
    if (props.internship && props.open) {
      /* Get ESCO occupations */
      ESCOAPI().getTopLevelConcepts(getCurrentLang()).then(data => {
        const arr = data || []
        setOccupations(arr)
        // setFetchingOccupations(false)
        return data
      })
      // When internship prop is given and accordion is open, set it to form
      const editInternship = getDefaultStateObject()
      setState(editInternship)
    } else setState(initialState)
  }, [props.internship, props.open])

  const fetchOccupationChildren = (uri) => {
    return ESCOAPI().getChildOccupations(uri, getCurrentLang()).then(data => data)
  }

  const setCompanyName = (e) => setState({ companyName: e.target.value })
  const setCompanyType = (cooperationTypeId) => setState({ cooperationTypeId })
  const setStart = (start) => setState({ start })
  const setEnd = (end) => setState({ end })

  const handleOccupationCheck = (selectedOccupation) => setState({ selectedOccupation })

  const handleCancel = (form) => {
    // When internship prop is given, set it to form
    const editInternship = getDefaultStateObject()
    setState(editInternship)
  }

  const getDefaultStateObject = () => {
    const { internship } = props
    // If occupation selected
    const selectedOccupation = internship.escoGroup
      ? {
          uri: internship.escoUri,
          title: getObjectNameTranslation(internship.escoName),
          code: internship.escoGroup,
          preferredLabel: {
            en: internship.escoName.en,
            fi: internship.escoName.fi
          }
        }
      : null
    return {
      companyName: internship.companyName,
      cooperationTypeId: internship.cooperationType.id,
      start: internship.start ? new Date(internship.start) : null,
      end: internship.end ? new Date(internship.end) : null,
      coachingId: internship.coachingId,
      selectedOccupation
    }
  }

  const confirmDelete = () => props.confirmDelete(props.internship)

  const validateForm = (values) => {
    const errors = {}
    /* Requirements */
    if (!values.companyName) errors.companyName = t('employment_required')
    if (!values.cooperationTypeId) errors.cooperationTypeId = t('type_required')
    /* If date set, check that date is valid */
    if (values.start && !isValid(values.start)) errors.start = t('date_invalid')
    if (values.end && !isValid(values.end)) errors.end = t('date_invalid')
    /* Field specific validations */
    if (values.start && values.end && isDifferenceInDaysNegative(values.start, values.end)) errors.end = t('end_date_cannot_be_before_start')
    return errors
  }

  const onSubmit = () => {
    const errors = validateForm(state)
    setErrors(errors)
    if (!props.loading && isEmpty(errors)) {
      const { companyName, cooperationTypeId, coachingId } = state
      const { projectId } = props
      const internship = { companyName, cooperationTypeId, coachingId }
      if (state.start) internship.start = oldGetDateTimeForApi(state.start)
      if (state.end) internship.end = oldGetDateTimeForApi(state.end)
      if (state.selectedOccupation) {
        internship.iscoGroupCode = state.selectedOccupation.code
        internship.escoNameEn = state.selectedOccupation.preferredLabel.en
        internship.escoNameFi = state.selectedOccupation.preferredLabel.fi
      }
      props.editInternship(projectId, props.internship.id, internship)
    }
  }

  if (!props.open) return null
  return (
    <Box sx={styles.entryBox}>
      <TextField
        value={state.companyName || ''}
        onChange={setCompanyName}
        label={`${t('internship_place')} *`}
        sx={styles.textField}
        name='companyName'
        fullWidth
        error={errors.companyName}
        helperText={errors.companyName ? errors.companyName : null}
        variant='standard'
      />
      <FormFieldWrapper>
        <Autocomplete
          value={state.cooperationTypeId}
          onChange={type => setCompanyType(type)}
          options={[...props.cooperationTypeOptions]}
          placeholder={t('type')}
          largeText
        />
      </FormFieldWrapper>
      <div style={{ marginTop: 10 }}>
        <DatePicker
          value={state.start}
          onChange={setStart}
          locale={getLocale()}
          name='start'
          inputFormat={t('date_format')}
          mask={t('date_format_mask')}
          cancelLabel={t('cancel')}
          emptyLabel={t('start_date')}
          sx={{ ...styles.datePicker, ...styles.marginRight }}
          invalidDateMessage={t('invalid_date_format')}
          margin='normal'
          fullWidth={false}
          maxDate={maxDate}
        />
        <Box component='span' sx={styles.divider}>-</Box>
        <DatePicker
          value={state.end}
          onChange={setEnd}
          locale={getLocale()}
          name='end'
          inputFormat={t('date_format')}
          mask={t('date_format_mask')}
          cancelLabel={t('cancel')}
          emptyLabel={t('end_date')}
          sx={{ ...styles.datePicker, ...styles.marginRight }}
          invalidDateMessage={t('invalid_date_format')}
          margin='normal'
          fullWidth={false}
          minDate={minDate}
        />
      </div>
      <div style={styles.title}>
        <FormLabel text={t('job_title')} styles={styles.label} />
        <Typography sx={styles.selectedOccupation}>{state.selectedOccupation?.title}</Typography>
        {/* Below code is here in case we want to try and make esco search. This may not totally work */}
        {/* <AsyncAutocomplete
          value={state.searchOccupation}
          keepClosed
          open={false}
          options={[]}
          onChange={handleSearchOccupation}
          label={`${t('search_for_job_title')}...`}
          largeText
        /> */}
        <OccupationPickerMenu
          buttonText='select_from_list'
          occupations={occupations}
          onOccupationSelect={handleOccupationCheck}
          topLevel={2}
          selectableLevel={3}
          badges
          fetchOccupationChildren={fetchOccupationChildren}
          selectedOccupation={state.selectedOccupation}
        />
      </div>
      <DialogActions style={styles.actionsHolder}>
        <div>
          <Button onClick={confirmDelete} sx={styles.redColor}>
            {t('remove')}
          </Button>
        </div>
        <div>
          <Button onClick={() => handleCancel()} sx={{ ...styles.marginRight, color: COLORS.black }}>
            {t('cancel')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            sx={styles.sendButton}
            disabled={props.savingInternship}
            onClick={onSubmit}
          >
            {t('save')}
            {props.savingInternship &&
              <CircularProgress color='secondary' size={16} />}
          </Button>
        </div>
      </DialogActions>
    </Box>
  )
}

export default EditInternship
