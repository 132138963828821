import React, { Fragment, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CircularProgress from '@mui/material/CircularProgress'
import FormLabel from '../FormLabel'
import { COLORS } from '../../utils/colors'
import DialogContentWrapper from '../DialogContentWrapper'
import CoachingResultsList from './CoachingResultsList'
import Spinner from '../Spinner'

const styles = {
  root: {
    maxWidth: 800
  },
  textField: {
    marginTop: 8,
    marginBottom: 8
  },
  smallInput: {
    display: 'block'
  },
  multiline: {
    marginTop: 16
  },
  smallLabel: {
    marginTop: 8,
    color: COLORS.label
  },
  message: {
    padding: 16,
    backgroundColor: COLORS.light,
    borderRadius: 4,
    color: COLORS.darkGrey,
    fontSize: 12,
    margin: 8
  },
  actionsWrapper: {
    padding: 16,
    borderTop: `1px solid ${COLORS.lightBorder}`
  },
  cancelButton: {
    color: COLORS.red,
    marginRight: 10
  },
  marginTop20: {
    marginTop: 20
  },
  backgroundText: {
    whiteSpace: 'pre-line'
  },
  listRoot: {
    width: '100%',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  tab: {
    width: '50%',
    maxWidth: 'unset'
  },
  labelColor: {
    color: COLORS.label
  },
  occupationsText: {
    marginBottom: 12
  },
  spinner: {
    color: COLORS.white
  },
  coachesContainer: {
    paddingTop: 10
  },
  formContainer: {
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  buttonContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  bold: {
    fontWeight: 'bold'
  }
}

const arePropsTheSame = (prevProps, nextProps) => {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return isEqual(prevProps.projectInfo, nextProps.projectInfo) && prevProps.fetching === nextProps.fetching &&
  isEqual(prevProps.coachingResultOptions, nextProps.coachingResultOptions) && prevProps.coachesFetching === nextProps.coachesFetching
}
const EditCoachingResults = React.memo((props) => {
  const initialState = {
    removableResult: null,
    showRemoveResultAlert: false,
    finnishResult: '',
    englishResult: ''
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  const [t] = useTranslation()

  const isTranslationPair = (option, lang) => {
    if (lang === 'fi') {
      const { englishResult } = state
      if (englishResult !== '') {
        const translationPair = props.coachingResultOptions.find(option => option.en === englishResult)
        if (translationPair != null) return option === translationPair.fi
        else return false
      }
    } else if (lang === 'en') {
      const { finnishResult } = state
      if (finnishResult !== '') {
        const translationPair = props.coachingResultOptions.find(option => option.fi === finnishResult)
        if (translationPair != null) return option === translationPair.en
        else return false
      }
    } else return false
  }

  const setFinnishResult = (e, finnishResult) => setState({ finnishResult })
  const setEnglishResult = (e, englishResult) => setState({ englishResult })

  const handleAddNewResult = () => {
    if (!isEmpty(state.finnishResult) && !isEmpty(state.englishResult)) {
      const newResult = { fi: state.finnishResult, en: state.englishResult }
      props.addResult(newResult)
      setState({
        finnishResult: '',
        englishResult: ''
      })
    }
  }

  const handleRemoveResult = () => {
    const result = { ...state.removableResult }
    props.removeCoachingResult(result)
    setState({
      removableResult: null,
      showRemoveResultAlert: false
    })
  }

  const handleRemoveResultAlert = (removableResult) => {
    setState({ showRemoveResultAlert: !state.showRemoveResultAlert, removableResult })
  }

  const isResultOnProject = (result, lang) => props.projectInfo != null && props.projectInfo.resultOptions != null && props.projectInfo.resultOptions.find(option => isEqual(result, option[lang]))

  const isFormInvalid = () => {
    const { finnishResult, englishResult } = state
    return finnishResult === '' || englishResult === ''
  }

  const getAvailableCoachingResultOptions = (lang) => {
    const results = []
    if (props.coachingResultOptions.length > 0) {
      props.coachingResultOptions.forEach((option) => {
        if (!isResultOnProject(option[lang], lang)) {
          results.push(option[lang])
        }
      })
    }
    return results
  }

  const renderRemoveResultAlert = () => {
    return (
      <Dialog
        open={state.showRemoveResultAlert}
        onClose={handleRemoveResultAlert}
        aria-labelledby='remove-result-alert'
      >
        <DialogContent>
          <DialogContentText>
            {t('remove_coaching_result_confirmation')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveResultAlert} color='primary'>
            {t('cancel')}
          </Button>
          <Button onClick={handleRemoveResult} color='error' autoFocus>
            {t('remove')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderButtonContent = (saveButtonText) => {
    const { setProjectInfoFetching, fetching, coachesFetching } = props
    if (setProjectInfoFetching || fetching || coachesFetching) {
      return <CircularProgress style={styles.spinner} size={16} />
    }
    return t(saveButtonText)
  }

  const renderSavedCoachingResults = () => {
    const { projectInfo } = props
    if (projectInfo) {
      return (
        <CoachingResultsList
          resultOptions={projectInfo.resultOptions}
          onClick={(option) => handleRemoveResultAlert(option)}
        />
      )
    } else {
      return <Spinner />
    }
  }

  const filterOptions = (options, { inputValue }, key) => {
    return options.filter(option => option.toLowerCase().includes(state[key].toLowerCase()))
  }

  const renderContent = () => {
    const { fetching, coachesFetching } = props
    const { finnishResult, englishResult } = state

    return (
      <DialogContentWrapper>
        <Typography variant='h6' />{t('new_result')}
        <Box style={styles.formContainer}>
          <FormLabel text={`${t('finnish')} *`} />
          <Autocomplete
            filterOptions={(options, state) => filterOptions(options, state, 'finnishResult')}
            value={finnishResult}
            onInputChange={(e, result) => setFinnishResult(e, result)}
            options={getAvailableCoachingResultOptions('fi')}
            noOptionsText={t('no_results_available')}
            renderInput={params => (
              <TextField {...params} variant='standard' placeholder={`${t('select_or_write')}...`} />
            )}
            renderOption={(props, option) => (
              <>
                <span {...props} style={isTranslationPair(option, 'fi') ? styles.bold : null}>{option}</span>
              </>
            )}
            freeSolo
          />
          <FormLabel text={`${t('english')} *`} />
          <Autocomplete
            filterOptions={(options, state) => filterOptions(options, state, 'englishResult')}
            value={englishResult}
            onInputChange={(e, result) => setEnglishResult(e, result)}
            options={getAvailableCoachingResultOptions('en')}
            noOptionsText={t('no_results_available')}
            renderInput={params => (
              <TextField {...params} variant='standard' placeholder={`${t('select_or_write')}...`} />
            )}
            renderOption={(props, option) => (
              <>
                <span {...props} style={isTranslationPair(option, 'en') ? styles.bold : null}>{option}</span>
              </>
            )}
            freeSolo
          />
          <Box style={styles.buttonContainer}>
            <Button
              onClick={handleAddNewResult}
              variant='contained'
              color='primary'
              disabled={fetching || coachesFetching || isFormInvalid()}
            >
              {renderButtonContent('add_result')}
            </Button>
          </Box>
        </Box>
        {renderSavedCoachingResults()}
        {renderRemoveResultAlert()}
      </DialogContentWrapper>
    )
  }

  return renderContent()
}, arePropsTheSame)

export default EditCoachingResults
