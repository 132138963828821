import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'
import { composeError, composeImportErrors } from '../utils/transforms'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  error: null,
  errorCode: null
})

/* ------------- Types and Action Creators ------------- */

export const displayError = createAction('DISPLAY_ERROR', (response) => ({ response }))
export const dismissError = createAction('DISMISS_ERROR')
export const displayImportErrors = createAction('DISPLAY_IMPORT_ERRORS', (response) => ({ response }))

/* ------------- Reducers ------------- */

const reducers = createReducer(
  {
    [displayError]: (state, { response }) => {
      const error = composeError(response)
      console.error(error)
      return state.merge({ error, errorCode: response })
    },
    [dismissError]: (state) => state.merge({ error: null, errorCode: null }),
    [displayImportErrors]: (state, { response }) => {
      const error = composeImportErrors(response)
      console.error(error)
      return state.merge({ error })
    }
  },
  INITIAL_STATE
)

export default reducers
