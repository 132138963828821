import React, { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, TextField, Typography } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import isValid from 'date-fns/isValid'
import ESCOAPI from '../../services/escoApi'
import { getCurrentLang, oldGetDateTimeForApi, isDifferenceInDaysNegative } from '../../utils/transforms'
import { COLORS } from '../../utils/colors'
import OccupationPickerMenu from './OccupationPickerMenu'
import Autocomplete from '../Autocomplete'
import FormLabel from '../FormLabel'
import DatePicker from '../DatePicker'
import { isEmpty } from 'lodash'
import FormFieldWrapper from '../FormFieldWrapper'
// import AsyncAutocomplete from '../AsyncAutocomplete'

const styles = {
  root: {
    maxWidth: 80,
    alignItems: 'center'
  },
  content: {
    padding: '16px 24px'
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 16
  },
  textField: {
    marginTop: 2,
    marginBottom: 2
  },
  searchField: {
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 20
  },
  actionsWrapper: {
    padding: 16
  },
  spinner: {
    color: COLORS.white
  },
  entryBox: {
    minHeight: 200
  },
  divider: {
    padding: '0 8px'
  },
  datePicker: {
    width: 200,
    '& input[type=text]': {
      fontSize: '1rem',
      color: COLORS.grey
    }
  },
  cancelButton: {
    marginRight: 2,
    color: COLORS.black
  },
  title: {
    marginTop: 20
  },
  label: {
    fontSize: '1rem',
    display: 'block'
  },
  selectedOccupation: {
    display: 'inline'
  },
  red: {
    color: COLORS.red
  }
}

const CreateInternship = (props) => {
  const [t] = useTranslation()
  const initialState = {
    isSaving: false,
    companyName: '',
    cooperationTypeId: null,
    startDate: null,
    endDate: null,
    selectedOccupation: null
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)
  const cooperationTypes = props.cooperationTypeOptions || []
  const [occupations, setOccupations] = useState([])
  const [errors, setErrors] = useState({})
  // const [fetchingOccupations, setFetchingOccupations] = useState(false)

  useEffect(() => {
    /* Get ESCO occupations */
    // setFetchingOccupations(true)
    ESCOAPI().getTopLevelConcepts(getCurrentLang()).then(data => {
      const arr = data || []
      setOccupations(arr)
      // setFetchingOccupations(false)
      return data
    })
  }, []) // On component mount

  const fetchOccupationChildren = (uri) => {
    return ESCOAPI().getChildOccupations(uri, getCurrentLang()).then(data => data)
  }

  const validateForm = (values) => {
    const errors = {}
    /* Requirements */
    if (!values.companyName) errors.companyName = t('employment_required')
    if (!values.cooperationTypeId) errors.cooperationTypeId = t('type_required')
    /* If date set, check that date is valid */
    if (values.start && !isValid(values.start)) errors.start = t('date_invalid')
    if (values.end && !isValid(values.end)) errors.end = t('date_invalid')
    /* Field specific validations */
    if (values.start && values.end && isDifferenceInDaysNegative(values.start, values.end)) errors.end = t('end_date_cannot_be_before_start')
    return errors
  }

  const handleCancel = (form) => {
    setState(initialState)
  }

  const onSubmit = (event) => {
    event.preventDefault()
    const errors = validateForm(state)
    setErrors(errors)
    if (!props.loading && isEmpty(errors)) {
      const { companyName, cooperationTypeId } = state
      const { coachingId } = props
      const projectId = props.projectId
      const internship = { companyName, cooperationTypeId, coachingId }
      if (state.startDate) internship.start = oldGetDateTimeForApi(state.startDate)
      if (state.endDate) internship.end = oldGetDateTimeForApi(state.endDate)
      if (state.selectedOccupation) {
        internship.iscoGroupCode = state.selectedOccupation.code
        internship.escoNameEn = state.selectedOccupation.preferredLabel.en
        internship.escoNameFi = state.selectedOccupation.preferredLabel.fi
      }
      setState({ isSaving: true }) // Set component state to saving, it will be used when resetting form values
      props.createInternship(projectId, internship)
    }
  }

  const setCompanyName = (e) => setState({ companyName: e.target.value })
  const setCooperationType = (cooperationTypeId) => setState({ cooperationTypeId })
  const setStartDate = (startDate) => setState({ startDate })
  const setEndDate = (endDate) => setState({ endDate })
  const handleOccupationCheck = (occupation) => setState({ selectedOccupation: occupation })

  /* Below is code for searching occupations, in case we want to try to make it work */
  /* let searchBuffer
  const handleSearchOccupation = async (searchText) => {
    searchBuffer = setTimeout(async () => {
      console.log(searchText)
      setFetchingOccupations(true)
      setOccupations([])
      const results = await ESCOAPI().getTextSearch(searchText, getCurrentLang())
      console.log(results)
      setOccupations(results)
      setFetchingOccupations(false)
    }, 700)
  } */

  return (
    <Box sx={styles.entryBox}>
      <form onSubmit={onSubmit} noValidate>
        <FormFieldWrapper>
          <TextField
            label={t('internship_place')}
            sx={styles.textField}
            variant='standard'
            name='companyName'
            fullWidth
            required
            value={state.companyName}
            onChange={setCompanyName}
            error={errors.companyName}
            helperText={errors.companyName ? errors.companyName : null}
          />
        </FormFieldWrapper>
        <Autocomplete
          value={state.cooperationTypeId}
          onChange={setCooperationType}
          options={[...cooperationTypes]}
          noOptionsMessage={() => t('no_services_available')}
          placeholder={t('type')}
          sx={styles.textField}
          required
          fullWidth
          largeText
        />
        {errors.cooperationTypeId && <Typography variant='caption' color='error'>{errors.cooperationTypeId}</Typography>}
        <div style={styles.column}>
          <DatePicker
            value={state.startDate}
            onChange={setStartDate}
            placeholder={t('start_date')}
            maxDate={state.endDate}
            sx={styles.datePicker}
          />
          <Box component='span' sx={styles.divider}>-</Box>
          <DatePicker
            value={state.endDate}
            onChange={setEndDate}
            placeholder={t('end_date')}
            minDate={state.startDate}
            sx={styles.datePicker}
          />
        </div>
        <div style={styles.title}>
          <FormLabel text={t('job_title')} styles={styles.label} />
          <Typography style={styles.selectedOccupation}>{state.selectedOccupation?.title}</Typography>
          {/* Below code is here in case we want to try and make esco search. This may not totally work */}
          {/* <AsyncAutocomplete
            value={state.searchOccupation}
            keepClosed
            open={false}
            options={[]}
            onChange={handleSearchOccupation}
            label={`${t('search_for_job_title')}...`}
            largeText
          /> */}
          <OccupationPickerMenu
            buttonText='select_from_list'
            occupations={occupations}
            onOccupationSelect={handleOccupationCheck}
            topLevel={2}
            selectableLevel={3}
            badges
            fetchOccupationChildren={fetchOccupationChildren}
            selectedOccupation={state.selectedOccupation}
          />
        </div>
        <DialogActions sx={styles.actionsHolder}>
          <Button onClick={handleCancel} sx={styles.cancelButton}>
            {t('cancel')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            sx={styles.sendButton}
            disabled={props.savingInternship}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </Box>
  )
}

export default CreateInternship
