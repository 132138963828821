import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DialogContentWrapper from '../DialogContentWrapper'
import ListHeader from '../ListHeader'
import InternshipsList from './InternshipsList'
import CreateInternship from './CreateInternship'

const useStyles = makeStyles(theme => ({
  languageRow: {
    display: 'flex',
    flexDirection: 'column'
  },
  cell: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  },
  tableHead: {
    minWidth: 120
  },
  smallTableHead: {
    maxWidth: 60,
    width: 60
  },
  headerIcon: {
    verticalAlign: 'middle',
    marginRight: 5
  },
  expandedDetails: {
    display: 'block'
  }
}))

const ApplicantInternships = (props) => {
  const classes = useStyles()
  const [t] = useTranslation()

  useEffect(() => {
    props.getApplicantInternships(props.coachingId)
    props.getCooperationTypes()
    /* props.getApplicantLanguageSkills(props.coachingId)
    props.getLanguageSkillLevels() */
  }, [])

  const renderCreateNewInternship = () => {
    return (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AddCircleOutlineIcon className={classes.headerIcon} />
          <Typography className={classes.heading}>
            {t('create_internship')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expandedDetails}>
          <CreateInternship
            cooperationTypeOptions={props.cooperationTypeOptions}
            projectId={props.projectId}
            coachingId={props.coachingId}
            createInternship={props.createInternship}
            savingInternship={props.savingInternship}
          />
        </AccordionDetails>
      </Accordion>
    )
  }
  return (
    <DialogContentWrapper>
      {renderCreateNewInternship()}
      <Box>
        <ListHeader title='internship_places' />
        <InternshipsList
          loading={props.loading}
          internships={props.applicantInternships}
          projectId={props.projectId}
          cooperationTypeOptions={props.cooperationTypeOptions}
          savingInternship={props.savingInternship}
          editInternship={props.editInternship}
          confirmDelete={props.confirmDelete}
        />
      </Box>
    </DialogContentWrapper>
  )
}
export default ApplicantInternships
