export const applicantOrderBys = {
  nameAsc: 0,
  nameDesc: 1,
  statusAsc: 2,
  statusDesc: 3,
  cityAsc: 4,
  cityDesc: 5,
  serviceAsc: 6,
  serviceDesc: 7,
  meetingAsc: 8,
  meetingDesc: 9,
  coachAsc: 10,
  coachDesc: 11,
  startDateAsc: 12,
  startDateDesc: 13,
  projectAsc: 14,
  projectDesc: 15
}
