import storage from 'redux-persist/lib/storage' // defaults to localStorage
import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable'

const transformConfig = {
  whitelistPerReducer: {
    applicants: ['applicantFilters', 'safeModeCoaching'],
    messages: ['messageFilters'],
    services: ['serviceFilters'],
    projects: ['projectFilters']
  }
}

export const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['applicants', 'messages', 'services', 'projects'],
  stateReconciler: seamlessImmutableReconciler,
  transforms: [seamlessImmutableTransformCreator(transformConfig)]
}
