import React from 'react'
import Dialog from '@mui/material/Dialog'
import { Button, CircularProgress, DialogContent, Stack, Typography } from '@mui/material'
import DialogHeader from './DialogHeader'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { useTranslation } from 'react-i18next'
const styles = {
  root: {
    maxWidth: 500,
    maxHeight: 350
  },
  spinner: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  }
}

const SavingDialog = (props) => {
  const [t] = useTranslation()
  let isOpen = props.isSaving || false
  let errorMessage = null
  // Get error message based on message key
  if (props.error && props.error.messageKey) {
    errorMessage = t(props.error.messageKey)
  }
  // Display API error message that we got from API
  // TODO: Use translated messages if that support is added to API
  if (props.error && props.error.message) {
    errorMessage = props.error.message
  }
  let successMessage = null
  // Get success message based on message key
  if (props.success && props.success.messageKey) {
    successMessage = t(props.success.messageKey)
  }
  // Display API success message that we got from API
  // TODO: Use translated messages if that support is added to API
  if (props.success && props.success.message) {
    successMessage = props.success.message
  }

  if (props.success) {
    isOpen = true
    setTimeout(props.dismissSuccess, 3000)
  }
  if (props.error)isOpen = true
  return (
    <Dialog
      open={isOpen}
      PaperProps={{ style: styles.root }}
      fullScreen
      fullWidth
      scroll='body'
      style={{ zIndex: 10000 }}
    >
      {props.isSaving &&
        <DialogHeader
          handleClose={null}
          label='saving'
        />}
      {!props.isSaving && props.success &&
        <DialogHeader
          handleClose={props.dismissSuccess}
          label='saving_success'
        />}
      {!props.isSaving && props.error &&
        <DialogHeader
          handleClose={props.dismissError}
          label='unknown_error'
        />}

      <DialogContent style={styles.spinner}>
        {props.isSaving && <CircularProgress size={100} />}
        {props.success &&
          <Stack alignItems='center'>
            <CheckCircleRoundedIcon color='success' sx={{ fontSize: '100px' }} />
            <Typography>{t('saving_success')}</Typography>
            <Typography>{successMessage}</Typography>
          </Stack>}
        {props.error &&
          <Stack alignItems='center' spacing={1}>
            <ErrorRoundedIcon color='error' sx={{ fontSize: '100px' }} />
            <Typography>{t('unknown_error')}</Typography>
            <Typography>{errorMessage}</Typography>
            <Stack direction='row' spacing={2}>
              <Button variant='contained' onClick={() => { navigator.clipboard.writeText(JSON.stringify(props.errorCode.data) + ' url:' + props.errorCode?.config?.url) }}>{t('copy_error')}</Button>
              <Button variant='contained' onClick={() => { props.dismissError() }}>{t('close')}</Button>
            </Stack>
          </Stack>}
      </DialogContent>
    </Dialog>
  )
}

export default SavingDialog
