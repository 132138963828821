import React, { useReducer, useEffect } from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppBar, Badge, Tabs, Tab, Box, Dialog, CircularProgress, Slide, Accordion, AccordionSummary, AccordionDetails, Typography, TextField } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import * as MeetingsActions from '../../redux/MeetingsRedux'
import * as CoachingEntriesActions from '../../redux/CoachingEntriesRedux'
import DialogHeader from '../DialogHeader'
import DialogContent from '@mui/material/DialogContent'
import TabPanel from '../TabPanel'
import CreateEntry from './CreateEntry'
import CoachingEntriesList from './CoachingEntriesList'
import ListHeader from '../ListHeader'
import { COLORS } from '../../utils/colors'
import { getLocale } from '../../utils/transforms'
import { customReducer } from '../../utils/reducer'
import { isAdmin } from '../../utils/permissions'
import { LocalizationProvider, StaticDatePicker, PickersDay } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { isSameDay, parseISO } from 'date-fns'
import { privilegeGroups } from '../../constants/enabledFeatures'

const baseStyle = {
  backgroundColor: COLORS.orange,
  color: COLORS.white,
  padding: '8px 16px',
  fontWeight: 'bold'
}

const styles = {
  root: {
    minHeight: 530,
    maxWidth: 880
  },
  evenContent: {
    display: 'flex',
    flexDirection: { md: 'initial', sm: 'column', xs: 'column' },
    alignItems: { md: 'initial', sm: 'center', xs: 'column' }
  },
  initContent: {
    display: 'flex'
  },
  tableHead: {
    ...baseStyle,
    minWidth: 100
  },
  tableHeadWide: {
    ...baseStyle,
    minWidth: 200
  },
  checkboxContainer: {
    backgroundColor: COLORS.orange
  },
  checkbox: {
    color: COLORS.white
  },
  calendar: {
    border: `1px solid ${COLORS.lightBorder}`
  },
  spinner: {
    display: 'flex',
    padding: '64px 16px',
    justifyContent: 'center',
    zIndex: 20
  },
  meetingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: { md: 3, sm: 0 },
    width: '100%',
    marginTop: { md: 0, sm: 1, xs: 1 },
    alignItems: { md: 'initial', sm: 'center' }
  },
  meetingHeaderIcon: {
    verticalAlign: 'middle',
    marginRight: 5
  },
  expandedDetails: {
    display: 'block'
  }
}

const CoachingEntriesDialog = (props) => {
  const [t] = useTranslation()
  const { applicant, nextMeeting, isOpen, onClose, fullScreen } = props
  const initialState = {
    tabValue: 0,
    selectedDate: nextMeeting ? nextMeeting.start : new Date(),
    slideLeft: false,
    selectedMeetings: [],
    editedMeetingId: null,
    shouldSetNextMeetingActive: false
  }
  /* Experiment with useReducer; useful on complex state objects, so not that useful here. */
  const reducer = (state, newState) => customReducer(state, newState, initialState)
  const [state, setState] = useReducer(reducer, initialState)
  const admin = isAdmin(props.user) || !privilegeGroups // TODO: Add logic to check if user is responsible for the project

  // Run when isOpen changes
  useEffect(() => {
    if (isOpen && !props.meetingsLoading) {
      if (nextMeeting) {
        setState({ shouldSetNextMeetingActive: true })
        focusCalendarOnNextMeeting()
      }
      composeInitialMeetingsFilters()
      props.getMeetings()
      props.getCoachingEntries({ coachingId: props.applicant.coachingId })
    }
  }, [isOpen])

  useEffect(() => {
    if (props.meetings.length > 0 && nextMeeting != null && state.shouldSetNextMeetingActive) {
      setState({ shouldSetNextMeetingActive: false })
      setMeetingAsActive(nextMeeting)
    } else if (props.meetings.length > 0 && state.editedMeetingId && state.tabValue === 1) {
      setEditedMeetingDaySelected()
    }
    handleDayChange(state.selectedDate)
  }, [props.meetings, nextMeeting])

  useEffect(() => {
    if (props.meetingCreated) {
      setState({ editedMeetingId: null })
    }
  }, [props.meetingCreated, props.meetingsLoading])

  const setInitialState = () => {
    setState({})
  }

  const setEditedMeetingDaySelected = () => {
    const meeting = props.meetings.find(meeting => meeting.id === state.editedMeetingId)
    setState({ selectedDate: meeting.start, editedMeetingId: null })
    setMeetingAsActive(meeting)
  }

  const focusCalendarOnNextMeeting = () => {
    const nextMeetingDate = parseISO(nextMeeting.start)
    setState({ selectedDate: nextMeetingDate })
  }

  const setMeetingAsActive = (meeting) => {
    const meetingDate = parseISO(meeting.start)
    handleDayChange(meetingDate)
  }

  const composeInitialMeetingsFilters = () => {
    const start = nextMeeting
      ? moment(nextMeeting.start).startOf('month').startOf('day').format('YYYY-MM-DD') + 'T00:00:00'
      : moment().startOf('month').startOf('day').format('YYYY-MM-DD') + 'T00:00:00'
    const end = nextMeeting
      ? moment(nextMeeting.start).endOf('month').endOf('day').format('YYYY-MM-DD') + 'T23:59:59'
      : moment().endOf('month').endOf('day').format('YYYY-MM-DD') + 'T23:59:59'
    const filters = {
      start,
      end,
      talentId: applicant.userId,
      projectId: applicant.projectId
    }
    props.setMeetingsFilters(filters)
  }

  const closeDialog = () => {
    setInitialState()
    onClose()
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const handleTabChange = (event, newValue) => {
    setState({ tabValue: newValue })
  }

  const handleDayChange = (day) => {
    const dayMeetings = getMeetingsForDay(day)
    if (dayMeetings.length > 0) {
      setState({ slideLeft: true, selectedMeetings: dayMeetings, selectedDate: day })
    } else {
      setState({ slideLeft: false, selectedMeetings: [], selectedDate: day })
    }
  }

  const handleMonthChange = (firstDayOfMonth) => {
    const firstDay = moment(firstDayOfMonth).format('YYYY-MM-DD') + 'T00:00:00'
    const lastDay = moment(firstDayOfMonth).endOf('month').endOf('day').format('YYYY-MM-DD') + 'T23:59:59'
    const filters = {
      ...props.meetingsFilters,
      start: firstDay,
      end: lastDay
    }
    props.setMeetingsFilters(filters)
    props.getMeetings()
  }

  const getMeetingsForDay = (day) => {
    return props.meetings.filter(meeting => isSameDay(parseISO(meeting.start), day))
  }

  const handleEditMeeting = (editFormState) => {
    setState({ editedMeetingId: editFormState.meetingId })
    props.editMeeting(editFormState)
  }

  const handleCancelMeeting = (editFormState) => {
    setState({ editedMeetingId: editFormState.meetingId })
    props.cancelMeeting(editFormState)
  }

  const renderLoader = () => {
    const { meetingsLoading } = props
    if (meetingsLoading) {
      return (
        <div style={styles.spinner}>
          <CircularProgress />
        </div>
      )
    }
  }

  /* Different badge color for cancelled meetings */
  const renderBadgeDay = (day, dayComponent) => {
    const dayMeetings = getMeetingsForDay(day)
    const isAnyScheduledMeetings = dayMeetings.some(meeting => !meeting.isCancelled)
    if (isAnyScheduledMeetings) return <Badge badgeContent={countMeetingsForDay(day)} color='primary' key={day}>{dayComponent}</Badge>
    else return <Badge badgeContent={countMeetingsForDay(day)} style={styles.cancelledBadgeColor} key={day}>{dayComponent}</Badge>
  }

  const renderCreateEntry = () => {
    return (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AddCircleOutlineIcon style={styles.meetingHeaderIcon} />
          <Typography style={styles.heading}>
            {t('create_entry')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={styles.expandedDetails}>
          <CreateEntry
            goBack={handleTabChange}
            applicant={applicant}
            user={props.user}
            loading={props.entriesLoading}
            entryCreated={props.entryCreated}
            okCreate={props.acknowledgeEntrySaveSuccess}
            createEntry={props.addCoachingEntry}
            getCoachingEntrySummaryText={props.getCoachingEntrySummaryText}
            coachingEntrySummary={props.coachingEntrySummary}
            isAdmin={admin}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderCalendarPanel = () => {
    const { selectedMeetings } = state
    if (state.tabValue === 1) {
      return (
        <TabPanel value={state.tabValue} index={1}>
          <Box sx={styles.evenContent}>
            <Box style={styles.calendar}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
                <StaticDatePicker
                  value={state.selectedDate}
                  open
                  onChange={handleDayChange}
                  onMonthChange={handleMonthChange}
                  openTo='day'
                  toolbarFormat='EEEE, d'
                  toolbarTitle=''
                  componentsProps={{ actionBar: { actions: [] } }}
                  sx={{ backgroundColor: COLORS.green, '.MuiTypography-overline ': { color: COLORS.lightText }, '.MuiTypography-h4': { color: COLORS.white }, '.MuiButtonBase-root': { display: 'none' } }}
                  renderDay={(day, selectedDays, pickersDayProps, dayInCurrentMonth, dayComponent) => {
                    if (!pickersDayProps.outsideCurrentMonth && !props.meetingsLoading) {
                    // Return day with badge support
                      return renderBadgeDay(day, <PickersDay {...pickersDayProps} />)
                    } else {
                    // Return normal day component
                      return <PickersDay {...pickersDayProps} />
                    }
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ backgroundColor: 'green' }} />}
                />
              </LocalizationProvider>
              {renderLoader()}
            </Box>
            <Box sx={styles.meetingsContainer}>
              <Typography variant='h6' style={styles.h6}>{t('days_meetings')}</Typography>
              {selectedMeetings.length === 0 &&
                <Typography style={styles.h6}>{t('no_meetings')}</Typography>}
              <Slide direction='left' in={state.slideLeft}>
                <Box>
                  <CoachingEntriesList
                    entries={selectedMeetings}
                    loading={props.meetingsLoading}
                    onEditMeeting={handleEditMeeting}
                    onCancelMeeting={handleCancelMeeting}
                    applicant={applicant}
                    isAdmin={admin}
                  />
                </Box>
              </Slide>
            </Box>
          </Box>
        </TabPanel>
      )
    }
    return null
  }

  const countMeetingsForDay = (day) => {
    const meetingsForDay = props.meetings.filter(item => {
      return isSameDay(parseISO(item.start), day)
    })
    return meetingsForDay.length
  }

  const renderEntriesPanel = () => {
    if (state.tabValue === 0) {
      const onEditEntry = props.editCoachingEntry
      const onDeleteCoachingEntry = props.deleteCoachingEntry
      return (
        <TabPanel value={state.tabValue} index={0}>
          {renderCreateEntry()}
          <Box>
            <ListHeader title='entries' />
            <CoachingEntriesList
              showCopying
              entries={props.entries}
              loading={props.entriesLoading}
              onEditEntry={onEditEntry}
              onEditMeeting={handleEditMeeting}
              onCancelMeeting={handleCancelMeeting}
              onDeleteCoachingEntry={onDeleteCoachingEntry}
              applicant={applicant}
              isAdmin={admin}
            />
          </Box>
        </TabPanel>
      )
    }
    return null
  }

  /* Render into a DOM node that exists outside the DOM hierarchy of the parent component.
  Document body is the target in this case.
  */
  return ReactDOM.createPortal(
    <Dialog
      open={isOpen}
      PaperProps={{ style: styles.root }}
      fullScreen={fullScreen}
      fullWidth
      scroll='paper'
    >
      <DialogHeader
        handleClose={closeDialog}
        label='entries'
      />
      <AppBar position='static' color='secondary'>
        <Tabs value={state.tabValue} onChange={handleTabChange} aria-label='simple tabs example' indicatorColor='primary'>
          <Tab label={t('entries')} {...a11yProps(0)} />
          <Tab label={t('calendar')} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <DialogContent dividers>
        {renderEntriesPanel()}
        {renderCalendarPanel()}
      </DialogContent>
    </Dialog>,
    document.body
  )
}
const mapStateToProps = (state) => {
  return {
    meetings: state.meetings.meetings,
    loading: state.meetings.loading,
    meetingCreated: state.meetings.createSuccessfull,
    user: state.user.user,
    meetingsFilters: state.meetings.meetingsFilters,
    allMeetings: state.meetings.allMeetings,
    entriesLoading: state.coachingEntries.loading,
    coachingEntrySummary: state.coachingEntries.coachingEntrySummary,
    entries: state.coachingEntries.coachingEntries,
    entryCreated: state.coachingEntries.createSuccessfull
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMeetings: () => dispatch(MeetingsActions.getMeetings()),
    createMeeting: (newMeeting) => dispatch(MeetingsActions.createMeeting(newMeeting)),
    acknowledgeMeetingSaveSuccess: () => dispatch(MeetingsActions.acknowledgeMeetingSaveSuccess()),
    setMeetingsFilters: (meetingsFilters) => dispatch(MeetingsActions.setMeetingsFilters(meetingsFilters)),
    editMeeting: (meeting) => dispatch(MeetingsActions.editMeeting(meeting)),
    cancelMeeting: (meeting) => dispatch(MeetingsActions.cancelMeeting(meeting)),
    getAllMeetings: () => dispatch(MeetingsActions.getAllMeetings()),
    addCoachingEntry: (projectId, newEntry) => dispatch(CoachingEntriesActions.addCoachingEntry(projectId, newEntry)),
    getCoachingEntrySummaryText: (coachingId) => dispatch(CoachingEntriesActions.getCoachingEntrySummaryText(coachingId)),
    getCoachingEntries: (params) => dispatch(CoachingEntriesActions.getCoachingEntries(params)),
    editCoachingEntry: (projectId, coachingEntryId, body) => dispatch(CoachingEntriesActions.editCoachingEntry(projectId, coachingEntryId, body)),
    deleteCoachingEntry: (projectId, coachingEntryId, coachingId) => dispatch(CoachingEntriesActions.deleteCoachingEntry(projectId, coachingEntryId, coachingId)),
    acknowledgeEntrySaveSuccess: () => dispatch(CoachingEntriesActions.acknowledgeEntrySaveSuccess())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  CoachingEntriesDialog
)
