import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import { format } from 'date-fns'
import FolderIcon from '@mui/icons-material/Folder'
import DeleteIcon from '@mui/icons-material/Delete'
import orderBy from 'lodash/orderBy'
import Spinner from '../Spinner'

const styles = theme => ({
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  pointer: {
    cursor: 'pointer'
  }
})

const AttachmentsList = (props) => {
  const [t] = useTranslation()

  if (props.items && props.items.length > 0) {
    const sortedItems = orderBy(props.items, ['created'], ['desc'])
    return (
      <Fragment key='attachment-list'>
        <List dense>
          {sortedItems.map((item, idx) => {
            return (
              <ListItem key={idx}>
                <ListItemAvatar onClick={(e) => props.downloadAttachment(item)} classes={{ root: props.classes.pointer }}>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={item.filename}
                  secondary={`${t('uploaded')}: ${format(new Date(item.created), t('datetime_format'))}`}
                  classes={{ root: props.classes.pointer }}
                  onClick={(e) => props.downloadAttachment(item)}
                />
                <ListItemSecondaryAction>
                  <IconButton edge='end' aria-label='delete' onClick={e => props.confirmDeleteFile(item)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
        {props.isDownloadingFile &&
          <Spinner centered />}
      </Fragment>
    )
  } else {
    return (
      <List>
        <ListItem>
          <ListItemText primary={t(props.emptyListMessage)} />
        </ListItem>
      </List>
    )
  }
}

export default withStyles(styles)((AttachmentsList))
