import React, { useEffect, useReducer } from 'react'
import { useTheme } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormTextField from '../FormTextField'
import CustomDatePicker from '../DatePicker'
import Autocomplete, { getTrainerOptions } from '../Autocomplete'
import DialogHeader from '../DialogHeader'
import SaveButton from '../SaveButton'
import { COLORS } from '../../utils/colors'
import DialogContentWrapper from '../DialogContentWrapper'
import FormFieldWrapper from '../FormFieldWrapper'
import { customReducer } from '../../utils/reducer'
import { storageTimes } from '../../constants/passiveStorageTimes'
import { useMediaQuery } from '@mui/material'

const styles = {
  root: {
    maxWidth: 800
  },
  smallInput: {
    display: 'block'
  },
  multiline: {
    marginTop: 16
  },
  smallLabel: {
    marginTop: 8,
    color: COLORS.label
  },
  message: {
    padding: 16,
    backgroundColor: COLORS.light,
    borderRadius: 4,
    color: COLORS.darkGrey,
    fontSize: 12,
    margin: 8
  },
  actionsWrapper: {
    padding: 16,
    borderTop: `1px solid ${COLORS.lightBorder}`
  },
  cancelButton: {
    color: COLORS.red,
    marginRight: 10
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  dateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 40
  },
  datepicker: {
    width: 200,
    marginLeft: 10,
    '& .MuiInput': {
      fontSize: 16
    }
  }
}

const CreateProjectDialog = (props) => {
  const initialState = {
    nameFi: '',
    nameEn: '',
    area: '',
    service: '',
    backgroundHelpEn: '',
    backgroundHelpFi: '',
    code: '',
    descriptionEn: '',
    descriptionFi: '',
    start: null,
    end: null,
    passiveStorageTime: '',
    responsiblePersonId: ''
  }
  const reducer = (state, newState) => customReducer(state, newState, initialState)
  const [state, setState] = useReducer(reducer, initialState)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [t] = useTranslation()
  useEffect(() => {
    if (props.saveSuccessful && props.isOpen) {
      handleClose()
      props.acknowledgeSaveSuccess()
    }
  }, [props.saveSuccessful])

  const resetState = () => setState({})

  const handleClose = () => {
    resetState()
    props.onClose()
  }

  const handleSave = () => {
    const generatedUUID = uuidv4()
    const params = {
      id: generatedUUID,
      ...state
    }
    props.createProject(params)
  }

  const setNameFi = (e) => setState({ nameFi: e.target.value })
  const setNameEn = (e) => setState({ nameEn: e.target.value })
  const setArea = (area) => setState({ area })
  const setService = (service) => setState({ service })
  const setCode = (e) => setState({ code: e.target.value })
  const setDescriptionFi = (e) => setState({ descriptionFi: e.target.value })
  const setDescriptionEn = (e) => setState({ descriptionEn: e.target.value })
  const setBackgroundHelpFi = (e) => setState({ backgroundHelpFi: e.target.value })
  const setBackgroundHelpEn = (e) => setState({ backgroundHelpEn: e.target.value })
  const setStart = (start) => setState({ start })
  const setEnd = (end) => setState({ end })
  const setPassiveStorageTime = (e) => setState({ passiveStorageTime: e.target.value })
  const setResponsible = (responsiblePersonId) => setState({ responsiblePersonId })

  const isFormInvalid = () => {
    return state.nameFi === '' || state.area === '' || state.service === '' || state.passiveStorageTime === '' || state.responsiblePersonId === ''
  }

  const getPassiveStorageTimeOptions = () => {
    const times = [
      <MenuItem value='' key='0'>-</MenuItem>
    ]
    for (const key in storageTimes) {
      if (Object.prototype.hasOwnProperty.call(storageTimes, key) && storageTimes[key] != null) {
        times.push(
          <MenuItem value={storageTimes[key]} key={storageTimes[key]}>
            {`${key} ${t('y')}`}
          </MenuItem>
        )
      }
    }
    return times
  }

  const renderForm = () => {
    const { nameFi, nameEn, service, area, code, responsiblePersonId, descriptionFi, descriptionEn, backgroundHelpFi, backgroundHelpEn, start, end, passiveStorageTime } = state
    return (
      <DialogContent dividers>
        <DialogContentWrapper>
          <FormFieldWrapper>
            <FormTextField
              label={t('name_fi')}
              value={nameFi}
              onChange={setNameFi}
              fullWidth
              required
              variant='standard'
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              label={t('name_en')}
              value={nameEn}
              onChange={setNameEn}
              fullWidth
              variant='standard'
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Autocomplete
              options={[...props.servicesOptions]}
              value={service}
              onChange={setService}
              label={t('service')}
              placeholder={`${t('select')}...`}
              noOptionsMessage={() => t('no_services_available')}
              largeText
              required
              margin='form'
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              label={t('code')}
              variant='standard'
              value={code}
              onChange={setCode}
              fullWidth
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Typography variant='caption'>{`${t('passive_phase_storage_time')} *`}</Typography>
            <Select
              value={passiveStorageTime}
              onChange={setPassiveStorageTime}
              fullWidth
              variant='standard'
            >
              {getPassiveStorageTimeOptions()}
            </Select>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Autocomplete
              largeText
              value={responsiblePersonId}
              onChange={setResponsible}
              label={`${t('responsible_trainer')} *`}
              options={getTrainerOptions(props.coachOptions)}
              noOptionsMessage={() => t('no_trainers_available')}
              placeholder={`${t('select')}...`}
            />
          </FormFieldWrapper>
          <div style={styles.row}>
            <FormFieldWrapper style={styles.dateWrapper}>
              <Typography variant='caption' style={styles.caption}>{t('start_date')}</Typography>
              <CustomDatePicker
                value={start}
                onChange={setStart}
                placeholder={t('select')}
                style={styles.datepicker}
              />
            </FormFieldWrapper>

            <FormFieldWrapper style={styles.dateWrapper}>
              <Typography variant='caption' style={styles.caption}>{`${t('end_date')} *`}</Typography>
              <CustomDatePicker
                value={end}
                onChange={setEnd}
                placeholder={t('select')}
                style={styles.datepicker}
              />
            </FormFieldWrapper>
          </div>
          <FormFieldWrapper>
            <Autocomplete
              options={[...props.regionsOptions]}
              value={area}
              onChange={setArea}
              groupBy={(option) => option.region}
              placeholder={t('area')}
              noOptionsMessage={() => t('no_areas_available')}
              largeText
              required
              margin='form'
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              label={t('description_fi')}
              value={descriptionFi}
              onChange={setDescriptionFi}
              rows={3}
              rowsMax={8}
              multiline
              fullWidth
              variant='standard'
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              label={t('description_en')}
              value={descriptionEn}
              onChange={setDescriptionEn}
              rows={3}
              rowsMax={8}
              multiline
              fullWidth
              variant='standard'
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              label={t('background_help_fi')}
              value={backgroundHelpFi}
              onChange={setBackgroundHelpFi}
              rows={5}
              rowsMax={8}
              multiline
              fullWidth
              variant='standard'
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormTextField
              label={t('background_help_en')}
              value={backgroundHelpEn}
              onChange={setBackgroundHelpEn}
              rows={5}
              rowsMax={8}
              multiline
              fullWidth
              variant='standard'
            />
          </FormFieldWrapper>
        </DialogContentWrapper>
      </DialogContent>
    )
  }

  const renderDialogActions = () => {
    return (
      <DialogActions style={styles.actionsWrapper}>
        <Button onClick={handleClose} style={styles.cancelButton}>
          {t('cancel')}
        </Button>
        <SaveButton
          label='create_project'
          fetching={props.fetching}
          disabled={isFormInvalid()}
          handleSave={handleSave}
        />
      </DialogActions>
    )
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.isOpen}
      scroll='paper'
      onClose={handleClose}
      PaperProps={{ style: styles.root }}
      fullWidth
    >
      <DialogHeader
        handleClose={handleClose}
        label='new_project'
      />
      {renderForm()}
      {renderDialogActions()}
    </Dialog>
  )
}

export default CreateProjectDialog
