import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import DatePicker from '../DatePicker'
import { COLORS } from '../../utils/colors'
import { getObjectNameTranslation } from '../../utils/transforms'

const styles = theme => ({
  root: {
    padding: 4,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500,
      margin: 20
    },
    margin: 10
  },
  title: {
    padding: '16px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  contentWrapper: {
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px'
    },
    height: 120
  },
  actionsWrapper: {
    margin: 0,
    padding: '6px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  sendButton: {
    [theme.breakpoints.up('sm')]: {
      margin: '10px 0px',
      marginLeft: 20
    },
    marginLeft: 15,
    width: 102
  },
  cancelButton: {
    color: COLORS.red
  },
  spinner: {
    color: COLORS.white
  },
  formControl: {
    width: '100%'
  },
  marginTop10: {
    marginTop: 10
  },
  endDatePicker: {
    width: '100%',
    '& input[type=text]': {
      fontSize: '1rem'
    }
  }
})

class EndServiceDialog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      result: ''
    }
  }

  componentDidUpdate (nextProps) {
    if (this.props.fetching && !nextProps.fetching) {
      this.handleClose()
    }
  }

  getOptions = () => {
    if (this.props.applicant) {
      const { coachingResultOptions } = this.props.applicant
      return coachingResultOptions.map((item) => (
        <MenuItem value={item.id} key={item.id}>
          {getObjectNameTranslation(item.name)}
        </MenuItem>
      ))
    }
  }

  handleSetResult = (e) => this.setState({ result: e.target.value })
  handleSetEndDate = (val) => { this.setState({ end: val }) }
  handleEndService = () => {
    const { result, end } = this.state
    const { projectId, userId } = this.props.applicant

    this.props.endService(projectId, userId, result, end)
  }

  handleClose = () => {
    this.props.close()
    this.setState({ result: '' })
    this.setState({ end: null })
  }

  renderButtonContent = () => {
    const { fetching, t, classes } = this.props
    if (fetching) {
      return <CircularProgress className={classes.spinner} size={16} />
    }
    return t('save')
  }

  render () {
    const { classes, t, open, close, applicant } = this.props
    const { result, end } = this.state
    const begin = applicant ? new Date(applicant.begin) : null

    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby='form-dialog-title'
        classes={{ paper: classes.root }}
        fullWidth
      >
        <DialogTitle id='form-dialog-title' classes={{ root: classes.title }}>
          {t('end_service')}
        </DialogTitle>
        <DialogContent classes={{ root: classes.contentWrapper }}>
          <form>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor='result' required>{t('select_result')}</InputLabel>
              <Select value={result} onChange={this.handleSetResult} input={<Input id='result' />}>
                {this.getOptions()}
              </Select>
            </FormControl>
            <FormControl className={`${classes.formControl} ${classes.marginTop10}`}>
              <DatePicker
                className={classes.endDatePicker}
                value={end}
                minDate={begin}
                placeholder={t('end_date')}
                onChange={this.handleSetEndDate}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions classes={{ root: classes.actionsWrapper }}>
          <Button onClick={this.handleClose} className={classes.cancelButton}>
            {t('cancel')}
          </Button>
          <Button
            onClick={this.handleEndService}
            variant='contained'
            color='primary'
            className={classes.sendButton}
            disabled={!result}
          >
            {this.renderButtonContent()}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)((withTranslation()(EndServiceDialog)))
