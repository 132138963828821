import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import { Select } from 'mui-rff'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { castCoachingLanguageApiObjectToFormObject, castToCoachingLanguageApiObject } from '../../utils/transforms'
import { TableBody } from '@mui/material'
import { COLORS } from '../../utils/colors'

const useStyles = makeStyles(theme => ({
  languageRow: {
    display: 'flex',
    flexDirection: 'column'
  },
  verticalAlignBottomOpen: {
    verticalAlign: 'bottom',
    borderBottom: 'none'
  },
  lang: {
    color: theme.palette.text.secondary,
    marginBottom: 4
  },
  buttonCell: {
    textAlign: 'right'
  },
  openRow: {
    borderBottom: 'none',
    minWidth: 120
  },
  smallOpenRow: {
    borderBottom: 'none',
    maxWidth: 60
  },
  rowButton: {
    padding: 0
  }
}))

const LanguageSkillRow = (props) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const skillLevels = props.languageSkillLevelOptions || []

  const initialState = {
    coachingId: props.coachingId || null,
    languageId: props.languageId || null,
    projectId: props.projectId || null,
    coachingLanguageId: props.applicantLanguageSkill ? props.applicantLanguageSkill.id : null
  }
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (props.applicantLanguageSkill) {
      const formValues = castCoachingLanguageApiObjectToFormObject(props.applicantLanguageSkill.coachingLanguageSkills)
      // set state
      const newState = { ...initialState, ...formValues }
      setState(newState)
    } else setState(initialState)
  }, [props.applicantLanguageSkill])

  const onSubmit = (formValues) => {
    const newSkills = castToCoachingLanguageApiObject(formValues, props.languageSkills)
    const languageSkills = {
      coachingId: formValues.coachingId,
      languageId: formValues.languageId,
      projectId: formValues.projectId,
      coachingLanguageId: formValues.coachingLanguageId,
      coachingLanguageSkills: castToCoachingLanguageApiObject(formValues, props.languageSkills)
    }
    // If no new skills, it means user is trying to delete this language skill.
    if (newSkills.length === 0) props.confirmDelete(props.applicantLanguageSkill.id)
    else props.saveLanguageSkills(languageSkills)
  }

  const handleCancel = (form) => {
    // reset state by calling mui-rff form api method
    form.reset()
  }

  const handleLanguageSkillDelete = (e) => {
    if (props.applicantLanguageSkill) props.confirmDelete(props.applicantLanguageSkill.id)
  }

  const renderButtonContent = (saveButtonText) => {
    if (props.savingLanguages) {
      return <CircularProgress className={classes.spinner} size={16} />
    }
    return t(saveButtonText)
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={state}
      render={({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: classes.verticalAlignBottomOpen }}>
                  <Typography className={classes.lang}>{t('fi')}</Typography>
                </TableCell>
                <TableCell classes={{ root: classes.openRow }}>
                  <Select
                    label={t('skill_level')}
                    className={classes.textField}
                    name='speaking'
                    fullWidth
                    data={skillLevels}
                    variant='standard'
                  />
                </TableCell>
                <TableCell classes={{ root: classes.openRow }}>
                  <Select
                    label={t('skill_level')}
                    className={classes.textField}
                    name='speachUnderstanding'
                    fullWidth
                    data={skillLevels}
                    data-testid='speach-understanding'
                    variant='standard'

                  />
                </TableCell>
                <TableCell classes={{ root: classes.openRow }}>
                  <Select
                    label={t('skill_level')}
                    className={classes.textField}
                    name='writing'
                    fullWidth
                    data={skillLevels}
                    variant='standard'
                  />

                </TableCell>
                <TableCell item xs={2} classes={{ root: classes.openRow }}>
                  <Select
                    label={t('skill_level')}
                    className={classes.textField}
                    name='readingUnderstanding'
                    fullWidth
                    data={skillLevels}
                    variant='standard'
                  />
                </TableCell>
                <TableCell classes={{ root: classes.smallOpenRow }} padding='none'>
                  <IconButton aria-label='delete' onClick={handleLanguageSkillDelete} classes={{ root: classes.rowButton }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align='right'>
                  <Button onClick={() => handleCancel(form)} classes={classes.cancelButton} sx={{ color: COLORS.red }}>
                    {t('cancel')}
                  </Button>
                </TableCell>
                <TableCell colSpan={1} align='right'>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={props.savingLanguages}
                  >
                    {renderButtonContent('save')}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      )}
    />
  )
}
export default LanguageSkillRow
