import React from 'react'
import { useTranslation } from 'react-i18next'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const styles = {
  title: {
    marginLeft: 6
  },
  menuButton: {
    marginLeft: 5
  }
}

const DialogHeader = ({ label, handleClose }) => {
  const [t] = useTranslation()
  return (
    <AppBar position='static'>
      <Toolbar disableGutters>

        {handleClose &&
          <IconButton style={styles.menuButton} color='inherit' onClick={handleClose} aria-label={t('close')} data-testid='close-dialog'>
            <CloseIcon />
          </IconButton>}
        <Typography variant='h6' color='inherit' style={styles.title}>
          {t(label)}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default DialogHeader
