import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, TextField } from '@mui/material'
import moment from 'moment-timezone'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import Autocomplete from '../Autocomplete'
import TimePicker from '../TimePicker'
import DatePicker from '../DatePicker'
import { COLORS } from '../../utils/colors'
import { copyDate, getCoachOptions } from '../../utils/transforms'
import { customReducer } from '../../utils/reducer'

const styles = {
  root: {
    maxWidth: 800,
    alignItems: 'center'
  },
  content: {
    padding: '16px 24px',
    display: 'flex'
  },
  calendar: {
    width: 312,
    display: 'flex',
    border: `1px solid ${COLORS.lightBorder}`
  },
  timePicker: {
    marginRight: 15,
    width: 100
  },
  actionsWrapper: {
    padding: 16
  },
  cancelButton: {
    color: COLORS.red,
    marginRight: 10
  },
  sendButton: {
    width: 102
  },
  spinner: {
    color: COLORS.white
  },
  col: {
    flexDirection: 'column'
  },
  marginNormal: {
    marginTop: 16,
    marginBottom: 8
  },
  timepickerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '60%'
  },
  datePicker: {
    width: 200,
    '& input[type=text]': {
      fontSize: '1rem',
      color: COLORS.label
    }
  }
}

const CreateMeetingForm = (props) => {
  const [t] = useTranslation()
  const { applicant, loading } = props
  const initialState = {
    startCalendarOpen: false,
    endCalendarOpen: false,
    talent: applicant ? applicant.userId : '',
    coach: '',
    start: new Date(),
    end: null,
    startTime: moment.tz(new Date(), 'Europe/Helsinki').utc(true).format('HH:mm'),
    endTime: '',
    description: '',
    projectId: applicant ? applicant.projectId : '',
    coachingId: applicant ? applicant.coachingId : '',
    entryType: null
  }
  const reducer = (state, newState) => customReducer(state, newState, initialState)
  const [state, setState] = useReducer(reducer, initialState)

  useEffect(() => {
    if (applicant) setApplicantData(applicant)
  }, [applicant])

  /* User change */
  useEffect(() => {
    if (props.user && props.user.coachId) {
      trySetLoggedInUserAsCoach()
    }
  }, [props.user])

  const handleResetState = () => setState({})

  const trySetLoggedInUserAsCoach = () => {
    const userCoach = getCoaches().find(coach => coach.value === props.user.coachId)
    if (userCoach) handleCoachChange(props.user.coachId)
  }

  const setApplicantData = (applicant) => {
    setState({ talent: applicant.userId, projectId: applicant.projectId })
  }

  const handleStartTimeChange = (event) => {
    if (event) {
      setState({ startTime: event })
    }
  }

  const handleEndTimeChange = (event) => {
    if (event) {
      setState({ endTime: event })
    }
  }

  const handleCoachChange = (val) => {
    setState({ coach: val })
  }

  const handleDescriptionChange = (event) => {
    setState({ description: event.target.value })
  }

  const handleDateChange = (e) => {
    const date = copyDate(e)
    setState({ start: date })
  }

  const handleSave = () => {
    if (!loading) {
      // Merge selected date with selected times
      const params = { ...state }
      const start = getDateMergedWithTime(state.start, state.startTime)
      const end = getDateMergedWithTime(state.start, state.endTime)
      params.start = start
      params.end = end
      props.createMeeting(params)
    }
  }

  const getDateMergedWithTime = (date, time) => {
    if (date == null) return null
    if (time === '') return null
    const hours = time.split(':')[0]
    const minutes = time.split(':')[1]
    const newDate = copyDate(date)
    newDate.setHours(hours)
    newDate.setMinutes(minutes)
    return newDate
  }

  const getCoaches = () => applicant ? getCoachOptions(applicant.coachOptions) : []

  const getName = () => applicant ? `${applicant.firstNames} ${applicant.lastName}` : ''

  const isFormInvalid = () => state.coach === '' || state.start == null || state.endTime === '' || state.startTime.length < 4

  const renderButtonContent = () => {
    if (loading) {
      return <CircularProgress style={styles.spinner} size={16} />
    }
    return t('save')
  }

  const renderButtons = () => {
    return (
      <DialogActions style={styles.actionsWrapper}>
        <Button onClick={handleResetState} style={styles.cancelButton}>
          {t('reset')}
        </Button>
        <Button
          onClick={handleSave}
          variant='contained'
          color='primary'
          style={styles.sendButton}
          disabled={isFormInvalid()}
        >
          {renderButtonContent()}
        </Button>
      </DialogActions>
    )
  }

  return (
    <Box>
      <form autoComplete='off'>
        <TextField
          value={getName()}
          label={t('applicant')}
          disabled
          margin='normal'
          fullWidth
          variant='standard'
        />
        <Autocomplete
          largeText
          margin='form'
          options={getCoaches()}
          value={state.coach}
          onChange={handleCoachChange}
          placeholder={`${t('select_trainer')} *`}
          noOptionsMessage={() => t('no_trainers_available')}
        />
        <DatePicker
          value={state.start}
          onChange={date => handleDateChange(date)}
          placeholder={`${t('date')}`}
          margin='normal'
          required
          style={{ ...styles.datePicker, ...styles.marginNormal }}
          minDate={!props.isAdmin && new Date()}
        />
        <Box style={styles.timepickerContainer}>
          <TimePicker label='start_time' value={state.startTime} onTimeChange={handleStartTimeChange} required />
          <TimePicker label='end_time' value={state.endTime} onTimeChange={handleEndTimeChange} required />
        </Box>
        <TextField
          value={state.description}
          onChange={handleDescriptionChange}
          label={t('description')}
          fullWidth
          multiline
          margin='normal'
          variant='standard'
        />
        {renderButtons()}
      </form>
    </Box>
  )
}

export default CreateMeetingForm
