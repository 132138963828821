import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import { NavLink } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import LanguageIcon from '@mui/icons-material/OutlinedFlag'
import MailIcon from '@mui/icons-material/Mail'
import CookieIcon from '@mui/icons-material/Cookie'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import MenuIcon from '@mui/icons-material/Menu'
import NavLinkButton from './NavLinkButton'
import { COLORS } from '../utils/colors'
import logo from '../static/logo.svg'
import { getCurrentLang } from '../utils/transforms'
import { pathApplicants, pathMessages, pathServices, pathProjects } from '../routes'
import { isAdmin } from '../utils/permissions'
import { signOut } from '../authConfig'
import { Box } from '@mui/system'
import withMediaQuery from '../utils/withMediaQuery'
class TopHeader extends Component {
  state = {
    langAnchorEl: null,
    navMenuAnchorEl: null
  }

  handleLogOut = () => {
    signOut()
  }

  handleLangMenuClick = (event) => this.setState({ langAnchorEl: event.currentTarget })
  handleLangMenuClose = () => this.setState({ langAnchorEl: null })
  changeLanguage = (lang) => {
    this.handleLangMenuClose()
    i18next.changeLanguage(lang, (err) => console.log(err))
  }

  handleNavMenuClick = (event) => this.setState({ navMenuAnchorEl: event.currentTarget })
  handleNavMenuClose = () => this.setState({ navMenuAnchorEl: null })
  closeAllMenus = () => {
    this.handleLangMenuClose()
    this.handleNavMenuClose()
  }

  renderLogoutButton = () => {
    const { t } = this.props
    return (
      <Fragment key='mobile-logout'>

        {!this.props.themeSMUp &&
          <Box sx={{ display: 'inline' }}>
            <Button
              onClick={this.handleLogOut}
              sx={{
                height: { md: 40, xs: 30 },
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <p style={{
                margin: 0,
                color: COLORS.black,
                fontSize: { md: 14, xs: 12 },
                textDecoration: 'none'
              }}
              >{t('logout')}
              </p>
            </Button>
          </Box>}
        {this.props.themeSMUp &&
          <Box sx={{ display: 'inline' }}>
            <IconButton
              onClick={this.handleLogOut}
              sx={{
                width: { md: 56, xs: 48 },
                height: { md: 56, xs: 48 },
                borderRadius: { md: 28, xs: 24 }
              }}
            >
              <ExitToAppIcon />
            </IconButton>
          </Box>}
      </Fragment>
    )
  }

  renderButtons = () => {
    const { t } = this.props
    const { langAnchorEl } = this.state
    return (
      <Box sx={{ display: 'inline-block' }}>
        <IconButton
          aria-owns={langAnchorEl ? 'lang-menu' : null}
          aria-haspopup='true'
          onClick={this.handleLangMenuClick}
          sx={{
            width: { md: 56, xs: 48 },
            height: { md: 56, xs: 48 },
            borderRadius: { md: 28, xs: 24 }
          }}
        >
          <LanguageIcon sx={{ color: COLORS.orange }} />
          <p style={{
            color: COLORS.black,
            fontSize: 14,
            fontWeight: '500',
            margin: '0 2px 0 2px'
          }}
          >
            {getCurrentLang().toUpperCase()}
          </p>
        </IconButton>
        <NavLink
          to={pathMessages} sx={{
            color: COLORS.black,
            margin: '0 5px',
            display: 'inline-block',
            verticalAlign: 'bottom'
          }}
        >
          <IconButton>
            <MailIcon />
          </IconButton>
        </NavLink>
        <IconButton onClick={() => { UC_UI.showFirstLayer() }}>
          <CookieIcon />
        </IconButton>
        <Menu
          id='lang-menu'
          anchorEl={langAnchorEl}
          open={Boolean(langAnchorEl)}
          onClose={this.handleLangMenuClose}
        >
          <MenuItem onClick={() => this.changeLanguage('fi')}>{t('fi')}</MenuItem>
          <MenuItem onClick={() => this.changeLanguage('en')}>{t('en')}</MenuItem>
        </Menu>
        {this.renderLogoutButton()}
      </Box>
    )
  }

  renderAdminDeskTopNav = () => {
    return (
      <Fragment key='protected-links'>
        <NavLinkButton
          label='applicants'
          path={pathApplicants}
          onClick={() => this.closeAllMenus()}
        />
        <NavLinkButton label='services' path={pathServices} onClick={() => this.closeAllMenus()} />
        <NavLinkButton label='projects' path={pathProjects} onClick={() => this.closeAllMenus()} />
      </Fragment>
    )
  }

  renderAdminMobileNav = () => {
    const { navMenuAnchorEl } = this.state
    return (
      <Fragment key='mobile-nav'>
        <IconButton
          aria-owns={navMenuAnchorEl ? 'nav-mob-menu' : null}
          aria-haspopup='true'
          onClick={this.handleNavMenuClick}
          sx={{
            width: { md: 56, xs: 48 },
            height: { md: 56, xs: 48 },
            borderRadius: { md: 28, xs: 24 }
          }}
        >
          <MenuIcon />
        </IconButton>
        <Menu id='lang-menu' anchorEl={navMenuAnchorEl} open={Boolean(navMenuAnchorEl)} onClose={this.handleNavMenuClose}>
          <MenuItem>
            <NavLinkButton
              label='applicants'
              path={pathApplicants}
              onClick={() => this.closeAllMenus()}
            />
          </MenuItem>
          <MenuItem>
            <NavLinkButton label='services' path={pathServices} onClick={() => this.closeAllMenus()} />
          </MenuItem>
          <MenuItem>
            <NavLinkButton label='projects' path={pathProjects} onClick={() => this.closeAllMenus()} />
          </MenuItem>
        </Menu>
      </Fragment>
    )
  }

  renderAdminNavButtons = () => {
    return (
      <Fragment key='nav-buttons'>
        <Box sx={{ display: { md: 'inline', xs: 'none' } }}>
          {this.renderAdminDeskTopNav()}
        </Box>
        <Box sx={{ display: { md: 'none', xs: 'inline' } }}>
          {this.renderAdminMobileNav()}
        </Box>
      </Fragment>
    )
  }

  renderNavLinks = () => {
    const { t, user } = this.props
    if (!isAdmin(user)) {
      /* Non admin users only have one page */
      return (
        <Fragment key='simple-nav'>
          <NavLinkButton
            label={t('applicants')}
            path={pathApplicants}
            sx={{
              margin: 0,
              color: COLORS.black,
              fontSize: { md: 14, xs: 12 },
              textDecoration: 'none'
            }}
          />
          <NavLinkButton label='projects' path={pathProjects} onClick={() => this.closeAllMenus()} />
        </Fragment>
      )
    } else {
      return this.renderAdminNavButtons()
    }
  }

  render () {
    const { user } = this.props

    return (
      <AppBar sx={{
        backgroundColor: COLORS.white,
        position: 'absolute',
        zIndex: 9999,
        padding: { md: '0px 20px', xs: '0px 10px' }
      }}
      >
        <Box sx={{
          width: '100%',
          maxWidth: 1360,
          margin: '0 auto'
        }}
        >
          <Toolbar disableGutters>
            <Box
              component='img' src={logo} sx={{
                maxHeight: { md: 32, xs: 28 }
              }}
              alt='Spring House'
            />
            <Box sx={{ flexGrow: 1 }}>
              {user != null &&
                this.renderNavLinks()}
            </Box>
            {user != null &&
              this.renderButtons()}
          </Toolbar>
        </Box>
      </AppBar>
    )
  }
}

export default withMediaQuery(withTranslation()(TopHeader))
