import React from 'react'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import { COLORS } from '../utils/colors'

const styles = {
  spinner: {
    color: COLORS.white
  }
}
const SaveButton = (props) => {
  const [t] = useTranslation()

  const renderButtonContent = () => {
    const { fetching } = props
    if (fetching) {
      return <CircularProgress style={styles.spinner} size={16} />
    }
    return t(props.label)
  }

  return (
    <Button
      onClick={props.handleSave}
      variant='contained'
      color='primary'
      disabled={props.fetching || props.disabled}
    >
      {renderButtonContent()}
    </Button>
  )
}
export default SaveButton
