import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import orderBy from 'lodash/orderBy'
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { format, differenceInMinutes, parseISO, addMinutes } from 'date-fns'
import EventIcon from '@mui/icons-material/Event'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import Chip from '@mui/material/Chip'
import { truncate } from 'lodash'
import AddToCalendarButton from '../AddToCalendarButton'
import { COLORS } from '../../utils/colors'
import { isMeetingEntry, isNoteEntry, isSummaryEntry } from '../../utils/entries'
import Spinner from '../Spinner'
import EditEntry from './EditEntry'
import EntryView from './EntryView'
import CopyEntries from './CopyEntries'

const styles = {
  calendar: {
    width: 312,
    display: 'flex',
    border: `1px solid ${COLORS.lightBorder}`
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiAccordionSummaryContent': {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  spinner: {
    display: 'flex',
    padding: '64px 16px',
    justifyContent: 'center'
  },
  entryHeaderIcon: {
    verticalAlign: 'middle',
    marginRight: 5
  },
  expandedDetails: {
    display: 'block'
  },
  editForm: {
    width: '100%'
  },
  cancelledBadgeColor: {
    backgroundColor: COLORS.lightBorder
  },
  cancelled: {
    color: COLORS.lightText,
    whiteSpace: 'pre-line'
  },
  red: {
    color: COLORS.red
  },
  bgRed: {
    backgroundColor: COLORS.red,
    color: COLORS.white
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  itemTextContent: {
    display: 'flex',
    width: '70%'
  },
  cancelledBox: {
    display: 'inline-flex',
    flexDirection: 'row',
    border: '1px solid red',
    backgroundColor: COLORS.errorBg,
    padding: '10px 20px'
  },
  priorityIcon: {
    '& .MuiIconRoot': {
      alignSelf: 'center',
      marginRight: 10
    }
  },
  addToCalendarButton: {
    display: 'inline-flex',
    border: `1px solid ${COLORS.orange}`,
    padding: 5
  },
  emptyText: {
    marginTop: 20,
    marginBottom: 20
  },
  description: {
    whiteSpace: 'pre-line'
  }
}

const CoachingEntriesList = (props) => {
  const [t] = useTranslation()
  const { applicant, entries } = props

  const getMeetingDuration = (meeting) => {
    if (meeting.end) {
      const durationInMinutes = differenceInMinutes(parseISO(meeting.end), parseISO(meeting.start))
      const hours = Math.floor(durationInMinutes / 60) || '00'
      const minutes = durationInMinutes % 60 || '00'
      return `${hours}${minutes}`
    } else {
      return '0030'
    }
  }

  const getEntryTypeName = (entryType) => {
    if (isMeetingEntry(entryType)) return t('meeting')
    else if (isNoteEntry(entryType)) return t('note')
    else if (isSummaryEntry(entryType)) return t('summary')
  }

  const getEntryDescriptionHeader = (entry) => {
    if (entry) {
      if (entry.description == null || entry.description === '') return '-'
      else {
        const truncatedDescription = truncate(entry.description, { length: 120, separator: ' ' })
        return truncatedDescription
      }
    }
  }

  const renderCancelledInformation = (meeting, index) => {
    if (meeting.isCancelled) {
      return (
        <Box style={styles.cancelledBox}>
          <PriorityHighIcon color='error' sx={styles.priorityIcon} />
          <Box style={styles.column}>
            <Typography style={styles.red}>
              {meeting.cancelledByTalent ? t('applicant_cancelled') : t('coach_cancelled')}
            </Typography>
            <Typography style={styles.red}>{t('reason_for_canceling') + ': '}{meeting.cancelReason}</Typography>
          </Box>
        </Box>
      )
    } else {
      return null
    }
  }

  const resolveMeetingEndTime = (meeting) => {
    if (meeting.end) {
      return format(parseISO(meeting.end), "yyyyMMdd'T'HHmmss")
    } else {
      return format(addMinutes(parseISO(meeting.start), 30), "yyyyMMdd'T'HHmmss")
    }
  }

  const renderLoader = () => {
    const { loading } = props
    if (loading) {
      return (
        <div style={styles.spinner}>
          <Spinner />
        </div>
      )
    }
  }

  const renderCalendarIntegration = (meeting) => {
    if (applicant && isMeetingEntry(meeting.entryType) && !meeting.isCancelled) {
      const event = {
        title: `Tapaaminen: ${applicant.firstNames} ${applicant.lastName}`,
        startDatetime: format(parseISO(meeting.start), "yyyyMMdd'T'HHmmss"),
        endDatetime: resolveMeetingEndTime(meeting),
        duration: getMeetingDuration(meeting),
        description: meeting.description || '',
        location: ''
      }
      return (
        <AddToCalendarButton
          event={event}
        />
      )
    } else {
      return null
    }
  }

  const renderEntryEndDate = (entry) => {
    if (entry.end) {
      return (
        <>
          {' - '}
          {format(new Date(entry.end), t('time_format')) + ' '}
        </>
      )
    }
    return null
  }

  const renderCopyEntries = () => {
    return (
      <>
        <Typography variant='h7'>{t('copy_entries')}</Typography>
        <Box sx={{ p: 1, borderTop: '1px solid lightgrey', borderBottom: '1px solid lightgrey', marginBottom: 2 }}>
          <CopyEntries entries={props.entries} />
        </Box>
      </>
    )
  }
  const renderEntry = (entry) => {
    const today = new Date().setHours(0, 0, 0, 0)
    /* Editing entries if only allowed for admins or
    if user is not admin but the entry's date is greater or equal to today. */
    if (props.isAdmin || (new Date(entry.start) >= today)) {
      return (
        <EditEntry
          entry={entry}
          applicant={applicant}
          onEditEntry={props.onEditEntry}
          onEditMeeting={props.onEditMeeting}
          onCancelMeeting={props.onCancelMeeting}
          onDeleteCoachingEntry={props.onDeleteCoachingEntry}
          loading={props.loading}
          isAdmin={props.isAdmin}
        />
      )
    }
    return (
      <EntryView
        entry={entry}
      />
    )
  }

  if (entries.length > 0) {
    let sortedEntries = [...entries]
    if (props.descending) {
      sortedEntries = orderBy(entries, 'start', 'desc')
    }

    return (
      <>
        {props.showCopying && renderCopyEntries()}
        {sortedEntries.map((entry, index) => {
          return (
            <Accordion key={entry.start + index} defaultExpanded={false}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ '& .MuiAccordionSummary-content': { display: 'inline' } }}>
                <Box style={{ ...styles.row, ...styles.spaceBetween }}>
                  <Box style={styles.itemTextContent}>
                    <EventIcon style={styles.entryHeaderIcon} />
                    <Typography style={entry.isCancelled ? styles.cancelled : styles.heading}>
                      {format(new Date(entry.start), t('date_format')) + ' '}
                      {t('clock_abbr') + ' '}
                      {format(new Date(entry.start), t('time_format')) + ' '}
                      {renderEntryEndDate(entry)}
                    </Typography>
                  </Box>
                  {entry.isCancelled && <Chip label={t('cancelled')} style={styles.bgRed} />}
                </Box>
                <Typography style={entry.isCancelled ? styles.cancelled : styles.heading}>{`${t('type')}: ${getEntryTypeName(entry.entryType)}`}</Typography>
                <Typography style={entry.isCancelled ? styles.cancelled : styles.description}>{`${t('description')}: ${getEntryDescriptionHeader(entry)}`}</Typography>
              </AccordionSummary>
              <AccordionDetails style={styles.expandedDetails}>
                {renderCancelledInformation(entry, index)}
                {renderCalendarIntegration(entry)}
                {renderEntry(entry)}
              </AccordionDetails>
            </Accordion>
          )
        })}
      </>
    )
  }
  if (props.loading) return renderLoader()
  return (
    <Typography style={styles.emptyText}>
      {t('no_results')}
    </Typography>
  )
}

export default CoachingEntriesList
