import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { format } from 'date-fns'
import EventIcon from '@mui/icons-material/Event'
import classNames from 'classnames'
import { COLORS } from '../../utils/colors'
import Spinner from '../Spinner'
import EditInternship from './EditInternship'
import { getObjectNameTranslation } from '../../utils/transforms'

const useStyles = makeStyles(theme => ({
  calendar: {
    width: 312,
    display: 'flex',
    border: `1px solid ${COLORS.lightBorder}`
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerColumn: {
    flexBasis: '45%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  spinner: {
    display: 'flex',
    padding: '64px 16px',
    justifyContent: 'center'
  },
  entriesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 20,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 20,
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  entryHeaderIcon: {
    verticalAlign: 'middle',
    marginRight: 5
  },
  expandedDetails: {
    display: 'block'
  },
  editForm: {
    width: '100%'
  },
  cancelledBadgeColor: {
    backgroundColor: COLORS.lightBorder
  },
  cancelled: {
    color: COLORS.lightText,
    whiteSpace: 'pre-line'
  },
  red: {
    color: COLORS.red
  },
  bgRed: {
    backgroundColor: COLORS.red,
    color: COLORS.white
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  itemTextContent: {
    display: 'flex'
  },
  priorityIcon: {
    alignSelf: 'center',
    marginRight: 10
  },
  emptyText: {
    marginTop: 20,
    marginBottom: 20
  },
  description: {
    whiteSpace: 'pre-line'
  }
}))

const InternshipsList = (props) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [expanded, setExpanded] = React.useState(false)

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const renderLoader = () => {
    const { loading } = props
    if (loading) {
      return (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      )
    }
  }

  const renderStartDate = (internship) => {
    if (internship.start) {
      return (
        <>
          {format(new Date(internship.start), t('date_format')) + ' '}
        </>
      )
    }
    return null
  }

  const renderEntryEndDate = (internship) => {
    if (internship.end) {
      return (
        <>
          {' - '}
          {format(new Date(internship.end), t('date_format'))}
        </>
      )
    }
    return null
  }

  if (props.internships.length > 0) {
    return (
      props.internships.map((internship, index) => {
        return (
          <Accordion key={internship.id + index} defaultExpanded={false} expanded={expanded === internship.id} onChange={handleAccordionChange(internship.id)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.headerRow }}>
              <div className={classes.headerColumn}>
                <Typography className={classes.description}>{`${t('internship')}: ${internship.companyName}`}</Typography>
                <Typography className={classes.heading}>{`${t('type')}: ${getObjectNameTranslation(internship.cooperationType.name)}`}</Typography>
                <Typography className={classes.heading}>{`${t('job_title')}: ${getObjectNameTranslation(internship.escoName)}`}</Typography>
              </div>
              <div className={classes.headerColumn}>
                <Box className={classNames(classes.row, classes.spaceBetween)}>
                  <Box className={classes.itemTextContent}>
                    <EventIcon className={classes.entryHeaderIcon} />
                    <Typography className={classes.heading}>
                      {renderStartDate(internship)}
                      {renderEntryEndDate(internship)}
                    </Typography>
                  </Box>
                </Box>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.expandedDetails}>
              <EditInternship
                open={expanded === internship.id}
                internship={internship}
                loading={props.loading}
                savingInternship={props.savingInternship}
                projectId={props.projectId}
                cooperationTypeOptions={props.cooperationTypeOptions}
                editInternship={props.editInternship}
                confirmDelete={props.confirmDelete}
              />
            </AccordionDetails>
          </Accordion>
        )
      })
    )
  }
  return (
    <Typography className={classes.emptyText}>
      {!props.loading &&
        t('no_results')}
      {renderLoader()}
    </Typography>
  )
}

export default InternshipsList
