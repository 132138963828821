import { call, put } from 'redux-saga/effects'
import * as AttachmentsActions from '../redux/AttachmentsRedux'
import * as ErrorActions from '../redux/ErrorRedux'
import * as SuccessActions from '../redux/SuccessRedux'
import * as SavingActions from '../redux/SavingRedux'
import { isOkResponse } from '../services/api'
import { composeSuccess } from '../utils/transforms'

export function * getCoachingAttachments (api, action) {
  try {
    const { coachingId } = action.payload
    const response = yield call(api.getCoachingAttachments, coachingId)
    if (isOkResponse(response)) {
      const coachingAttachments = response.data
      yield put(AttachmentsActions.getCoachingAttachmentsSuccess(coachingAttachments))
    } else {
      yield put(AttachmentsActions.getCoachingAttachmentsFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(AttachmentsActions.getCoachingAttachmentsFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * addCoachingAttachment (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, coachingId, file } = action.payload

  try {
    const response = yield call(api.addCoachingAttachment, projectId, coachingId, file)
    if (isOkResponse(response)) {
      const success = composeSuccess('attachment_added')
      yield put(SuccessActions.displaySuccess(success))
      yield put(AttachmentsActions.addCoachingAttachmentSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(AttachmentsActions.getCoachingAttachmentsRequest(coachingId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(AttachmentsActions.addCoachingAttachmentFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(AttachmentsActions.addCoachingAttachmentFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * deleteCoachingAttachment (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, coachingId, fileId } = action.payload

  try {
    const response = yield call(api.deleteCoachingAttachment, projectId, coachingId, fileId)
    if (isOkResponse(response)) {
      const success = composeSuccess('attachment_deleted')
      yield put(SuccessActions.displaySuccess(success))
      yield put(AttachmentsActions.deleteCoachingAttachmentSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(AttachmentsActions.getCoachingAttachmentsRequest(coachingId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(AttachmentsActions.deleteCoachingAttachmentFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(AttachmentsActions.deleteCoachingAttachmentFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * addProjectAttachment (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, file } = action.payload
  try {
    const response = yield call(api.addProjectAttachment, projectId, file)
    if (isOkResponse(response)) {
      const success = composeSuccess('attachment_added')
      yield put(SuccessActions.displaySuccess(success))
      yield put(AttachmentsActions.addProjectAttachmentSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(AttachmentsActions.getProjectAttachmentsRequest(projectId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(AttachmentsActions.addProjectAttachmentFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(AttachmentsActions.addProjectAttachmentFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * getProjectAttachments (api, action) {
  try {
    const { projectId } = action.payload
    const response = yield call(api.getProjectAttachments, projectId)
    if (isOkResponse(response)) {
      const projectAttachments = response.data
      yield put(AttachmentsActions.getProjectAttachmentsSuccess(projectAttachments))
    } else {
      yield put(AttachmentsActions.getProjectAttachmentsFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(AttachmentsActions.getProjectAttachmentsFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * deleteProjectAttachment (api, action) {
  yield put(SavingActions.setSaving(true))
  const { projectId, fileId } = action.payload

  try {
    const response = yield call(api.deleteProjectAttachment, projectId, fileId)
    if (isOkResponse(response)) {
      const success = composeSuccess('attachment_deleted')
      yield put(SuccessActions.displaySuccess(success))
      yield put(AttachmentsActions.deleteProjectAttachmentSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(AttachmentsActions.getProjectAttachmentsRequest(projectId))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(AttachmentsActions.deleteProjectAttachmentFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(AttachmentsActions.deleteProjectAttachmentFailure())
    yield put(SavingActions.setSaving(false))
  }
}
