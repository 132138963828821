import React from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { differenceInYears } from 'date-fns'
import i18next from 'i18next'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ToolTip from '../ToolTip'
import AsyncAutocomplete from '../AsyncAutocomplete'
import Spinner from '../Spinner'
import TextArea from '../TextArea'
import orderBy from 'lodash/orderBy'
import { COLORS } from '../../utils/colors'
import { workExperiences } from '../../constants/workExperiences'
import { educationLevels } from '../../constants/educationLevels'
import { unemploymentDurations } from '../../constants/unemploymentDurations'
import { getLocale, getObjectNameTranslation } from '../../utils/transforms'
import DialogContentWrapper from '../DialogContentWrapper'
import MemoizedAutocomplete from '../MemoizedAutocomplete'
import { FormControl, InputLabel } from '@mui/material'

const styles = {
  root: {
    maxWidth: 800
  },
  textField: {
    marginTop: 1,
    marginBottom: 1
  },
  smallInput: {
    display: 'block'
  },
  multiline: {
    marginTop: 16
  },
  smallLabel: {
    marginTop: 2,
    color: COLORS.label
  },
  message: {
    padding: 16,
    backgroundColor: COLORS.light,
    borderRadius: 4,
    color: COLORS.darkGrey,
    fontSize: 12,
    margin: 8
  },
  actionsWrapper: {
    padding: 16,
    borderTop: `1px solid ${COLORS.lightBorder}`
  },
  cancelButton: {
    color: COLORS.red,
    marginRight: 10
  },
  marginTop20: {
    marginTop: 20
  },
  backgroundText: {
    whiteSpace: 'pre-line'
  },
  listRoot: {
    width: '100%',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  labelColor: {
    color: COLORS.label
  },
  occupationsText: {
    marginBottom: 12
  },
  spinner: {
    color: COLORS.white
  },
  textareaAutosize: {
    font: 'inherit',
    border: 0,
    letteSpacing: 'inherit',
    color: 'currentColor'
  },
  phoneNumbers: {
    display: 'flex',
    flexDirection: 'row'
  },
  otherNumbers: {
    marginLeft: 30
  }
}

const ApplicantEdit = (props) => {
  const [t] = useTranslation()
  const onFormChange = (applicant) => {
    // Save changed values
    props.onFormChange(applicant)
  }

  // This helper function is for API validation which doesn't (yet) have empty string logic
  const castCorrectEmptyValueIfNeeded = (val) => val === '' ? null : val

  const fetchNationalitiesIfNeeded = () => {
    if (props.countries.length === 0) props.getCountries()
  }

  const fetchLanguagesIfNeeded = () => {
    if (props.languages.length === 0) props.getLanguages()
  }

  const setFirstNames = (e) => {
    // Merge applicant state with new value
    const applicant = { firstNames: e.target.value }
    onFormChange(applicant)
  }
  const setLastName = (e) => {
    // Merge applicant state with new value
    const applicant = { lastName: e.target.value }
    onFormChange(applicant)
  }
  const setPhoneNumber = (e) => {
    // Merge applicant state with new value
    const applicant = { phoneNumber: e.target.value }
    onFormChange(applicant)
  }
  const setEmail = (e) => {
    // Merge applicant state with new value
    const applicant = { email: e.target.value }
    onFormChange(applicant)
  }
  const setAreaId = (areaId) => {
    // Merge applicant state with new value
    const applicant = { areaId }
    onFormChange(applicant)
  }
  const setStreetAddress = (e) => {
    // Merge applicant state with new value
    const applicant = { streetAddress: e.target.value }
    onFormChange(applicant)
  }
  const handleSearchCodeChange = (searchCode) => {
    props.searchPostalCodes(searchCode)
  }
  const setPostalCode = (postalCodeId) => {
    // Merge applicant state with new value
    const applicant = { postalCodeId }
    onFormChange(applicant)
  }
  const onPostalCodeClose = (event, reason) => {
    // If onClose reason: 'escape', then clear searched codes
    // Atleast should return this according to the docs, but doesn't!!!
    /* if (reason === 'escape') {
      props.clearSearchedPostalCodes()
    } */
    // Instead when the value is 0, the value has already been set by traditional onValueChange
    // So when the value is anything but 0, we can clear the searched codes because user unexpectedly
    // exited the autocomplete without selecting a value
    if (event.target.value !== 0) props.clearSearchedPostalCodes()
  }
  const setOrderer = (e) => {
    // Merge applicant state with new value
    const applicant = { ordererName: e.target.value }
    onFormChange(applicant)
  }
  const setAdditionalInfo = (e) => {
    // Merge applicant state with new value
    const applicant = { additionalInfo: castCorrectEmptyValueIfNeeded(e.target.value) }
    onFormChange(applicant)
  }
  const handleUnemploymentDurationChange = (e) => {
    // Merge applicant state with new value
    const applicant = { unemploymentDuration: e.target.value }
    onFormChange(applicant)
  }
  const handleNationalityChange = (nationalityId) => {
    // Merge applicant state with new value
    const applicant = { nationalityId }
    onFormChange(applicant)
  }
  const handleNativeLanguageChange = (nativeLanguageId) => {
    // Merge applicant state with new value
    const applicant = { nativeLanguageId }
    onFormChange(applicant)
  }
  const handleWorkExperienceChange = (e) => {
    // Merge applicant state with new value
    const applicant = { workExperience: e.target.value }
    onFormChange(applicant)
  }
  const handleEducationChange = (educationId) => {
    // Merge applicant state with new value
    const applicant = { levelOfEducation: educationId }
    onFormChange(applicant)
  }
  const handleBirthdayChange = (date) => {
    // Merge applicant state with new value
    const applicant = { dateOfBirth: date }
    onFormChange(applicant)
  }
  const setSSN = (e) => {
    // Merge applicant state with new value
    const applicant = { socialSecurityNumber: e.target.value }
    onFormChange(applicant)
  }
  const toggleApplyingForJobs = () => {
    // Merge applicant state with new value
    const applicant = { staffPointJobSeeker: !props.applicantState.staffPointJobSeeker }
    onFormChange(applicant)
  }

  const handleAttendancesChange = (e) => {
    let applicant
    if (Number.isNaN(parseInt(e.target.value))) applicant = { attendances: null }
    else if (e.target.value <= 0) applicant = { attendances: null }
    else applicant = { attendances: parseInt(e.target.value) }
    onFormChange(applicant)
  }

  const handleStudyPointsChange = (e) => {
    let applicant
    if (Number.isNaN(parseInt(e.target.value))) applicant = { studyPoints: null }
    else if (e.target.value <= 0) applicant = { studyPoints: null }
    else applicant = { studyPoints: parseInt(e.target.value) }
    onFormChange(applicant)
  }

  const getAge = () => {
    if (props.applicantState.dateOfBirth) {
      return differenceInYears(new Date(), new Date(props.applicantState.dateOfBirth))
    } else {
      return '-'
    }
  }

  const getUnemploymentDurationOptions = () => {
    const durations = [
      <MenuItem value='' key='0'>-</MenuItem>
    ]
    for (const key in unemploymentDurations) {
      if (Object.prototype.hasOwnProperty.call(unemploymentDurations, key) && unemploymentDurations[key] != null) {
        durations.push(
          <MenuItem value={unemploymentDurations[key]} key={unemploymentDurations[key]}>
            {t(key)}
          </MenuItem>
        )
      }
    }
    return durations
  }

  const getWorkExperienceOptions = () => {
    const experiences = [
      <MenuItem value='' key='0'>-</MenuItem>
    ]
    for (const key in workExperiences) {
      if (Object.prototype.hasOwnProperty.call(workExperiences, key) && workExperiences[key] != null) {
        experiences.push(
          <MenuItem value={workExperiences[key]} key={workExperiences[key]}>
            {t(key)}
          </MenuItem>
        )
      }
    }
    return experiences
  }

  const getEducationLevels = () => {
    const levels = []
    for (const key in educationLevels) {
      if (Object.prototype.hasOwnProperty.call(educationLevels, key) && educationLevels[key] != null) {
        levels.push({ value: educationLevels[key], label: t(key) })
      }
    }
    return orderBy(levels, ['label'], ['asc'])
  }

  const getNativeLanguages = () => {
    const languages = props.languages || ['']
    let array = languages.length > 0 ? [] : [getPreselectedValue('nativeLanguage')]
    if (array[0] == null) array = []
    for (const key in languages) {
      if (Object.prototype.hasOwnProperty.call(languages, key) && languages[key] != null) {
        array.push({ value: languages[key].id, label: getObjectNameTranslation(languages[key].name) })
      }
    }
    return array
  }

  const getNationalityOptions = () => {
    const countries = props.countries || ['']
    let array = countries.length > 0 ? [] : [getPreselectedValue('nationality')]
    if (array[0] == null) array = []
    for (const key in countries) {
      if (Object.prototype.hasOwnProperty.call(countries, key) && countries[key] != null) {
        array.push({ value: countries[key].id, label: getObjectNameTranslation(countries[key].name) })
      }
    }
    return array
  }

  const getPostalCodeOptions = () => {
    const { searchedPostalCodes } = props
    const array = []
    for (const key in searchedPostalCodes) {
      if (Object.prototype.hasOwnProperty.call(searchedPostalCodes, key) && searchedPostalCodes[key] != null) {
        array.push({ value: searchedPostalCodes[key].id, label: `${searchedPostalCodes[key].code} ${searchedPostalCodes[key].postOffice}` })
      }
    }
    return orderBy(array, ['label'], ['asc'])
  }

  const getPreselectedValue = (key) => {
    if (props.applicantState[key]) {
      if (key === 'postalCode') return { value: props.applicantState[key].id, label: `${props.applicantState[key].code} ${props.applicantState[key].postOffice}` }
      return { value: props.applicantState[key].id, label: getObjectNameTranslation(props.applicantState[key].name) }
    }
  }

  const getBackGroundHelp = () => {
    if (props.applicantState && props.applicantState.backgroundHelp) {
      const userLang = i18next.language.substring(0, 2)
      return props.applicantState.backgroundHelp[userLang]
    }
    return ''
  }

  const renderPhoneNumberInformation = () => {
    const { applicantState } = props
    if (applicantState.phoneNumberInImport) {
      return (
        <div style={{ ...styles.phoneNumbers, ...styles.smallLabel }}>
          <TextField
            variant='standard'
            label={t('phone')}
            sx={styles.textField}
            value={applicantState.phoneNumber || ''} // https://reactjs.org/docs/forms.html#controlled-components
            onChange={setPhoneNumber}
            required
          />
          <ToolTip title={t('from_koulutusportti')}>
            <div style={styles.otherNumbers}>
              <Typography variant='caption' sx={styles.smallLabel}>{t('others')}</Typography>
              <Typography>{applicantState.phoneNumberInImport}</Typography>
            </div>
          </ToolTip>
        </div>
      )
    } else {
      return (
        <TextField
          variant='standard'
          label={t('phone')}
          sx={styles.textField}
          value={applicantState.phoneNumber || ''} // https://reactjs.org/docs/forms.html#controlled-components
          onChange={setPhoneNumber}
          fullWidth
          required
        />
      )
    }
  }

  const renderBasicInformationContent = () => {
    const { fetching, applicantState } = props
    if (!fetching && applicantState) {
      return (
        <DialogContentWrapper>
          <TextField
            variant='standard'
            label={t('first_name')}
            sx={styles.textField}
            value={applicantState.firstNames || ''}
            onChange={setFirstNames}
            fullWidth
            required
          />
          <TextField
            variant='standard'
            label={t('last_name')}
            sx={styles.textField}
            value={applicantState.lastName || ''}
            onChange={setLastName}
            fullWidth
            required
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
            <DatePicker
              openTo='year'
              toolbarTitle=''
              views={['year', 'month', 'day']}
              value={applicantState.dateOfBirth}
              mask={t('date_format_mask')}
              inputFormat={t('date_format')}
              onChange={handleBirthdayChange}
              cancelLabel={t('cancel')}
              DialogProps={{ sx: { color: 'primary', fontSize: 'small', '& .MuiPickersToolbar-root': { width: '100%', backgroundColor: COLORS.green, color: COLORS.white }, '& .MuiTypography-root': { color: COLORS.white } } }}
              renderInput={props => <TextField {...props} label={t('birthday')} variant='standard' InputProps={{ ...props.InputProps, color: 'primary' }} inputProps={{ ...props.inputProps, color: 'primary' }} />}
            />
          </LocalizationProvider>
          <Typography variant='caption' sx={{ ...styles.smallLabel, ...styles.smallInput }}>{t('age')}</Typography>
          <Typography>{getAge()}</Typography>

          <TextField
            variant='standard'
            label={t('social_security_number')}
            sx={styles.textField}
            value={applicantState.socialSecurityNumber || ''} // https://reactjs.org/docs/forms.html#controlled-components
            onChange={setSSN}
            fullWidth
          />

          {renderPhoneNumberInformation()}
          <TextField
            variant='standard'
            label={t('email')}
            sx={styles.textField}
            value={applicantState.email || ''} // https://reactjs.org/docs/forms.html#controlled-components
            onChange={setEmail}
            fullWidth
            required
          />
          <MemoizedAutocomplete
            groupBy={(option) => option.region}
            value={applicantState.areaId || ''}
            options={[...props.regionsWithCities]}
            onChange={setAreaId}
            label={t('hometown')}
            largeText
            sx={styles.textField}
          />
          <TextField
            variant='standard'
            label={t('street_address')}
            sx={styles.textField}
            value={applicantState.streetAddress || ''} // https://reactjs.org/docs/forms.html#controlled-components
            onChange={setStreetAddress}
            fullWidth
          />

          <div style={styles.smallLabel}>
            <AsyncAutocomplete
              value={applicantState.postalCodeId || ''}
              options={[...getPostalCodeOptions()]}
              onChange={handleSearchCodeChange}
              onValueSelect={setPostalCode}
              onClose={onPostalCodeClose}
              label={t('zip_code')}
              largeText
              searchingOptions={props.searchingOptions}
              preselectedValue={getPreselectedValue('postalCode')}
            />
          </div>
          <div style={{ ...styles.textField, marginTop: styles.smallLabel.marginTop * 6 }}>
            <MemoizedAutocomplete
              value={applicantState.nationalityId || ''}
              options={[...getNationalityOptions()]}
              onChange={handleNationalityChange}
              onOpen={fetchNationalitiesIfNeeded}
              placeholder={`${t('select')}...`}
              largeText
              label={t('nationality')}
            />
          </div>
          <div style={{ ...styles.textField, marginTop: styles.smallLabel.marginTop * 6 }}>
            <MemoizedAutocomplete
              value={applicantState.nativeLanguageId || ''}
              options={[...getNativeLanguages()]}
              onChange={handleNativeLanguageChange}
              onOpen={fetchLanguagesIfNeeded}
              label={t('native_language')}
              largeText
            />
          </div>
          <div style={{ ...styles.textField, marginTop: styles.smallLabel.marginTop * 6 }}>
            <MemoizedAutocomplete
              value={applicantState.levelOfEducation || ''}
              options={[...getEducationLevels()]}
              onChange={handleEducationChange}
              placeholder={`${t('select_highest')}...`}
              label={t('education_level')}
              largeText
            />
          </div>
          <FormControl sx={styles.smallLabel}>
            <InputLabel id='workExperience' variant='standard'>{t('work_experience')}</InputLabel>
            <Select
              labelId='workExperience'
              value={applicantState.workExperience || ''}
              onChange={handleWorkExperienceChange}
              fullWidth
              variant='standard'
              sx={styles.textField}
            >
              {getWorkExperienceOptions()}
            </Select>
          </FormControl>
          <FormControl sx={styles.smallLabel}>
            <InputLabel variant='standard'>{t('unemployment_duration')}</InputLabel>
            <Select
              value={applicantState.unemploymentDuration || ''}
              onChange={handleUnemploymentDurationChange}
              fullWidth
              variant='standard'
              sx={styles.textField}
            >
              {getUnemploymentDurationOptions()}
            </Select>
          </FormControl>
          <TextField
            value={Number.isInteger(parseInt(applicantState.attendances)) ? applicantState.attendances : ''}
            onChange={handleAttendancesChange}
            fullWidth
            variant='standard'
            sx={styles.textField}
            type='number'
            label={t('participation_duration')}
            inputProps={{ min: 0 }}
          />

          <TextField
            value={Number.isInteger(parseInt(applicantState.studyPoints)) ? applicantState.studyPoints : ''}
            onChange={handleStudyPointsChange}
            fullWidth
            variant='standard'
            sx={styles.textField}
            type='number'
            label={t('study_points')}
            inputProps={{ min: 0 }}
          />
          <div style={{ ...styles.smallLabel, marginTop: 20 }}>
            <Typography variant='caption' style={{ fontSize: 15 }}>{t('applying_for_staffpoint_jobs')}</Typography>
            <Checkbox
              checked={applicantState.staffPointJobSeeker}
              onChange={toggleApplyingForJobs}
              color='primary'
            />
          </div>
          <TextField
            variant='standard'
            label={t('orderer')}
            sx={styles.textField}
            value={applicantState.ordererName || ''} // https://reactjs.org/docs/forms.html#controlled-components
            onChange={setOrderer}
            fullWidth
          />
          <Typography sx={styles.smallLabel} variant='caption'>{t('additional_info')}</Typography>
          <TextArea
            label='additional_info'
            value={applicantState.additionalInfo}
            onChange={setAdditionalInfo}
          />
          {renderBackgroundInfo()}
        </DialogContentWrapper>
      )
    } else {
      return (
        <Spinner centered />
      )
    }
  }

  const renderBackgroundInfo = () => {
    const { applicantState } = props
    if (applicantState && applicantState.backgroundHelp) {
      return (
        <div style={styles.marginTop20}>
          <Typography variant='caption'>{getBackGroundHelp()}</Typography>
          <Typography styles={{ root: styles.backgroundText }}>
            {applicantState.backgroundText || ''}
          </Typography>
        </div>
      )
    } else return null
  }

  return renderBasicInformationContent()
}

export default ApplicantEdit
