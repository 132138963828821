import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

const styles = {
  h6: {
    marginTop: 20,
    marginBottom: 20
  }
}

const ListHeader = (props) => {
  const [t] = useTranslation()

  return (
    <Typography variant='h6' style={styles.h6}>
      {t(props.title)}
    </Typography>
  )
}

export default ListHeader
