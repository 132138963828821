import React, { Fragment } from 'react'
import { withStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import ProjectMenu from './ProjectMenu'
import { COLORS } from '../../utils/colors'
import { getCurrentLang } from '../../utils/transforms'

const styles = theme => ({
  card: {
    marginBottom: 32
  },
  cellRow: {
    paddingTop: 4,
    paddingBottom: 4,
    display: 'flex',
    alignItems: 'center'
  },
  cellIcon: {
    color: COLORS.white,
    margin: '0 4px'
  },
  smallCellIcon: {
    color: COLORS.darkGrey,
    fontSize: 12,
    marginRight: 8
  },
  link: {
    color: COLORS.grey,
    textDecoration: 'none',
    '&:hover': {
      color: COLORS.darkGrey
    }
  },
  empty: {
    color: COLORS.lightText
  },
  leftCell: {
    padding: '8px 16px',
    width: 90
  },
  cell: {
    padding: '8px 16px'
  },
  active: {
    color: COLORS.greenDarken
  },
  enrolled: {
    color: COLORS.orange
  },
  ended: {
    color: COLORS.red
  },
  header: {
    background: COLORS.orange,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 9,
    paddingTop: 4,
    paddingBottom: 4,
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignContent: 'stretch'
  },
  leftHeader: {
    display: 'flex',
    width: '50%'
  },
  rightHeader: {
    display: 'flex'
  },
  headerText: {
    color: COLORS.white,
    display: 'inline-flex',
    transition: 'none',
    alignItems: 'center'
  },
  menuIconContainer: {
    color: COLORS.white
  }
})

const ProjectRowMobile = (props) => {
  const [t] = useTranslation()
  const { classes, project } = props

  const getProjectName = () => {
    if (getCurrentLang() === 'fi') {
      return project.name.fi || project.name.en
    } else {
      return project.name.en || project.name.fi
    }
  }

  const renderProjectMenu = () => {
    return (
      <div className={classes.menuIconContainer}>
        <ProjectMenu
          project={project}
          showProjectDialog={props.showProjectDialog}
        />
      </div>
    )
  }

  const renderProjectMobileRow = () => {
    return (
      <Fragment key={project.id}>
        <TableRow>
          <TableCell className={classes.leftCell}>{t('area')}</TableCell>
          <TableCell className={classes.cell}>{project.areaId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.leftCell}>{t('start_date')}</TableCell>
          <TableCell className={classes.cell}>{project.start ? moment(project.start).format('DD.MM.YYYY') : '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.leftCell}>{t('end_date')}</TableCell>
          <TableCell className={classes.cell}>{project.end ? moment(project.end).format('DD.MM.YYYY') : '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.leftCell}>{t('service')}</TableCell>
          <TableCell className={classes.cell}>{props.getServiceName(project)}</TableCell>
        </TableRow>
      </Fragment>
    )
  }

  return (
    <Card className={classes.card}>
      <div className={classes.header}>
        <div className={classes.leftHeader}>
          <Typography className={classes.headerText}>{getProjectName()}</Typography>
        </div>
        <div className={classes.rightHeader}>
          {renderProjectMenu()}
        </div>
      </div>
      <Table>
        <TableBody>
          {renderProjectMobileRow()}
        </TableBody>
      </Table>
    </Card>
  )
}

export default withStyles(styles)((ProjectRowMobile))
