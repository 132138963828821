import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { COLORS } from '../../utils/colors'

const styles = theme => ({
  root: {
    padding: 4,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500,
      margin: 20
    },
    margin: 10
  },
  title: {
    padding: '16px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  actionsWrapper: {
    margin: 0,
    marginTop: 15,
    padding: '6px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  sendButton: {
    [theme.breakpoints.up('sm')]: {
      margin: '10px 0px',
      marginLeft: 20
    },
    marginLeft: 15,
    width: 102
  },
  cancelButton: {
    color: COLORS.red
  },
  spinner: {
    color: COLORS.white
  },
  formControl: {
    width: '100%'
  }
})

class RollbackAlertDialog extends Component {
  componentDidUpdate (nextProps) {
    if (this.props.fetching && !nextProps.fetching) {
      this.props.close()
    }
  }

  renderButtonContent = () => {
    const { fetching, t, classes } = this.props
    if (fetching) {
      return <CircularProgress className={classes.spinner} size={16} />
    }
    return t('yes')
  }

  render () {
    const { classes, t, open, close, rollbackText, rollback } = this.props
    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby='form-dialog-title'
        classes={{ paper: classes.root }}
        fullWidth
      >
        <DialogTitle id='form-dialog-title' classes={{ root: classes.title }}>
          {rollbackText}?
        </DialogTitle>
        <DialogActions classes={{ root: classes.actionsWrapper }}>
          <Button onClick={close} className={classes.cancelButton}>
            {t('cancel')}
          </Button>
          <Button
            onClick={rollback}
            variant='contained'
            color='primary'
            className={classes.sendButton}
          >
            {this.renderButtonContent()}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(withTranslation()(RollbackAlertDialog))
