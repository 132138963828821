import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { COLORS } from '../../utils/colors'
import { getObjectNameTranslation } from '../../utils/transforms'
import Spinner from '../Spinner'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(theme => ({
  h6: {
    marginTop: 20,
    marginBottom: 20
  },
  label: {
    color: COLORS.label,
    fontSize: 14,
    marginTop: 16
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    width: 200
  }
}))

export default function ProjectBasicDetails (props) {
  const classes = useStyles()
  const [t] = useTranslation()

  const getResponsiblePersonName = (project) => {
    if (!project) return null
    else if (isEmpty(project.responsiblePerson)) return '-'
    return `${project.responsiblePerson.firstName} ${project.responsiblePerson.lastName}`
  }

  const renderContent = () => {
    const { project } = props
    if (project) {
      return (
        <div>
          <Typography className={classes.label}>{t('name_fi')}</Typography>
          <Typography>{project.name.fi}</Typography>
          <Typography className={classes.label}>{t('name_en')}</Typography>
          <Typography>{project.name.en}</Typography>
          <Typography className={classes.label}>{t('service')}</Typography>
          <Typography>{getObjectNameTranslation(project.serviceName)}</Typography>
          <Typography className={classes.label}>{t('code')}</Typography>
          <Typography>{project.code}</Typography>
          <Typography className={classes.label}>{t('passive_phase_storage_time')}</Typography>
          <Typography>{project.passiveStorageTime ? project.passiveStorageTime + t('y') : '-'}</Typography>
          <Typography className={classes.label}>{t('responsible_trainer')}</Typography>
          <Typography>{getResponsiblePersonName(project)}</Typography>
          <div className={classes.row}>
            <div className={classes.col}>
              <Typography className={classes.label}>{t('start_date')}</Typography>
              <Typography>{project.start ? format(new Date(project.start), t('date_format')) : '-'}</Typography>
            </div>
            <div className={classes.col}>
              <Typography className={classes.label}>{t('end_date')}</Typography>
              <Typography>{project.end ? format(new Date(project.end), t('date_format')) : '-'}</Typography>
            </div>
          </div>
          <Typography className={classes.label}>{t('area')}</Typography>
          <Typography>{project.areaId}</Typography>
          <Typography className={classes.label}>{t('description_fi')}</Typography>
          <Typography>{project.description ? project.description.fi : '-'}</Typography>
          <Typography className={classes.label}>{t('description_en')}</Typography>
          <Typography>{project.description ? project.description.en : '-'}</Typography>
          <Typography className={classes.label}>{t('background_help_fi')}</Typography>
          <Typography>{project.backgroundHelp ? project.backgroundHelp.fi : '-'}</Typography>
          <Typography className={classes.label}>{t('background_help_en')}</Typography>
          <Typography>{project.backgroundHelp ? project.backgroundHelp.en : '-'}</Typography>
        </div>
      )
    } else return <Spinner />
  }
  return renderContent()
}
