import React, { useEffect, useReducer } from 'react'
import { Box } from '@mui/material'
import CreateMeetingForm from './CreateMeetingForm'
import CreateNoteForm from './CreateNoteForm'
import CreateSummaryForm from './CreateSummaryForm'
import EntryTypeSelect from './EntryTypeSelect'
import { COLORS } from '../../utils/colors'
import { isMeetingEntry, isNoteEntry, isSummaryEntry } from '../../utils/entries'

const styles = {
  root: {
    maxWidth: 800,
    alignItems: 'center'
  },
  content: {
    padding: '16px 24px',
    display: 'flex'
  },
  actionsWrapper: {
    padding: 16
  },
  spinner: {
    color: COLORS.white
  },
  entryBox: {
    minHeight: 200
  }
}

const CreateEntry = (props) => {
  const initialState = {
    entryType: ''
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)

  /* Created event happened */
  useEffect(() => {
    if (props.entryCreated) {
      handleClose()
      props.okCreate()
    }
  }, [props.entryCreated])

  useEffect(() => {
    if (isSummaryEntry(state.entryType)) {
      props.getCoachingEntrySummaryText(props.applicant.coachingId)
    }
  }, [state.entryType])

  const handleResetState = () => setState({ ...initialState })

  const handleEntryTypeChange = (entryType) => setState({ entryType })

  const handleSave = (formState) => {
    if (!props.loading) {
      // Merge selected entry type with form state
      const entry = { ...formState, entryType: state.entryType }
      const projectId = props.applicant.projectId
      props.createEntry(projectId, entry)
    }
  }

  const handleClose = (e) => {
    handleResetState()
    props.goBack(e, 0)
  }

  const renderSelectedEntryTypeForm = () => {
    const { entryType } = state
    if (entryType === '') return null
    else if (isMeetingEntry(entryType)) {
      return (
        <CreateMeetingForm
          goBack={props.goBack}
          applicant={props.applicant}
          user={props.user}
          createMeeting={handleSave}
          loading={props.loading}
          okCreate={props.acknowledgeMeetingSaveSuccess}
          isAdmin={props.isAdmin}
        />
      )
    } else if (isNoteEntry(entryType)) {
      return (
        <CreateNoteForm
          goBack={props.goBack}
          applicant={props.applicant}
          user={props.user}
          create={handleSave}
          loading={props.loading}
          isAdmin={props.isAdmin}
        />
      )
    } else if (isSummaryEntry(entryType)) {
      return (
        <CreateSummaryForm
          goBack={props.goBack}
          applicant={props.applicant}
          user={props.user}
          create={handleSave}
          loading={props.loading}
          coachingEntrySummary={props.coachingEntrySummary}
          isAdmin={props.isAdmin}
        />
      )
    }
  }

  return (
    <Box style={styles.entryBox}>
      <EntryTypeSelect
        value={state.entryType}
        onTypeChange={handleEntryTypeChange}
      />
      {renderSelectedEntryTypeForm()}
    </Box>
  )
}

export default CreateEntry
