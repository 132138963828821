import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  services: [],
  servicesTotal: 0,
  allIds: [],
  serviceFilters: {},
  visibleServices: [], // Subset of services, displayed in current page of services table.
  previousVisibleServices: [], // Previous visible services are stored so that view changes can be reverted if API call fails.
  fetching: false,
  error: null,
  pageSize: 10,
  page: 1,
  selectAll: false,
  servicesOptions: [],
  saveSuccessful: false
})

/* ------------- Types and Action Creators ------------- */

export const getServices = createAction('GET_SERVICES')
export const getServicesSuccess = createAction('GET_SERVICES_SUCCESS', (services, servicesTotal, allIds) => ({ services, servicesTotal, allIds }))
export const getServicesFailure = createAction('GET_SERVICES_FAILURE', (error) => ({ error }))
export const changeServicesPage = createAction('CHANGE_SERVICES_PAGE', (page) => ({ page }))
export const changeServiceRowsPerPage = createAction('CHANGE_SERVICE_ROWS_PER_PAGE', (pageSize) => ({ pageSize }))
export const setServiceFilters = createAction('SET_SERVICE_FILTERS', (serviceFilters) => ({ serviceFilters }))
export const reloadVisibleServices = createAction('RELOAD_VISIBLE_SERVICES')
export const reloadVisibleServicesSuccess = createAction('RELOAD_VISIBLE_SERVICES_SUCCESS', (services, servicesTotal, allIds) => ({ services, servicesTotal, allIds }))
export const reloadVisibleServicesFailure = createAction('RELOAD_VISIBLE_SERVICES_FAILURE', (error) => ({ error }))
export const getServicesOptions = createAction('GET_SERVICES_OPTIONS')
export const getServicesOptionsSuccess = createAction('GET_SERVICES_OPTIONS_SUCCESS', (servicesOptions) => ({ servicesOptions }))
export const getServicesOptionsFailure = createAction('GET_SERVICES_OPTIONS_FAILURE', (error) => ({ error }))
export const createService = createAction('CREATE_SERVICE', (service) => ({ service }))
export const createServiceSuccess = createAction('CREATE_SERVICE_SUCCESS')
export const createServiceFailure = createAction('CREATE_SERVICE_FAILURE', (error) => ({ error }))
export const acknowledgeServiceSaveSuccess = createAction('ACKNOWLEDGE_SERVICE_SAVE_SUCCESS')
export const reloadAllFetchedServices = createAction('RELOAD_ALL_FETCHED_SERVICES')
export const reloadAllFetchedServicesSuccess = createAction('RELOAD_ALL_FETCHED_SERVICES_SUCCESS', (services, servicesTotal, allIds) => ({ services, servicesTotal, allIds }))
export const reloadAllFetchedServicesFailure = createAction('RELOAD_ALL_FETCHED_SERVICES_FAILURE', (error) => ({ error }))
export const editService = createAction('EDIT_SERVICE', (service) => ({ service }))
export const editServiceSuccess = createAction('EDIT_SERVICE_SUCCESS')
export const editServiceFailure = createAction('EDIT_SERVICE_FAILURE', (error) => ({ error }))
export const resetServiceFilters = createAction('RESET_SERVICE_FILTERS')

/* ------------- Reducers ------------- */
const reducers = createReducer(
  {
    [getServices]: (state) => state.merge({ fetching: true }),
    [getServicesSuccess]: (state, { services, servicesTotal, allIds }) => {
      /* First fetch */
      if (state.page === 1) {
        /* First results to the screen */
        const firstResults = services.slice(0, state.pageSize)

        return state.merge({
          fetching: false,
          servicesTotal,
          allIds,
          services,
          visibleServices: [...firstResults]
        })
      }
      /* Add next results to the services array */
      return state.merge({
        fetching: false,
        servicesTotal,
        allIds,
        services: [...state.services, ...services],
        visibleServices: [...services]
      })
    },
    [getServicesFailure]: (state, { error }) => state.merge({ fetching: false, error }),
    [changeServicesPage]: (state, { page }) => {
      const nextServices = state.services.slice((state.pageSize * (page - 1)), (state.pageSize * (page)))
      return state.merge({ page, visibleServices: [...nextServices] })
    },
    [changeServiceRowsPerPage]: (state, { pageSize }) => {
      return state.merge({ pageSize, page: 1, services: [] })
    },
    [setServiceFilters]: (state, { serviceFilters }) => {
      return state.merge({
        serviceFilters,

        // Reset services list state
        services: [],
        visibleServices: [],
        previousVisibleServices: [],
        servicesTotal: 0,
        pageSize: state.pageSize,
        page: 1
      })
    },
    [reloadVisibleServices]: (state) => state.merge({ fetching: true }),
    [reloadVisibleServicesSuccess]: (state, { services, servicesTotal, allIds }) => {
      const newServices = [
        ...state.services.slice(0, ((state.page - 1) * state.pageSize)),
        ...services,
        ...state.services.slice((state.page * state.pageSize))
      ]
      const visibleServices = services

      return state.merge({ fetching: false, services: newServices, visibleServices, servicesTotal, allIds })
    },
    [getServicesOptions]: (state) => state.merge({ servicesFetching: true }),
    [getServicesOptionsSuccess]: (state, { servicesOptions }) => state.merge({ servicesOptions, servicesFetching: false }),
    [getServicesOptionsFailure]: (state, { error }) => state.merge({ error, servicesFetching: false }),
    [createService]: (state) => state.merge({ fetching: true }),
    [createServiceSuccess]: (state) => state.merge({ fetching: false, saveSuccessful: true }),
    [createServiceFailure]: (state, { error }) => state.merge({ error, fetching: false, saveSuccessful: false }),
    [editService]: (state) => state.merge({ fetching: true }),
    [editServiceSuccess]: (state) => state.merge({ fetching: false, saveSuccessful: true }),
    [editServiceFailure]: (state, { error }) => state.merge({ error, fetching: false, saveSuccessful: false }),
    [acknowledgeServiceSaveSuccess]: (state) => state.merge({ saveSuccessful: false }),
    [reloadAllFetchedServices]: (state) => state.merge({ fetching: true }),
    [reloadAllFetchedServicesSuccess]: (state, { services, servicesTotal, allIds }) => {
      const visibleServices = [...services.slice(((state.page - 1) * state.pageSize), services.length)]
      return state.merge({ fetching: false, services, visibleServices, servicesTotal, allIds })
    },
    [reloadAllFetchedServicesFailure]: (state, { error }) => state.merge({ error, fetching: false }),
    [resetServiceFilters]: (state) => state.merge({ serviceFilters: {} })
  },
  INITIAL_STATE
)

export default reducers
