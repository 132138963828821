import React from 'react'
import { COLORS } from '../utils/colors'

const styles = {
  wrapper: {
    color: COLORS.label,
    marginTop: 12
  }
}
const FormFieldWrapper = (props) => {
  return (
    <div style={(styles.wrapper, props.style)}>
      {props.children}
    </div>
  )
}
export default FormFieldWrapper
