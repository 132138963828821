import React, { useEffect, useReducer, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'

import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import CircularProgress from '@mui/material/CircularProgress'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import DialogHeader from '../DialogHeader'
import { COLORS } from '../../utils/colors'
import SaveButton from '../SaveButton'
import FileUploadInput from '../FileUploadInput'
import FormFieldWrapper from '../FormFieldWrapper'
import Autocomplete from '../Autocomplete'
import { getImportApplicantsTemplate } from '../../services/api'
import { getObjectNameTranslation } from '../../utils/transforms'
import { customReducer } from '../../utils/reducer'
import { FormControl, InputLabel, useMediaQuery } from '@mui/material'

const springerApplicant = 'springer'
const koulutusporttiApplicant = 'koulutusportti'

const baseLinkStyle = {
  marginBottom: 20,
  marginRight: 10
}
const styles = {
  root: {
    maxWidth: 500,
    margin: 20
  },
  dialogContentWrapper: {
    padding: '16px 24px',
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
    flex: 1,
    minHeight: 480
  },
  columnWrapper: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    padding: { md: '16px 24px', sm: 10 }
  },
  actionsWrapper: {
    margin: 0,
    padding: { md: '6px 24px', sm: 10 }
  },
  cancelButton: {
    marginRight: 10
  },
  spinner: {
    color: COLORS.white
  },
  formControl: {
    width: '100%',
    marginTop: 10
  },
  marginTop10: {
    marginTop: 10
  },
  marginTop20: {
    marginTop: 20
  },
  datePicker: {
    width: '100%',
    '& input[type=text]': {
      fontSize: '1rem',
      color: COLORS.label
    }
  },
  labelColor: {
    color: COLORS.label,
    display: 'inline-block'
  },
  newStatus: {
    width: '100%',
    marginTop: 10
  },
  notificationText: {
    color: COLORS.red,
    marginTop: 20
  },
  link: {
    ...baseLinkStyle
  },
  disabledLink: {
    ...baseLinkStyle,
    color: COLORS.grey,
    cursor: 'initial'
  }
}

const ApplicantFileUploadDialog = (props) => {
  const [t] = useTranslation()
  const initialState = {
    file: null,
    serviceId: null,
    projectId: null,
    serviceProjects: [],
    isUploading: false,
    isDownloadingExampleFile: false,
    importFileType: null
  }
  const reducer = (state, newState) => customReducer(state, newState, initialState)
  const [state, setState] = useReducer(reducer, initialState)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const fileRef = useRef()

  useEffect(() => {
    if (props.springerFileUploadSuccess && state.isUploading) {
      // Close dialog after uploading and upload successfull
      closeDialogOnSuccess()
    }
  }, [props.springerFileUploadSuccess])

  useEffect(() => {
    if (props.springerFileUploadFailure) {
      setState({ isUploading: false })
      if (fileRef && fileRef.current) fileRef.current.resetInput()
    }
  }, [props.springerFileUploadFailure])

  useEffect(() => {
    if (props.koulutusporttiFileUploadSuccess && state.isUploading) closeDialogOnSuccess()
  }, [props.koulutusporttiFileUploadSuccess])

  useEffect(() => {
    if (props.koulutusporttiFileUploadFailure) handleFileUploadFailure()
  }, [props.koulutusporttiFileUploadFailure])

  const handleServiceChange = (serviceId) => {
    setState({ serviceId })
    const filters = { services: [{ value: serviceId }] }
    props.getServiceProjectsOptions(filters)
  }
  const setProject = (projectId) => setState({ projectId })

  const closeDialogOnSuccess = () => {
    // Close dialog after uploading and upload successfull
    handleClose()
    props.dismissResult()
  }

  const handleFileUploadFailure = () => {
    setState({ isUploading: false })
    if (fileRef && fileRef.current) fileRef.current.resetInput()
  }

  const getServiceProjectOptions = () => {
    if (state.serviceId != null) {
      const serviceProjects = []
      props.serviceProjectsOptions.forEach(project => {
        serviceProjects.push({
          label: getObjectNameTranslation(project.name),
          value: project.id
        })
      })
      return serviceProjects
    } else return []
  }

  const shouldSaveBeDisabled = () => {
    return state.file == null || state.projectId == null || state.isUploading || state.importFileType == null
  }

  const handleSave = () => {
    const { file, projectId } = state
    props.dismissResult()
    if (state.importFileType === springerApplicant) {
      const formData = new FormData()
      formData.append('TalentsFile', file)
      setState({ isUploading: true })
      props.importSpringerFile(projectId, formData)
    } else if (state.importFileType === koulutusporttiApplicant) {
      const formData = new FormData()
      formData.append('TalentsFile', file)
      setState({ isUploading: true })
      props.importKoulutusporttiFile(projectId, formData)
    }
  }

  const handleClose = () => {
    props.close()
    setState({})
  }

  const handleTemplateDownload = async (e) => {
    e.preventDefault()
    setState({ isDownloadingExampleFile: true })
    const template = await getImportApplicantsTemplate()
    if (template != null) setState({ isDownloadingExampleFile: false })
    else setState({ isDownloadingExampleFile: false })
  }

  const handleFileChange = (file) => setState({ file })

  const renderDownloadExampleFileLink = () => {
    // If basic springer import, example file can be downloaded
    if (state.importFileType === springerApplicant) {
      return (
        <Typography style={styles.link}>
          <Link href='#' onClick={e => handleTemplateDownload(e)}>
            {t('download_example_file')}
          </Link>
          {t('springer_import_help')}
        </Typography>
      )
    } else {
      return null
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      PaperProps={{ style: styles.root }}
      fullWidth
    >
      <DialogHeader
        handleClose={handleClose}
        label='import_applicants'
      />
      <DialogContent dividers>
        <div style={styles.dialogContentWrapper}>
          <FormControl>
            <InputLabel variant='standard'>{t('import_type')}</InputLabel>
            <Select
              variant='standard'
              value={state.importFileType || ''}
              onChange={(e) => setState({ importFileType: e.target.value })}
              fullWidth
            >
              <MenuItem value={springerApplicant} key='import-option1'>
                {t('springer_applicants')}
              </MenuItem>
              <MenuItem value={koulutusporttiApplicant} key='import-option2'>
                {t('koulutusportti_coachings')}
              </MenuItem>
            </Select>
          </FormControl>
          <div style={styles.columnWrapper}>
            {renderDownloadExampleFileLink()}
            {state.isDownloadingExampleFile &&
              <CircularProgress size={16} />}
          </div>
          <FileUploadInput
            ref={fileRef}
            onFileChange={handleFileChange}
          />
          <FormFieldWrapper>
            <Autocomplete
              value={state.serviceId}
              onChange={service => handleServiceChange(service)}
              options={[...props.services]}
              label={t('service')}
              noOptionsMessage={() => t('no_services_available')}
              placeholder={`${t('select')}...`}
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Typography variant='caption'>{t('project')}</Typography>
            <Autocomplete
              value={state.projectId}
              onChange={projectId => setProject(projectId)}
              options={getServiceProjectOptions()}
              noOptionsMessage={() => t('no_projects_available')}
              placeholder={`${t('select')}...`}
            />
          </FormFieldWrapper>
        </div>
      </DialogContent>
      <DialogActions style={{ root: styles.actionsWrapper }}>
        <Button onClick={handleClose} style={styles.cancelButton}>
          {t('cancel')}
        </Button>
        <SaveButton
          label='save'
          fetching={state.isUploading}
          disabled={shouldSaveBeDisabled()}
          handleSave={handleSave}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ApplicantFileUploadDialog
