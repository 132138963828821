import React from 'react'
import Autocomplete from './Autocomplete'

const areOptionsInitialized = (prevProps, nextProps) => {
  if (prevProps.options == null) return false
  return prevProps.options.length > 0 && nextProps.options.length > 0
}

const isTextSame = (prevProps, nextProps) => {
  /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
  /* DO NOT render if value has not changed and options have been initialized. So in short,
  this component TRUSTS that the options props DO NOT change.  */
  // Yeah, we CAN'T trust that the options don't change. They do change
  if (areOptionsInitialized(prevProps, nextProps) && prevProps.value === nextProps.value && prevProps.options.length === nextProps.options.length) return true
  else return false
}

// Use memoization to avoid unnecessary re-renderings (value not changed after options updated (they only get fetched once))
const MemoizedAutocomplete = React.memo((props) => {
  return (
    <Autocomplete {...props} />
  )
}, isTextSame)
export default MemoizedAutocomplete
