import React, { useState } from 'react'
import { truncate } from 'lodash'
import { useTranslation } from 'react-i18next'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import HelpIcon from '@mui/icons-material/Help'
import ExploreIcon from '@mui/icons-material/Explore'
import ExploreOffIcon from '@mui/icons-material/ExploreOff'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import EventAvailable from '@mui/icons-material/EventAvailable'
import Checkbox from '@mui/material/Checkbox'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TextField, Typography } from '@mui/material'
import { format } from 'date-fns'
/* Custom stuff */
import Autocomplete from '../Autocomplete'
import CustomDatePicker from '../DatePicker'
import ApplicantMenu from './ApplicantMenu'
import Spinner from '../Spinner'
import { COLORS } from '../../utils/colors'
import { getObjectNameTranslation, getLocale } from '../../utils/transforms'
import ToolTip from '../ToolTip'

const styles = {
  cellRow: {
    paddingTop: 4,
    paddingBottom: 4,
    display: 'flex',
    alignItems: 'center'
  },
  active: {
    color: COLORS.greenDarken,
    marginRight: 4
  },
  enrolled: {
    color: COLORS.orange,
    marginRight: 4
  },
  ended: {
    color: COLORS.red,
    marginRight: 4
  },
  contactIcon: {
    color: COLORS.darkGrey,
    fontSize: 12,
    marginRight: 1
  },
  link: {
    color: COLORS.grey,
    textDecoration: 'none',
    '&:hover': {
      color: COLORS.darkGrey
    }
  },
  empty: {
    color: COLORS.lightText
  },
  cell: {
    padding: '8px 16px'
  },
  infoCell: {
    padding: '8px 0px'
  },
  rowIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  datePicker: {
    width: '100%',
    '& input[type=text]': {
      fontSize: 12
    }
  },
  additionalInfoContent: {
    maxWidth: 620,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    wordBreak: 'break-word'
  },
  additionalInfo: {
    padding: 16,
    whiteSpace: 'pre-line'
  },
  textIcon: {
    marginRight: 8,
    verticalAlign: 'bottom'
  },
  cellMaxWidth: {
    maxWidth: 135
  },
  biggerCellMaxWidth: {
    maxWidth: 200
  },
  block: {
    display: 'block',
    wordWrap: 'break-word'
  }
}

const ApplicantRow = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [t] = useTranslation()

  const handleInfoClick = event => {
    setAnchorEl(event.currentTarget)
    props.getCoachingSummary(props.applicant.coachingId)
  }

  const handleInfoClose = () => setAnchorEl(null)

  const setApplicantStartDate = (date) => {
    const { userId, projectId, coachId } = props.applicant
    props.setStartDate(projectId, userId, coachId, date)
  }

  const setApplicantTrainer = (trainerId) => {
    const { userId, projectId, begin } = props.applicant
    props.setTrainer(projectId, userId, trainerId, begin)
  }

  const setSelectedApplicant = () => props.selectApplicant(props.applicant.userId, props.applicant.projectId, props.applicant.status)
  const checkIsApplicantSelected = () => props.isSelected(props.applicant.userId, props.applicant.projectId)

  const service = () => getObjectNameTranslation(props.applicant.serviceName)
  const project = () => getObjectNameTranslation(props.applicant.projectName)
  const area = () => props.applicant.areaId

  const endedOverTwoWeekAgo = (end) => {
    const endUnixTime = new Date(end).getTime()
    const unixTimeNow = new Date().getTime()
    /* Check if difference in milliseconds is over 2 weeks */
    return (unixTimeNow - endUnixTime) > 1209600000
  }

  const showApplicantDialog = () => {
    props.openApplicantDialog(props.applicant)
  }

  const showNotesDialog = () => {
    props.openNotesDialog(props.applicant)
  }

  const openRollbackDialog = () => {
    props.openRollback(props.applicant)
  }

  const openApplicantStatusDialog = () => {
    props.openApplicantStatusDialog(props.applicant)
  }

  const getRollbackText = () => {
    if (props.applicant && props.applicant.begin) {
      if (props.applicant.end) return t('rollbackToActive')
      return t('rollbackToEnrolled')
    }
  }

  const getCoachOptions = () => {
    const { coachOptions } = props.applicant
    return [...coachOptions].map(({ firstName, lastName, id }) =>
      ({ label: `${firstName} ${lastName}`, value: id })
    )
  }

  const toggleSafeMode = () => {
    if (props.applicant && !props.safeModeOn) {
      props.toggleSafeMode(props.applicant.coachingId)
    } else props.toggleSafeMode(null)
  }

  const renderInitialAssessmentStatus = () => {
    const { initialAssessmentStarted, initialAssessmentEnded, initialAssessmentCoach, initialAssessmentEnd } = props.applicant
    let Icon = null
    /* If no assessment data, return null */
    if (!initialAssessmentStarted && !initialAssessmentEnded) return null
    /* If assessment ended over two weeks ago, return null */
    else if (initialAssessmentEnded && endedOverTwoWeekAgo(initialAssessmentEnd)) {
      return null
    } else if (initialAssessmentEnded) {
      Icon = <ToolTip title={t('initial_assessment_ended')}><ExploreOffIcon fontSize='small' style={{ color: COLORS.green, display: 'flex', marginRight: '2px' }} /></ToolTip>
    } else if (initialAssessmentStarted) {
      Icon = <ToolTip title={t('initial_assessment_in_progress')}><ExploreIcon fontSize='small' style={{ color: COLORS.orange, display: 'flex', marginRight: '2px' }} /></ToolTip>
    }
    return (
      (initialAssessmentStarted || initialAssessmentEnded) &&
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '3px' }}>
          {Icon}
          <span>{`${initialAssessmentCoach.firstName} ${initialAssessmentCoach.lastName}`}</span>
        </div>
    )
  }

  const renderStatus = () => {
    const { begin, end, coachingResultName } = props.applicant
    const statusArray = getObjectNameTranslation(applicant.statusString).split(' ')
    const status = statusArray[0]
    const date = statusArray.length > 1 ? statusArray[1] : null
    const statusClass = end ? styles.ended : (begin ? styles.active : styles.enrolled)
    return (
      <div>
        <span style={statusClass}>{status}</span>
        <span style={styles.block}>{date}</span>
        {coachingResultName && (<span styles={styles.block}>{getObjectNameTranslation(coachingResultName)}</span>)}
        {renderInitialAssessmentStatus()}
      </div>
    )
  }

  const handleCopySummaryToClipBoard = () => {
    navigator.clipboard.writeText(props.coachingSummary)
  }

  const renderAdditionalInfo = () => {
    const { coachingSummary, loadingCoachingEntries } = props
    return (
      <div style={styles.rowIconContainer}>
        <ToolTip title={t('summary')}>
          <IconButton onClick={handleInfoClick}>
            <InfoIcon />
          </IconButton>
        </ToolTip>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleInfoClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <div style={styles.additionalInfoContent}>
            <Typography style={styles.additionalInfo}>
              {coachingSummary}
              {(!coachingSummary && loadingCoachingEntries) &&
                <Spinner />}
              {(!coachingSummary && !loadingCoachingEntries) &&
                t('no_entry_summary')}
            </Typography>
            {coachingSummary !== '' &&
              <ToolTip title={t('copy_text_to_clipboard')} style={{ position: 'sticky', top: '0' }}>
                <IconButton onClick={handleCopySummaryToClipBoard}>
                  <FileCopyIcon />
                </IconButton>
              </ToolTip>}
          </div>
        </Popover>
      </div>
    )
  }

  const handleNextMeetingClick = (event, nextMeeting) => {
    props.openCoachingEntriesDialog(props.applicant, nextMeeting)
  }

  const renderNextMeetingDescription = () => {
    const { nextMeeting } = props.applicant
    if (nextMeeting) {
      return (
        <div style={styles.cellRow}>
          <HelpIcon sx={styles.contactIcon} />
          <span>
            {truncate(nextMeeting.description, { length: 16, separator: ' ' }) || t('no_description')}
          </span>
        </div>
      )
    } else {
      return null
    }
  }

  const renderMeetingsInput = () => {
    const { nextMeeting } = props.applicant
    const meetingDate = nextMeeting && nextMeeting.start ? new Date(nextMeeting.start) : null
    const startAdornment = <EventAvailable sx={{ color: COLORS.darkGrey, fontSize: 12 }} />
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
        <DatePicker
          value={meetingDate}
          open={false}
          onChange={() => {}}
          onOpen={event => handleNextMeetingClick(event, nextMeeting)}
          inputFormat={t('date_format')}
          disableMaskedInput
          cancelLabel={t('cancel')}
          emptyLabel={t('no_future_meetings')}
          sx={styles.datePicker}
          renderInput={props => <TextField color='primary' {...props} sx={styles.datePicker} placeholder={getMeetingsLabel()} variant='standard' InputProps={{ ...props.InputProps, color: 'primary', endAdornment: '', startAdornment }} inputProps={{ ...props.inputProps, color: 'primary', readOnly: true, value: getMeetingsLabel() }} onClick={event => handleNextMeetingClick(event, nextMeeting)} />}
        />
      </LocalizationProvider>
    )
  }

  const getMeetingsLabel = () => {
    const { nextMeeting } = props.applicant
    if (nextMeeting) return format(new Date(nextMeeting.start), t('datetime_format'))
    return t('not_planned')
  }
  const { applicant } = props

  return (
    <TableRow selected={checkIsApplicantSelected()} hover data-testid={props.testId}>
      <TableCell padding='checkbox'>
        <Checkbox
          checked={checkIsApplicantSelected()}
          onChange={setSelectedApplicant}
          color='primary'
        />
      </TableCell>
      <TableCell sx={{ ...styles.cell, ...styles.cellMaxWidth }}>
        {applicant.firstNames} {applicant.lastName}
      </TableCell>
      <TableCell sx={styles.cell}>
        <HomeIcon sx={styles.contactIcon} />
        <span>{area()}</span>
      </TableCell>
      <TableCell sx={styles.cell}>
        <div style={styles.cellRow}>{project()}</div>
      </TableCell>
      <TableCell sx={styles.cell}>
        <div style={styles.cellRow}>{service()}</div>
      </TableCell>
      <TableCell sx={{ ...styles.cell, ...styles.biggerCellMaxWidth }}>
        {renderStatus()}
      </TableCell>
      <TableCell sx={styles.cell}>
        <Autocomplete
          options={getCoachOptions()}
          value={applicant.coachId}
          onChange={setApplicantTrainer}
          placeholder={t('select_trainer')}
          noOptionsMessage={() => t('no_trainers_available')}
        />
      </TableCell>
      <TableCell sx={styles.cell}>
        <CustomDatePicker
          value={applicant.begin}
          onChange={setApplicantStartDate}
          placeholder={t('start_date')}
        />
      </TableCell>
      <TableCell sx={{ ...styles.cell, ...styles.cellMaxWidth }}>
        {renderNextMeetingDescription()}
        <div style={{ ...styles.cellRow, ...styles.cellMaxWidth }}>
          {renderMeetingsInput()}
        </div>
      </TableCell>
      <TableCell sx={styles.infoCell}>
        {renderAdditionalInfo()}
      </TableCell>
      <TableCell sx={styles.infoCell}>
        <div style={styles.rowIconContainer}>
          <ApplicantMenu
            showApplicantDialog={showApplicantDialog}
            showCoachingEntriesDialog={event => props.openCoachingEntriesDialog(applicant, null)}
            showNotesDialog={showNotesDialog}
            openRollBack={openRollbackDialog}
            rollbackText={getRollbackText()}
            showApplicantStatusDialog={openApplicantStatusDialog}
            hasActiveNotes={props.applicant.activeCoachingNotes}
            toggleSafeMode={toggleSafeMode}
            safeModeOn={props.safeModeOn}
          />
        </div>
      </TableCell>
    </TableRow>
  )
}

export default ApplicantRow
