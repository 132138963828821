import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import { NavLink } from 'react-router-dom'
import Button from '@mui/material/Button'
import { COLORS } from '../utils/colors'

const styles = theme => ({
  navLink: {
    color: COLORS.black,
    margin: '0 5px',
    display: 'inline-block',
    verticalAlign: 'bottom'
  },
  navigationButton: {
    marginLeft: 10,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 30
    }
  },
  navText: {
    margin: 0,
    color: COLORS.black,
    fontSize: 14,
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  activeNav: {
    textDecoration: 'none',
    color: COLORS.orange,
    '& svg': {
      color: COLORS.orange
    }
  }
})

const NavLinkButton = (props) => {
  const { classes, t } = props
  return (
    <Button className={classes.navigationButton} onClick={props.onClick} color='inherit'>
      <NavLink
        to={props.path}
        className={({ isActive }) => isActive ? classes.activeNav : classes.navText}
      >
        {t(props.label)}
      </NavLink>
    </Button>
  )
}

export default withStyles(styles)(withTranslation()(NavLinkButton))
