import { call, put } from 'redux-saga/effects'
import * as UsersActions from '../redux/UsersRedux'
import * as ErrorActions from '../redux/ErrorRedux'
import { isOkResponse } from '../services/api'

export function * getMe (api, action) {
  try {
    const response = yield call(api.getMe)
    if (isOkResponse(response)) {
      const user = response.data
      yield put(UsersActions.getMeSuccess(user))
    } else {
      yield put(UsersActions.getMeFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(UsersActions.getMeFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * getAdmins (api, action) {
  try {
    const response = yield call(api.getAdmins)
    if (isOkResponse(response)) {
      const admins = response.data
      yield put(UsersActions.getAdminsSuccess(admins))
    } else {
      yield put(UsersActions.getAdminsFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(UsersActions.getAdminsFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}
