import React, { Fragment } from 'react'
import moment from 'moment'
import { withStyles } from '@mui/styles'
import { withTranslation } from 'react-i18next'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import SmsIcon from '@mui/icons-material/Sms'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import PhoneIcon from '@mui/icons-material/PhoneOutlined'
import ScheduleIcon from '@mui/icons-material/ScheduleOutlined'

import { COLORS } from '../../utils/colors'

const styles = theme => ({
  card: {
    marginBottom: 32
  },
  cellRow: {
    paddingTop: 4,
    paddingBottom: 4,
    display: 'flex',
    alignItems: 'center'
  },
  cellIcon: {
    color: COLORS.white,
    margin: '0 4px'
  },
  smallCellIcon: {
    color: COLORS.darkGrey,
    fontSize: 12,
    marginRight: 8
  },
  link: {
    color: COLORS.grey,
    textDecoration: 'none',
    '&:hover': {
      color: COLORS.darkGrey
    }
  },
  empty: {
    color: COLORS.lightText
  },
  leftCell: {
    padding: '8px 16px',
    width: 90
  },
  cell: {
    padding: '8px 16px'
  },
  active: {
    color: COLORS.greenDarken
  },
  enrolled: {
    color: COLORS.orange
  },
  ended: {
    color: COLORS.red
  },
  header: {
    background: COLORS.orange,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    cursor: 'pointer'
  },
  headerText: {
    color: COLORS.white
  },
  checkbox: {
    color: COLORS.white
  },
  additionalInfo: {
    padding: 16
  },
  additionalInfoContainer: {
    position: 'absolute',
    right: 24
  },
  additionalInfoIcon: {
    color: COLORS.white
  }
})

class MessageRowMobile extends React.Component {
  state = {
    anchorEl: null
  }

  isSms = (msg) => {
    return msg.messageType === 'SMS'
  }

  isEmail = (msg) => {
    return msg.messageType === 'Email'
  }

  renderSmsMobileRow = () => {
    const { classes, t, message } = this.props
    return (
      <Fragment key={message.id}>
        <TableRow>
          <TableCell className={classes.leftCell}>{t('recipient')}</TableCell>
          <TableCell className={classes.cell}>
            <div className={classes.cellRow}>
              <PhoneIcon className={classes.smallCellIcon} />
              {message.receiverPhoneNumber}
            </div>
            <div className={classes.cellRow}>
              <ScheduleIcon className={classes.smallCellIcon} />
              {moment(message.sentDateTime).format('DD.MM.YYYY HH:mm:ss')}
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.leftCell}>{t('message')}</TableCell>
          <TableCell className={classes.cell}>{message.plainTextMessage}</TableCell>
        </TableRow>
      </Fragment>
    )
  }

  renderEmailMobileRow = () => {
    const { classes, t, message } = this.props
    return (
      <Fragment key={message.id}>
        <TableRow>
          <TableCell className={classes.leftCell}>{t('recipient')}</TableCell>
          <TableCell className={classes.cell}>
            <div className={classes.cellRow}>
              <EmailIcon className={classes.smallCellIcon} />
              {message.receiverEmail}
            </div>
            <div className={classes.cellRow}>
              <ScheduleIcon className={classes.smallCellIcon} />
              {moment(message.sentDateTime).format('DD.MM.YYYY HH:mm:ss')}
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.leftCell}>{t('subject')}</TableCell>
          <TableCell className={classes.cell}>{message.subject}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.leftCell}>{t('message')}</TableCell>
          <TableCell className={classes.cell}>
            <Typography
              component='span' className={classes.rightColumn} color='textPrimary'
              dangerouslySetInnerHTML={{ __html: message.formattedMessage }}
            />
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }

  render () {
    const { classes, message } = this.props

    return (
      <Card className={classes.card}>
        <div className={classes.header}>
          {this.isSms(message) &&
            <SmsIcon className={classes.cellIcon} />}
          {this.isEmail(message) &&
            <EmailIcon className={classes.cellIcon} />}
          <Typography className={classes.headerText}>
            {`${message.receiverFirstNames} ${message.receiverLastName}`}
          </Typography>
        </div>
        <Table>
          <TableBody>
            {this.isSms(message) &&
              this.renderSmsMobileRow()}
            {this.isEmail(message) &&
              this.renderEmailMobileRow()}
          </TableBody>
        </Table>
      </Card>
    )
  }
}

export default withStyles(styles)(withTranslation()(MessageRowMobile))
