import { call, put } from 'redux-saga/effects'
import * as NotesActions from '../redux/NotesRedux'
import * as ApplicantsActions from '../redux/ApplicantsRedux'
import * as ErrorActions from '../redux/ErrorRedux'
import * as SuccessActions from '../redux/SuccessRedux'
import * as SavingActions from '../redux/SavingRedux'
import { isOkResponse } from '../services/api'
import { composeSuccess } from '../utils/transforms'

export function * getNotes (api, action) {
  try {
    const { applicantId } = action.payload
    const params = { coachingId: applicantId }
    const response = yield call(api.getNotes, params)
    if (isOkResponse(response)) {
      const notes = response.data
      yield put(NotesActions.getNotesSuccess(notes))
    } else {
      yield put(NotesActions.getNotesFailure())
      yield put(ErrorActions.displayError(response))
    }
  } catch (e) {
    yield put(NotesActions.getNotesFailure())
    yield put(ErrorActions.displayError(e.response))
  }
}

export function * activateNote (api, action) {
  yield put(SavingActions.setSaving(true))
  try {
    const { projectId, noteId, applicantId } = action.payload
    const response = yield call(api.activateCoachingNote, projectId, noteId)
    if (isOkResponse(response)) {
      // Show success toast
      const success = composeSuccess('note_activated')
      yield put(SuccessActions.displaySuccess(success))
      yield put(NotesActions.activateNoteSuccess())
      yield put(NotesActions.getNotesRequest(applicantId))
      yield put(SavingActions.setSaving(false))
      // Refresh applicants list so activeNotes property is updated on the list
      yield put(ApplicantsActions.getApplicants())
    } else {
      yield put(NotesActions.activateNoteFailure())
      yield put(ErrorActions.displayError(response))
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(NotesActions.activateNoteFailure())
    yield put(ErrorActions.displayError(e.response))
    yield put(SavingActions.setSaving(false))
  }
}

export function * passivateNote (api, action) {
  yield put(SavingActions.setSaving(true))
  try {
    const { projectId, noteId, applicantId } = action.payload
    const response = yield call(api.passivateCoachingNote, projectId, noteId)
    if (isOkResponse(response)) {
      // Show success toast
      const success = composeSuccess('note_passivated')
      yield put(SuccessActions.displaySuccess(success))
      yield put(NotesActions.passivateNoteSuccess())
      yield put(NotesActions.getNotesRequest(applicantId))
      yield put(SavingActions.setSaving(false))
      // Refresh applicants list so activeNotes property is updated on the list
      yield put(ApplicantsActions.getApplicants())
    } else {
      yield put(NotesActions.passivateNoteFailure())
      yield put(ErrorActions.displayError(response))
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(NotesActions.passivateNoteFailure())
    yield put(ErrorActions.displayError(e.response))
    yield put(SavingActions.setSaving(false))
  }
}

export function * editNote (api, action) {
  yield put(SavingActions.setSaving(true))
  const { note } = action.payload
  try {
    const response = yield call(api.editCoachingNote, note)
    if (isOkResponse(response)) {
      const success = composeSuccess('note_updated')
      yield put(SuccessActions.displaySuccess(success))
      yield put(NotesActions.editNoteSuccess())
      yield put(NotesActions.getNotesRequest(note.coachingId))
      yield put(SavingActions.setSaving(false))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(NotesActions.editNoteFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(NotesActions.editNoteFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * addNote (api, action) {
  yield put(SavingActions.setSaving(true))
  const { note } = action.payload
  try {
    const response = yield call(api.addCoachingNote, note)
    if (isOkResponse(response)) {
      const success = composeSuccess('note_updated')
      yield put(SuccessActions.displaySuccess(success))
      yield put(NotesActions.addNoteSuccess())
      yield put(NotesActions.getNotesRequest(note.coachingId))
      yield put(SavingActions.setSaving(false))
      // Refresh applicants list so activeNotes property is updated on the list
      yield put(ApplicantsActions.getApplicants())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(NotesActions.addNoteFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(NotesActions.addNoteFailure())
    yield put(SavingActions.setSaving(false))
  }
}
