import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 800
    }
  },
  tooltip: {
    fontSize: 14
  }
})

/* Show tooltip only on hover */
const ToolTip = (props) => {
  const { children, title } = props
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      classes={{ tooltip: props.classes.tooltip }}
      title={title}
      aria-labelledby={`tooltip-${title}`}
    >
      {children}
    </Tooltip>
  )
}

ToolTip.propTypes = {
  title: PropTypes.string
}

export default withStyles(styles)(withTranslation()(ToolTip))
