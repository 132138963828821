import React, { Fragment, useReducer, useEffect, useRef, useState } from 'react'
import { useTheme, withStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import CircularProgress from '@mui/material/CircularProgress'

import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '../TabPanel'
import DialogHeader from '../DialogHeader'
import Spinner from '../Spinner'
import { COLORS } from '../../utils/colors'
import ESCOAPI from '../../services/escoApi'
import EscoOccupationList from '../EscoOccupationList'
import DialogContentWrapper from '../DialogContentWrapper'
import ApplicantEdit from './ApplicantEdit'
import ApplicantLanguageSkills from '../languages/ApplicantLanguageSkills'
import AttachmentsList from '../attachments/AttachmentsList'
import FileUploadInput from '../FileUploadInput'
import ConfirmationDialog from '../ConfirmationDialog'
import { getCurrentLang, getObjectNameTranslation } from '../../utils/transforms'
import ApplicantInternships from '../internships/ApplicantInternships'
import { getCoachingAttachmentFile } from '../../services/api'
import { customReducer } from '../../utils/reducer'
import { useMediaQuery } from '@mui/material'

const styles = {
  root: {
    maxWidth: 800

  },
  textField: {
    marginTop: 8,
    marginBottom: 8
  },
  smallInput: {
    display: 'block'
  },
  multiline: {
    marginTop: 16
  },
  smallLabel: {
    marginTop: 8,
    color: COLORS.label
  },
  message: {
    padding: 16,
    backgroundColor: COLORS.light,
    borderRadius: 4,
    color: COLORS.darkGrey,
    fontSize: 12,
    margin: 8
  },
  actionsWrapper: {
    padding: 2,
    borderTop: `1px solid ${COLORS.lightBorder}`
  },
  cancelButton: {
    color: COLORS.red,
    marginRight: 2
  },
  attachmentsActions: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    alignSelf: 'flex-end'
  },
  marginTop20: {
    marginTop: 20
  },
  backgroundText: {
    whiteSpace: 'pre-line'
  },
  listRoot: {
    width: '100%',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  tab: {
    width: '50%',
    maxWidth: 'unset'
  },
  labelColor: {
    color: COLORS.label
  },
  occupationsText: {
    marginBottom: 1
  },
  spinner: {
    color: COLORS.white
  }
}

const ApplicantDialog = (props) => {
  const initialState = {
    firstNames: null,
    lastName: null,
    phoneNumber: null,
    email: null,
    areaId: null, // (city)
    additionalInfo: null,
    backgroundText: null,
    unemploymentDuration: null,
    workExperience: null,
    levelOfEducation: null,
    dateOfBirth: null,
    socialSecurityNumber: null,
    tabValue: 0,
    selectedOccupations: [],
    file: null,
    streetAddress: null,
    postalCode: null,
    postOffice: null,
    nativeLanguage: null,
    nationality: null,
    ordererName: null,
    isDownloadingFile: false,
    phoneNumberInImport: null,
    staffPointJobSeeker: false,
    attendances: null,
    studyPoints: null
  }
  const reducer = (state, newState) => customReducer(state, newState, initialState)
  const [state, setState] = useReducer(reducer, initialState)
  const [occupations, setOccupations] = useState([])
  const [t] = useTranslation()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const fileRef = useRef()

  useEffect(() => {
    /* Get ESCO occupations */
    ESCOAPI().getTopLevelConcepts(getCurrentLang()).then(data => {
      const arr = data || []
      setOccupations(arr)
      return data
    })
  }, []) // On component mount

  useEffect(() => {
    if (props.applicantInfo) {
      /* Set applicant information to edit field */
      setFormState(props.applicantInfo)
    }
  }, [props.applicantInfo])

  useEffect(() => {
    /* Close after applicant info save (confusing naming here with setApplicantInfoFetching) */
    if (!props.setApplicantInfoFetching && props.open) handleClose()
  }, [props.setApplicantInfoFetching])

  useEffect(() => {
    /* Set applicant target occupations to be compatible with list component  */
    const occupations = castToEscoOccupations(props.savedOccupations)
    setState({ selectedOccupations: occupations })
  }, [props.savedOccupations])

  const setFormState = (applicant) => {
    setState({
      ...props.applicantInfo,
      postalCodeId: props.applicantInfo.postalCode ? props.applicantInfo.postalCode.id : null,
      nativeLanguageId: props.applicantInfo.nativeLanguage ? props.applicantInfo.nativeLanguage.id : null,
      nationalityId: props.applicantInfo.nationality ? props.applicantInfo.nationality.id : null
    })
  }

  const fetchOccupationChildren = (uri) => {
    return ESCOAPI().getChildOccupations(uri, getCurrentLang()).then(data => data)
  }

  /* Cast springer api professions to Esco occupations that the list component understands */
  const castToEscoOccupations = (professions) => {
    return professions.map(profession => {
      return {
        uri: profession.escoUri,
        code: profession.iscoGroupCode,
        title: getProfessionTitleByUserLanguage(profession),
        preferredLabel: {
          fi: profession.nameFi,
          en: profession.nameEn
        }
      }
    })
  }

  const downloadAttachment = async (file) => {
    setState({ isDownloadingFile: true })

    const downloadedAttachment = await getCoachingAttachmentFile(props.coaching.coachingId, file.coachingFileId, file.filename)
    if (downloadedAttachment != null) setState({ isDownloadingFile: false })
    else setState({ isDownloadingFile: false })
  }

  const getProfessionTitleByUserLanguage = (profession) => {
    if (getCurrentLang() === 'fi') return profession.nameFi
    else return profession.nameEn
  }

  const resetState = () => setState({})

  const handleClose = () => {
    resetState()
    props.onClose()
  }

  const handleApplicantSave = () => {
    // Detect changes and trigger actions
    const { applicantInfo } = props
    const birthDay = state.dateOfBirth
    const params = {
      talentId: applicantInfo.userId,
      projectId: applicantInfo.projectId,
      firstNames: state.firstNames,
      lastName: state.lastName,
      phoneNumber: state.phoneNumber,
      email: state.email,
      municipalityOfResidence: state.areaId === 'N/A' ? null : state.areaId,
      additionalInfo: state.additionalInfo,
      dateOfBirth: birthDay ? moment.tz(birthDay, 'Europe/Helsinki').utc(true).format() : null,
      levelOfEducation: state.levelOfEducation,
      workExperience: state.workExperience,
      unemploymentDuration: state.unemploymentDuration,
      socialSecurityNumber: state.socialSecurityNumber === '' ? null : state.socialSecurityNumber,
      streetAddress: state.streetAddress,
      postalCodeId: state.postalCodeId,
      nativeLanguageId: state.nativeLanguageId,
      nationalityId: state.nationalityId,
      ordererName: state.ordererName,
      staffPointJobSeeker: state.staffPointJobSeeker,
      attendances: state.attendances,
      studyPoints: state.studyPoints
    }
    // Save changed values
    props.setApplicantInfo(params)
  }

  const setNewApplicantState = (newState) => {
    setState({ ...newState })
  }

  const handleOccupationsSave = () => {
    const { applicantInfo, onOccupationsSave } = props
    const { selectedOccupations } = state
    const professions = {
      targetProfessions: selectedOccupations.map(occu => {
        return {
          escoUri: occu.uri,
          iscoGroupCode: occu.code,
          nameFi: occu.preferredLabel.fi,
          nameEn: occu.preferredLabel.en
        }
      })
    }
    onOccupationsSave(applicantInfo.userId, professions)
  }

  const handleAttachmentSave = () => {
    const { coaching } = props
    const { file } = state
    const formData = new FormData()
    formData.append('File', file)
    props.addAttachmentFile(coaching.projectId, coaching.coachingId, formData)
    fileRef.current.resetInput()
  }

  const handleTabChange = (event, newValue) => {
    setState({ tabValue: newValue })
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const handleFileChange = (file) => setState({ file })

  const isOccupationSelected = (occup) => state.selectedOccupations.find(selected => selected.code === occup.code) != null

  const handleOccupationCheck = (occupation) => {
    if (isOccupationSelected(occupation)) {
      /* Remove selected occupations from component state */
      const occupationsWithoutUncheckedOccupation = state.selectedOccupations.filter(selected => selected.code !== occupation.code)
      setState({ selectedOccupations: occupationsWithoutUncheckedOccupation })
    } else {
      /* Set selected occupation to state */
      const occupationsWithNewlyChecked = [...state.selectedOccupations, occupation]
      setState({ selectedOccupations: occupationsWithNewlyChecked })
    }
  }

  const openConfirmFileDelete = (fileForDelete) => {
    setState({ fileForDelete, isConfirmFileDeleteDialogOpen: true })
  }

  const closeConfirmFileDelete = () => {
    setState({ fileForDelete: null, isConfirmFileDeleteDialogOpen: false })
  }

  const handleAttachmentDelete = () => {
    const { fileForDelete } = state
    const { coaching } = props
    props.deleteCoachingAttachment(coaching.projectId, coaching.coachingId, fileForDelete.coachingFileId)
    closeConfirmFileDelete()
  }

  const openConfirmLanguageSkillDelete = (applicantLanguageForDelete) => {
    setState({ applicantLanguageForDelete, isConfirmApplicantLanguageDeleteDialogOpen: true })
  }

  const closeConfirmLanguageSkillDelete = () => {
    setState({ applicantLanguageForDelete: null, isConfirmApplicantLanguageDeleteDialogOpen: false })
  }

  const openConfirmInternshipDelete = (internshipForDelete) => {
    setState({ internshipForDelete, isConfirmInternshipDeleteDialogOpen: true })
  }

  const closeConfirmInternshipDelete = () => {
    setState({ internshipForDelete: null, isConfirmInternshipDeleteDialogOpen: false })
  }

  const handleLanguageSkillDelete = () => {
    const { applicantLanguageForDelete } = state
    const { coaching } = props
    props.deleteApplicantLanguage(coaching.projectId, applicantLanguageForDelete.id, coaching.coachingId)
    closeConfirmLanguageSkillDelete()
  }

  const handleInternshipDelete = () => {
    const { internshipForDelete } = state
    const { coaching } = props
    props.deleteInternship(coaching.projectId, internshipForDelete.id, coaching.coachingId)
    closeConfirmInternshipDelete()
  }

  const renderSavedOccupations = () => {
    if (props.savedOccupations.length > 0) {
      const langKey = getCurrentLang() === 'fi' ? 'nameFi' : 'nameEn'
      return (
        <Fragment key='saved-occupations'>
          <Typography sx={styles.labelColor}>{t('saved_target_occupations')}:</Typography>
          <Typography sx={styles.occupationsText}>
            {props.savedOccupations.reduce((accumulator, occupation, index, src) => {
              const startOfText = accumulator === '' ? '' : `${accumulator}; `
              return startOfText + occupation[langKey]
            }, '')}
          </Typography>
        </Fragment>
      )
    } else {
      return (
        <Typography sx={{ ...styles.occupationsText, ...styles.labelColor }}>{t('no_saved_target_occupation')}</Typography>
      )
    }
  }

  const renderTargetOccupations = () => {
    const { selectedOccupations } = state
    if (occupations.length > 0) {
      return (
        <DialogContentWrapper>
          {renderSavedOccupations()}
          <div style={styles.listRoot}>
            <EscoOccupationList
              occupations={occupations}
              topLevel={2}
              selectable
              fetchOccupationChildren={fetchOccupationChildren}
              onOccupationCheck={handleOccupationCheck}
              selectedOccupations={selectedOccupations}
              badges
            />
          </div>
        </DialogContentWrapper>
      )
    } else {
      return <Spinner />
    }
  }

  const renderTargetOccupationsButtons = () => {
    return (
      <DialogActions sx={styles.actionsWrapper}>
        <Button onClick={handleClose} sx={styles.cancelButton}>
          {t('cancel')}
        </Button>
        <Button
          onClick={handleOccupationsSave}
          variant='contained'
          color='primary'
        >
          {renderButtonContent('save_occupations')}
        </Button>
      </DialogActions>
    )
  }

  const renderBasicInformationContent = () => {
    const { fetching, applicantInfo, regionsWithCities, searchPostalCodes, searchedPostalCodes, searchingPostalCodes, clearSearchedPostalCodes, countries, getCountries, languages, getLanguages } = props
    if (!fetching && applicantInfo) {
      return (
        <ApplicantEdit
          applicantState={state}
          onFormChange={setNewApplicantState}
          regionsWithCities={regionsWithCities}
          searchPostalCodes={searchPostalCodes}
          searchedPostalCodes={searchedPostalCodes}
          searchingOptions={searchingPostalCodes}
          countries={countries}
          getCountries={getCountries}
          languages={languages}
          getLanguages={getLanguages}
          clearSearchedPostalCodes={clearSearchedPostalCodes}
        />
      )
    } else {
      return (
        <Spinner centered />
      )
    }
  }

  const renderLanguageSkills = () => {
    const { coaching } = props
    return (
      <ApplicantLanguageSkills
        coachingId={coaching ? coaching.coachingId : null}
        projectId={coaching ? coaching.projectId : null}
        getLanguageSkills={props.getLanguageSkills}
        getApplicantLanguageSkills={props.getApplicantLanguageSkills}
        getLanguageSkillLevels={props.getLanguageSkillLevels}
        languageSkills={props.languageSkills}
        applicantLanguageSkills={props.applicantLanguageSkills}
        languageSkillLevelOptions={props.languageSkillLevelOptions}
        saveLanguageSkills={props.saveLanguageSkills}
        savingLanguages={props.savingLanguages}
        confirmLanguageSkillDelete={openConfirmLanguageSkillDelete}
      />
    )
  }

  const renderButtonContent = (saveButtonText) => {
    const { setApplicantInfoFetching, fetching, attachmentLoading } = props
    if (setApplicantInfoFetching || fetching || attachmentLoading) {
      return <CircularProgress sx={styles.spinner} size={16} />
    }
    return t(saveButtonText)
  }

  const renderBasicInformationButtons = () => {
    const { setApplicantInfoFetching } = props
    return (
      <DialogActions sx={styles.actionsWrapper}>
        <Button onClick={handleClose} sx={styles.cancelButton}>
          {t('cancel')}
        </Button>
        <Button
          onClick={handleApplicantSave}
          variant='contained'
          color='primary'
          disabled={setApplicantInfoFetching}
        >
          {renderButtonContent('save_basic_information')}
        </Button>
      </DialogActions>
    )
  }

  const renderAttachmentFiles = () => {
    const { attachments, coaching } = props

    return (
      <DialogContentWrapper>
        <Typography variant='h6' gutterBottom>{t('upload_new_attachment')}</Typography>
        <FileUploadInput
          ref={fileRef}
          onFileChange={handleFileChange}
        />
        <div style={styles.attachmentsActions}>
          <Button onClick={handleClose} sx={styles.cancelButton}>
            {t('cancel')}
          </Button>
          <Button
            onClick={handleAttachmentSave}
            variant='contained'
            color='primary'
            disabled={props.attachmentLoading || !state.file}
          >
            {renderButtonContent('save_attachment')}
          </Button>
        </div>
        <Typography variant='h6' sx={{ marginTop: 2 }}>{t('coaching_attachments')}</Typography>
        <AttachmentsList
          items={attachments}
          coachingId={coaching ? coaching.coachingId : null}
          confirmDeleteFile={file => openConfirmFileDelete(file)}
          downloadAttachment={downloadAttachment}
          isDownloadingFile={state.isDownloadingFile}
          emptyListMessage='no_coaching_attachments'
        />
      </DialogContentWrapper>
    )
  }

  const renderInternships = () => {
    return (
      <ApplicantInternships
        loading={props.fetching}
        coachingId={props.coaching ? props.coaching.coachingId : null}
        projectId={props.coaching ? props.coaching.projectId : null}
        cooperationTypeOptions={props.cooperationTypeOptions}
        getCooperationTypes={props.getCooperationTypes}
        createInternship={props.createInternship}
        savingInternship={props.savingInternship}
        getApplicantInternships={props.getApplicantInternships}
        applicantInternships={props.applicantInternships}
        editInternship={props.editInternship}
        confirmDelete={openConfirmInternshipDelete}
      />
    )
  }

  const renderDialogActions = () => {
    if (state.tabValue === 0) {
      return renderBasicInformationButtons()
    } else if (state.tabValue === 2) {
      return renderTargetOccupationsButtons()
    } else return null
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={handleClose}
      PaperProps={{ sx: styles.root, id: 'ApplicantDialogContainer' }}
      fullWidth
      scroll='paper'

    >
      <DialogHeader
        handleClose={handleClose}
        label='applicant_info'
      />
      <AppBar position='static' color='secondary'>
        <Tabs value={state.tabValue} onChange={handleTabChange} aria-label='simple tabs example' indicatorColor='primary' variant='scrollable'>
          <Tab label={t('basic_information')} sx={{ minWidth: 'fit-content', flex: 1 }} {...a11yProps(0)} />
          <Tab label={t('language_skills')} sx={{ minWidth: 'fit-content', flex: 1 }} {...a11yProps(1)} />
          <Tab label={t('target_occupations')} sx={{ minWidth: 'fit-content', flex: 1 }} {...a11yProps(2)} />
          <Tab label={t('attachment_files')} sx={{ minWidth: 'fit-content', flex: 1 }} {...a11yProps(3)} />
          <Tab label={t('internship')} sx={{ minWidth: 'fit-content', flex: 1 }} {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <DialogContent dividers>
        <TabPanel value={state.tabValue} index={0}>
          {renderBasicInformationContent()}
        </TabPanel>
        <TabPanel value={state.tabValue} index={1}>
          {renderLanguageSkills()}
        </TabPanel>
        <TabPanel value={state.tabValue} index={2}>
          {renderTargetOccupations()}
        </TabPanel>
        <TabPanel value={state.tabValue} index={3}>
          {renderAttachmentFiles()}
        </TabPanel>
        <TabPanel value={state.tabValue} index={4}>
          {renderInternships()}
        </TabPanel>
      </DialogContent>
      {renderDialogActions()}
      <ConfirmationDialog
        isOpen={state.isConfirmFileDeleteDialogOpen}
        title={t('delete_attachment')}
        message={`${t('delete_coaching_file_confirmation')} ${state.fileForDelete ? state.fileForDelete.filename : ''}`}
        close={closeConfirmFileDelete}
        handleOk={handleAttachmentDelete}
      />
      <ConfirmationDialog
        isOpen={state.isConfirmApplicantLanguageDeleteDialogOpen}
        title={t('delete_language_skill')}
        message={`${t('delete_language_skill_confirmation')} ${state.applicantLanguageForDelete ? getObjectNameTranslation(state.applicantLanguageForDelete.language.name) : ''}?`}
        close={closeConfirmLanguageSkillDelete}
        handleOk={handleLanguageSkillDelete}
      />
      <ConfirmationDialog
        isOpen={state.isConfirmInternshipDeleteDialogOpen}
        title={t('delete_internship')}
        message={`${t('delete_internship_confirmation')} ${state.internshipForDelete ? state.internshipForDelete.companyName : ''}?`}
        close={closeConfirmInternshipDelete}
        handleOk={handleInternshipDelete}
      />
    </Dialog>
  )
}

export default withStyles(styles)(ApplicantDialog)
