import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import TopHeader from './TopHeader'

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: '80px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '90px 10px'
    },
    maxWidth: '100%'
  }
})

class Layout extends Component {
  render () {
    const { classes, children, user } = this.props

    return (
      <div className={classes.root}>
        <TopHeader user={user} />
        <main className={classes.content}>
          {/* <div className={classes.toolbar} /> */}
          {children}
        </main>
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(Layout))
