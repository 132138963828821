import { call, put, select } from 'redux-saga/effects'
import * as ProjectsActions from '../redux/ProjectsRedux'
import * as ErrorActions from '../redux/ErrorRedux'
import * as SuccessActions from '../redux/SuccessRedux'
import * as SavingActions from '../redux/SavingRedux'
import { composeSuccess, composeProjectFilters } from '../utils/transforms'
import { isOkResponse } from '../services/api'

export function * getProjects (api, action) {
  const projectsState = (state) => state.projects
  const { page, pageSize, projectFilters } = yield select(projectsState)
  const apiPage = page - 1
  const filters = composeProjectFilters(projectFilters)

  const params = {
    ...filters,
    skip: apiPage * pageSize,
    take: pageSize
  }
  try {
    const response = yield call(api.getProjects, params)
    if (isOkResponse(response)) {
      const projects = response.data.list
      const projectsTotal = response.data.totalCount
      const allIds = response.data.allIds
      yield put(ProjectsActions.getProjectsSuccess(projects, projectsTotal, allIds))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ProjectsActions.getProjectsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ProjectsActions.getProjectsFailure())
  }
}

export function * setProjectFilters (api, action) {
  yield put(ProjectsActions.getProjects())
}

export function * changeProjectsPage (action) {
  const { page } = action.payload
  const projectsState = (state) => state.projects
  const { projects, pageSize } = yield select(projectsState)

  if ((page * pageSize) >= projects.length) {
    yield put(ProjectsActions.getProjects())
  }
}

export function * changeProjectRowsPerPage (action) {
  yield put(ProjectsActions.reloadVisibleProjects())
}

export function * reloadVisibleProjects (api, action) {
  const projectsState = (state) => state.projects
  const { page, projectFilters, pageSize } = yield select(projectsState)
  const filters = composeProjectFilters(projectFilters)

  const params = {
    ...filters,
    skip: ((page - 1) * pageSize),
    take: pageSize
  }

  try {
    const response = yield call(api.getProjects, params)
    if (isOkResponse(response)) {
      const projects = response.data.list
      const projectsTotal = response.data.totalCount
      const allIds = response.data.allIds
      yield put(ProjectsActions.reloadVisibleProjectsSuccess(projects, projectsTotal, allIds))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ProjectsActions.reloadVisibleProjectsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ProjectsActions.reloadVisibleProjectsFailure())
  }
}

/* Use this after creating new project! Because this fetches all the projects already
fetched and updates them to state. This is incase user adds a project which should be listed in the pages
before the current page the user is on. */
export function * reloadAllFetchedProjects (api, action) {
  const projectsState = (state) => state.projects
  const { page, projectFilters, pageSize } = yield select(projectsState)
  const filters = composeProjectFilters(projectFilters)
  const params = {
    ...filters,
    skip: 0,
    take: (page * pageSize)
  }
  try {
    const response = yield call(api.getProjects, params)

    if (isOkResponse(response)) {
      const projects = response.data.list
      const projectsTotal = response.data.totalCount
      const allIds = response.data.allIds
      yield put(ProjectsActions.reloadAllFetchedProjectsSuccess(projects, projectsTotal, allIds))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ProjectsActions.reloadAllFetchedProjectsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ProjectsActions.reloadAllFetchedProjectsFailure())
  }
}

export function * getProjectsOptions (api, action) {
  try {
    const response = yield call(api.getProjectsOptions)
    if (isOkResponse(response)) {
      const projectsOptions = response.data
      yield put(ProjectsActions.getProjectsOptionsSuccess(projectsOptions))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ProjectsActions.getProjectsOptionsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ProjectsActions.getProjectsOptionsFailure())
  }
}

export function * createProject (api, action) {
  yield put(SavingActions.setSaving(true))
  const { project } = action.payload
  try {
    const response = yield call(api.createProject, project)
    if (isOkResponse(response)) {
      const success = composeSuccess('project_created')
      yield put(SuccessActions.displaySuccess(success))
      yield put(ProjectsActions.createProjectSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(ProjectsActions.reloadAllFetchedProjects())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ProjectsActions.createProjectFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ProjectsActions.createProjectFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * editProject (api, action) {
  yield put(SavingActions.setSaving(true))
  const { project } = action.payload
  try {
    const response = yield call(api.editProject, project)
    if (isOkResponse(response)) {
      const success = composeSuccess('project_updated')
      yield put(SuccessActions.displaySuccess(success))
      yield put(ProjectsActions.editProjectSuccess())
      yield put(SavingActions.setSaving(false))
      yield put(ProjectsActions.reloadAllFetchedProjects())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ProjectsActions.editProjectFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ProjectsActions.editProjectFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * getProject (api, action) {
  const { projectId } = action.payload
  try {
    const response = yield call(api.getProject, projectId)

    if (isOkResponse(response)) {
      const project = response.data
      yield put(ProjectsActions.getProjectSuccess(project))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ProjectsActions.getProjectFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ProjectsActions.getProjectFailure())
  }
}

export function * getProjectsInCity (api, action) {
  const { city } = action.payload
  try {
    const response = yield call(api.getProjectsInCity, city)
    if (isOkResponse(response)) {
      const projectsInCity = response.data
      yield put(ProjectsActions.getProjectsInCitySuccess(projectsInCity))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ProjectsActions.getProjectsInCityFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ProjectsActions.getProjectsInCityFailure())
  }
}

export function * getFilteredProjectsOptions (api, action) {
  const { projectFilters } = action.payload
  const filters = composeProjectFilters(projectFilters)

  const params = {
    ...filters,
    skip: 0,
    take: filters.take || 1000 // Surely service won't have more than 1000 projects, right?
  }
  try {
    const response = yield call(api.getProjects, params)
    if (isOkResponse(response)) {
      const filteredProjectsOptions = response.data.list
      yield put(ProjectsActions.getFilteredProjectsOptionsSuccess(filteredProjectsOptions))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(ProjectsActions.getFilteredProjectsOptionsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(ProjectsActions.getFilteredProjectsOptionsFailure())
  }
}
