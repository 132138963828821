import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import orderBy from 'lodash/orderBy'
import { COLORS } from '../../utils/colors'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import LoadingButton from '@mui/lab/LoadingButton'
import DoneIcon from '@mui/icons-material/Done'
import { Button, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { formatEntriesToString } from '../../utils/transforms'
const styles = {
  container: {
    display: 'flex',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: COLORS.white,
    alignItems: 'center'
  }
}

const CopyEntries = (props) => {
  const [t] = useTranslation()
  const [copying, setCopying] = useState(false)
  const [copied, setCopied] = useState(false)
  const [copyDate, setCopyDate] = useState(true)
  const [copyName, setCopyName] = useState(true)
  const [copyMeetings, setCopyMeetings] = useState(true)
  const [copyNotes, setCopyNotes] = useState(true)
  const [copyType, setCopyType] = useState(true)
  const [sortBy, setSortBy] = useState('descending')
  const { entries } = props
  const handleCopyEntriesToClipBoard = () => {
    try {
      setCopied(false)
      setCopying(true)
      let sortedEntries = entries.filter((entry) =>
        (copyNotes && entry.entryType === 'Note') || (copyMeetings && entry.entryType === 'Meeting'))
      if (sortBy === 'descending') sortedEntries = orderBy(sortedEntries, 'start', 'desc')
      else sortedEntries = orderBy(sortedEntries, 'start', 'asc')
      const settings = { name: copyName, date: copyDate, type: copyType }
      navigator.clipboard.writeText(formatEntriesToString(sortedEntries, settings, t))
      setCopied(true)
      setCopying(false)
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Grid style={styles.container} container spacing={1}>
      <Grid container item style={{ alignItems: 'center' }} xs={4} sm={3} md={2} sx={{ marginTop: 1, border: '1px solid lightgrey', borderRadius: 3 }} justifyContent='end'>
        <Typography variant='caption'>{t('meeting')}
          <Checkbox
            checked={copyMeetings}
            onChange={() => {
              setCopyMeetings(!copyMeetings)
              setCopied(false)
            }}
          />
        </Typography>
        <Typography variant='caption'>{t('note')}
          <Checkbox
            checked={copyNotes}
            onChange={() => {
              setCopyNotes(!copyNotes)
              setCopied(false)
            }}
          />
        </Typography>
      </Grid>
      <Grid container item xs={6} sm={5} md={2.5} style={{ alignItems: 'center' }} sx={{ marginTop: 1, marginLeft: 1, border: '1px solid lightgrey', borderRadius: 3 }} justifyContent='end'>
        <Typography variant='caption'>{t('description')}
          <Checkbox disabled checked />
        </Typography>
        <Typography variant='caption'>{t('trainer')}
          <Checkbox
            checked={copyName}
            onChange={() => {
              setCopyName(!copyName)
              setCopied(false)
            }}
          />
        </Typography>
        <Typography variant='caption'>{t('date')}
          <Checkbox
            checked={copyDate}
            onChange={() => {
              setCopyDate(!copyDate)
              setCopied(false)
            }}
          />
        </Typography>
        <Typography variant='caption'>{t('entry_type')}
          <Checkbox
            checked={copyType}
            onChange={() => {
              setCopyType(!copyType)
              setCopied(false)
            }}
          />
        </Typography>
      </Grid>
      <Grid container item xs={4} sm={3} md={3.7}>
        <RadioGroup
          row
          style={{ alignItems: 'center' }}
          sx={{ marginLeft: 1, padding: 1, border: '1px solid lightgrey', borderRadius: 3 }}
          value={sortBy}
          onChange={(e, value) => {
            setSortBy(value)
            setCopied(false)
          }}
        >
          <FormControlLabel labelPlacement='start' value='descending' control={<Radio />} label={t('date_descending')} />
          <FormControlLabel labelPlacement='start' value='ascending' control={<Radio />} label={t('date_ascending')} />
        </RadioGroup>
      </Grid>
      {!copied &&
        <Grid item xs={10} sm={10} md={3.5} style={{ display: 'grid' }}>
          <LoadingButton onClick={handleCopyEntriesToClipBoard} loading={copying} loadingPosition='end' variant='filled' endIcon={<FileCopyIcon />}>
            {t('copy_entries_to_clipboard')}
          </LoadingButton>
        </Grid>}
      {copied &&
        <Grid item container style={{ display: 'grid' }} xs={10} sm={10} md={4}>
          <Button onClick={handleCopyEntriesToClipBoard} variant='filled' style={{ color: COLORS.white, backgroundColor: COLORS.green }} endIcon={<DoneIcon />}>
            {t('copied')}
          </Button>
        </Grid>}

    </Grid>
  )
}

export default CopyEntries
