import { call, put, select } from 'redux-saga/effects'
import moment from 'moment'
import * as MeetingsActions from '../redux/MeetingsRedux'
import * as ApplicantsActions from '../redux/ApplicantsRedux'
import * as ErrorActions from '../redux/ErrorRedux'
import * as SuccessActions from '../redux/SuccessRedux'
import * as SavingActions from '../redux/SavingRedux'
import * as CoachingEntriesActions from '../redux/CoachingEntriesRedux'
import { composeSuccess, getDateTimeForApi } from '../utils/transforms'
import { isOkResponse } from '../services/api'

export function * getMeetings (api, action) {
  const meetingsState = (state) => state.meetings
  const { meetingsFilters } = yield select(meetingsState)

  try {
    const response = yield call(api.getMeetings, meetingsFilters)
    if (isOkResponse(response)) {
      const meetings = response.data
      yield put(MeetingsActions.getMeetingsSuccess(meetings))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(MeetingsActions.getMeetingsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(MeetingsActions.getMeetingsFailure())
  }
}

export function * createMeeting (api, action) {
  yield put(SavingActions.setSaving(true))
  const { newMeeting } = action.payload
  const params = {
    start: getDateTimeForApi(newMeeting.start),
    end: getDateTimeForApi(newMeeting.end),
    description: newMeeting.description,
    talent: newMeeting.talent,
    coach: newMeeting.coach
  }

  try {
    const response = yield call(api.createMeeting, newMeeting.projectId, params)
    if (isOkResponse(response)) {
      const success = composeSuccess('meeting_created')
      yield put(SuccessActions.displaySuccess(success))
      yield put(MeetingsActions.createMeetingSuccess())
      yield put(SavingActions.setSaving(false))
      // Get applicants in case next meeting edited and also meetings with stateful filters
      yield put(ApplicantsActions.getApplicants())
      yield put(MeetingsActions.getMeetings())
      yield put(MeetingsActions.getAllMeetings())
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(MeetingsActions.createMeetingFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(MeetingsActions.createMeetingFailure())
  }
}

export function * editMeeting (api, action) {
  yield put(SavingActions.setSaving(true))
  const { meeting } = action.payload
  const params = {
    start: getDateTimeForApi(meeting.start),
    end: getDateTimeForApi(meeting.end),
    description: meeting.description,
    coach: meeting.coach
  }

  try {
    const response = yield call(api.editMeeting, meeting.projectId, meeting.meetingId, params)
    if (isOkResponse(response)) {
      const success = composeSuccess('meeting_edited')
      yield put(SuccessActions.displaySuccess(success))
      yield put(MeetingsActions.editMeetingSuccess())
      yield put(SavingActions.setSaving(false))
      // Get applicants in case next meeting edited and also meetings with stateful filters
      yield put(ApplicantsActions.getApplicants())
      yield put(MeetingsActions.getMeetings())
      yield put(MeetingsActions.getAllMeetings())
      /* Get entries as well */
      yield put(CoachingEntriesActions.getCoachingEntries({ coachingId: meeting.coachingId }))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(MeetingsActions.editMeetingFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(MeetingsActions.editMeetingFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * cancelMeeting (api, action) {
  yield put(SavingActions.setSaving(true))
  const { meeting } = action.payload
  try {
    const response = yield call(api.cancelMeeting, meeting.projectId, meeting.meetingId, meeting)
    if (isOkResponse(response)) {
      const success = composeSuccess('meeting_canceled')
      yield put(SuccessActions.displaySuccess(success))
      yield put(MeetingsActions.cancelMeetingSuccess())
      yield put(SavingActions.setSaving(false))
      // Get applicants in case next meeting canceled and also meetings with stateful filters
      yield put(ApplicantsActions.getApplicants())
      yield put(MeetingsActions.getMeetings())
      yield put(MeetingsActions.getAllMeetings())
      /* Get entries as well */
      yield put(CoachingEntriesActions.getCoachingEntries({ coachingId: meeting.coachingId }))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(MeetingsActions.cancelMeetingFailure())
      yield put(SavingActions.setSaving(false))
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(MeetingsActions.cancelMeetingFailure())
    yield put(SavingActions.setSaving(false))
  }
}

export function * getAllMeetings (api, action) {
  const meetingsState = (state) => state.meetings
  const { meetingsFilters } = yield select(meetingsState)
  const allMeetingsFilters = { ...meetingsFilters }
  const wayback = moment().subtract(10, 'years').format('YYYY-MM-DD') + 'T00:00:00'
  const faraway = moment().add(10, 'years').format('YYYY-MM-DD') + 'T23:59:59'
  allMeetingsFilters.start = wayback
  allMeetingsFilters.end = faraway
  try {
    const response = yield call(api.getMeetings, allMeetingsFilters)
    if (isOkResponse(response)) {
      const meetings = response.data
      yield put(MeetingsActions.getAllMeetingsSuccess(meetings))
    } else {
      yield put(ErrorActions.displayError(response))
      yield put(MeetingsActions.getAllMeetingsFailure())
    }
  } catch (e) {
    yield put(ErrorActions.displayError(e.response))
    yield put(MeetingsActions.getAllMeetingsFailure())
  }
}
