import React from 'react'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import MuiAC from '@mui/material/Autocomplete'
import { alpha } from '@mui/material'
import { Box } from '@mui/system'
import withMediaQuery from '../utils/withMediaQuery'

export function getTrainerOptions (coaches) {
  const trainers = []
  if (coaches && coaches.length > 0) {
    coaches.forEach((coach) => {
      const label = `${coach.firstNames} ${coach.lastName}`
      trainers.push({ label, value: coach.userId })
    })
  }
  return trainers
}

export function getAdminOptions (coaches) {
  const trainers = []
  if (coaches && coaches.length > 0) {
    coaches.forEach((coach) => {
      const label = `${coach.firstName} ${coach.lastName}`
      trainers.push({ label, value: coach.id })
    })
  }
  return trainers
}

const styles = {
  container: {
    marginTop: 16
  },
  input: {
    display: 'flex',
    padding: '6px 0'
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  noOptionsMessage: {
    fontSize: 16
  },
  singleValue: {
    fontSize: 12
  },
  largeSingleValue: {
    fontSize: 16
  },
  smallPlaceholder: {
    position: 'absolute',
    left: 2,
    fontSize: 12
  },
  placeholder: {
    fontSize: '0.875rem'
  },
  disabled: {
    opacity: 0.33
  },
  muiSelect: {
    display: 'flex',
    fontSize: 12
  },
  largeMuiSelect: {
    display: 'flex',
    fontSize: '0.875rem'
  },
  formMargin: {
    marginTop: 0
  }
}
class Autocomplete extends React.Component {
  handleOnChange = (e, item) => {
    if (item) this.props.onChange(item.value)
    else this.props.onChange(null)
  }

  renderDesktop = (isMobile = false) => {
    const { value, placeholder, options, disabled, noOptionsMessage, margin, required } = this.props
    const containerClass = disabled ? styles.disabled : null
    const marginClass = isMobile ? null : margin === 'form' ? styles.formMargin : null
    const placeholderText = required ? `${placeholder} *` : placeholder
    return (
      <Box sx={{ ...containerClass, ...marginClass }}>
        <MuiAC
          groupBy={this.props?.groupBy}
          sx={isMobile ? styles.muiSelect : styles.largeMuiSelec}
          options={options}
          value={options.find((item) => item && item.value === value) || null}
          onChange={this.handleOnChange}
          onOpen={this.props.onOpen}
          placeholder={placeholderText}
          disabled={disabled}
          size={this.props.largeText ? 'medium' : 'small'}
          renderOption={options.length === 1
            ? (params, option) => {
                return (
                  <Typography {...params} sx={this.props.largeText ? { ...styles.largeSingleValue } : { ...styles.singleValue }}>
                    {option.label}
                  </Typography>
                )
              }
            : (params, option) => {
                return (
                  <MenuItem
                    selected={params.isFocused}
                    style={{
                      fontWeight: params.isSelected ? 500 : 400,
                      padding: 8
                    }}
                    {...params}
                  >
                    {option.label}
                  </MenuItem>
                )
              }}
          noOptionsText={noOptionsMessage ? noOptionsMessage() : 'No options'}
          ListboxProps={{ style: { color: alpha('#000000', 0.87) } }}
          renderInput={this.props.renderInput ? this.props.renderInput : (params) => <TextField {...params} variant='standard' inputProps={{ sx: this.props.largeText ? { fontSize: 'medium' } : { fontSize: 'small' }, ...params.inputProps }} label={this.props.label} placeholder={placeholderText} sx={styles.input} />}
        />
      </Box>
    )
  }

  render () {
    return (
      <div>
        {!this.props.themeSMUp &&
          <Box>
            {this.renderDesktop(true)}
          </Box>}
        {this.props.themeSMUp &&
          <Box>
            {this.renderDesktop()}
          </Box>}
      </div>
    )
  }
}

export default withMediaQuery(Autocomplete)
