import React from 'react'
import { format } from 'date-fns'
import i18next from 'i18next'
import moment from 'moment-timezone'
import fiLocale from 'date-fns/locale/fi'
import enLocale from 'date-fns/locale/en-US'
import { languages } from '../i18n/i18n'
import { TIMEOUT_ERROR, CONNECTION_ERROR, NETWORK_ERROR } from 'apisauce'
import isEmpty from 'lodash/isEmpty'
import { DateTime } from 'luxon'

const formatDateStart = (dateObj) => moment(dateObj).format('YYYY-MM-DD') + 'T00:00:00'
const formatDateEnd = (dateObj) => moment(dateObj).format('YYYY-MM-DD') + 'T23:59:59'

export const copyDate = (date) => new Date(+date) // Convert to numeric type

export const formatToHelsinkiDateTime = (date) => moment.tz(date, 'Europe/Helsinki').utc(true).format()

export const formatDateToFinnishFormat = (date) => format(new Date(date), 'dd.MM.yyyy HH:mm')

export const composeApplicantFilters = (state) => {
  const filters = {}

  // Begin date
  if (state.startDateStart) filters.beginDateStart = formatDateStart(state.startDateStart)
  if (state.startDateEnd) filters.beginDateEnd = formatDateEnd(state.startDateEnd)

  // End date
  if (state.endDateStart) filters.endDateStart = formatDateStart(state.endDateStart)
  if (state.endDateEnd) filters.endDateEnd = formatDateEnd(state.endDateEnd)

  // Areas
  if (state.hometowns && state.hometowns.length) filters.areas = state.hometowns.asMutable().map(({ value }) => value)

  // Statuses
  if (state.status) filters.statuses = [state.status]

  // Coaching results
  if (state.coachingResult) filters.results = [state.coachingResult]

  // Coaches
  if (state.trainers && state.trainers.length) filters.coaches = state.trainers.map(({ value }) => value)

  // Coaches
  if (state.noCoachOnly) filters.noCoachOnly = state.noCoachOnly

  // Services
  if (state.services && state.services.length) filters.serviceIds = state.services.map(({ value }) => value)

  // Services
  if (state.projects && state.projects.length) filters.projectIds = state.projects.map(({ value }) => value)

  // Additional info
  if (state.searchString) filters.searchString = state.searchString

  // Name
  if (state.nameSearchString) filters.nameSearchString = state.nameSearchString

  // Initial assessment state
  if (state.assessmentState) filters.initialAssessmentFiltering = state.assessmentState

  // Initial assessment coaches
  if (state.assessmentCoaches && state.assessmentCoaches.length) filters.initialAssessmentCoaches = state.assessmentCoaches.map(({ value }) => value)

  // Order by
  if (state.orderBy) filters.orderBy = state.orderBy

  if (state.selectedOccupations && state.selectedOccupations.length) filters.iscoGroupCodes = state.selectedOccupations.map(occupation => occupation.code)

  if (state.socialSecurityNumber) filters.socialSecurityNumber = state.socialSecurityNumber

  if (state.dateOfBirth) filters.dateOfBirth = state.dateOfBirth

  return filters
}

export const decomposeApplicantFilters = (propsFilters) => {
  const filters = {}

  // Begin date
  if (propsFilters.beginDateStart) filters.startDateStart = formatDateStart(propsFilters.beginDateStart)
  if (propsFilters.beginDateEnd) filters.startDateEnd = formatDateEnd(propsFilters.beginDateEnd)

  // End date
  if (propsFilters.endDateStart) filters.endDateStart = formatDateStart(propsFilters.endDateStart)
  if (propsFilters.endDateEnd) filters.endDateEnd = formatDateEnd(propsFilters.endDateEnd)

  // Areas
  if (propsFilters.areas && propsFilters.areas.length) filters.hometowns = propsFilters.areas.asMutable()

  // Statuses
  if (propsFilters.statuses) filters.status = propsFilters.statuses[0]

  // Coaches
  if (propsFilters.coaches && propsFilters.coaches.length) filters.trainers = propsFilters.coaches.asMutable()

  // Services
  if (propsFilters.serviceIds && propsFilters.serviceIds.length) filters.services = propsFilters.serviceIds.asMutable()

  // Projects
  if (propsFilters.projectIds && propsFilters.projectIds.length) filters.projects = propsFilters.projectIds

  // Additional info
  if (propsFilters.searchString) filters.searchString = propsFilters.searchString

  // Name
  if (propsFilters.nameSearchString) filters.nameSearchString = propsFilters.nameSearchString

  // Order by
  if (propsFilters.orderBy) filters.orderBy = propsFilters.orderBy

  return filters
}

export const composeMessageFilters = (state) => {
  const filters = {}
  if (state.nameSearchString) filters.nameSearchString = state.nameSearchString
  if (state.senderNameSearchString) filters.senderNameSearchString = state.senderNameSearchString

  return filters
}

export const composeServiceFilters = (state) => {
  const filters = {}
  if (state.startDateStart) filters.beginDateStart = formatDateStart(state.startDateStart)
  if (state.startDateEnd) filters.beginDateEnd = formatDateEnd(state.startDateEnd)
  if (state.endDateStart) filters.endDateStart = formatDateStart(state.endDateStart)
  if (state.endDateEnd) filters.endDateEnd = formatDateEnd(state.endDateEnd)
  if (state.activity) filters.activity = state.activity
  if (state.searchString) filters.searchString = state.searchString

  return filters
}

export const composeProjectFilters = (state) => {
  const filters = {}
  if (isEmpty(state)) return {}
  if (state.startDateStart) filters.beginDateStart = formatDateStart(state.startDateStart)
  if (state.startDateEnd) filters.beginDateEnd = formatDateEnd(state.startDateEnd)
  if (state.endDateStart) filters.endDateStart = formatDateStart(state.endDateStart)
  if (state.endDateEnd) filters.endDateEnd = formatDateEnd(state.endDateEnd)
  if (state.activity) filters.activity = state.activity
  if (state.searchString) filters.searchString = state.searchString
  if (state.regions && state.regions.length > 0) filters.areas = state.regions.map(({ value }) => value)
  if (state.services && state.services.length > 0) filters.services = state.services.map(({ value }) => value)

  return filters
}

// Takes an { en, fi } object and returns the correct property based on client's language
export const getObjectNameTranslation = (obj) => {
  if (obj == null) return ''
  // Use Finnish or fallback to English
  if (i18next.language && i18next.language.substr(0, 2) === 'fi') {
    return obj.fi || obj.en
  }

  // Use English or fallback to Finnish
  return obj.en || obj.fi
}

// Convert apisauce response object into error object that is used to display errors in UI
export const composeError = (response) => {
  console.error(response)
  const { problem, success } = response
  if (success) {
    // Response was ok, no error to display
    return null
  }

  if ([TIMEOUT_ERROR, CONNECTION_ERROR, NETWORK_ERROR].includes(problem)) {
    // Network error
    return {
      messageKey: 'network_error'
    }
  }

  // API responded with error object
  if (response.data && typeof response.data === 'object') {
    // If has message failType, use it
    if (response.data.failType === 'AlreadyExists') {
      return {
        messageKey: 'data_already_exists'
      }
    }
    // If has message object, use it
    if (response.data.message) {
      const activeLang = getCurrentLang()
      let translatedMessage = response.data.message[activeLang]
      if (translatedMessage == null && typeof response.data.message !== 'string') {
        // When response.data.message is not a string, it means it's an object
        translatedMessage = response.data.message[getOtherLanguage()]
      } else if (typeof response.data.message === 'string') {
        // response.data.message is a string which we can use
        translatedMessage = response.data.message
      }
      return {
        message: translatedMessage
      }
    }

    // Otherwise flatten error object into string
    const message = Object.keys(response.data).map((key) => {
      return response.data[key]
    }).join(', ')

    return {
      message
    }
  }

  // Handle unknown errors
  return {
    messageKey: 'error'
  }
}

export const composeImportErrors = (response) => {
  if (response != null) {
    if ([TIMEOUT_ERROR, CONNECTION_ERROR, NETWORK_ERROR].includes(response.problem)) {
      // Network error
      return {
        messageKey: 'network_error'
      }
    }
    // API responded with error array
    if (response.data && Array.isArray(response.data)) {
      // Get all the errors
      const message = response.data.map((rowErrors, idx) => {
        const rowSpecificErrors = rowErrors.errors.map((errorMsg) => errorMsg).join(' ')
        return <p key={`row-error-${idx}`}>Errors at row {rowErrors.rowNumber}: {rowSpecificErrors}</p>
      })
      // Set a wrapper to set row errors on new lines
      const wrapper = <div style={{ flexDirection: 'row' }}>{message}</div>
      return {
        message: wrapper
      }
    }
  }

  // Handle unknown errors
  return {
    messageKey: 'error'
  }
}

export const composeSuccess = (msg) => {
  return {
    messageKey: msg
  }
}

export const getCurrentLang = () => {
  const availableLanguages = languages.map(({ value }) => value)
  const currentLang = i18next.language.substr(0, 2)

  if (availableLanguages.includes(currentLang)) {
    return currentLang
  }
  return languages[0].value // Fallback to default lang
}

const getOtherLanguage = () => {
  const availableLanguages = languages.map(({ value }) => value)
  const currentLang = i18next.language.substr(0, 2)
  const otherLang = availableLanguages.find(lang => lang !== currentLang)
  return otherLang
}

export const getLocale = () => {
  if (getCurrentLang() === 'fi') return fiLocale
  return enLocale
}

export const formatDate = (date) => {
  if (date) return format(date, 'dd.MM.yyyy')
  else return null
}

export const getUserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export const getCoachOptions = (coachOptions) => {
  return [...coachOptions].map(({ firstName, lastName, id }) =>
    ({ label: `${firstName} ${lastName}`, value: id })
  )
}

export const removeNullValuedProperties = (obj) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] === null) {
        delete obj[key]
      }
    }
  }
  return obj
}

export 
/**
 * Returns a string of linebreak seperated entries
 *
 * @param entries Array of entry objects
 * @param settings Object with settings date, type and name. Determines what is returned
 * @param t i18next [t] funtion from useTranslation()  
 */
const formatEntriesToString =(entries,settings,t) => {
  let str =''
  let str2
  entries.forEach(entry => {
    str2=''
    if(settings.date) str+=format(new Date(entry.start), t('date_format'))
    if(settings.type){
      str2+= ' - '
      if(entry.isCancelled)str2+=t('cancelled')+' ' 
      str2+= t(entry.entryType.toLowerCase())
  }
    if(settings.name)str2+= ` - ${entry.coachFirstNames} ${entry.coachLastName}`
    if(entry.description && entry.description!=="")str2+='\n'+entry.description
    str2+='\n\n'
    if(str2!=='\n\n') str+=str2
  }
  )
  return str
}

export const getDateTimeForApi = (date) => {
  try{
  return DateTime.fromJSDate(date).toString()
}
catch{
  return date
}
}

/* This is used in internship create and edit */
export const oldGetDateTimeForApi = (date) => {
  const dateTime = date != null ? moment.tz(date, 'Europe/Helsinki').utc(true).format() : null
  return dateTime
}
/**
 * Get the count of filled filters for object
 *
 * @returns
 */
export const getFilterCount = (filters) => {
  /* TÄÄLLÄ VÄHENNÄ FILTEREISTÄ COACHINGID */
  if (isEmpty(filters)) return 0
  const clone = Object.assign({}, filters, { orderBy: undefined })
  return Object.values(clone).reduce((acc, value) => {
    if ((value != null && value != '') || (Array.isArray(value) && value.length !== 0)) {
      return acc + 1
    } else return acc
  }, 0)
}

export const castToSelectOptions = (objects, dontAddEmptyValueToStart) => {
  const options = objects.map(obj => {
    return {
      label: getObjectNameTranslation(obj.name),
      value: obj.id
    }
  })
  // If this param is missing or set to true, add empty value to start of list,
  if (!dontAddEmptyValueToStart) options.unshift({ label: '-', value: 'empty' })
  return options
}

export const castToCoachingLanguageApiObject = (form, skillOptions) => {
  const skills = []
  /* Search API fetched skillOptions for correct form ids. Why? Because frontend dev Harri thought that it's better to have
  static UI components on the page rather than dynamically create them on the fly per every language. Better on UX and performance stand point
  (dynamic DOM tree changes are costly performance wise and user doesn't need to wait for API fetch to be done to see something). */
  const languageSkillRefs = {
    speaking: skillOptions.find(skill => skill.name.fi === 'Puhuminen'),
    writing: skillOptions.find(skill => skill.name.fi === 'Kirjoittaminen'),
    speachUnderstanding: skillOptions.find(skill => skill.name.fi === 'Kuullun ymmärtäminen'),
    readingUnderstanding: skillOptions.find(skill => skill.name.fi === 'Luetun ymmärtäminen')
  }
  // If form value is empty, remove object from array so it is removed from coaching
  if (form.speaking && form.speaking !== 'empty') {
    skills.push({
      coachingLanguageSkillId: form.coachingLanguageId,
      languageSkillId: languageSkillRefs.speaking.id,
      languageSkillLevelId: form.speaking
    })
  }
  if (form.writing && form.writing !== 'empty') {
    skills.push({
      coachingLanguageSkillId: form.coachingLanguageId,
      languageSkillId: languageSkillRefs.writing.id,
      languageSkillLevelId: form.writing
    })
  }
  if (form.speachUnderstanding && form.speachUnderstanding !== 'empty') {
    skills.push({
      coachingLanguageSkillId: form.coachingLanguageId,
      languageSkillId: languageSkillRefs.speachUnderstanding.id,
      languageSkillLevelId: form.speachUnderstanding
    })
  }
  if (form.readingUnderstanding && form.readingUnderstanding !== 'empty') {
    skills.push({
      coachingLanguageSkillId: form.coachingLanguageId,
      languageSkillId: languageSkillRefs.readingUnderstanding.id,
      languageSkillLevelId: form.readingUnderstanding
    })
  }
  return skills
}

export const castCoachingLanguageApiObjectToFormObject = (skills) => {
  if (!skills) return null
  const skillsObject = {
    speaking: skills.find(skill => skill.languageSkill.name.fi === 'Puhuminen'),
    writing: skills.find(skill => skill.languageSkill.name.fi === 'Kirjoittaminen'),
    speachUnderstanding: skills.find(skill => skill.languageSkill.name.fi === 'Kuullun ymmärtäminen'),
    readingUnderstanding: skills.find(skill => skill.languageSkill.name.fi === 'Luetun ymmärtäminen')
  }
  return {
    speaking: skillsObject.speaking ? skillsObject.speaking.languageSkillLevel.id : null,
    writing: skillsObject.writing ? skillsObject.writing.languageSkillLevel.id : null,
    speachUnderstanding: skillsObject.speachUnderstanding ? skillsObject.speachUnderstanding.languageSkillLevel.id : null,
    readingUnderstanding: skillsObject.readingUnderstanding ? skillsObject.readingUnderstanding.languageSkillLevel.id : null
  }
}

export /**
 * Returns true if end date is before start date
 *
 * @param {*} start
 * @param {*} end
 */
const isDifferenceInDaysNegative = (start, end) => {
  // Parse the entries
  const startDate = Date.parse(start)
  const endDate = Date.parse(end)
  // Make sure they are valid
  if (isNaN(startDate)) {
    return false
  }
  if (isNaN(endDate)) {
    return false
  }
  // Check the date range, 86400000 is the number of milliseconds in one day
  const difference = (endDate - startDate) / (86400000 * 7)
  if (difference < 0) {
    return true
  }
  return false
}
