import React from 'react'
import { withTranslation } from 'react-i18next'
import { addYears, format, isValid, subYears } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import fiLocale from 'date-fns/locale/fi'
import enLocale from 'date-fns/locale/en-US'
import { getCurrentLang } from '../utils/transforms'
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { COLORS } from '../utils/colors'
class CustomDatePicker extends React.Component {
  getLocale = () => {
    if (getCurrentLang() === 'fi') return fiLocale
    return enLocale
  }

  getLabel = () => {
    const { value, placeholder, required } = this.props
    const isValidDate = value ? isValid(new Date(value)) : null
    if (isValidDate) return format(new Date(value), 'dd.MM.yyyy')
    return `${placeholder} ${required ? '*' : ''}`
  }

  getMinDate = () => {
    const { minDate } = this.props
    if (minDate) return minDate
    return subYears(new Date(), 10)
  }

  getMaxDate = () => {
    const { maxDate } = this.props
    if (maxDate) return maxDate
    return addYears(new Date(), 10)
  }

  getPlaceholderText = () => {
    return this.props.placeholder
  }

  render () {
    const { style, t, value, disabled } = this.props
    const fvalue = value === null ? null : new Date(value)
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={this.getLocale()}>
        <DatePicker
          toolbarFormat='EEE, LLL d'
          toolbarTitle=''
          value={fvalue}
          onChange={this.props?.onChange}
          mask={t('date_format_mask')}
          inputFormat={t('date_format')}
          cancelLabel={t('cancel')}
          sx={{ width: 122, ...style }}
          minDate={this.getMinDate()}
          maxDate={this.getMaxDate()}
          disabled={disabled}
          DialogProps={{ sx: { color: 'primary', fontSize: 'small', '& .MuiPickersToolbar-root': { width: '100%', backgroundColor: COLORS.green, color: COLORS.white }, '& .MuiTypography-root': { color: COLORS.white } } }}
          renderInput={props => <TextField {...props} placeholder={this.getLabel()} variant='standard' InputProps={{ ...props.InputProps, sx: { fontSize: 12 }, color: 'primary' }} inputProps={{ ...props.inputProps, placeholder: this.getPlaceholderText(), color: 'primary' }} />}
        />
      </LocalizationProvider>
    )
  }
}

export default withTranslation()(CustomDatePicker)
