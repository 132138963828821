import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, TextField } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import Autocomplete from '../Autocomplete'
import EntryTypeSelect from './EntryTypeSelect'
import { COLORS } from '../../utils/colors'
import { getLocale, getCoachOptions } from '../../utils/transforms'
import { note as noteConstant } from '../../utils/entries'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const styles = {
  content: {
    padding: '16px 24px',
    display: 'flex'
  },
  calendar: {
    width: 312,
    display: 'flex',
    border: `1px solid ${COLORS.lightBorder}`
  },
  actionsHolder: {
    justifyContent: 'space-between'
  },
  redColor: {
    color: COLORS.red
  },
  cancelMeeting: {
    color: COLORS.white
  },
  marginRight: {
    marginRight: 10
  },
  spinner: {
    color: COLORS.white
  }
}

const EditNoteForm = (props) => {
  const [t] = useTranslation()
  const { applicant, entry, loading } = props
  const initialState = {
    startCalendarOpen: false,
    coach: '',
    start: null,
    description: '',
    meetingId: '',
    projectId: applicant ? applicant.projectId : '',
    cancelReason: '',
    cancelledByTalent: false,
    entryType: noteConstant
  }
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)

  const handleResetState = () => {
    setState({
      coach: entry.coachId,
      start: new Date(entry.start),
      description: entry.description,
      meetingId: entry.id,
      entryType: noteConstant
    })
  }

  useEffect(() => {
    if (props.entry) {
      handleResetState()
    }
  }, [props.entry])

  const handleStartCalendarOpen = (e) => {
    setState({ startCalendarOpen: !state.startCalendarOpen })
  }

  const handleNewMeetingStartChange = (val) => {
    setState({ start: new Date(val) })
  }

  const handleCoachChange = (val) => setState({ coach: val })

  const handleDescriptionChange = (event) => {
    setState({ description: event.target.value })
  }

  const handleSave = () => {
    if (!loading) props.onEditEntry(state)
  }

  const getCoaches = () => applicant ? getCoachOptions(applicant.coachOptions) : []

  const getName = () => applicant ? `${applicant.firstNames} ${applicant.lastName}` : ''

  const validateEditForm = () => state.coach === '' || state.start == null

  const renderLoaderButtonContent = (text) => {
    if (loading) {
      return <CircularProgress style={styles.spinner} size={16} />
    }
    return t(text)
  }

  const renderEditButtons = () => {
    return (
      <DialogActions style={styles.actionsHolder}>
        <div>
          <Button onClick={props.onDeleteEntry} style={styles.redColor} disabled={props.loading}>
            {t('delete_entry')}
          </Button>
        </div>
        <div>
          <Button onClick={handleResetState} style={styles.marginRight}>
            {t('cancel')}
          </Button>
          <Button
            onClick={handleSave}
            variant='contained'
            color='primary'
            style={styles.sendButton}
            disabled={validateEditForm()}
          >
            {renderLoaderButtonContent('save')}
          </Button>
        </div>
      </DialogActions>
    )
  }

  return (
    <Box>
      <form autoComplete='off'>
        <EntryTypeSelect
          value={noteConstant}
          disabled
        />
        <TextField
          value={getName()}
          label={t('applicant')}
          disabled
          margin='normal'
          fullWidth
          variant='standard'
        />
        <Autocomplete
          options={getCoaches()}
          value={state.coach}
          onChange={handleCoachChange}
          placeholder={`${t('select_trainer')} *`}
          noOptionsMessage={() => t('no_trainers_available')}
          margin='normal'
        />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
          <DatePicker
            value={state.start}
            open={state.startCalendarOpen}
            onOpen={handleStartCalendarOpen}
            onClose={handleStartCalendarOpen}
            onChange={handleNewMeetingStartChange}
            inputFormat={t('datetime_format')}
            mask={t('datetime_format_mask')}
            cancelLabel={t('cancel')}
            emptyLabel={`${t('start_datetime')} *`}
            style={styles.marginRight}
            invalidDateMessage={t('invalid_date_format')}
            margin='normal'
            ampm={false}
            minDate={!props.isAdmin && new Date()}
            renderInput={props => <TextField {...props} label={t('start_datetime')} variant='standard' InputProps={{ ...props.InputProps, color: 'primary' }} inputProps={{ ...props.inputProps, color: 'primary' }} />}
          />
        </LocalizationProvider>
        <TextField
          value={state.description}
          onChange={handleDescriptionChange}
          label={t('description')}
          fullWidth
          multiline
          margin='normal'
          variant='standard'
        />
        {renderEditButtons()}
      </form>
    </Box>
  )
}

export default EditNoteForm
