import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import TablePagination from '@mui/material/TablePagination'

const styles = theme => ({
})

class SpringerTablePagination extends Component {
  labelDisplayedRows = ({ from, to, count }) => {
    const { pageSize } = this.props
    const numPages = Math.ceil(count / pageSize)
    const currentPage = Math.floor(from / pageSize) + 1
    return `${currentPage} / ${numPages}`
  }

  handleChangePage = (event, page) => this.props.handleChangePage(page + 1)
  handleChangeRowsPerPage = (event) => this.props.handleChangeRowsPerPage(event.target.value)

  render () {
    const { t, count, page, fetching, pageSize, labelRowsPerPage } = this.props
    // Disable next button when fetching or at last page
    const nextDisabled = fetching || page >= (Math.ceil(count / pageSize) - 1)

    return (
      <TablePagination
        component='div'
        count={count}
        rowsPerPage={pageSize}
        page={page}
        backIconButtonProps={{ 'aria-label': t('previous_page') }}
        nextIconButtonProps={{ 'aria-label': t('next_page'), disabled: nextDisabled }}
        rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
        labelDisplayedRows={this.labelDisplayedRows}
        labelRowsPerPage={labelRowsPerPage}
      />
    )
  }
}

export default withStyles(styles)(withTranslation()(SpringerTablePagination))
