import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, Typography } from '@mui/material'
import Select from '@mui/material/Select'
import { COLORS } from '../../utils/colors'
import { meeting, note, summary } from '../../utils/entries'

const styles = {
  root: {
    maxWidth: 800,
    alignItems: 'center'
  },
  content: {
    padding: '16px 24px',
    display: 'flex'
  },
  actionsWrapper: {
    padding: 16
  },
  spinner: {
    color: COLORS.white
  },
  entryBox: {
    minHeight: 200
  },
  label: {
    color: COLORS.label
  }
}

const EntryTypeSelect = (props) => {
  const [t] = useTranslation()

  const handleEntryTypeChange = (e) => props.onTypeChange(e.target.value)

  const getEntryTypeOptions = () => {
    const entryTypes = [
      <MenuItem value='' key='0'>-</MenuItem>,
      <MenuItem value={meeting} key='Meeting'>{t('meeting')}</MenuItem>,
      <MenuItem value={note} key='Note'>{t('note')}</MenuItem>,
      <MenuItem value={summary} key='Summary'>{t('summary')}</MenuItem>
    ]
    return entryTypes
  }

  return (
    <Fragment key='entry-type'>
      <Typography style={styles.label} variant='caption'>{t('type')}</Typography>
      <Select
        value={props.value || ''}
        onChange={handleEntryTypeChange}
        fullWidth
        disabled={props.disabled}
        variant='standard'
      >
        {getEntryTypeOptions()}
      </Select>
    </Fragment>
  )
}

export default EntryTypeSelect
