import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import LanguageSkillRow from './LanguageSkillRow'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { TableBody } from '@mui/material'

const useStyles = makeStyles(theme => ({
  languageRow: {
    display: 'flex',
    flexDirection: 'column'
  },
  cell: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  },
  tableHead: {
    minWidth: 120
  },
  smallTableHead: {
    maxWidth: 60,
    width: 60
  }
}))
const finnishLanguage = 130

const ApplicantLanguageSkills = (props) => {
  const classes = useStyles()
  const [t] = useTranslation()

  useEffect(() => {
    props.getApplicantLanguageSkills(props.coachingId)
    props.getLanguageSkillLevels()
    props.getLanguageSkills()
  }, [])

  const handleConfirmLanguageSkillDelete = (applicantLanguageId) => {
    const applicantLanguage = props.applicantLanguageSkills.find(lang => lang.id === applicantLanguageId)
    props.confirmLanguageSkillDelete(applicantLanguage)
  }
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className={classes.tableHead}>
            <div className={classes.cell}>{t('language')}</div>
          </TableCell>
          <TableCell className={classes.tableHead}>
            <div className={classes.cell}>{t('speaking')}</div>
          </TableCell>
          <TableCell className={classes.tableHead}>
            <div className={classes.cell}>{t('speach_understanding')}</div>
          </TableCell>
          <TableCell className={classes.tableHead}>
            <div className={classes.cell}>{t('writing')}</div>
          </TableCell>
          <TableCell className={classes.tableHead}>
            <div className={classes.cell}>{t('reading_understanding')}</div>
          </TableCell>
          <TableCell className={classes.smallTableHead} />
        </TableRow>
        {/* Languages */}
        <TableRow>
          <TableCell colSpan={6} padding='none'>
            <LanguageSkillRow
              coachingId={props.coachingId}
              projectId={props.projectId}
              languageId={finnishLanguage}
              applicantLanguageSkill={props.applicantLanguageSkills.length > 0 ? props.applicantLanguageSkills[0] : null}
              languageSkills={props.languageSkills}
              languageSkillLevelOptions={props.languageSkillLevelOptions}
              savingLanguages={props.savingLanguages}
              saveLanguageSkills={props.saveLanguageSkills}
              confirmDelete={handleConfirmLanguageSkillDelete}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
export default ApplicantLanguageSkills
